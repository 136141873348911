// @vendors
import styled from 'styled-components';
// @components
import Box from '../../Box';
// @hocs
import withMediaQuery from '../../../hoc/withMediaQuery';
// @assets
import captainIconImg from '../../../assets/images/captain_icon.svg';
import playerInFieldIcon from '../../../assets/images/lineup_player_infield.svg';
import playerInSubIcon from '../../../assets/images/lineup_player_insub.svg';

export const LineupBg = styled.img`
  width: 100%;
  display: block;
  pointer-events: none;
  @media (min-width: 320px) and (max-width: 767px) {
    width: 125%;
    position: absolute;
    left: -12.5%;
    top: 0;
  }
`;

export const StyledLineupPlayer = withMediaQuery(styled.img`
  width: auto;
  ${({ isMobile }) => `
    max-width: ${isMobile ? '2rem' : '3rem'};
  `}
  cursor: pointer;
  display: inline-block;
  ${({ isNotEmpty, isMobile }) =>
    isNotEmpty &&
    `
      max-width: ${isMobile ? '2rem' : '3rem'};
      border-radius: 50%;
      background-color: #fff;
    `}
`);

export const CaptainIcon = styled.img.attrs({ src: captainIconImg })`
  position: absolute;
  top: 0;
  left: -0.75rem;
  width: 1.5rem;
  @media (min-width: 320px) and (max-width: 767px) {
    top: 0;
    left: -0.25rem;
    width: 0.75rem;
  }
  ${({ mini }) =>
    mini &&
    `
    top: 0;
    inset-inline-start: 1.75rem;
    width: 0.875rem;
  `}
`;

export const ReplacementIcon = styled.img.attrs(({ isInField }) => ({
  src: isInField ? playerInFieldIcon : playerInSubIcon,
}))`
  position: absolute;
  top: 0;
  right: -0.75rem;
  width: 1.5rem;
  @media (min-width: 320px) and (max-width: 767px) {
    top: 0;
    left: -0.25rem;
    width: 0.75rem;
  }
  ${({ mini }) =>
    mini &&
    `
    top: 0;
    inset-inline-start: 1.75rem;
    width: 0.875rem;
  `}
`;

export const CardInfoIcon = styled.img`
  width: 100%;
  cursor: pointer;
  grid-area: info;
`;

export const CardPlayerImage = styled.img`
  display: inline-block;
  width: 2.25rem;
  height: 2.25rem;
`;

export const FieldPlayerContainer = styled(Box)`
  @media (min-width: 320px) and (max-width: 767px) {
    .field-player-options-btn {
      font-size: 0.5rem;
      padding: 0.125rem;
      line-height: 1;
    }
  }
`;
