import React, { useEffect } from 'react';
import { Col, Container } from 'reactstrap';
import RedeemVoucher from '../components/RedeemVoucher';
import { Cookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import useTrackers from '../hooks/useTrackers';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

const VoucherPage = () => {
  const trackers = useTrackers();

  useEffect(() => {
    new Cookies().set('voucher-viewed', true);

    trackers.track('Page View', { page: 'Voucher' });
  }, []);
  const intl = useIntl();
  const history = useHistory();

  const onSkip = () => {
    history.push(DEFAULT_LANDING_PAGE);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-voucher">
          <div className="row">
            <Col md={{ offset: 4, size: 4 }}>
              <img src={require('../assets/images/icon-coin.png')} alt="" />

              <RedeemVoucher />

              <button className="btn btn-link text-muted" onClick={onSkip}>
                {intl.formatMessage({ id: 'Skip' })}
              </button>
            </Col>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default VoucherPage;
