import Box from '../Box';
import React from 'react';
import PlayerGroupTitle from './PlayerGroupTitle';
import Player from './Player';

const extractPlayerIds = (lineupPlayers) =>
  lineupPlayers.map((player) => player.id).filter((player) => !!player);

const groupPlayers = (players) =>
  players.reduce((rv, x) => {
    (rv[x.position] = rv[x.position] || []).push(x);
    return rv;
  }, {});

export const calculateColumns = (showTeam) => {
  const columns = {
    player: { width: showTeam ? '50%' : '60%', offset: '4px' },
    playerWithInfo: { width: showTeam ? '40%' : '50%', offset: '4px' },
    price: { width: showTeam ? '15%' : '20%', offset: '2px' },
    points: { width: showTeam ? '15%' : '20%', offset: '2px' },
  };
  if (showTeam) columns.team = { width: '20%', offset: '2px' };

  return columns;
};

const PlayerList = ({ players, selectedPlayers, onToggle, onShowInfo, showTeam }) => {
  const playerGroups = groupPlayers(players);

  return Object.keys(playerGroups).map((key) => (
    <Box key={key}>
      <PlayerGroupTitle showTeam={showTeam}>{key}</PlayerGroupTitle>
      {playerGroups[key].map((player) => {
        return (
          <Player
            key={player.id}
            player={player}
            selectedPlayerIds={selectedPlayers ? extractPlayerIds(selectedPlayers) : []}
            onToggle={onToggle}
            onShowInfo={onShowInfo}
            showTeam={showTeam}
          />
        );
      })}
    </Box>
  ));
};

export default PlayerList;
