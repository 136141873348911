import * as actionTypes from '../../constants/action-types/profile';

export const fetchMyPredictionsStart = () => ({
  type: actionTypes.FETCH_MY_PREDICTIONS_START,
});

export const fetchMyPredictionsSuccess = (predictions) => ({
  type: actionTypes.FETCH_MY_PREDICTIONS_SUCCESS,
  payload: { predictions },
});

export const fetchMyPredictionsFailure = () => ({
  type: actionTypes.FETCH_MY_PREDICTIONS_FAILURE,
});

export const selectMyPredictionsMatchStart = () => ({
  type: actionTypes.SELECT_MY_PREDICTIONS_MATCH_START,
});

export const selectMyPredictionsMatchSuccess = (matchId) => ({
  type: actionTypes.SELECT_MY_PREDICTIONS_MATCH_SUCCESS,
  payload: { matchId },
});

export const selectMyPredictionsMatchFailure = () => ({
  type: actionTypes.SELECT_MY_PREDICTIONS_MATCH_FAILURE,
});

export const unselectMyPredictionsMatch = () => ({
  type: actionTypes.UNSELECT_MY_PREDICTIONS_MATCH,
});

export const addMatchToMyPredictions = (matchId) => ({
  type: actionTypes.ADD_MATCH_TO_MY_PREDICTIONS,
  payload: { matchId },
});

export const fetchNotificationsStart = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_START,
});

export const fetchNotificationsSuccess = (notifications) => ({
  type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  payload: { notifications },
});

export const fetchNotificationsFailure = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_FAILURE,
});

export const receiveFriends = (friends) => ({
  type: actionTypes.RECEIVE_FRIENDS,
  payload: { friends },
});

export const receiveUserPrizes = (prizes) => ({
  type: actionTypes.RECEIVE_USER_PRIZES,
  payload: { prizes },
});

export const receiveUserNewPrizeCount = (count) => ({
  type: actionTypes.RECEIVE_USER_NEW_PRIZE_COUNT,
  payload: { count },
});

export const showPrizeRedemptionModal = (userPrize) => ({
  type: actionTypes.SHOW_PRIZE_REDEMPTION_MODAL,
  payload: { userPrize },
});

export const hidePrizeRedemptionModal = () => ({
  type: actionTypes.HIDE_PRIZE_REDEMPTION_MODAL,
});

export const updateProfileSuccess = (data) => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  payload: { data },
});

export const receiveOrders = (orders) => ({
  type: actionTypes.RECEIVE_ORDERS,
  payload: { orders },
});
