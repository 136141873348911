// @vendors
import styled from 'styled-components';
import {
  layout,
  position,
  flexbox,
  grid,
  space,
  border,
  color,
  typography,
  shadow,
  background,
  system,
} from 'styled-system';
// @config
import { styledLogicalProperties } from '../constants/styles';

const Box = styled.div`
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${grid}
  ${border}
  ${color}
  ${typography}
  ${shadow}
  ${background}
  ${system({
    cursor: true,
    transform: true,
    overflow: true,
    pointerEvents: true,
    transition: true,
    filter: true,
    boxSizing: true,
    ...styledLogicalProperties,
  })}
`;

export default Box;
