// @vendors
import styled from 'styled-components';
import {
  layout,
  flexbox,
  position,
  color,
  typography,
  border,
  space,
  system,
  grid,
} from 'styled-system';
// @config
import { styledLogicalProperties } from '../constants/styles';

const Text = styled.p`
  display: inline-block;
  word-break: normal;
  margin: 0;
  line-height: 1;
  ${layout}
  ${flexbox}
  ${position}
  ${space}
  ${color}
  ${typography}
  ${border}
  ${grid}
  ${system({
    textTransform: true,
    textDecoration: true,
    textAlign: true,
    whiteSpace: true,
    wordBreak: true,
    cursor: true,
    textOverflow: true,
    ...styledLogicalProperties,
  })}
  & > strong {
    font-weight: bold;
  }
  ${({ textStroke }) => textStroke && `-webkit-text-stroke: ${textStroke};`}
  :hover {
    text-decoration: auto;
    ${({ hoverColor, theme: { colors } }) =>
      hoverColor && `color: ${colors ? colors[hoverColor] : hoverColor};`}
  }
`;

export default Text;
