import React, { useEffect } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import ProfileTabs from '../../components/profile/Tabs';
import ProfileSidebar from '../../components/profile/Sidebar';
import { useIntl } from 'react-intl';
import { fetchMyFriends } from '../../redux/profile/thunks';

function ProfileFriendsPage(props) {
  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Friends' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);

    props.fetchMyFriends();
  }, []);

  const { friends } = props;

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile page-profile-friends">
          <Row>
            <Col md={3} className="col-sidebar">
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="profile-content">
                <ProfileTabs activeTab="friends" />

                <TabContent activeTab="friends">
                  <TabPane tabId="friends">
                    <div className="row align-items-center">
                      {friends.map((friend) => (
                        <div className="col-md-3" key={friend.id}>
                          <div className="friend" key={friend.id}>
                            <img
                              className="friend-picture"
                              src={
                                friend.profile_picture?.thumb ||
                                require('../../assets/images/user.png')
                              }
                              alt={`${friend.name}'s avatar`}
                            />
                            <h2 className="friend-name">{friend.name}</h2>
                          </div>
                        </div>
                      ))}
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { friends } = state.profile;
  return { friends };
};

export default connect(mapStateToProps, { fetchMyFriends })(ProfileFriendsPage);
