import {
  loginSuccess,
  fetchMeSuccess,
  logoutSuccess,
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  setReferrer as storeSetReferrer,
  clearReferrer as storeClearReferrer,
} from './actions';
import api from '../../helpers/api';
import { clearToken, setToken, setRefreshToken } from '../../helpers/auth';
import { fetchSettings } from '../generic/thunks';
import { removeCookie, setCookie } from '../../helpers/generic';

export function login(identifier, password) {
  return async function (dispatch) {
    const json = await api
      .login(identifier, password)
      .then((response) => response.data)
      .then((json) => {
        setToken(json.access_token);
        setRefreshToken(json.refresh_token);

        return json;
      });

    const user = await dispatch(fetchMe());
    return { ...json, user };
  };
}

export function loginUsingFacebook(accessToken, referrer) {
  return async function (dispatch) {
    const json = await api
      .loginUsingFacebook(accessToken, referrer)
      .then((response) => response.data)
      .then((json) => {
        setToken(json.access_token);
        setRefreshToken(json.refresh_token);

        return json;
      });

    const user = await dispatch(fetchMe());
    return { ...json, user };
  };
}

export function logout() {
  return async function (dispatch) {
    clearToken();
    window.FB.logout();
    dispatch(logoutSuccess());
    await dispatch(fetchSettings());
  };
}

export function registerUsingEmail(data) {
  return async function (dispatch) {
    await api.registerUsingEmail(data).then((response) => response.data.data);
    const json = await dispatch(login(data.email, data.password));
    return json;
  };
}

export function forgetPassword(values) {
  return async function (dispatch) {
    dispatch(forgetPasswordStart());

    return await api
      .forgetPassword(values)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(forgetPasswordSuccess(json));

        return json;
      })
      .catch((error) => {
        const { message, errors } = error.response.data;
        dispatch(forgetPasswordFailure(message, errors));
        throw error;
      });
  };
}

export function resetPassword(data) {
  return function (dispatch) {
    dispatch(resetPasswordStart(data));

    return api
      .resetPassword(data.otp, data.identifier, data.newPassword)
      .then((response) => response.data.message)
      .then((json) => dispatch(resetPasswordSuccess(json)))
      .catch((error) => {
        const { message, errors } = error.response.data;
        dispatch(resetPasswordFailure(message, errors));

        throw error;
      });
  };
}

export function fetchMe() {
  return async function (dispatch) {
    const data = await api.fetchMe().then((response) => response.data.data);
    dispatch(fetchMeSuccess(data));

    return data;
  };
}

export function setReferrer(referrer) {
  return async function (dispatch) {
    setCookie('referrer', referrer);
    dispatch(storeSetReferrer(referrer));
  };
}

export function clearReferrer() {
  return async function (dispatch) {
    removeCookie('referrer');
    dispatch(storeClearReferrer());
  };
}
