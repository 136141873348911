import React, { useEffect } from 'react';
import CompetitionLists from '../components/competitions/CompetitionLists';
import { useIntl } from 'react-intl';
import MetaTags from 'react-meta-tags';
import useTrackers from "../hooks/useTrackers";

const FantasyPage = (props) => {
  const trackers = useTrackers();
  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Fantasy' }));

      trackers.track('Page View', { page: 'Lobby', type: 'fantasy' });
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'meta.description.competitions-fantasy' })}
        />
      </MetaTags>

      <CompetitionLists type="fantasy" />
    </React.Fragment>
  );
};

export default FantasyPage;
