import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import Modal from '../../../components/Modal';
import { useHistory } from 'react-router-dom';
export function InsufficientFundsModal({ open, setOpen, remainingToJoin }) {
  const { formatMessage } = useIntl();
  const history = useHistory();
  return (
    <Modal
      centered
      isOpen={open}
      toggle={() => setOpen(false)}
      title={formatMessage({ id: 'You need more coins!' })}
      footer={
        <Button block onClick={() => history.push('/pricing')}>
          {formatMessage({ id: 'Go to Payment' })}
        </Button>
      }
    >
      <p className="mb-0">
        <FormattedMessage
          id="remaining_coins_to_join_message"
          values={{ coins: remainingToJoin }}
        />
      </p>

      <hr />

      {formatMessage({ id: 'You can either:' })}
      <ol>
        <li>{formatMessage({ id: 'Pay with Fawry' })}</li>
        <li>{formatMessage({ id: 'Pay with Credit Card' })}</li>
        <li>{formatMessage({ id: 'Earn coins by referring' })}</li>
      </ol>
    </Modal>
  );
}
