import React from 'react';
import { useIntl } from 'react-intl';

const NoEntries = (props) => {
  const intl = useIntl();
  const { message = intl.formatMessage({ id: 'no-entries' }) } = props;

  return (
    <React.Fragment>
      <div className="no-entries">
        <img src={require('../assets/images/no-entries.svg')} alt="" />
        <p>{message}</p>
      </div>
    </React.Fragment>
  );
};

export default NoEntries;
