// @vendors
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { FormattedMessage, useIntl } from 'react-intl';
import { findIndex } from 'lodash';
// @components
import Box from '../../Box';
import Text from '../../Text';
// @styles
import {
  LineupBg,
  StyledLineupPlayer,
  CaptainIcon,
  CardInfoIcon,
  CardPlayerImage,
  FieldPlayerContainer,
  ReplacementIcon,
} from './styles';
// @static
import lineupBgImg from '../../../assets/images/lineup-bg.svg';
import lineupBgMobileImg from '../../../assets/images/lineup-bg-mobile.svg';
import lineupPlayerImg from '../../../assets/images/lineup-player.png';
import withMediaQuery from '../../../hoc/withMediaQuery';
import cardInfoIcon from '../../../assets/images/info-card-icon.svg';
import { ENTRY_TEAM_PLAYER_STATUS_SIDELINED } from '../../../constants/generic';
import NumberFormat from 'react-number-format';
import { ToggleFilterBtn } from '../../../pages/competitions/components/CompetitionTeamSetup/styles';
import { FaFilter } from 'react-icons/fa';

const substitutePositions = ['Goalkeeper', 'Defender', 'Midfielder', 'Attacker'];
const positionShortNames = {
  Goalkeeper: 'GK',
  Defender: 'DEF',
  Midfielder: 'MID',
  Attacker: 'ATT',
};
const gridInfo = {
  gridTemplateAreas: "'info player position points price options'",
  gridTemplateColumns: {
    xs: '1rem 0.85fr 0.3fr 0.3fr 0.3fr 1rem',
    md: '1rem 1fr 0.5fr 0.5fr 0.25fr 0.5rem',
  },
  gridColumnGap: { xs: '0.75rem', md: '1.75rem' },
};

const LineupPlayer = ({
  index,
  position,
  player,
  onClick,
  onRemove,
  onSetCaptain,
  showPosition,
  isSoftDisable,
  ...rest
}) => (
  <FieldPlayerContainer
    display="flex"
    flexDirection="column"
    alignItems="center"
    position="relative"
    {...rest}
  >
    <StyledLineupPlayer
      id={`${position}_${player?.id}`}
      key={`${position}_${index}`}
      src={player?.image?.thumb || lineupPlayerImg}
      onClick={() => onClick(position, index, player)}
      isNotEmpty={!!player?.image?.thumb}
    />
    {player?.id && (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="0.25rem"
        cursor="pointer"
        mt="0.25rem"
      >
        {player?.common_name && (
          <Text
            fontSize={{ xs: '0.5rem', md: '0.8rem' }}
            color="#fff"
            textAlign="center"
            onClick={() => onClick(position, index, player)}
            p="0.25rem"
            bg="#201f2f"
            width="100%"
          >
            {player?.common_name}
          </Text>
        )}
        {player?.team?.code && (
          <Text
            fontSize="0.6rem"
            color="#fff"
            textAlign="center"
            onClick={() => onClick(position, index, player)}
            p="0.25rem"
            bg="#58576f"
            width="100%"
          >
            {player?.team?.code}
          </Text>
        )}
        {isSoftDisable && (
          <Text
            fontSize="0.6rem"
            color="#201f2f"
            textAlign="center"
            onClick={() => onClick(position, index, player)}
            p="0.25rem"
            bg="#5ad3b8"
            width="100%"
          >
            <NumberFormat
              value={player?.scored_fantasy_points || 0}
              displayType={'text'}
              thousandSeparator={false}
              decimalScale={2}
            />
          </Text>
        )}
      </Box>
    )}
    {position && showPosition && (
      <Text
        mt="0.2rem"
        fontSize="0.6rem"
        color="#201f2f"
        textAlign="center"
        textTransform="uppercase"
        onClick={() => onClick(position, index, player)}
        cursor="pointer"
        fontWeight="bold"
      >
        {positionShortNames[position]}
      </Text>
    )}
    {player?.id && !isSoftDisable && (
      <Box position="absolute" top="0" right={{ xs: '-0.25rem', md: '-0.75rem' }}>
        <UncontrolledDropdown>
          <DropdownToggle size="xs" color="dark" className="d-block field-player-options-btn">
            <BsThreeDots />
          </DropdownToggle>
          <DropdownMenu className="p-0">
            <DropdownItem onClick={() => onRemove(position, player, index)}>
              <FormattedMessage id="Remove" />
            </DropdownItem>
            {player?.is_captain !== 1 && (
              <DropdownItem onClick={() => onSetCaptain(player)}>
                <FormattedMessage id="Set as captain" />
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Box>
    )}
    {player?.is_captain === 1 && <CaptainIcon />}
    {player?.id && isSoftDisable && (player?.isInField || player?.isInSub) && (
      <ReplacementIcon isInField={player?.isInfield} />
    )}
  </FieldPlayerContainer>
);

const CardPlayer = withMediaQuery(
  ({
    index,
    position,
    player,
    onClick,
    onRemove,
    onSetCaptain,
    isMobile,
    isSoftDisable,
    ...rest
  }) => {
    const intl = useIntl();
    return (
      <Box
        width="100%"
        borderBottom="1px solid #7070704d"
        key={`lineup_player_card_${player?.id}_${index}`}
        display="grid"
        gridTemplateAreas={gridInfo.gridTemplateAreas}
        gridTemplateColumns={gridInfo.gridTemplateColumns}
        gridColumnGap={gridInfo.gridColumnGap}
        p="0.5rem 0"
        alignItems="center"
        {...rest}
      >
        {player?.id && (
          <CardInfoIcon src={cardInfoIcon} onClick={() => onClick(position, index, player)} />
        )}
        <Box
          display="flex"
          alignItems="center"
          gridArea="player"
          onClick={() => onClick(position, index, player)}
          cursor="pointer"
          position="relative"
        >
          {player?.is_captain === 1 && <CaptainIcon mini />}
          <CardPlayerImage
            id={`${position}_${player?.id}`}
            key={`${position}_${index}`}
            src={player?.image?.thumb || lineupPlayerImg}
          />
          <div>
            {player?.common_name && (
              <Text mis="0.5rem" fontSize="0.875rem" color="#201f2f" display="block">
                {player?.common_name}
              </Text>
            )}
            {player?.team?.code && (
              <Text mt="0.25rem" mis="0.5rem" fontSize="0.75rem" color="#201f2f" display="block">
                {player?.team?.code}
              </Text>
            )}
          </div>
        </Box>
        {position && (
          <Box
            gridArea="position"
            onClick={() => onClick(position, index, player)}
            cursor="pointer"
          >
            <Text fontSize="0.875rem" color="#201f2f">
              {positionShortNames[position]}
            </Text>
          </Box>
        )}
        <Box gridArea="points" onClick={() => onClick(position, index, player)} cursor="pointer">
          <Text fontSize="0.75rem" color="#201f2f">
            <NumberFormat
              value={player?.scored_fantasy_points || 0}
              displayType={'text'}
              thousandSeparator={false}
              decimalScale={2}
            />
          </Text>
        </Box>
        <Box gridArea="price" onClick={() => onClick(position, index, player)} cursor="pointer">
          <Text fontSize="0.75rem" color="#201f2f">
            {player?.price ? `${player?.price} ${intl.formatMessage({ id: 'M' })}` : '-'}
          </Text>
        </Box>
        {player?.id && !isSoftDisable && (
          <Box gridArea="options">
            <UncontrolledDropdown>
              <DropdownToggle
                style={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  border: 'none',
                }}
              >
                <Text color="#5ad37c" cursor="pointer">
                  <BsThreeDotsVertical />
                </Text>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onRemove(position, player, index)}>
                  <FormattedMessage id="Remove" />
                </DropdownItem>
                {player?.is_captain !== 1 && (
                  <DropdownItem onClick={() => onSetCaptain(player)}>
                    <FormattedMessage id="Set as captain" />
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Box>
        )}
      </Box>
    );
  }
);

const CardSeparator = ({ isSub, ...rest }) => (
  <Box
    width="100%"
    bg="#34495e"
    borderRadius="0.25rem"
    height="2.25rem"
    alignItems="center"
    display="grid"
    gridTemplateAreas={gridInfo.gridTemplateAreas}
    gridTemplateColumns={gridInfo.gridTemplateColumns}
    gridColumnGap={gridInfo.gridColumnGap}
    {...rest}
  >
    <Text fontSize={{ xs: '0.75rem', md: '1rem' }} color="#fff" gridArea="player">
      <FormattedMessage id={isSub ? 'Substitutes' : 'MatchScoreBreakdown.TableHeader.player'} />
    </Text>
    <Text fontSize={{ xs: '0.75rem', md: '1rem' }} color="#fff" gridArea="position">
      <FormattedMessage id="Position" />
    </Text>
    <Text fontSize={{ xs: '0.75rem', md: '1rem' }} color="#fff" gridArea="points">
      <FormattedMessage id="match-event-types.groups.points" />
    </Text>
    <Text fontSize={{ xs: '0.75rem', md: '1rem' }} color="#fff" gridArea="price">
      <FormattedMessage id="Price" />
    </Text>
  </Box>
);

const CompetitionLineup = ({
  lineupView,
  lineup,
  value,
  onClick,
  onRemove,
  onSetCaptain,
  isMobile,
  isSoftDisable,
  onToggleFilters,
  ...rest
}) => {
  const [keeper, setKeeper] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [midfielders, setMidfielders] = useState([]);
  const [attackers, setAttackers] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);
  const [lineupFormation, setLineupFormation] = useState([]);

  useEffect(() => {
    setLineupFormation(`${lineup}`.split('-').map((no) => Number(no)));
  }, [lineup]);

  useEffect(() => {
    if (value?.length === 15 && lineupFormation?.length === 3) {
      // prepare players format
      let tempKeeper = [];
      let tempDefenders = [];
      let tempMidfielders = [];
      let tempAttackers = [];
      const tempSubstitutes = [];

      tempKeeper = value.slice(0, 1);
      tempSubstitutes[0] = value[1];
      tempDefenders = value.slice(2, lineupFormation[0] + 2);
      tempSubstitutes[1] = value[lineupFormation[0] + 2];
      tempMidfielders = value.slice(
        lineupFormation[0] + 3,
        lineupFormation[0] + 3 + lineupFormation[1]
      );

      tempSubstitutes[2] = value[lineupFormation[0] + 3 + lineupFormation[1]];
      tempAttackers = value.slice(
        lineupFormation[0] + 4 + lineupFormation[1],
        lineupFormation[0] + 4 + lineupFormation[1] + lineupFormation[2]
      );
      tempSubstitutes[3] = value[lineupFormation[0] + 4 + lineupFormation[1] + lineupFormation[2]];

      // prepare players in field and players in sub
      [tempKeeper, tempDefenders, tempMidfielders, tempAttackers].forEach(
        (positionPlayers, positionIndex) => {
          const playerToReplaceIndex = findIndex(positionPlayers, {
            status: ENTRY_TEAM_PLAYER_STATUS_SIDELINED,
          });
          if (playerToReplaceIndex !== -1) {
            const tmpPlayer = { ...positionPlayers[playerToReplaceIndex], isInSub: true };
            const replacement = tempSubstitutes[positionIndex];

            if (replacement.status === 'played') {
              if (positionIndex === 0)
                tempKeeper[playerToReplaceIndex] = {
                  ...tempSubstitutes[positionIndex],
                  isInField: true,
                };
              else if (positionIndex === 1)
                tempDefenders[playerToReplaceIndex] = {
                  ...tempSubstitutes[positionIndex],
                  isInField: true,
                };
              else if (positionIndex === 2)
                tempMidfielders[playerToReplaceIndex] = {
                  ...tempSubstitutes[positionIndex],
                  isInField: true,
                };
              else if (positionIndex === 3)
                tempAttackers[playerToReplaceIndex] = {
                  ...tempSubstitutes[positionIndex],
                  isInField: true,
                };

              tempSubstitutes[positionIndex] = { ...tmpPlayer };
            }
          }
        }
      );
      // Set final players
      setKeeper(tempKeeper);
      setDefenders(tempDefenders);
      setMidfielders(tempMidfielders);
      setAttackers(tempAttackers);
      setSubstitutes(tempSubstitutes);
    }
  }, [value, lineupFormation]);

  return lineupView === 'field' ? (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt="2rem" mb="1.25rem">
        <Text display="block" fontSize="1.375rem" fontWeight="bold" color="#000">
          <FormattedMessage
            id={isSoftDisable ? 'CompetitionLineup.Team' : 'CompetitionLineup.SelectTeam'}
          />
        </Text>
        {!isSoftDisable && (
          <>
            <ToggleFilterBtn className="btn btn-primary btn-mw" onClick={onToggleFilters}>
              <FaFilter /> <FormattedMessage id="Filters" />
            </ToggleFilterBtn>
          </>
        )}
      </Box>
      <Box
        width="100%"
        display="flex"
        position="relative"
        height={{ xs: '80vh', md: 'auto' }}
        {...rest}
      >
        <LineupBg src={isMobile ? lineupBgMobileImg : lineupBgImg} />
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          zIndex="1"
          p={{ xs: '0', md: '0 4rem 0rem' }}
          mt={isMobile ? '-1rem' : 0}
        >
          <Box width="100%" display="flex" justifyContent="space-around">
            {keeper.map((player, index) => (
              <LineupPlayer
                key={`Goalkeeper_position_${index}`}
                index={index}
                position="Goalkeeper"
                onClick={onClick}
                onRemove={onRemove}
                player={player}
                onSetCaptain={onSetCaptain}
                isSoftDisable={isSoftDisable}
              />
            ))}
          </Box>
          <Box width="100%" display="flex" justifyContent="space-around">
            {defenders.map((player, index) => (
              <LineupPlayer
                key={`Defender_position_${index}`}
                index={index}
                position="Defender"
                onClick={onClick}
                onRemove={onRemove}
                player={player}
                onSetCaptain={onSetCaptain}
                isSoftDisable={isSoftDisable}
              />
            ))}
          </Box>
          <Box width="100%" display="flex" justifyContent="space-around">
            {midfielders.map((player, index) => (
              <LineupPlayer
                key={`Midfielder_position_${index}`}
                index={index}
                position="Midfielder"
                onClick={onClick}
                onRemove={onRemove}
                player={player}
                onSetCaptain={onSetCaptain}
                isSoftDisable={isSoftDisable}
              />
            ))}
          </Box>
          <Box width="100%" display="flex" justifyContent="space-around">
            {attackers.map((player, index) => (
              <LineupPlayer
                key={`Attacker_position_${index}`}
                index={index}
                position="Attacker"
                onClick={onClick}
                onRemove={onRemove}
                player={player}
                onSetCaptain={onSetCaptain}
                isSoftDisable={isSoftDisable}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Text display="block" fontSize="1.375rem" fontWeight="bold" color="#000" my="2rem">
        <FormattedMessage
          id={
            isSoftDisable ? 'CompetitionLineup.Substitutes' : 'CompetitionLineup.SelectSubstitutes'
          }
        />
      </Text>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-around"
        backgroundImage="linear-gradient(180deg, transparent 49.5%, transparent 50.5%, rgba(230,230,230,1) 51%)"
        pb="1rem"
      >
        {substitutes.map((player, index) => (
          <LineupPlayer
            key={`Substitute_position_${index}`}
            index={[1, ...lineupFormation][index]}
            position={substitutePositions[index]}
            onClick={onClick}
            onRemove={onRemove}
            player={player}
            onSetCaptain={onSetCaptain}
            showPosition
            isSoftDisable={isSoftDisable}
          />
        ))}
      </Box>
    </>
  ) : (
    <Box width="100%" display="flex" flexDirection="column" {...rest}>
      <CardSeparator />
      {keeper.map((player, index) => (
        <CardPlayer
          key={`Goalkeeper_position_${index}`}
          index={index}
          position="Goalkeeper"
          onClick={onClick}
          onRemove={onRemove}
          player={player}
          onSetCaptain={onSetCaptain}
          isSoftDisable={isSoftDisable}
        />
      ))}
      {defenders.map((player, index) => (
        <CardPlayer
          key={`Defender_position_${index}`}
          index={index}
          position="Defender"
          onClick={onClick}
          onRemove={onRemove}
          player={player}
          onSetCaptain={onSetCaptain}
          isSoftDisable={isSoftDisable}
        />
      ))}
      {midfielders.map((player, index) => (
        <CardPlayer
          key={`Midfielder_position_${index}`}
          index={index}
          position="Midfielder"
          onClick={onClick}
          onRemove={onRemove}
          player={player}
          onSetCaptain={onSetCaptain}
          isSoftDisable={isSoftDisable}
        />
      ))}
      {attackers.map((player, index) => (
        <CardPlayer
          key={`Attacker_position_${index}`}
          index={index}
          position="Attacker"
          onClick={onClick}
          onRemove={onRemove}
          player={player}
          onSetCaptain={onSetCaptain}
          isSoftDisable={isSoftDisable}
        />
      ))}
      <CardSeparator isSub mt="0.5rem" />
      {substitutes.map((player, index) => (
        <CardPlayer
          key={`Substitute_position_${index}`}
          index={[1, ...lineupFormation][index]}
          position={substitutePositions[index]}
          onClick={onClick}
          onRemove={onRemove}
          player={player}
          onSetCaptain={onSetCaptain}
          isSoftDisable={isSoftDisable}
        />
      ))}
    </Box>
  );
};

CompetitionLineup.propTypes = {
  lineupView: PropTypes.string,
  lineup: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

CompetitionLineup.defaultProps = {
  lineupView: 'field',
  onClick: () => {},
};

export default withMediaQuery(CompetitionLineup);
