import React, { useEffect } from 'react';
import {
  FaBasketballBall,
  FaCalendar,
  FaTicketAlt,
  FaMoneyCheck,
  FaUsers,
  FaUser,
  FaTrophy,
} from 'react-icons/fa';
import { formatDate } from '../../../helpers/generic';
import { FormattedMessage } from 'react-intl';
import useTrackers from '../../../hooks/useTrackers';

const Overview = (props) => {
  const trackers = useTrackers();
  const { competition } = props;
  const { participants } = competition;
  const { start, end } = competition.season;

  const participantFillingRatio =
    participants.current / (participants.max || participants.current || 1);

  useEffect(() => {
    trackers.track('Page View', { page: `Competition`, name: competition.name, tab: 'Overview' });
  }, []);

  return (
    <React.Fragment>
      <div className="competition-overview">
        <div className="row">
          <div className="col-md-6">
            <h3 className="widget-header">
              <FormattedMessage id="Basic Info" />
            </h3>
            <div className="competition-overview-card">
              <div className="card-body">
                {start && end && (
                  <div className="competition-info">
                    <FaCalendar className="competition-info-icon" />
                    <div className="competition-info-data">
                      <h3 className="competition-info-label">
                        <FormattedMessage id="Live from" /> {formatDate(start)}{' '}
                        <FormattedMessage id="to" /> {formatDate(end)}
                      </h3>
                    </div>
                  </div>
                )}

                {competition.creator && (
                  <div className="competition-info">
                    <FaUser className="competition-info-icon" />
                    <div className="competition-info-data">
                      <h3 className="competition-info-label">
                        <FormattedMessage id="Creator" />:
                      </h3>
                      <p className="competition-info-value">{competition.creator.name}</p>
                    </div>
                  </div>
                )}

                <div className="competition-info">
                  <FaBasketballBall className="competition-info-icon" />
                  <div className="competition-info-data">
                    <h3 className="competition-info-label">
                      <FormattedMessage id="Matches" />:
                    </h3>
                    <p className="competition-info-value">{competition.season.game_count}</p>
                  </div>
                </div>

                <div className="competition-info">
                  <FaTicketAlt className="competition-info-icon" />
                  <div className="competition-info-data">
                    <h3 className="competition-info-label">
                      <FormattedMessage id="Entry Fee" />:
                    </h3>
                    <p className="competition-info-value">
                      {competition.entry_fee} <FormattedMessage id="coins" />
                    </p>
                  </div>
                </div>

                {!!competition.calculate_reward && (
                  <div className="competition-info">
                    <FaMoneyCheck className="competition-info-icon" />
                    <div className="competition-info-data">
                      <h3 className="competition-info-label">
                        <FormattedMessage id="Reward" />:
                      </h3>
                      <p className="competition-info-value">
                        {competition.reward} <FormattedMessage id="coins" />
                      </p>
                    </div>
                  </div>
                )}

                {!!competition.winning_strategy && (
                  <div className="competition-info">
                    <FaTrophy className="competition-info-icon" />
                    <div className="competition-info-data">
                      <h3 className="competition-info-label">
                        <FormattedMessage id="Winners" />:
                      </h3>
                      <p className="competition-info-value">
                        <FormattedMessage id={competition.winning_strategy} />
                      </p>
                    </div>
                  </div>
                )}

                <div className="competition-info competition-info-participants mb-0">
                  <FaUsers className="competition-info-icon" />
                  <div className="competition-info-data">
                    <div className="competition-info-label">
                      <FormattedMessage id="Participants" />{' '}
                      <small>
                        (<FormattedMessage id="min" />: {participants.min} - <FormattedMessage id="max" />: {participants.max})
                      </small>
                    </div>
                    <div className="custom-progress-bar">
                      <div className="full">
                        <div
                          className="filled"
                          style={{ width: `${participantFillingRatio * 100}%` }}
                        />
                      </div>
                      <p className="description">
                        {participants.current}{' '}
                        {participants.max && (
                          <React.Fragment>
                            <FormattedMessage id="out of" /> {participants.max}
                          </React.Fragment>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 offset-md-1">
            <div className="competition-prizes">
              <h3 className="widget-header">
                <FormattedMessage id="Prizes" />
              </h3>
              <div className="prize-list">
                {competition.prizes.map((prize) => (
                  <div className="prize" key={prize.id}>
                    <h3 className="prize-name">{prize.name}</h3>
                    {prize.image && (
                      <img src={prize.image.original} alt={prize.name} className="prize-image" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Overview;
