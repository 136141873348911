import React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import Tag from '../Tag';
import { MdTimer } from 'react-icons/md';

const ActiveTo = ({ activeTo }) => {
  const intl = useIntl();

  activeTo = dayjs(activeTo);

  const color = activeTo.isAfter(dayjs()) ? 'primary' : 'secondary';

  return (
    <React.Fragment>
      <Tag title={intl.formatMessage({ id: 'Active to' })} color={color}>
        <span className="active-to">
          <MdTimer className="icon" /> {activeTo.format('DD/MM/YYYY hh:mm A')}
        </span>
      </Tag>
    </React.Fragment>
  );
};
export default ActiveTo;
