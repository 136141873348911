// @vendors
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
// @components
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
// @styles
import { PlayerImg, CloseIcon } from './styles';
// @static
import closeIcon from '../../../../assets/images/icon-close.svg';
import headerImg from '../../../../assets/images/lineup-player-select-header.jpg';
// constants
import { SCORE_BREAKDOWN_STATS_LIST } from '../../../../constants/generic';
import {CompetitionContext} from "../../show";

const MatchScoreBreakdown = ({ matchId, onClose, competition, ...rest }) => {
  const { setOpenJoiningModal } = useContext(CompetitionContext);

  const { score_breakdown: items = [] } = useSelector(
    (state) => state?.matches?.loadedMatches?.[matchId] || {}
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      borderRadius="0.625rem"
      border="1px solid #34495e"
      bg="#fff"
      p="1rem"
      position="relative"
      {...rest}
    >
      {/* Close Button */}
      {onClose && <CloseIcon src={closeIcon} onClick={onClose} />}
      {/* Header */}
      <Box
        width="100%"
        height="5.5rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url('${headerImg}')`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        borderTopLeftRadius="0.3125rem"
        borderTopRightRadius="0.3125rem"
      >
        <Text fontSize="1.75rem" fontWeight="600" color="#fff" textAlign="center">
          <FormattedMessage id="MatchScoreBreakdown.Title" />
        </Text>
      </Box>
      {/* Table Container */}
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        border="1px solid #eae9ea"
        py="1rem"
        borderBottomLeftRadius="0.3125rem"
        borderBottomRightRadius="0.3125rem"
        overflowX="auto"
        mb="1rem"
      >
        {/* Table Head */}
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${SCORE_BREAKDOWN_STATS_LIST.length + 1}, 1fr)`}
          minWidth="46rem"
          p="0.25rem 0"
          borderBottom="1px solid #eae9ea"
          mb="1rem"
        >
          {/* Player Head */}
          <Text
            fontSize="0.75rem"
            fontWeight="bold"
            color="#34495e"
            textAlign="center"
            p="0.375rem"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            width="6rem"
          >
            <FormattedMessage id="MatchScoreBreakdown.TableHeader.player" />
          </Text>
          {/* Stats Heads */}
          {SCORE_BREAKDOWN_STATS_LIST.map((scoreKey) => (
            <Text
              fontSize="0.75rem"
              fontWeight="bold"
              color="#34495e"
              textAlign="center"
              key={`table_header_${scoreKey}`}
              borderInlineStart="1px solid #eae9ea"
              p="0.375rem"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              width="5rem"
            >
              <FormattedMessage id={`match-event-types.groups.${scoreKey}`} />
            </Text>
          ))}
        </Box>
        {/* Table Body */}
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${SCORE_BREAKDOWN_STATS_LIST.length + 1}, 1fr)`}
          gridRowGap="0.875rem"
          width="100%"
          minWidth="22.5rem"
        >
          {items.map((item) => (
            <React.Fragment key={`table_item_${item?.player?.id}`}>
              {/* Player Card */}
              <Box display="flex" alignItems="center" minWidth="6rem">
                <PlayerImg src={item?.player?.image?.original} />
                <Box display="flex" flexDirection="column">
                  <Text fontSize="0.625rem" color="#2e384d">
                    {item?.player?.common_name}
                  </Text>
                  <Text fontSize="0.625rem" color="#2e384d" textTransform="uppercase">
                    {item?.player?.team?.code} {item?.player?.position?.substring(0, 3)}
                  </Text>
                </Box>
              </Box>
              {/* Stats Cards */}
              {SCORE_BREAKDOWN_STATS_LIST.map((scoreKey) => (
                <Text
                  fontSize="0.625rem"
                  color="#2e384d"
                  textAlign="center"
                  key={`table_item_${item?.player?.id}_${scoreKey}`}
                  p="0.375rem"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                  width="5rem"
                >
                  {item?.stats?.[scoreKey] || 0} <FormattedMessage id="Pts" />
                </Text>
              ))}
            </React.Fragment>
          ))}
        </Box>
      </Box>

      {!competition.joined && competition.participants.max - competition.participants.current > 0 && (
        <button className="btn btn-primary btn-mw" onClick={() => setOpenJoiningModal(true)}>
          <FormattedMessage id="Join Now" />
        </button>
      )}
    </Box>
  );
};

MatchScoreBreakdown.propTypes = {
  matchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func,
};

MatchScoreBreakdown.defaultProps = {
  onClose: null,
};

export default MatchScoreBreakdown;
