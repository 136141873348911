import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../helpers/generic';
import { FaBaseballBall, FaCoins, FaTicketAlt, FaUsers } from 'react-icons/fa';
import { useHistory } from 'react-router';
import NumberFormat from 'react-number-format';
import {
  COMPETITION_STATE_RUNNING,
  COMPETITION_STATE_UPCOMING,
  COMPETITION_TYPE_FANTASY,
} from '../../constants/generic';
import useTrackers from '../../hooks/useTrackers';

const CompetitionCard = (props) => {
  const { competition, featured } = props;
  const history = useHistory();
  const trackers = useTrackers();

  const from = competition.season.start && formatDate(competition.season.start);
  const to = competition.season.end && formatDate(competition.season.end);

  const isFantasy = competition.type === COMPETITION_TYPE_FANTASY;
  const isFilled = competition.participants.current >= competition.participants.max;
  const isUpcoming = competition.state === COMPETITION_STATE_UPCOMING;
  const isRunning = competition.state === COMPETITION_STATE_RUNNING;
  const isJoined = competition.joined;
  const isJoinNow = !isFilled && isUpcoming && !isJoined;
  const isPlayable = isJoined && (isUpcoming || isRunning);
  const isMoreDetails = !isJoinNow && !isPlayable;

  const open = () => {
    if (isJoinNow) {
      trackers.track('Button Clicked', { button: 'competition.join_new', source: 'predictions' });
    }

    if (isFantasy) {
      history.push(`/competitions/${competition.id}/team-setup`);
    } else {
      history.push(`/competitions/${competition.id}/matches`);
    }
  };

  return (
    <React.Fragment>
      <div className={`competition-card ${featured ? 'featured' : ''}`}>
        {competition.status === 'pending-approval' && <Lock />}
        <div className="competition-inner-wrapper" onClick={open}>
          <div
            className="card-header"
            style={{
              backgroundImage: competition.cover ? `url(${competition.cover.original})` : null,
            }}
          >
            <div className="card-header-inner">
              <h3 className="competition-card-dates">
                {from && (
                  <React.Fragment>
                    <FormattedMessage id="Live from" /> <b>{from}</b>{' '}
                  </React.Fragment>
                )}
                {from && to && (
                  <React.Fragment>
                    <FormattedMessage id="to" /> <b>{to}</b>
                  </React.Fragment>
                )}
              </h3>

              {competition.featured && (
                <span className="competition-tag">
                  <FormattedMessage id="Featured" />
                </span>
              )}

              <h2 className="competition-name" title={competition.name}>
                {competition.name}
              </h2>

              <div className="competition-sponsors">
                {competition.sponsors.map(
                  (sponsor) =>
                    sponsor.logo && <img src={sponsor.logo.original} alt={sponsor.name} />
                )}
              </div>
            </div>
          </div>

          <div className="card-body">
            {competition.creator && (
              <h3 className="competition-creator">
                <FormattedMessage id="Created by" />:{' '}
                <span title={competition.creator.name}>
                  <b>{competition.creator.name}</b>
                </span>
              </h3>
            )}

            <div className="competition-info-list">
              <div className="row">
                <div className="col">
                  <div className="competition-info mb-3 mb-md-0">
                    <FaBaseballBall className="competition-info-icon" />
                    <p className="competition-info-value" title={competition.season.game_count}>
                      {competition.season.game_count}
                    </p>
                    <h3 className="competition-info-label">
                      <FormattedMessage id="Games" />
                    </h3>
                  </div>
                </div>
                {!!competition.calculate_reward && (
                  <div className="col">
                    <div className="competition-info mb-3">
                      <FaCoins className="competition-info-icon" />
                      <p className="competition-info-value" title={competition.reward}>
                        {competition.reward}
                      </p>
                      <h3 className="competition-info-label">
                        <FormattedMessage id="Coins Total Prize" />
                      </h3>
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="competition-info">
                    <FaTicketAlt className="competition-info-icon" />
                    <p className="competition-info-value" title={competition.entry_fee}>
                      <NumberFormat
                        value={competition.entry_fee}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </p>
                    <h3 className="competition-info-label">
                      <FormattedMessage id="Coins Entry Fee" />
                    </h3>
                  </div>
                </div>
                <div className="col">
                  <div className="competition-info">
                    <FaUsers className="competition-info-icon" />
                    <p className="competition-info-value" title={competition.participants.current}>
                      <NumberFormat
                        value={competition.participants.current}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </p>
                    <h3 className="competition-info-label">
                      <span className="total-participants">
                        <FormattedMessage id="out of" />{' '}
                        <NumberFormat
                          value={competition.participants.max}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      </span>{' '}
                      <br />
                      <FormattedMessage id="Participants" />
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <button className="btn btn-primary btn-lg btn-block">
              {isJoinNow && <FormattedMessage id="Join Now" />}
              {isPlayable && <FormattedMessage id={isFantasy ? 'Play Now' : 'Predict Now'} />}
              {isMoreDetails && <FormattedMessage id="More Details" />}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Lock = () => {
  return (
    <div className="competition-lock d-flex align-items-center justify-content-center">
      <img src={require('../../assets/images/icon-lock.png')} alt="" />
    </div>
  );
};

export default CompetitionCard;
