import React, { useEffect, useState } from 'react';
import MatchFilters from './MatchFilters';
import { FormattedMessage, useIntl } from 'react-intl';
import api from '../../../../helpers/api';
import MatchList from './MatchList';
import { useDispatch, useSelector } from 'react-redux';
import { getInitSelectedMatches } from '../../../../helpers/generic';
import { receiveOfficialMatches } from '../../../../redux/matches/actions';

const Step2 = (props) => {
  const dispatch = useDispatch();
  const fetchedMatches = useSelector((s) => s.matches.official);
  const { setFieldValue } = props;
  const [matches, setMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState(
    getInitSelectedMatches(props.values.match_ids, fetchedMatches)
  );
  const [tempSelectedMatches, setTempSelectedMatches] = useState({});
  const [tempDeselectedMatches, setTempDeselectedMatches] = useState({});
  const intl = useIntl();

  useEffect(() => {
    setFieldValue('match_ids', Object.keys(selectedMatches));
  }, [selectedMatches]);

  const fetchMatches = (filters) => {
    filters = { ...filters, 'for-user-created-competitions': true };

    if (props.values.fantasy_competition_id) {
      filters.competition = props.values.fantasy_competition_id;
    }

    api.fetchOfficialMatches(filters).then((r) => {
      const { data: matches } = r.data;
      dispatch(receiveOfficialMatches(matches));

      setMatches(matches);
    });
  };

  const getAvailableMatches = () => {
    const selectedMatchIds = Object.keys(selectedMatches).map((id) => parseInt(id));

    return matches.filter((m) => selectedMatchIds.indexOf(m.id) === -1);
  };

  const onAdd = () => {
    setSelectedMatches({ ...selectedMatches, ...tempSelectedMatches });
    setTempSelectedMatches({});
  };

  const onRemove = () => {
    const newSelectedMatches = { ...selectedMatches };
    Object.keys(tempDeselectedMatches).forEach((k) => {
      delete newSelectedMatches[k];
    });

    setSelectedMatches(newSelectedMatches);
    setTempDeselectedMatches({});
  };

  return (
    <React.Fragment>
      <div className="step2">
        <div className="header">
          <div className="row">
            <div className="col-md-8">
              <MatchFilters onFilter={(filters) => fetchMatches(filters)} />
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <FormattedMessage id="Maximum allowed games are" />: 500
              </p>
            </div>
          </div>
        </div>

        <div className="body">
          <div className="row align-items-center">
            <div className="col-md-5">
              <MatchList
                matches={getAvailableMatches()}
                selectedMatches={tempSelectedMatches}
                onChangeSelection={(matches) => setTempSelectedMatches(matches)}
                title={intl.formatMessage({ id: 'Available Matches' })}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary btn-block btn-sm" onClick={onAdd}>
                <FormattedMessage id="Add" />
              </button>
              <button className="btn btn-danger btn-block btn-sm" onClick={onRemove}>
                <FormattedMessage id="Remove" />
              </button>
            </div>
            <div className="col-md-5">
              <MatchList
                matches={Object.values(selectedMatches)}
                selectedMatches={tempDeselectedMatches}
                onChangeSelection={(matches) => setTempDeselectedMatches(matches)}
                title={intl.formatMessage({ id: 'Selected Matches' })}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step2;
