import * as React from 'react';
import Theme1Ar from './Theme1Ar';
import Theme1En from './Theme1En';
import { locale } from '../helpers/generic';

const ThemeSelector = (props) => {
  const currentLocale = locale();

  if (currentLocale === 'ar') {
    import('../assets/scss/app-rtl.scss');
  } else {
    import('../assets/scss/app.scss');
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={() => null}>
        {currentLocale === 'en' ? <Theme1En {...props} /> : <Theme1Ar {...props} />}
      </React.Suspense>
    </React.Fragment>
  );
};

export default ThemeSelector;
