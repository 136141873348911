import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchSearchResults, selectSearchResult } from '../../redux/search/thunks';
import Loader from '../Loader';
import Match from '../Match';
import { Button, Col, Row } from 'reactstrap';

function Results(props) {
  const onSelect = (matchId) => {
    props.selectSearchResult(matchId);
  };

  const loadMore = () => props.onLoadMore();

  const { results } = props;

  return (
    <React.Fragment>
      <div id="search-results">
        <h2 className="search-results-header">
          <FormattedMessage id="Search Results" />
        </h2>

        {props.results.loading && !props.results.items.length ? (
          <Loader />
        ) : !props.results.items.length ? (
          <p className="no-results">
            <FormattedMessage id="There are no results for your search." />
          </p>
        ) : (
          <React.Fragment>
            {props.results.items.map((result, i) => {
              const match = props.loadedMatches[result];

              return <Match match={match} showCompetition={true} onSelect={onSelect} key={i} />;
            })}

            {props.results.loading ? (
              <Loader />
            ) : (
              results.paginator.hasMorePages() && (
                <Row className="mb-3">
                  <Col md={{ size: 4, offset: 4 }}>
                    <Button color="secondary btn-block btn-load-more" onClick={loadMore}>
                      <FormattedMessage id="Load More" />
                    </Button>
                  </Col>
                </Row>
              )
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { loadedMatches } = state.matches;
  const { results } = state.search;
  return { results, loadedMatches };
};

export default connect(mapStateToProps, { fetchSearchResults, selectSearchResult })(Results);
