export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';

export const LOGIN_WITH_FACEBOOK_START = 'LOGIN_WITH_FACEBOOK_START';
export const LOGIN_WITH_FACEBOOK_SUCCESS = 'LOGIN_WITH_FACEBOOK_SUCCESS';
export const LOGIN_WITH_FACEBOOK_FAILURE = 'LOGIN_WITH_FACEBOOK_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_REFERRER = 'SET_REFERRER';
export const CLEAR_REFERRER = 'CLEAR_REFERRER';
