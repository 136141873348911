import {
  fetchMatchesStart,
  fetchMatchesSuccess,
  fetchMatchesFailure,
  receiveMatches,
  selectMatchFailure,
  selectMatchSuccess,
  selectMatchStart,
  saveMultiplePredictionsStart,
  saveMultiplePredictionsSuccess,
  saveMultiplePredictionsFailure,
  receiveBets,
} from './actions';
import api from '../../helpers/api';
import { addMatchToMyPredictions } from '../profile/actions';

export function fetchMatches(filters) {
  return async function (dispatch) {
    dispatch(fetchMatchesStart());

    return await api
      .fetchMatches(filters)
      .then((response) => response.data)
      .then((json) => {
        dispatch(receiveMatches(json.data));
        dispatch(fetchMatchesSuccess(json.data, json.meta?.dates));

        return json;
      })
      .catch(() => dispatch(fetchMatchesFailure()));
  };
}

export function selectMatch(matchId) {
  return async function (dispatch) {
    dispatch(selectMatchStart());

    api
      .fetchMatch(matchId)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveMatches([json]));
        dispatch(selectMatchSuccess(matchId));
      })
      .catch(() => dispatch(selectMatchFailure(matchId)));
  };
}

export function saveMultiplePredictions(matchId, bets) {
  return async function (dispatch) {
    dispatch(saveMultiplePredictionsStart(matchId));

    return await api
      .saveMultiplePredictions(matchId, bets)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveBets(matchId, json));
        dispatch(saveMultiplePredictionsSuccess(matchId));
        dispatch(addMatchToMyPredictions(matchId));
      })
      .catch((e) => {
        dispatch(saveMultiplePredictionsFailure(matchId));
        throw e;
      });
  };
}
