// @vendors
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
// @api
import api from '../../../helpers/api';
// @components
import Box from '../../Box';
import { Select } from '../../form/Select';
import { clean } from '../../../helpers/generic';
import useTrackers from '../../../hooks/useTrackers';
import PlayerList from '../../players/PlayerList';

const formatTeamList = (teams) => teams.map((t) => ({ value: t.id, label: t.name }));

const CompetitionTeamSetupFilters = (props) => {
  const { lineupPlayers, competition, onShowInfo, onToggle } = props;
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const trackers = useTrackers();

  const [teams, setTeams] = useState([]);

  const [filters, setFilters] = useState({
    competition: competition.id,
    team: '',
    q: '',
    price_from: '',
    price_to: '',
  });

  const intl = useIntl();

  const fetchPlayers = useCallback(
    debounce((unparsedFilters = filters) => {
      let parsedFilters = { ...unparsedFilters, team: unparsedFilters.team?.value };

      trackers.track('Button Clicked', {
        button: 'competition.filter_players',
        filters: parsedFilters,
      });

      api.fetchPlayers(clean(parsedFilters)).then((response) => {
        setPlayers(response.data.data);
      });
    }, 500),
    []
  );

  const setFilter = (key, value) => {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    fetchPlayers(newFilters);
  };

  useEffect(() => {
    const { competition } = filters;
    api.fetchTeams({ competition }).then((response) => {
      setTeams(response.data.data);
    });

    fetchPlayers();
  }, []);

  useEffect(() => {
    setSelectedPlayers(lineupPlayers);
  }, [lineupPlayers]);

  return (
    <Box>
      <FormGroup>
        <Label>
          <FormattedMessage id="Team" />
        </Label>
        <Select
          value={filters.team}
          options={formatTeamList(teams)}
          onChange={(value) => setFilter('team', value)}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup>
        <Label>
          <FormattedMessage id="Search" />
        </Label>
        <Input value={filters.q} onChange={(e) => setFilter('q', e.target.value)} />
      </FormGroup>

      <FormGroup>
        <Label>
          <FormattedMessage id="Max Cost" />
        </Label>
        <Row>
          <Col>
            <Input
              type="number"
              value={filters.price_from}
              placeholder={`${intl.formatMessage({ id: 'From' })} (1.2)`}
              onChange={(e) => setFilter('price_from', e.target.value)}
            />
          </Col>
          <Col>
            <Input
              type="number"
              value={filters.price_to}
              placeholder={`${intl.formatMessage({ id: 'To' })} (5.6)`}
              onChange={(e) => setFilter('price_to', e.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>

      <Box height="49.5rem" overflowY="auto" overflowX="hidden">
        <PlayerList
          players={players}
          selectedPlayers={selectedPlayers}
          onToggle={onToggle}
          onShowInfo={onShowInfo}
        />
      </Box>
    </Box>
  );
};

CompetitionTeamSetupFilters.propTypes = {
  lineupPlayers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CompetitionTeamSetupFilters;
