import styled from 'styled-components';

export const PlayerImg = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
  z-index: 2;
  position: relative;
`;

export const TeamImg = styled.img`
  width: 2rem;
  height: 2rem;
  position: absolute;
  inset-block-start: 60%;
  inset-inline-start: 70%;
  z-index: 3;
  ${({ isMobile }) =>
    isMobile &&
    `
    width: 1.5rem;
    height: 1.5rem;
    inset-block-start: 65%;
  `}
`;
