import * as actionTypes from '../../constants/action-types/ranking';

export const clearRanking = () => ({
  type: actionTypes.CLEAR_RANKING,
});

export const fetchMonthlyFriendRankingStart = () => ({
  type: actionTypes.FETCH_MONTHLY_FRIEND_RANKING_START,
});

export const fetchMonthlyFriendRankingSuccess = (ranking, meta) => ({
  type: actionTypes.FETCH_MONTHLY_FRIEND_RANKING_SUCCESS,
  payload: { ranking, meta },
});

export const fetchMonthlyFriendRankingFailure = () => ({
  type: actionTypes.FETCH_MONTHLY_FRIEND_RANKING_FAILURE,
});

export const fetchMyRankingStart = (type) => ({
  type: actionTypes.FETCH_MY_RANKING_START,
  payload: { type },
});

export const fetchMyRankingSuccess = (type, rankingItem, cacheUntil) => ({
  type: actionTypes.FETCH_MY_RANKING_SUCCESS,
  payload: { type, rankingItem, cacheUntil },
});

export const fetchMyRankingFailure = (type) => ({
  type: actionTypes.FETCH_MY_RANKING_FAILURE,
  payload: { type },
});
