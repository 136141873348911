// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import { MdPublic, MdLock } from 'react-icons/md';
import { UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../Box';
import Text from '../Text';
// @assets
import placeholderImg from '../../assets/images/group-card-placeholder.jpg';
import userPlaceholderImg from '../../assets/images/user-placeholder.png';

const MemberImg = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  display: inline-block;
  margin-inline-start: -0.5rem;
  border-radius: 100%;
  border: 1px solid #fff;
  object-fit: cover;
`;

const GroupCard = ({
  id,
  name,
  type,
  image,
  description,
  badge,
  joined,
  members,
  membersCount,
  onClick,
  ...rest
}) => {
  const history = useHistory();
  return (
    <Box
      id={`group_${id}`}
      display="flex"
      flexDirection="column"
      width="100%"
      border="2px solid #5ad37c"
      boxShadow="0 0 10px 0 rgba(52, 73, 94, 0.25)"
      bg="#f4f6fc"
      borderRadius="0.625rem"
      position="relative"
      cursor="pointer"
      onClick={() => history.push(`/groups/${id}`)}
      {...rest}
    >
      {/* Badge */}
      {badge > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="1.375rem"
          height="1.375rem"
          bg="#fc2c5d"
          fontSize="0.75rem"
          fontWeight="500"
          color="#fff"
          position="absolute"
          top="-0.375rem"
          right="-0.375rem"
          borderRadius="100%"
          zIndex="1"
        >
          {badge}
        </Box>
      )}
      {/* Header */}
      <Box
        position="relative"
        width="100%"
        minHeight="7rem"
        bg="#000"
        background={`url(${image || placeholderImg})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0.5rem 1rem 1.375rem"
        borderTopRightRadius="0.525rem"
        borderTopLeftRadius="0.525rem"
      >
        <Text textAlign="center" fontSize="1.5rem" color="#fff">
          {name}
        </Text>
        <Box
          position="absolute"
          bottom="-0.875rem"
          left="auto"
          right="auto"
          borderRadius="0.375rem"
          p="0.375rem 0.875rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={type === 'public' ? '#5ad3b8' : '#afadb2'}
          color="#000"
        >
          {type === 'public' ? <MdPublic /> : <MdLock />}
          <Text fontSize="1rem" fontWeight="500" color="#000" mis="0.5rem">
            {type === 'public' ? (
              <FormattedMessage id="Public Group" />
            ) : (
              <FormattedMessage id="Private Group" />
            )}
          </Text>
        </Box>
      </Box>
      {/* Content */}
      <Box display="flex" flexDirection="column" p="1rem" height="100%">
        {description && (
          <>
            <Text fontSize="1rem" fontWeight="500" color="#34495e" mb="0.375rem">
              Description:
            </Text>
            <Text fontSize="0.75rem" fontWeight="400" color="#34495e" lineHeight="1.3">
              {description}
            </Text>
          </>
        )}
        <Box display="flex" my="0.75rem" alignItems="center">
          <Text fontSize="1rem" fontWeight="500" color="#34495e" mie="1rem">
            Members:
          </Text>
          {members.slice(0, 5).map((member) => (
            <React.Fragment key={`group_${id}_user_${member?.id}`}>
              <MemberImg
                id={`group_${id}_user_${member?.id}`}
                src={member?.photo?.thumb || userPlaceholderImg}
                onError={(e) => {
                  e.target.src = userPlaceholderImg;
                }}
              />
              <UncontrolledTooltip placement="top" target={`group_${id}_user_${member?.id}`}>
                {member?.name}
              </UncontrolledTooltip>
            </React.Fragment>
          ))}
          {membersCount - 5 > 0 && (
            <Text fontSize="0.75rem" fontWeight="500" color="#34495e" mis="0.5rem">
              +{membersCount - 5}
            </Text>
          )}
        </Box>
        <button
          className={`btn btn-primary w-100 mt-auto`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick(id);
          }}
        >
          {joined ? <FormattedMessage id="Play Now" /> : <FormattedMessage id="Join Now" />}
        </button>
      </Box>
    </Box>
  );
};

GroupCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  joined: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  membersCount: PropTypes.number,
  onClick: PropTypes.func,
};

GroupCard.defaultProps = {
  name: '',
  type: 'public',
  image: '',
  description: '',
  badge: 0,
  joined: false,
  members: [],
  membersCount: 0,
  onClick: () => {},
};

export default GroupCard;
