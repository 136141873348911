import React from 'react';
import CompetitionPrize from './CompetitionPrize';

function CompetitionPrizes(props) {
  const { prizes } = props;

  return (
    <React.Fragment>
      <div className="competition-prizes">
        <div className="competition-prize-list">
          {prizes.slice(0, 3).map((prize, i) => (
            <CompetitionPrize prize={prize} ranking={i + 1} key={i} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompetitionPrizes;
