import api from '../../helpers/api';
import { changeLocale } from '../../helpers/generic';
import {
  updateSettingFailure,
  updateSettingStart,
  updateSettingSuccess,
  fetchSettingsSuccess,
  increaseCoins,
  receiveCreators,
  receiveRecentNotifications,
  receivePackages,
  receiveNewNotificationCount,
} from './actions';

export function fetchSettings() {
  return async function (dispatch) {
    api.fetchSettings().then((response) => {
      dispatch(fetchSettingsSuccess(response.data.data));
    });
  };
}

export function updateSetting(key, value) {
  return async function (dispatch) {
    dispatch(updateSettingStart(key, value));

    api
      .updateSetting({ key, value })
      .then(() => {
        if (key === 'language') {
          return changeLocale(value);
        }
        dispatch(updateSettingSuccess(key, value));
      })
      .catch(() => {
        dispatch(updateSettingFailure(key));
      });
  };
}

export function getUpcomingDateWithMatches() {
  return async function () {
    const response = await api.getUpcomingDateWithMatches();

    return response.data.data.date;
  };
}

export function chargePrepaidCard(code) {
  return async function (dispatch) {
    const response = await api.chargePrepaidCard(code);

    dispatch(increaseCoins(response.data.data.coins));
  };
}

export function fetchCreators() {
  return async function (dispatch) {
    const response = await api.fetchCreators();
    dispatch(receiveCreators(response.data.data));
  };
}

export function redeemVoucher(code) {
  return async function (dispatch) {
    const response = await api.redeemVoucher(code);

    dispatch(increaseCoins(response.data.data.coins));
  };
}

export function fetchRecentNotifications() {
  return async function (dispatch) {
    const response = await api.fetchNotifications({ limit: 5 });
    dispatch(receiveRecentNotifications(response.data.data));
    dispatch(receiveNewNotificationCount(response.data.meta.new_notification_count));
  };
}

export function markNotificationsAsRead() {
  return async function (dispatch) {
    await api.markNotificationsAsRead();
    dispatch(receiveNewNotificationCount(0));
  };
}

export function generateFawryReferenceCode(packageId, phoneNumber) {
  return async function () {
    return await api.generateFawryReferenceCode(packageId, phoneNumber).then((r) => r.data.data);
  };
}

export function fetchPackages() {
  return async function (dispatch, getState) {
    const packages = getState().generic.packages;

    if (!packages) {
      const response = await api.fetchPackages();
      dispatch(receivePackages(response.data.data));
    }
  };
}
