import React from 'react';
import { connect } from 'react-redux';
import { selectMatch } from '../../redux/matches/thunks';
import Match from '../Match';
import Tour from '../Tour';

function CompetitionWithMatches(props) {
  const { selectMatch } = props;

  const filtered = [];
  props.competition.matchIds.forEach((matchId) => {
    const match = props.loadedMatches[matchId];

    if (props.stateFilter === 'ALL' || match.match.state.code === props.stateFilter) {
      filtered.push(match);
    }
  });

  const { tutorial } = props;

  return (
    <React.Fragment>
      {props.first &&
        tutorial.active &&
        tutorial.shown.sidebarPredictions &&
        !tutorial.shown.match && (
          <Tour step="match" stepNumber="2" content="Select a match, and set your predictions." />
        )}

      {filtered.length !== 0 && (
        <div className="competition-with-matches">
          <h3 className="competition-name">
            {props.competition.logo && (
              <img
                src={props.competition.logo?.thumb}
                alt={`${props.competition.name} competition logo`}
              />
            )}
            {props.competition.name}
          </h3>
          <div className="match-list">
            {filtered.map((match, i) => {
              return (
                <div className={`match-wrapper ${i === 0 ? 'tutorial-match' : ''}`} key={match.id}>
                  <Match
                    match={match}
                    selectedMatchId={props.selectedMatch.id}
                    onSelect={selectMatch}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { tutorial } = state.generic;
  const { loadedMatches } = state.matches;
  const { stateFilter, selectedMatch } = state.matches.matches;
  return { loadedMatches, stateFilter, selectedMatch, tutorial };
};

export default connect(mapStateToProps, { selectMatch })(CompetitionWithMatches);
