import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { hidePrizeRedemptionModal } from '../redux/profile/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  fetchMyPrizes,
  generatePrizeRedemptionForms,
  submitPrizeRedemptionRequest,
} from '../redux/profile/thunks';
import { toast } from 'react-toastify';
import FormText from 'reactstrap/es/FormText';
import ValidationErrors from './ValidationErrors';
import Error from './Error';
import { FaEdit, FaCheck, FaExternalLinkAlt } from 'react-icons/fa';
import ViewExistingFile from './ViewExistingFile';
import ValidationRuleFactory from '../helpers/validation-rule-factory';
import { WALLET_ISSUERS } from '../constants/generic';

const PrizeRedemptionModal = () => {
  const prizeRedemption = useSelector((s) => s.profile.prizeRedemption);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [step, setStep] = useState(1);
  const [values, setValues] = useState();

  let title = null;
  if (prizeRedemption.userPrize) {
    title = intl.formatMessage(
      { id: 'redeem-prize' },
      {
        prize: `${prizeRedemption.userPrize?.amount} ${intl.formatMessage({ id: 'EGP' })}`,
      }
    );
  }

  const onNext = (newValues) => {
    setValues({ ...values, ...newValues });
    setStep(step + 1);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={prizeRedemption.showModal}
        toggle={() => dispatch(hidePrizeRedemptionModal())}
        title={title}
        id="prize-redemption-modal"
        onOpened={() => setStep(1)}
        backdrop="static"
      >
        <Steps currentStep={step} />

        {step === 1 && <Step1 onNext={onNext} />}
        {step === 2 && <Step2 values={values} onNext={onNext} />}
        {step === 3 && <Step3 values={values} />}
      </Modal>
    </React.Fragment>
  );
};

const Steps = ({ currentStep }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div className="progress-steps">
        <div className="progress-step">
          <img
            title={intl.formatMessage({ id: 'Personal Info' })}
            src={require('../assets/images/icon-personal-info-active.svg')}
            alt=""
          />
        </div>

        <div className="bar" />

        <div className="progress-step">
          <img
            title={intl.formatMessage({ id: 'Redemption Method' })}
            src={
              currentStep >= 2
                ? require('../assets/images/icon-redemption-method-active.svg')
                : require('../assets/images/icon-redemption-method.svg')
            }
            alt=""
          />
        </div>

        <div className="bar" />

        <div className="progress-step">
          <img
            title={intl.formatMessage({ id: 'Review' })}
            src={
              currentStep >= 3
                ? require('../assets/images/icon-review-active.svg')
                : require('../assets/images/icon-review.svg')
            }
            alt=""
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const Step1 = ({ onNext }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const userPrize = useSelector((s) => s.profile.prizeRedemption.userPrize);
  const existingNationalIdScans = useSelector((s) => s.auth.user.national_id_scans);
  const isNationalIdVerified = useSelector((s) => s.auth.user.national_id_verified);
  const user = useSelector((s) => s.auth.user);

  const [validationErrors, setValidationErrors] = useState(null);

  const initialData = {
    user_prize_id: userPrize?.id,
    legal_name: user.legal_name ?? '',
    phone_number: user.phone_number ?? '',
    email: user.email ?? '',
    national_id: user.national_id ?? '',
    national_id_scans: { front: '', back: '' },
    forms: null,
  };
  const [data, setData] = useState(initialData);

  const validationSchema = Yup.object().shape({
    legal_name: ValidationRuleFactory.legalName(),
    email: ValidationRuleFactory.email(),
    phone_number: ValidationRuleFactory.phoneNumber(),
    national_id: ValidationRuleFactory.nationalId(true),
    national_id_scans: ValidationRuleFactory.nationalIdScans(intl),
  });

  const onEditInfo = () => {
    setData({ ...data, forms: null });
  };

  const getForms = (newValues, setSubmitting) => {
    dispatch(generatePrizeRedemptionForms(newValues))
      .then((forms) => {
        setSubmitting(false);
        setData({ ...newValues, forms });
      })
      .catch((e) => {
        if (e.response.status === 422) {
          setValidationErrors(e.response.data.errors);
        }

        setSubmitting(false);
      });
  };

  const setNationalIdScan = (event, field, setFieldValue) => {
    const files = Object.values(event.currentTarget.files);

    setFieldValue(field, files.length ? files[0] : null);
  };

  return (
    <React.Fragment>
      {validationErrors && <ValidationErrors errors={validationErrors} />}

      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => getForms(values, setSubmitting)}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            setTouched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          } = props;

          return (
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <FormGroup>
                <Label for="name">
                  <FormattedMessage id="Full Legal Name" />
                </Label>
                <Input
                  type="text"
                  name="legal_name"
                  id="legal-name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.legal_name}
                  invalid={errors.legal_name && touched.legal_name}
                  disabled={!!data.forms}
                />
              </FormGroup>

              <FormGroup>
                <Label for="email">
                  <FormattedMessage id="email" />
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  invalid={errors.email && touched.email}
                  disabled={!!data.forms}
                />
              </FormGroup>

              <FormGroup>
                <Label for="phone-number">
                  <FormattedMessage id="Phone Number" />
                </Label>
                <Input
                  type="text"
                  name="phone_number"
                  id="phone-number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  invalid={errors.phone_number && touched.phone_number}
                  disabled={!!data.forms}
                />
              </FormGroup>

              <FormGroup>
                <Label for="notional-id">
                  <FormattedMessage id="National Id" />
                </Label>
                <Input
                  type="text"
                  name="national_id"
                  id="national-id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.national_id}
                  invalid={errors.national_id && touched.national_id}
                  disabled={user.national_id_verified || !!data.forms}
                />
              </FormGroup>

              <FormGroup>
                <Label for="national-id-scan-front">
                  <FormattedMessage id="National Id Scans (front and back)" />
                </Label>
                <div className="row">
                  <div className="col">
                    <FormGroup>
                      <Label for="national-id-scan-front">
                        <FormattedMessage id="Front Side" />
                      </Label>
                      :{' '}
                      {!isNationalIdVerified && (
                        <Input
                          type="file"
                          required
                          id="national-id-scan-front"
                          invalid={errors.national_id_scans && touched.national_id_scans}
                          onChange={(e) =>
                            setNationalIdScan(e, 'national_id_scans.front', setFieldValue)
                          }
                          onBlur={() => setTouched({ ...touched, national_id_scans: true })}
                          disabled={!!data.forms}
                        />
                      )}
                      {!!existingNationalIdScans.front && (
                        <ViewExistingFile
                          href={existingNationalIdScans.front.original}
                          target="_blank"
                        >
                          <FormattedMessage id="View uploaded" /> <FaExternalLinkAlt />
                        </ViewExistingFile>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup>
                      <Label for="national-id-scan-back">
                        <FormattedMessage id="Back Side" />
                      </Label>
                      :{' '}
                      {!isNationalIdVerified && (
                        <Input
                          type="file"
                          required
                          id="national-id-scan-back"
                          invalid={errors.national_id_scans && touched.national_id_scans}
                          onChange={(e) =>
                            setNationalIdScan(e, 'national_id_scans.back', setFieldValue)
                          }
                          onBlur={() => setTouched({ ...touched, national_id_scans: true })}
                          disabled={!!data.forms}
                        />
                      )}
                      {!!existingNationalIdScans.back && (
                        <ViewExistingFile
                          href={existingNationalIdScans.back.original}
                          target="_blank"
                        >
                          <FormattedMessage id="View uploaded" /> <FaExternalLinkAlt />
                        </ViewExistingFile>
                      )}
                    </FormGroup>
                  </div>
                </div>

                {!!touched.national_id_scans && !!errors.national_id_scans?.length && (
                  <FormText color="danger">{errors.national_id_scans}</FormText>
                )}
              </FormGroup>

              <div className="my-3">
                <Button
                  type="submit"
                  disabled={isSubmitting || !!data.forms}
                  className="btn btn-primary btn-block"
                >
                  <FormattedMessage id="Get Forms" />
                  {isSubmitting && <Spinner />}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      {data.forms && (
        <React.Fragment>
          <div className="row">
            {data.forms.map((form) => {
              return (
                <div className="col-auto" key={form.name}>
                  <div className="prize-redemption-form">
                    <h3 className="form-name">
                      <FormattedMessage id={form.name} />
                    </h3>

                    <div className="my-1">
                      <img src={require('../assets/images/icon-document.png')} alt="" />
                    </div>

                    <a href={form.file} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="Preview" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            <div className="col-6">
              <Button
                type="button"
                className="btn btn-secondary btn-block mr-1"
                onClick={onEditInfo}
              >
                <FaEdit /> <FormattedMessage id="Edit Info" />
              </Button>
            </div>
            <div className="col-6">
              <Button
                type="button"
                className="btn btn-success btn-block"
                onClick={() => onNext(data)}
              >
                <FormattedMessage id="Sign & Continue" /> <FaCheck />
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Step2 = ({ values, onNext }) => {
  const initialValues = {
    redemption_method: {
      name: 'E-Wallet',
      options: {
        phone_number: values.phone_number,
        wallet: {
          issuer: WALLET_ISSUERS[0],
        },
      },
    },
  };

  const validationSchema = Yup.object().shape({
    redemption_method: Yup.object().shape({
      name: Yup.string().required(),
      options: Yup.object().shape({
        phone_number: Yup.string().matches(/^(01)[0-9]{9}$/),
      }),
    }),
  });

  const onSubmit = (values) => {
    onNext(values);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>
                <FormattedMessage id="Redemption Method" />
              </Label>

              <FormGroup check>
                <Input
                  type="radio"
                  name="redemption_method[name]"
                  id="redemption-method-name-e-wallet"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="E-Wallet"
                  invalid={errors.redemption_method?.name && touched.redemption_method?.name}
                  checked={values.redemption_method.name === 'E-Wallet'}
                />
                <Label check for="redemption-method-name-e-wallet">
                  <FormattedMessage id="E-Wallet" />
                </Label>
              </FormGroup>

              {values.redemption_method.name === 'E-Wallet' && (
                <div className="sub-options mt-1 mb-3">
                  <FormGroup>
                    <Label for="method-wallet-issuer">
                      <FormattedMessage id="Wallet Issuer" />
                    </Label>
                    <div>
                      {WALLET_ISSUERS.map((issuer) => (
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="redemption_method[options][wallet][issuer]"
                            id={`redemption-method-options-wallet-issuer-${issuer}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={issuer}
                            invalid={
                              errors.redemption_method?.options?.wallet?.issuer &&
                              touched.redemption_method?.options?.wallet?.issuer
                            }
                            checked={values.redemption_method.options?.wallet?.issuer === issuer}
                          />
                          <Label check for={`redemption-method-options-wallet-issuer-${issuer}`}>
                            <FormattedMessage id={`wallet.issuer.${issuer}`} />
                          </Label>
                        </FormGroup>
                      ))}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-0">
                    <Label for="method-phone-number">
                      <FormattedMessage id="Phone Number" />
                    </Label>
                    <Input
                      type="text"
                      name="redemption_method[options][phone_number]"
                      id="redemption-method-options-phone-number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.redemption_method.options.phone_number}
                      invalid={
                        errors.redemption_method?.options.phone_number &&
                        touched.redemption_method?.options.phone_number
                      }
                    />
                  </FormGroup>
                </div>
              )}

              <FormGroup check>
                <Input
                  type="radio"
                  name="redemption_method[name]"
                  id="redemption-method-name-coins"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Coins"
                  invalid={errors.redemption_method?.name && touched.redemption_method?.name}
                  checked={values.redemption_method.name === 'Coins'}
                />
                <Label check for="redemption-method-name-coins">
                  <FormattedMessage id="Coins" />
                </Label>
              </FormGroup>
            </FormGroup>

            <Button type="submit" disabled={isSubmitting} className="btn btn-success btn-block">
              <FormattedMessage id="Next" />
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const Step3 = ({ values }) => {
  const { national_id_scans } = values;
  const [previews, setPreviews] = useState([]);
  const [validationErrors, setValidationErrors] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const existingNationalIdScans = useSelector((s) => s.auth.user.national_id_scans);

  useEffect(() => {
    setPreviews([
      national_id_scans.front
        ? URL.createObjectURL(national_id_scans.front)
        : existingNationalIdScans.front.original,
      national_id_scans.back
        ? URL.createObjectURL(national_id_scans.back)
        : existingNationalIdScans.back.original,
    ]);

    return () => {
      URL.revokeObjectURL(previews[0]);
      URL.revokeObjectURL(previews[1]);
    };
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(submitPrizeRedemptionRequest({ ...values }))
      .then(async () => {
        await dispatch(fetchMyPrizes());
        dispatch(hidePrizeRedemptionModal());
        toast.success(intl.formatMessage({ id: 'redemption-request-added' }));
      })
      .catch((e) => {
        if (e.response.status === 422) {
          setValidationErrors(e.response.data.errors);
        } else {
          setError(e.response.data.message);
        }

        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {!!validationErrors && <ValidationErrors errors={validationErrors} />}

      {!!error && <Error error={error} />}

      <div className="step-review">
        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="Full Legal Name" />:
          </h3>
          <p className="review-item-value">{values.legal_name}</p>
        </div>

        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="Phone Number" />:
          </h3>
          <p className="review-item-value">{values.phone_number}</p>
        </div>

        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="Email" />:
          </h3>
          <p className="review-item-value">{values.email}</p>
        </div>

        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="National Id" />:
          </h3>
          <p className="review-item-value">{values.national_id}</p>
        </div>

        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="National Id Scans (front and back)" />:
          </h3>
          <div>
            <p className="review-item-value">
              {previews.map((preview, key) => (
                <img
                  key={key}
                  src={preview}
                  className="national-id-scan-preview"
                  alt="national id scan preview"
                />
              ))}
            </p>
          </div>
        </div>

        <div className="review-item">
          <h3 className="review-item-label">
            <FormattedMessage id="Redemption Method" />:
          </h3>
          <p className="review-item-value">
            {values.redemption_method.name}{' '}
            {values.redemption_method.name === 'E-Wallet' ? (
              <React.Fragment>
                -{' '}
                <FormattedMessage
                  id={`wallet.issuer.${values.redemption_method.options.wallet.issuer}`}
                />{' '}
                - {values.redemption_method.options.phone_number}
              </React.Fragment>
            ) : null}
          </p>
        </div>
      </div>

      <Button
        onClick={() => onSubmit(values)}
        className="btn btn-primary btn-success btn-block"
        disabled={loading}
      >
        <FormattedMessage id="Submit" />
        {loading && <Spinner />}
      </Button>
    </React.Fragment>
  );
};

export default PrizeRedemptionModal;
