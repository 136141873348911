import React, { useState } from 'react';
import CustomInput from './CustomInput';

const ToggledCustomInput = ({ toggle, type, ...rest }) => {
  const [toggled, setToggled] = useState(false);
  const toggleIcon = toggled
    ? require('../../assets/images/icon-eye.svg')
    : require('../../assets/images/icon-eye-strikethrough.svg');

  const className = `${rest.className} ${toggle ? 'has-toggle' : ''}`;

  return (
    <CustomInput
      {...rest}
      className={className}
      type={toggled ? 'text' : type ?? 'text'}
      addon={
        <span className="toggle-wrapper">
          <img
            className="toggle-icon"
            src={toggleIcon}
            onClick={() => setToggled(!toggled)}
            alt=""
          />
        </span>
      }
    />
  );
};

export default ToggledCustomInput;
