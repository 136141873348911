import React from 'react';
import { FormattedMessage } from 'react-intl';

const NotFoundPage = () => {
  return (
    <div className="page page-404">
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <h1>
            <FormattedMessage id="Page not found" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
