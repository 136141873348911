import React, { useState } from 'react';
import { Input as BaseInput } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { verifyPhoneNumber } from '../../redux/profile/thunks';

const PhoneNumberInput = ({ placeholder, onChange, onSent, onThrottle, ...rest }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const [buttonText, setButtonText] = useState(intl.formatMessage({ id: 'Verify' }));

  placeholder = placeholder ?? intl.formatMessage({ id: 'Enter your phone number' });

  const onVerify = () => {
    setLoading(true);
    setMessage('');
    setStatus('');

    dispatch(verifyPhoneNumber(value))
      .then(() => {
        setMessage(intl.formatMessage({ id: 'otp_sent' }));
        setStatus('text-success');
        setButtonText(intl.formatMessage({ id: 'Resend' }));

        onSent();
      })
      .catch((e) => {
        if (e.response.status === 422) {
          setMessage(intl.formatMessage({ id: 'used_phone_number' }));
          setStatus('text-danger');
        } else if (e.response.status === 429) {
          onThrottle();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="custom-input-wrapper custom-input-wrapper-phone-number">
        <span className="icon-wrapper">
          <img src={require('../../assets/images/icon-phone.svg')} alt="" />
        </span>
        <BaseInput
          {...rest}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <button className="btn btn-primary" onClick={onVerify} disabled={loading}>
          {buttonText}
        </button>
      </div>
      {!!message && <p className={`mt-1 mb-0 ${status}`}>{message}</p>}
    </React.Fragment>
  );
};

export default PhoneNumberInput;
