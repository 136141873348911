// @vendors
import React, { useEffect } from 'react';

const GroupRulesEn = () => {
  useEffect(() => {}, []);

  return (
    <div className="page-rules">
      <div className="rule-section">
        <p>You can create new groups and invite your friends to join.</p>

        <p>
          Group managers can create competitions for users inside the group to join. These
          competitions will have coins rewards only.
        </p>

        <h3 className="rule-header">#eksab_groups rules</h3>

        <ul>
          <li>You can create fantasy or prediction competitions.</li>
          <li>Public groups are open for anyone to join.</li>
          <li>Private groups can be joined by invitation only.</li>
          <li>
            Leagues available for user-created competitions are English Primer League, Italian
            League, Spanish League, Europa League, and Champions League.
          </li>
          <li>Also you can use World Cup and, Euro.</li>
          <li>
            For Fantasy competitions, all games should be in the same league. While for predictions,
            you are free to choose games from multiple leagues
          </li>
          <li>Group manager is not allowed to change the competition after it was created.</li>
          <li>
            The prize is determined based on the number of participants (Entry Fee * Participant
            Count).
          </li>
          <li>
            Competition will be auto-cancelled if the number of participants didn't fill the min
            requirement one hour before the competition starts. All entry fees will be refunded.
          </li>
          <li>Min participants is determined by the competition creator at create time.</li>
        </ul>
      </div>
    </div>
  );
};

export default GroupRulesEn;
