// @vendors
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../components/Box';
import Text from '../components/Text';
// @assets
import eksabLogo from '../assets/images/logo.svg';
import androidDownload from '../assets/images/android-download.png';
import iosDownload from '../assets/images/ios-download.png';
import downloadIllustration from '../assets/images/download-illustration.svg';

const DownloadAppPage = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    bg="#fafbfc"
    width="100%"
    overflow="hidden"
    overflowY="auto"
    p="2.75rem 0.75rem 1rem"
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="45rem"
      width="100%"
      overflow="hidden"
    >
      <EksabLogo src={eksabLogo} />
      <Text textAlign="center" fontSize="3rem" fontWeight="900" color="#34495e" mb="1rem">
        <FormattedMessage id="DownloadAppPage.Title" />
      </Text>
      <Text textAlign="center" fontSize="1rem" color="#34495e">
        <FormattedMessage id="DownloadAppPage.Description" />
      </Text>
      <Box display="flex" m="2.25rem 0">
        <AppDownloadButton
          src={iosDownload}
          onClick={() => {
            let win = window.open(
              'https://apps.apple.com/eg/app/%D8%A7%D9%83%D8%B3%D8%A8-eksab/id1263114952',
              '_blank'
            );
            win.focus();
          }}
        />
        <AppDownloadButton
          src={androidDownload}
          onClick={() => {
            let win = window.open(
              'https://eksab-static.s3-eu-west-1.amazonaws.com/EksabApp.apk',
              '_blank'
            );
            win.focus();
          }}
        />
      </Box>
      <DownloadImg src={downloadIllustration} />
    </Box>
  </Box>
);

export default DownloadAppPage;

const EksabLogo = styled.img`
  width: 10.25rem;
  margin-bottom: 4rem;
`;

const AppDownloadButton = styled.img`
  margin: 0 0.75rem;
  max-height: 3.5rem;
  cursor: pointer;
  max-width: Calc(50% - 1.5rem);
`;

const DownloadImg = styled.img`
  width: Calc(100% - 1.5rem);
`;
