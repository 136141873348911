import React from 'react';
import { useIntl } from 'react-intl';
import EGP from './EGP';
import Coins from './Coins';
import Time from './Time';

const TransactionDate = ({ date }) => {
  return (
    <p className="transaction-date transaction-label">
      <Time time={date} />
    </p>
  );
};

const TransactionProvider = ({ provider }) => {
  return <img src={provider.logo} className="transaction-logo" alt={provider.name} />;
};

const TransactionRef = ({ transaction }) => {
  const intl = useIntl();
  const { fawry_reference_code: referenceCode } = transaction;

  return (
    <div className="transaction-ref">
      <p className="transaction-label">{intl.formatMessage({ id: 'Reference Code' })}</p>
      <p className="transaction-code mb-0">{referenceCode.code}</p>

      <div className="mt-3">
        <p className="transaction-label">{intl.formatMessage({ id: 'Expires At' })}</p>
        <p className="transaction-code mb-0">
          <Time time={referenceCode.expires_at} />
        </p>
      </div>

      {referenceCode.paid_at && (
        <div className="mt-3">
          <p className="transaction-label">{intl.formatMessage({ id: 'Paid At' })}</p>
          <p className="transaction-code mb-0">
            <Time time={referenceCode.paid_at} />
          </p>
        </div>
      )}
    </div>
  );
};

const TransactionStatus = ({ status }) => {
  const intl = useIntl();

  return (
    <div className="transaction-status">
      <p className="transaction-label">{intl.formatMessage({ id: 'Status' })}</p>
      <p className="transaction-status mb-0">
        {intl.formatMessage({ id: 'transactions.statuses.' + status })}
      </p>
    </div>
  );
};

const TransactionDescription = ({ transaction }) => {
  const intl = useIntl();

  const { description } = transaction;

  return (
    <React.Fragment>
      <p className="transaction-description mb-3">{description}</p>

      <div className="row">
        <div className="col-4">
          <p className="transaction-label">{intl.formatMessage({ id: 'Coins' })}</p>
          <p className="transaction-value mb-0">
            <Coins coins={transaction.coins} />
          </p>
        </div>
        <div className="col-4">
          <p className="transaction-label">{intl.formatMessage({ id: 'EGP' })}</p>
          <p className="transaction-value mb-0">
            <EGP egp={transaction.egp} />
          </p>
        </div>
        <div className="col-4">
          <TransactionStatus status={transaction.status} />
        </div>
      </div>
    </React.Fragment>
  );
};

const Transaction = ({ transaction }) => {
  return (
    <React.Fragment>
      <div className="transaction">
        <div className="row align-items-center">
          <div className="col-md-3">
            <TransactionDate date={transaction.created_at} />
            <TransactionProvider provider={transaction.provider} />
          </div>

          <div className="col-md-6">
            <TransactionDescription transaction={transaction} />
          </div>

          <div className="col-md-3">
            {transaction.fawry_reference_code && <TransactionRef transaction={transaction} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Transaction;
