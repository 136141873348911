import {
  fetchMyPredictionsStart,
  fetchMyPredictionsSuccess,
  fetchMyPredictionsFailure,
  selectMyPredictionsMatchStart,
  selectMyPredictionsMatchSuccess,
  selectMyPredictionsMatchFailure,
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  receiveFriends,
  receiveUserPrizes,
  updateProfileSuccess,
  receiveUserNewPrizeCount,
  receiveOrders,
} from './actions';
import api from '../../helpers/api';
import { receiveMatches } from '../matches/actions';
import { markNotificationsAsRead } from '../generic/thunks';

export function fetchMyPredictions() {
  return async function (dispatch) {
    dispatch(fetchMyPredictionsStart());

    api
      .fetchMyPredictions()
      .then((response) => response.data.data)
      .then((json) => {
        const parsed = parsePredictions(json);

        dispatch(receiveMatches(parsed));
        dispatch(fetchMyPredictionsSuccess(json));
      })
      .catch(() => dispatch(fetchMyPredictionsFailure()));
  };
}

export function fetchNotifications() {
  return async function (dispatch) {
    dispatch(fetchNotificationsStart());

    await api
      .fetchNotifications()
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(fetchNotificationsSuccess(json));
      })
      .catch(() => dispatch(fetchNotificationsFailure()));

    await dispatch(markNotificationsAsRead());
  };
}

const parsePredictions = (predictions) => {
  return predictions.map((prediction) => {
    let parsed = prediction.bet.game;
    parsed['match_season_id'] = parsed.id;

    return parsed;
  });
};

export function selectMyPredictionMatch(matchId) {
  return async function (dispatch) {
    dispatch(selectMyPredictionsMatchStart());

    api
      .fetchMatch(matchId)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveMatches([json]));
        dispatch(selectMyPredictionsMatchSuccess(matchId));
      })
      .catch(() => dispatch(selectMyPredictionsMatchFailure(matchId)));
  };
}

export function fetchMyFriends() {
  return async function (dispatch) {
    return await api
      .fetchMyFriends()
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveFriends(json));
      });
  };
}

export function fetchMyPrizes() {
  return async function (dispatch) {
    await api
      .fetchMyPrizes()
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveUserPrizes(json));
      });

    await api.markMyPrizesAsRead();

    dispatch(receiveUserNewPrizeCount(0));
  };
}

export function fetchMyNewPrizeCount() {
  return async function (dispatch) {
    return await api
      .fetchMyNewPrizeCount()
      .then((response) => response.data.data.count)
      .then((count) => {
        dispatch(receiveUserNewPrizeCount(count));
      });
  };
}

export function fetchTransactions() {
  return async function () {
    return await api.fetchTransactions().then((response) => response.data.data);
  };
}

export function updateProfile(data) {
  return async function (dispatch) {
    const response = await api.updateProfile(data).then((response) => response.data.data);

    dispatch(updateProfileSuccess(response));
  };
}

export function verifyPhoneNumber(phoneNumber) {
  return async function () {
    await api.verifyPhoneNumber(phoneNumber);
  };
}

export function updatePhoneNumber(data) {
  return async function (dispatch) {
    await api.updatePhoneNumber(data);
    const { phone_number } = data;

    dispatch(updateProfileSuccess({ phone_number }));
  };
}

export function generatePrizeRedemptionForms(values) {
  return async function () {
    return await api.generatePrizeRedemptionForms(values).then((response) => response.data.data);
  };
}

export function submitPrizeRedemptionRequest(values) {
  return async function () {
    return await api.submitPrizeRedemptionRequest(values).then((response) => response.data.data);
  };
}

export function fetchOrders() {
  return async function (dispatch) {
    await api
      .fetchMyOrders()
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveOrders(json));
      });
  };
}
