import React, { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { clean } from '../../helpers/generic';

function Filters(props) {
  const [filters, setFilters] = useState({ q: '', ...props.initialFilters });

  const setFilter = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const onFilter = () => {
    props.onFilter(clean({ ...filters }));
  };

  return (
    <React.Fragment>
      <div id="search-filters">
        <Row>
          <Col md={6}>
            <div className="match-search">
              <input
                value={filters.q}
                type="text"
                className="form-control"
                placeholder={useIntl().formatMessage({ id: 'search-message' })}
                onChange={(event) => setFilter('q', event.target.value)}
              />
              <button className="btn" onClick={onFilter}>
                <MdSearch className="icon" />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Filters;
