import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import CoinsFormatted from './Coins';
import EGPFormatted from './EGP';
import useTrackers from '../hooks/useTrackers';

const Coins = ({ coins }) => {
  return (
    <div className="pricing-package-coins">
      <h2 className="value">
        <CoinsFormatted coins={coins} />
        <small>
          <FormattedMessage id="coins" />
        </small>
      </h2>
    </div>
  );
};

const EGP = ({ pricingPackage }) => {
  let { egp: originalPrice, discounted_price: discountedPrice } = pricingPackage;

  originalPrice = parseFloat(originalPrice);
  discountedPrice = parseFloat(discountedPrice);

  return (
    <div className="pricing-package-egp">
      <div className="wrapper">
        <span className="currency">
          <FormattedMessage id="EGP" />
        </span>

        <h2 className="egp">
          <EGPFormatted egp={discountedPrice} />
        </h2>

        {originalPrice !== discountedPrice && (
          <p className="original-price">
            <NumberFormat
              value={originalPrice}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={0}
            />
          </p>
        )}
      </div>
    </div>
  );
};

const PricingPackage = ({ pricingPackage: p, ...props }) => {
  const intl = useIntl();
  const trackers = useTrackers();

  const onSelect = (p) => {
    trackers.track('Button Clicked', {
      button: 'pricing.buy_now',
      package: p.coins,
    });

    props.onSelect(p);
  };

  return (
    <div className="pricing-package" onClick={() => onSelect(p)}>
      <img src={require('../assets/images/icon-pay.png')} className="pricing-icon" alt="" />
      <h3 className="package-name">{p.name}</h3>
      <div className="payment-methods">
        <h4>
          <FormattedMessage id="Pay with:" />
        </h4>
        <img
          src={require('../assets/images/fawry.png')}
          className="payment-method-icon"
          alt="fawry"
        />
        <img
          src={require('../assets/images/logo-visa.png')}
          className="payment-method-icon"
          alt="visa"
        />
        <img
          src={require('../assets/images/logo-mastercard.png')}
          className="payment-method-icon"
          alt="mastercard"
        />
      </div>

      <Coins coins={p.coins} />

      <EGP pricingPackage={p} />

      <button className="btn btn-primary btn-buy-now" onClick={() => onSelect(p)}>
        {intl.formatMessage({ id: 'Buy Now' })} <br />
      </button>
    </div>
  );
};

export default PricingPackage;
