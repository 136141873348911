import * as actionTypes from '../../constants/action-types/matches';

const initMatches = {
  items: [],
  stateFilter: 'ALL',
  loading: false,
  selectedMatch: { id: null, loading: false },
  nextDate: null,
  previousDate: null,
};

const INIT_STATE = {
  official: {},
  loadedMatches: {},
  matches: initMatches,
  flat: [],
  savingPredictionsForMatchIds: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_MATCHES:
      return {
        ...state,
        loadedMatches: mergeGames(state.loadedMatches, action.payload.matches),
      };

    case actionTypes.RECEIVE_OFFICIAL_MATCHES:
      return {
        ...state,
        official: mergeGames(state.official, action.payload.matches),
      };

    case actionTypes.RECEIVE_BETS:
      const { matchId } = action.payload;

      return {
        ...state,
        loadedMatches: {
          ...state.loadedMatches,
          [matchId]: {
            ...state.loadedMatches[matchId],
            bets: action.payload.bets.filter((bet) => bet.type !== 'game-score'),
            game_score_bet: action.payload.bets.filter((bet) => bet.type === 'game-score')[0],
          },
        },
      };
    case actionTypes.CLEAR_MATCHES:
      return { ...state, matches: initMatches };

    case actionTypes.FETCH_MATCHES_START:
      return {
        ...state,
        matches: { ...state.matches, items: [], loading: true, nextDate: null, previousDate: null },
      };
    case actionTypes.FETCH_MATCHES_SUCCESS:
      return onFetchMatchSuccess(state, action);

    case actionTypes.FETCH_MATCHES_FAILURE:
      return { ...state, matches: { ...state.matches, loading: false } };
    case actionTypes.SELECT_MATCH_START:
      return {
        ...state,
        matches: { ...state.matches, selectedMatch: { id: null, loading: true } },
      };
    case actionTypes.SELECT_MATCH_SUCCESS:
      return {
        ...state,
        matches: {
          ...state.matches,
          selectedMatch: {
            ...state.matches.selectedMatch,
            id: action.payload.matchId,
            loading: false,
          },
        },
      };
    case actionTypes.UNSELECT_MATCH:
      return {
        ...state,
        matches: { ...state.matches, selectedMatch: { id: null, loading: false } },
      };

    case actionTypes.SAVE_MULTIPLE_PREDICTIONS_START:
      return {
        ...state,
        savingPredictionsForMatchIds: [
          ...state.savingPredictionsForMatchIds,
          action.payload.matchId,
        ],
      };
    case actionTypes.SAVE_MULTIPLE_PREDICTIONS_SUCCESS:
      return {
        ...state,
        savingPredictionsForMatchIds: state.savingPredictionsForMatchIds.filter(
          (matchId) => action.payload.matchId !== matchId
        ),
      };
    case actionTypes.SAVE_MULTIPLE_PREDICTIONS_FAILURE:
      return {
        ...state,
        savingPredictionsForMatchIds: state.savingPredictionsForMatchIds.filter(
          (matchId) => action.payload.matchId !== matchId
        ),
      };

    case actionTypes.FILTER_MATCHES_BY_STATE:
      return {
        ...state,
        matches: { ...state.matches, stateFilter: action.payload.stateFilter },
      };

    default:
      return { ...state };
  }
};

const groupMatchesByCompetition = (matches) => {
  let competitions = [];

  matches.forEach((match) => {
    const { competition } = match.season;

    if (competition) {
      if (!competitions[competition.id]) {
        const { id, name, logo } = competition;
        competitions[competition.id] = { id, name, logo, matchIds: [] };
      }

      competitions[competition.id].matchIds.push(match.id);
    }
  });

  return Object.values(competitions);
};

const onFetchMatchSuccess = (state, action) => {
  const grouped = groupMatchesByCompetition(action.payload.matches);

  return {
    ...state,
    matches: {
      ...state.matches,
      items: grouped,
      nextDate: action.payload.dates?.nextDate,
      previousDate: action.payload?.dates?.previousDate,
      loading: false,
    },
    flat: grouped.map((c) => c.matchIds).flat(),
  };
};

const mergeGames = (currentGames, newGames) => {
  let merged = { ...currentGames };

  newGames.forEach((v) => {
    v.id = v.match_season_id ?? v.id;
    merged[v.id] = v;
  });

  return merged;
};
