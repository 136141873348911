import React from 'react';
import Loader from './Loader';

function FullPageLoader() {
  return (
    <React.Fragment>
      <div id="full-page-loader">
        <Loader />
      </div>
    </React.Fragment>
  );
}

export default FullPageLoader;
