import Box from '../Box';
import {
  DeselectButton,
  InfoButton,
  SelectButton,
  StyledBox,
} from '../competitions/CompetitionTeamSetupFilters/styles';
import React from 'react';
import PlayerName from './PlayerName';
import PlayerPrice from './PlayerPrice';
import PlayerPoints from './PlayerFantasyPoint';
import { FaInfo, FaMinus, FaPlus } from 'react-icons/fa';
import { calculateColumns } from './PlayerList';
import PlayerTeam from './PlayerTeam';

const Player = ({ player, selectedPlayerIds, onToggle, onShowInfo, showTeam }) => {
  const columns = calculateColumns(showTeam);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py=".5rem"
      cursor="pointer"
      onClick={
        onToggle
          ? () => {
              onToggle(player.position, player);
            }
          : undefined
      }
    >
      {onShowInfo && (
        <StyledBox flex="calc(10% - 2px)">
          <InfoButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onShowInfo(player);
            }}
          >
            <FaInfo />
          </InfoButton>
        </StyledBox>
      )}
      <StyledBox
        flex={`calc(${columns[onShowInfo ? 'playerWithInfo' : 'player'].width} - ${
          columns[onShowInfo ? 'playerWithInfo' : 'player'].offset
        })`}
      >
        <PlayerName player={player} />
      </StyledBox>
      {showTeam && (
        <StyledBox flex={`calc(${columns.team.width} - ${columns.team.offset})`} textAlign="center">
          <PlayerTeam player={player} />
        </StyledBox>
      )}
      <StyledBox flex={`calc(${columns.price.width} - ${columns.price.offset})`} textAlign="center">
        <PlayerPrice player={player} />
      </StyledBox>
      <StyledBox
        flex={`calc(${columns.points.width} - ${columns.points.offset})`}
        textAlign="center"
      >
        <PlayerPoints points={player.fantasy_points} />
      </StyledBox>
      {onToggle && (
        <StyledBox flex={`calc(10% - 2px)`}>
          {selectedPlayerIds.indexOf(player.id) !== -1 ? (
            <DeselectButton>
              <FaMinus />
            </DeselectButton>
          ) : (
            <SelectButton>
              <FaPlus />
            </SelectButton>
          )}
        </StyledBox>
      )}
    </Box>
  );
};

export default Player;
