// @vendors
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { filter, findIndex, isEqual, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Prompt, useLocation } from 'react-router';
import { Tooltip } from 'reactstrap';
// @components
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import Modal from '../../../../components/Modal';
import CompetitionSquadSelectionCard from '../../../../components/competitions/CompetitionSquadSelectionCard';
import CompetitionLineupSelector, {
  lineupsList,
} from '../../../../components/competitions/CompetitionLineupSelector';
import CompetitionLineup from '../../../../components/competitions/CompetitionLineup';
import CompetitionLineupPlayerInfo from '../../../../components/competitions/CompetitionLineupPlayerInfo';
import CompetitionLineupPlayerSelector from '../CompetitionLineupPlayerSelector';
import CompetitionTeamSetupFilters from '../../../../components/competitions/CompetitionTeamSetupFilters';
// styles
import { StyledOverlay, LineupViewItem, VLine, SaveLineupBtn } from './styles';
// @redux
import { getCompetitionTeam, saveCompetitionTeam } from '../../../../redux/team/thunks';
import withMediaQuery from '../../../../hoc/withMediaQuery';
// other
import { CompetitionContext } from '../../show';
import useTrackers from '../../../../hooks/useTrackers';
import useJoinCompetition from '../../../../hooks/useJoinCompetition';
// @assets
import lineupViewFieldActive from '../../../../assets/images/lineup-view-field-active.svg';
import lineupViewField from '../../../../assets/images/lineup-view-field.svg';
import lineupViewListActive from '../../../../assets/images/lineup-view-list-active.svg';
import lineupViewList from '../../../../assets/images/lineup-view-list.svg';
import PlayerScoreBreakdown from '../../../../components/competitions/lineup/PlayerScoreBreakdown';

import { InsufficientFundsModal } from '../InsufficientFundsModal';

const CompetitionTeamSetup = ({ competition, isEnabled, isMobile, ...rest }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const trackers = useTrackers();
  const [lineupView, setLineupView] = useState('field'); // 'field' or 'list'
  const [showDisabledTooltip, setDisabledTooltip] = useState(false);
  const [isSoftDisable, setSoftDisable] = useState(false);
  const [initialBudget, setInitialBudget] = useState(30);
  const [activeLineup, setActiveLineup] = useState(lineupsList[0]?.id);
  const [lineupPlayers, setLineupPlayers] = useState(new Array(15).fill({}));
  const [savedLineupPlayers, setSavedLineupPlayers] = useState(new Array(15).fill({}));
  const [shouldBlockNavigation, setBlockNavigation] = useState(false);
  const [playerSelectorModal, setPlayerSelectorModal] = useState({
    open: false,
    position: null,
    index: null,
    value: null,
  });
  const [playerInfoModal, setPlayerInfoModal] = useState({
    open: false,
    value: null,
  });

  const [playerScoreBreakdownModal, setPlayerScoreBreakdownModal] = useState({
    open: false,
    player: null,
  });

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [saveLineupValidationError, setSaveLineupValidationError] = useState(null);

  const fieldRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    trackers.track('Page View', { page: `Competition`, name: competition.name, tab: 'Team Setup' });

    if (location.state?.scrollToField) {
      fieldRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (!showDisabledTooltip) {
      fieldRef.current.scrollIntoView();
    }
  }, [showDisabledTooltip]);

  useEffect(() => {
    // Get Team
    if (competition?.id && isEnabled) {
      dispatch(getCompetitionTeam(competition?.id)).then((res) => {
        if (
          (res?.data?.players?.length === 15 || res?.data?.players?.length === 11) &&
          res?.data?.formation
        ) {
          // Transform array if players don't have subs
          let playersArr = [...res?.data?.players];
          const formationList = `${res?.data?.formation}`.split('-').map((no) => Number(no));
          if (res?.data?.players?.length === 11) {
            const gk = playersArr.slice(0, 1);
            const def = playersArr.slice(1, formationList[0] + 1);
            const mid = playersArr.slice(
              formationList[0] + 1,
              formationList[0] + formationList[1] + 1
            );
            const att = playersArr.slice(
              formationList[0] + formationList[1] + 1,
              formationList[0] + formationList[1] + formationList[2] + 3
            );
            playersArr = [...gk, {}, ...def, {}, ...mid, {}, ...att, {}];
          }
          setActiveLineup(res?.data?.formation);
          setSavedLineupPlayers(res?.data?.players);
          setLineupPlayers(playersArr);
        } else {
          setActiveLineup(lineupsList[0]?.id);
          setLineupPlayers(new Array(15).fill({}));
        }
      });
    }
    // Set Soft Disable
    if (competition?.state !== 'upcoming') setSoftDisable(true);
    else setSoftDisable(false);
    // Set Initial Budget
    if (competition?.budget) setInitialBudget(Number(competition?.budget));
  }, [competition, isEnabled]);

  useEffect(() => {
    setDisabledTooltip(!isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  }, [shouldBlockNavigation]);

  useEffect(() => {
    if (!isEqual(lineupPlayers, savedLineupPlayers)) {
      setBlockNavigation(true);
    } else {
      setBlockNavigation(false);
    }
  }, [lineupPlayers, savedLineupPlayers]);

  // Get number of selected lineup players
  const getSquadPlayersNo = () => {
    return filter(lineupPlayers, (player) => !!player?.id)?.length || 0;
  };

  // Get squad remaining money
  const getSquadRemainingMoney = () => {
    const selectedPlayers = filter(lineupPlayers, (player) => !!player?.id);
    if (selectedPlayers) {
      let totalPrice = Number(
        selectedPlayers.map((player) => Number(player?.price)).reduce((a, b) => a + b, 0) || 0
      );
      if (totalPrice) return Number(Number(initialBudget - totalPrice).toFixed(2));
    }
    return Number(initialBudget).toFixed(2);
  };

  // Clear Lineup Players
  const handleResetPlayers = () => {
    trackers.track('Button Clicked', { button: 'competition.reset_lineup' });

    setLineupPlayers(new Array(15).fill({}));
  };

  // Setting Active Lineup
  const handleSelectLineup = (lineup) => {
    trackers.track('Lineup Selected', { lineup });

    if (lineup !== activeLineup) {
      const selectedPlayersNo = filter(lineupPlayers, (player) => !!player?.id);
      if (
        selectedPlayersNo?.length === 0 ||
        window.confirm(intl.formatMessage({ id: 'CompetitionPage.LineupChagneConfirm' }))
      ) {
        handleResetPlayers();
        setActiveLineup(lineup);
      }
    }
  };

  // Open player selector modal
  const handleLineupPositionClick = (position, index, player) => {
    if (isSoftDisable) {
      setPlayerScoreBreakdownModal({ open: true, player });
    } else {
      // Open Player selector modal
      setPlayerSelectorModal({
        open: true,
        position,
        index,
        value: player,
      });
    }
  };

  // Close player selector modal
  const handlePlayerSelectorModalClose = () => {
    setPlayerSelectorModal({
      open: false,
      position: null,
      index: null,
      value: null,
    });
  };

  // Open player info modal
  const handlePlayerInfoClick = (player) => {
    trackers.track('Button Clicked', { button: 'competition.show_player_info' });

    setPlayerInfoModal({
      open: true,
      value: player,
    });
  };

  // Close player info modal
  const handlePlayerInfoModalClose = () => {
    setPlayerInfoModal({
      open: false,
      value: null,
    });
  };

  // Close player score breakdown modal
  const handlePlayerScoreBreakdownModalClose = () => {
    setPlayerScoreBreakdownModal({ open: false, player: null });
  };

  // Change Position Player
  const changePositionPlayer = (
    position,
    player,
    index = playerSelectorModal?.index // used only for adding players
  ) => {
    if (position && activeLineup) {
      // Determine to remove/add player
      if (findIndex(lineupPlayers, { id: player?.id }) === -1) {
        // Set new Player for specified position
        let formationSchema = activeLineup.split('-').map((no) => Number(no));
        formationSchema = {
          Goalkeeper: 1 + 1,
          Defender: formationSchema[0] + 1,
          Midfielder: formationSchema[1] + 1,
          Attacker: formationSchema[2] + 1,
        };
        // Search for actual index to be changed
        let indexToBeChanged = null;
        let foundIndex = false;
        Object.keys(formationSchema).forEach((positionKey) => {
          if (positionKey !== position && !foundIndex)
            indexToBeChanged += formationSchema[positionKey];
          else foundIndex = true;
        });
        // Calculate actual index to be changed
        if (index !== null) {
          indexToBeChanged += index;
        } else {
          // Search for first empty index to replace or replace last if no empty index exists
          const listToBeSearched = lineupPlayers.slice(
            indexToBeChanged,
            indexToBeChanged + formationSchema[position]
          );
          const firstEmptyItemIndex = findIndex(listToBeSearched, (item) => !item?.id);
          if (firstEmptyItemIndex !== -1) {
            indexToBeChanged += firstEmptyItemIndex;
          } else {
            indexToBeChanged += formationSchema[position] - 1;
          }
        }
        // Set new lineup players state
        const newLineupPlayers = [...lineupPlayers];
        newLineupPlayers[indexToBeChanged] = player;
        setLineupPlayers(newLineupPlayers);

        trackers.track('Player Added');

        // Handle Modal
        if (playerSelectorModal?.open) handlePlayerSelectorModalClose();
      } else {
        // Remove player from lineup players state
        const newLineupPlayers = [...lineupPlayers];
        newLineupPlayers[findIndex(lineupPlayers, { id: player?.id })] = {};
        // Set new lineup players state
        setLineupPlayers(newLineupPlayers);
        // Handle Modal
        if (playerSelectorModal?.open)
          setPlayerSelectorModal({ ...playerSelectorModal, value: null });
      }
      // Close Player Info modal
      if (playerInfoModal?.open) handlePlayerInfoModalClose();
    }
  };

  // Handle Captain Player
  const handleCaptainPlayer = (player) => {
    const newLineupPlayers = [...lineupPlayers];
    const currentCaptainIndex = findIndex(lineupPlayers, { is_captain: 1 });
    if (currentCaptainIndex !== -1)
      newLineupPlayers[currentCaptainIndex] = {
        ...newLineupPlayers[currentCaptainIndex],
        is_captain: 0,
      };
    const newCaptainIndex = findIndex(lineupPlayers, { id: player?.id });
    if (newCaptainIndex !== -1)
      newLineupPlayers[newCaptainIndex] = {
        ...newLineupPlayers[newCaptainIndex],
        is_captain: 1,
      };
    setLineupPlayers(newLineupPlayers);
  };

  // Handle Save Lineup
  const handleSaveLineup = () => {
    // Save Team
    if (getSquadPlayersNo() === 15 && getSquadRemainingMoney() >= 0 && activeLineup) {
      trackers.track('Button Clicked', { button: 'competition.save_lineup' });
      const lineup = `${activeLineup}`.split('-').map((no) => Number(no));
      const subsIndexes = [
        1,
        lineup[0] + 2,
        lineup[0] + 3 + lineup[1],
        lineup[0] + 4 + lineup[1] + lineup[2],
      ];
      dispatch(
        saveCompetitionTeam(competition?.id, {
          formation: activeLineup,
          players: lineupPlayers.map((player, index) => ({
            id: player?.id,
            is_captain: player?.is_captain || 0,
            is_substitute: subsIndexes.includes(index) ? 1 : 0,
          })),
        })
      )
        .then(() => {
          setSavedLineupPlayers(lineupPlayers);
          toast.success(intl.formatMessage({ id: 'CompetitionPage.LineupSaveSuccess' }));
        })
        .catch((e) => {
          if (isEmpty(e?.response?.data?.errors)) {
            toast.error(intl.formatMessage({ id: 'CompetitionPage.LineupSaveError' }));
          } else {
            Object.keys(e?.response?.data?.errors).forEach((errorKey) => {
              toast.error(
                intl.formatMessage({ id: `CompetitionPage.LineupSaveError.${errorKey}` })
              );
            });
          }
        });
    } else {
      // Set error message
      if (getSquadPlayersNo() !== 15)
        setSaveLineupValidationError('CompetitionPage.LineupSaveError.PlayersNo');
      else if (getSquadRemainingMoney() < 0)
        setSaveLineupValidationError('CompetitionPage.LineupSaveError.Budget');
      else if (!activeLineup)
        setSaveLineupValidationError('CompetitionPage.LineupSaveError.Formation');
      // Auto hide message
      setTimeout(() => {
        setSaveLineupValidationError(null);
      }, 2000);
    }
  };

  const filters = (
    <CompetitionTeamSetupFilters
      lineupPlayers={lineupPlayers}
      competition={competition}
      onToggle={changePositionPlayer}
      onShowInfo={handlePlayerInfoClick}
    />
  );

  return (
    <Box position="relative" ref={fieldRef}>
      <Prompt
        when={shouldBlockNavigation}
        message={intl.formatMessage({ id: 'CompetitionPage.TeamSetupUnsavedPopup' })}
      />

      {showDisabledTooltip && <Overlay competition={competition} />}

      <Box
        className="row"
        pb="2rem"
        position="relative"
        filter={isEnabled ? '' : 'grayscale(1) blur(8px)'}
        pointerEvents={!isEnabled ? 'none' : ''}
        {...rest}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          className="col-md-8"
          overflow={{ xs: 'hidden', md: 'auto' }}
        >
          {!isSoftDisable && (
            <CompetitionSquadSelectionCard
              playersNo={getSquadPlayersNo()}
              money={getSquadRemainingMoney()}
              deadline={competition?.season?.start}
              onReset={handleResetPlayers}
            />
          )}

          <Box width="100%" display="flex" justifyContent="center" mt="2rem">
            <LineupViewItem active={lineupView === 'field'} onClick={() => setLineupView('field')}>
              <img src={lineupView === 'field' ? lineupViewFieldActive : lineupViewField} alt="" />
              <Text
                fontSize="0.875rem"
                color={lineupView === 'field' ? '#5ad37c' : '#34495e'}
                ml="0.5rem"
              >
                <FormattedMessage id="CompetitionPage.FieldView" />
              </Text>
            </LineupViewItem>
            <VLine />
            <LineupViewItem active={lineupView === 'list'} onClick={() => setLineupView('list')}>
              <img src={lineupView === 'list' ? lineupViewListActive : lineupViewList} alt="" />
              <Text
                fontSize="0.875rem"
                color={lineupView === 'list' ? '#5ad37c' : '#34495e'}
                ml="0.5rem"
              >
                <FormattedMessage id="CompetitionPage.ListView" />
              </Text>
            </LineupViewItem>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="2rem"
            mb="1.25rem"
          >
            <Text display="block" fontSize="1.375rem" fontWeight="bold" color="#000">
              <FormattedMessage
                id={isSoftDisable ? 'CompetitionPage.Formation' : 'CompetitionPage.SelectFormation'}
              />
            </Text>
            {!isSoftDisable && (
              <>
                <SaveLineupBtn
                  id="SaveLineupValidationErrorTooltip"
                  className="btn btn-primary btn-mw"
                  position={{ xs: 'fixed', md: 'static' }}
                  bottom="0"
                  left="0"
                  width={{ xs: '100%', md: 'auto' }}
                  zIndex="1001"
                  onClick={handleSaveLineup}
                >
                  <FormattedMessage id="CompetitionPage.SaveLineup" />
                </SaveLineupBtn>
                <Tooltip
                  placement="top"
                  target="SaveLineupValidationErrorTooltip"
                  isOpen={!!saveLineupValidationError}
                >
                  <FormattedMessage id={saveLineupValidationError} />
                </Tooltip>
              </>
            )}
          </Box>

          <CompetitionLineupSelector
            value={activeLineup}
            onSelect={handleSelectLineup}
            lineupView={lineupView}
            isSoftDisable={isSoftDisable}
          />

          <CompetitionLineup
            lineup={activeLineup}
            value={lineupPlayers}
            onClick={handleLineupPositionClick}
            onRemove={changePositionPlayer}
            onSetCaptain={handleCaptainPlayer}
            onToggleFilters={() => setFilterModalOpen(!filterModalOpen)}
            lineupView={lineupView}
            isSoftDisable={isSoftDisable}
          />
        </Box>
        <div className="offset-md-1" />
        {!isSoftDisable && !isMobile && (
          <div className="col-md-3">
            <Text display="block" fontSize="1.375rem" fontWeight="bold" color="#000" mb="1.25rem">
              <FormattedMessage id="Filters" />
            </Text>

            {filters}
          </div>
        )}
        {playerSelectorModal?.open && (
          <Modal
            isOpen
            toggle={() => handlePlayerSelectorModalClose()}
            id="lineup-player-selector-modal"
            backdrop="static"
            noModalBody
            contentClassName="rounded-0"
          >
            <CompetitionLineupPlayerSelector
              competitionId={competition?.id}
              position={playerSelectorModal?.position}
              value={playerSelectorModal?.value}
              selectedPlayers={lineupPlayers}
              onSelect={changePositionPlayer}
              onClose={handlePlayerSelectorModalClose}
              height="80vh"
            />
          </Modal>
        )}
        {playerInfoModal?.open && (
          <Modal
            isOpen
            toggle={() => handlePlayerInfoModalClose()}
            id="lineup-player-info-modal"
            backdrop="static"
            noModalBody
            contentClassName="rounded-0"
          >
            <CompetitionLineupPlayerInfo
              value={playerInfoModal?.value}
              selectedPlayers={lineupPlayers}
              onSelect={changePositionPlayer}
              onClose={handlePlayerInfoModalClose}
              maxHeight="80vh"
            />
          </Modal>
        )}

        {playerScoreBreakdownModal?.open && (
          <Modal
            isOpen
            toggle={() => handlePlayerScoreBreakdownModalClose()}
            id="lineup-player-score-breakdown-modal"
            backdrop="static"
            noModalBody
            contentClassName="rounded-0"
          >
            <PlayerScoreBreakdown
              player={playerScoreBreakdownModal.player}
              onClose={handlePlayerScoreBreakdownModalClose}
              maxHeight="80vh"
            />
          </Modal>
        )}
      </Box>

      <Modal
        isOpen={filterModalOpen}
        toggle={() => setFilterModalOpen(!filterModalOpen)}
        id="filter-modal"
        title="Filters"
      >
        {filters}
      </Modal>
    </Box>
  );
};

const Overlay = ({ competition }) => {
  const { setOpenJoiningModal } = useContext(CompetitionContext);
  const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(false);
  const [remainingToJoinCompetition, setRemainingToJoinCompetition] = useState();
  const openInsufficientFundsModal = ({ remaining }) => {
    setInsufficientFundsModalOpen(true);
    setRemainingToJoinCompetition(remaining);
  };
  const { canJoin, onJoinNow } = useJoinCompetition(competition, openInsufficientFundsModal);

  useEffect(() => {
    if (canJoin) setOpenJoiningModal(true);
  }, [canJoin]);

  return (
    <StyledOverlay>
      <InsufficientFundsModal
        remainingToJoin={remainingToJoinCompetition}
        open={insufficientFundsModalOpen}
        setOpen={setInsufficientFundsModalOpen}
      />
      <Text fontSize="1.8rem" display="block" color="#fff" fontWeight="bold" mt="5rem" mb=".5rem">
        <FormattedMessage id="CompetitionPage.NotJoinedPopoverTitle" />
      </Text>
      <Text fontSize="1rem" display="block" color="#ccc" mb="1.5rem">
        <FormattedMessage id="CompetitionPage.NotJoinedPopoverBody" />
      </Text>
      <button className="btn btn-primary btn-mw" onClick={onJoinNow}>
        <FormattedMessage id="Join Now" />
      </button>
    </StyledOverlay>
  );
};

CompetitionTeamSetup.propTypes = {
  competition: PropTypes.shape({}),
  isEnabled: PropTypes.bool,
};

CompetitionTeamSetup.defaultProps = {
  competition: {},
  isEnabled: false,
};

export default withMediaQuery(CompetitionTeamSetup);
