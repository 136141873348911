import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import NotificationTime from './notification-time';
import { useHistory } from 'react-router';

const InvitedToGroup = ({ notification }) => {
  const { payload } = notification;
  const history = useHistory();

  const onClick = () => {
    history.push(`/groups/${payload.group.id}`);
  };

  return (
    <div className={`notification ${!notification.read_at ? 'new' : ''}`} onClick={onClick}>
      <Row>
        <Col md={9}>
          <div className="content">
            <h3 className="notification-header">
              <FormattedMessage id="notifications.invited-to-group.title" />
            </h3>
            <p className="notification-body">
              <FormattedMessage
                id="notifications.invited-to-group.body"
                values={{
                  group: <strong>{payload.group.name}</strong>,
                  inviter: <strong>{payload.inviter.name}</strong>,
                }}
              />
            </p>
          </div>
        </Col>
        <Col md={3}>
          <NotificationTime notification={notification} />
        </Col>
      </Row>
    </div>
  );
};

export default memo(InvitedToGroup);
