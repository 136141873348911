// @vendors
import { isEqual, findIndex, remove } from 'lodash';
// @api
import api from '../../helpers/api';
// @redux
import {
  saveMyGroupsList,
  saveAllGroupsList,
  saveRecommendedGroupsList,
  saveGroupDetails,
  saveGroupMembers,
  saveNonGroupMembers,
  saveGroupManagers,
  fetchGroupRankingStart,
  fetchGroupRankingSuccess,
  fetchGroupRankingFailure,
} from './actions';
// @constants
import { DEFAULT_LIST_SCHEMA } from '../../constants/generic';

export function fetchMyGroupsList(filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { myGroupsList },
    } = getState();
    let currentList = myGroupsList || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchMyGroupsList({ ...filters, page: currentList?.meta?.current_page + 1 })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveMyGroupsList([...currentList?.items, ...response?.data], response?.meta, filters)
            );
        })
        .catch(console.error);
    }
  };
}

export function fetchAllGroupsList(filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { allGroupsList },
    } = getState();
    let currentList = allGroupsList || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchAllGroupsList({ ...filters, page: currentList?.meta?.current_page + 1 })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveAllGroupsList([...currentList?.items, ...response?.data], response?.meta, filters)
            );
        })
        .catch(console.error);
    }
  };
}

export function fetchRecommendedGroupsList(filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { recommendedGroupsList },
    } = getState();
    let currentList = recommendedGroupsList || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchRecommendedGroupsList({
          ...filters,
          page: currentList?.meta?.current_page + 1,
        })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveRecommendedGroupsList(
                [...currentList?.items, ...response?.data],
                response?.meta,
                filters
              )
            );
        })
        .catch(console.error);
    }
  };
}

export function addGroupMember(groupId, userId, role) {
  return async function (dispatch, getState) {
    if (groupId && userId && role) {
      await api
        .addGroupMember(groupId, userId, role)
        .then((response) => response.data.data)
        .then((member) => {
          // Mutate non group members state
          const {
            groups: { groupDetails },
          } = getState();
          const newNonGroupMembers = { ...groupDetails?.nonMembers };
          const indexToBeChanged = findIndex(newNonGroupMembers.items, { id: member?.id });
          const itemToBeChanged = newNonGroupMembers.items[indexToBeChanged];
          if (itemToBeChanged !== -1) {
            delete itemToBeChanged.can_add;
            dispatch(
              saveNonGroupMembers(
                newNonGroupMembers?.items,
                newNonGroupMembers?.meta,
                newNonGroupMembers.filters
              )
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      console.error('An Error occurred while trying to add member to group.');
    }
  };
}

export function joinGroup(groupId, entryCode) {
  return async function (dispatch, getState) {
    if (groupId) {
      return await api
        .joinGroup(groupId, entryCode)
        .then((response) => response.data)
        .then((data) => {
          // Mutate group item 'joined' state
          const {
            groups: { myGroupsList, allGroupsList, recommendedGroupsList },
          } = getState();
          const newMyGroupsList = { ...myGroupsList };
          const newAllGroupsList = { ...allGroupsList };
          const newRecommenedGroupsList = { ...recommendedGroupsList };
          // Check in all groups
          const indexToBeChangedAll = findIndex(newAllGroupsList.items, { id: groupId });
          const itemToBeChangedAll = newAllGroupsList.items[indexToBeChangedAll];
          if (indexToBeChangedAll !== -1) {
            itemToBeChangedAll.joined = true;
            dispatch(
              saveAllGroupsList(
                newAllGroupsList?.items,
                newAllGroupsList?.meta,
                newAllGroupsList.filters
              )
            );
          }
          // Check in recommended groups
          const indexToBeChangedRecommended = findIndex(newRecommenedGroupsList.items, {
            id: groupId,
          });
          const itemToBeChangedRecommended =
            newRecommenedGroupsList.items[indexToBeChangedRecommended];
          if (indexToBeChangedRecommended !== -1) {
            itemToBeChangedRecommended.joined = true;
            dispatch(
              saveAllGroupsList(
                newRecommenedGroupsList?.items,
                newRecommenedGroupsList?.meta,
                newRecommenedGroupsList.filters
              )
            );
          }
          // Add to my groups
          const itemToBeChangedMy = itemToBeChangedAll || itemToBeChangedRecommended || {};
          if (itemToBeChangedMy) {
            newMyGroupsList.items = [...newMyGroupsList.items, { ...itemToBeChangedMy }];
            dispatch(
              saveMyGroupsList(
                newMyGroupsList?.items,
                newMyGroupsList?.meta,
                newMyGroupsList.filters
              )
            );
          }
          return data;
        });
    } else {
      console.error('An Error occurred while trying to add member to group.');
    }
  };
}

export function removeGroupMember(groupId, userId) {
  return async function (dispatch, getState) {
    if (groupId && userId) {
      return await api
        .removeGroupMember(groupId, userId)
        .then((response) => response.data)
        .then((data) => {
          // Mutate group item 'joined' and latest_members state
          const {
            groups: { myGroupsList, allGroupsList, recommendedGroupsList, groupDetails },
          } = getState();
          const newMyGroupsList = { ...myGroupsList };
          const newAllGroupsList = { ...allGroupsList };
          const newRecommenedGroupsList = { ...recommendedGroupsList };
          const newMembersList = { ...groupDetails?.members };
          const newManagersList = { ...groupDetails?.managers };
          // Check in all groups
          const indexToBeChangedAll = findIndex(newAllGroupsList.items, { id: groupId });
          const itemToBeChangedAll = newAllGroupsList.items[indexToBeChangedAll];
          if (indexToBeChangedAll !== -1) {
            itemToBeChangedAll.joined = false;
            remove(itemToBeChangedAll.latest_members, { id: userId });
            dispatch(
              saveAllGroupsList(
                newAllGroupsList?.items,
                newAllGroupsList?.meta,
                newAllGroupsList.filters
              )
            );
          }
          // Check in recommended groups
          const indexToBeChangedRecommended = findIndex(newRecommenedGroupsList.items, {
            id: groupId,
          });
          const itemToBeChangedRecommended =
            newRecommenedGroupsList.items[indexToBeChangedRecommended];
          if (indexToBeChangedRecommended !== -1) {
            itemToBeChangedRecommended.joined = false;
            remove(itemToBeChangedRecommended.latest_members, { id: userId });
            dispatch(
              saveAllGroupsList(
                newRecommenedGroupsList?.items,
                newRecommenedGroupsList?.meta,
                newRecommenedGroupsList.filters
              )
            );
          }
          // Check in my groups
          const indexToBeChangedMy = findIndex(newMyGroupsList.items, { id: groupId });
          if (indexToBeChangedMy !== -1) {
            remove(newMyGroupsList.items, { id: groupId });
            dispatch(
              saveMyGroupsList(
                newMyGroupsList?.items,
                newMyGroupsList?.meta,
                newMyGroupsList.filters
              )
            );
          }
          // Check in groups members
          const indexToBeChangedMembers = findIndex(newMembersList.items, { id: userId });
          if (indexToBeChangedMembers !== -1) {
            remove(newMembersList.items, { id: userId });
            dispatch(
              saveGroupMembers(newMembersList?.items, newMembersList?.meta, newMembersList.filters)
            );
          }
          // Check in groups managers
          const indexToBeChangedManagers = findIndex(newManagersList.items, { id: userId });
          if (indexToBeChangedManagers !== -1) {
            remove(newManagersList.items, { id: userId });
            dispatch(
              saveGroupManagers(
                newManagersList?.items,
                newManagersList?.meta,
                newManagersList.filters
              )
            );
          }
          return data;
        });
    } else {
      console.error('An Error occurred while trying to remove member from group.');
    }
  };
}

export function createGroup(payload = {}) {
  return async function () {
    return api.createGroup(payload);
  };
}

export function fetchGroupDetails(groupId) {
  return async function (dispatch) {
    await api
      .fetchGroupDetails(groupId)
      .then((res) => res.data.data)
      .then((groupData) => {
        dispatch(saveGroupDetails(groupData));
      })
      .catch((e) => {
        console.error(e);
      });
  };
}

export function fetchGroupMembers(groupId, filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { groupDetails },
    } = getState();
    let currentList = groupDetails?.members || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchGroupMembers(groupId, {
          ...filters,
          page: currentList?.meta?.current_page + 1,
          managers: 0,
        })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveGroupMembers([...currentList?.items, ...response?.data], response?.meta, filters)
            );
        })
        .catch(console.error);
    }
  };
}

export function fetchNonGroupMembers(groupId, filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { groupDetails },
    } = getState();
    let currentList = groupDetails?.nonMembers || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchNonGroupMembers(groupId, { ...filters, page: currentList?.meta?.current_page + 1 })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveNonGroupMembers(
                [
                  ...currentList?.items,
                  ...response?.data?.map((item) => ({ ...item, can_add: true })),
                ],
                response?.meta,
                filters
              )
            );
        })
        .catch(console.error);
    }
  };
}

export function fetchGroupManagers(groupId, filters = {}) {
  return async function (dispatch, getState) {
    const {
      groups: { groupDetails },
    } = getState();
    let currentList = groupDetails?.managers || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchGroupMembers(groupId, {
          ...filters,
          page: currentList?.meta?.current_page + 1,
          managers: 1,
        })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveGroupManagers([...currentList?.items, ...response?.data], response?.meta, filters)
            );
        })
        .catch(console.error);
    }
  };
}

export function fetchGroupMember(groupId, memberId) {
  return async function () {
    return await api
      .fetchGroupMember(groupId, memberId)
      .then((response) => response.data.data)
      .catch(console.error);
  };
}

export function addGroupMembers(groupId, members = []) {
  return async function () {
    if (groupId && members) {
      const bodyMembers = members.map((member) => ({ user_id: member?.id, role: 1 }));
      return await api
        .addGroupMembers(groupId, bodyMembers)
        .then((response) => response.data)
        .catch(console.error);
    } else {
      console.error('An Error occurred while trying to add members to group.');
    }
  };
}

export function changeGroupMemberRole(groupId, memberId, role) {
  return async function (dispatch, getState) {
    if (groupId && memberId && role) {
      return await api
        .changeGroupMemberRole(groupId, memberId, role)
        .then((response) => response.data.data)
        .then((member) => {
          const {
            groups: { groupDetails },
          } = getState();
          const newMembersList = { ...groupDetails?.members };
          const newManagersList = { ...groupDetails?.managers };
          if (member?.role === 2) {
            // Move from group members to group managers
            const indexToBeChangedAll = findIndex(newMembersList.items, { id: memberId });
            if (indexToBeChangedAll !== -1) {
              remove(newMembersList.items, { id: memberId });
              newManagersList.items.push(member);
            }
          } else {
            // Move from group managers to group members
            const indexToBeChangedAll = findIndex(newManagersList.items, { id: memberId });
            if (indexToBeChangedAll !== -1) {
              remove(newManagersList.items, { id: memberId });
              newMembersList.items.push(member);
            }
          }
          dispatch(
            saveGroupMembers(newMembersList?.items, newMembersList?.meta, newMembersList.filters)
          );
          dispatch(
            saveGroupManagers(
              newManagersList?.items,
              newManagersList?.meta,
              newManagersList.filters
            )
          );
          return member;
        });
    } else {
      console.error('An Error occurred while trying to modify group member role.');
    }
  };
}

export function fetchGroupRanking(groupId) {
  return async function (dispatch, getState) {
    dispatch(fetchGroupRankingStart());

    const page = getState().groups.groupDetails.ranking.page + 1;
    await api
      .fetchRanking({ group: groupId, page })
      .then((response) => response.data)
      .then((json) => {
        let data = json.data || [];

        let meta = json.meta || {};
        meta.pending = !json.data;

        dispatch(fetchGroupRankingSuccess(data, meta));
      })
      .catch(() => {
        dispatch(fetchGroupRankingFailure());
      });
  };
}
