import { combineReducers } from 'redux';
import auth from './auth/reducer';
import competitions from './competitions/reducer';
import matches from './matches/reducer';
import ranking from './ranking/reducer';
import profile from './profile/reducer';
import generic from './generic/reducer';
import search from './search/reducer';
import prizes from './prizes/reducer';
import groups from './groups/reducer';
import users from './users/reducer';
import store from './store/reducer';
import { LOGOUT_SUCCESS } from '../constants/action-types/auth';

const appReducer = combineReducers({
  auth,
  competitions,
  matches,
  ranking,
  profile,
  generic,
  search,
  prizes,
  groups,
  users,
  store,
});

export default (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};
