import Text from '../Text';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const PlayerPrice = ({ player }) => {
  return (
    <Text fontWeight="bold">
      ${player.price}
      <FormattedMessage id="M" />
    </Text>
  );
};

export default PlayerPrice;
