import * as actionTypes from '../../constants/action-types/generic';
import { FETCH_ME_SUCCESS } from '../../constants/action-types/auth';

const INIT_STATE = {
  documentTitle: null,
  tutorial: {
    active: false,
    shown: {
      sidebarPredictions: false,
      match: false,
      prediction: false,
    },
  },
  userSettings: {
    language: { value: null, loading: false },
    receive_notifications: { value: null, loading: false },
    auto_navigate_to_next_prediction: { value: null, loading: false },
    referral_code: null,
  },
  settings: {},
  creators: [],
  packages: null,
  recentNotifications: {
    list: null,
    newNotificationCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_DOCUMENT_TITLE:
      return { ...state, documentTitle: action.payload.title };
    case actionTypes.SET_TUTORIAL_STATUS:
      return {
        ...state,
        tutorial: { ...state.tutorial, active: action.payload.status === 'active' },
      };
    case actionTypes.MARK_TUTORIAL_STEP_AS_DONE:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          shown: { ...state.tutorial.shown, [action.payload.step]: true },
        },
      };

    case actionTypes.UPDATE_SETTING_START:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.payload.key]: { value: action.payload.value, loading: true },
        },
      };
    case actionTypes.UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.payload.key]: { value: action.payload.value, loading: false },
        },
      };
    case actionTypes.UPDATE_SETTING_FAILURE:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.payload.key]: { ...state.settings[action.payload.key], loading: false },
        },
      };
    case actionTypes.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    case FETCH_ME_SUCCESS:
      return {
        ...state,
        userSettings: {
          language: { value: action.payload.settings.language, loading: false },
          receive_notifications: {
            value: action.payload.settings.receive_notifications,
            loading: false,
          },
          auto_navigate_to_next_prediction: {
            value: action.payload.settings.auto_navigate_to_next_prediction,
            loading: false,
          },
          referral_code: action.payload.settings.referral_code,
        },
      };

    case actionTypes.RECEIVE_CREATORS:
      return {
        ...state,
        creators: action.payload.creators,
      };

    case actionTypes.RECEIVE_RECENT_NOTIFICATIONS:
      return {
        ...state,
        recentNotifications: {
          ...state.recentNotifications,
          list: action.payload.notifications,
        },
      };

    case actionTypes.RECEIVE_NEW_NOTIFICATION_COUNT:
      return {
        ...state,
        recentNotifications: {
          ...state.recentNotifications,
          newNotificationCount: action.payload.count,
        },
      };

    case actionTypes.RECEIVE_PACKAGES:
      return {
        ...state,
        packages: action.payload.packages,
      };

    default:
      return { ...state };
  }
};
