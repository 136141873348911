export const SAVE_MY_GROUPS_LIST = 'SAVE_MY_GROUPS_LIST';

export const SAVE_ALL_GROUPS_LIST = 'SAVE_ALL_GROUPS_LIST';

export const SAVE_RECOMMENDED_GROUPS_LIST = 'SAVE_RECOMMENDED_GROUPS_LIST';

export const SAVE_GROUP_DETAILS = 'SAVE_GROUP_DETAILS';

export const SAVE_GROUP_MEMBERS = 'SAVE_GROUP_MEMBERS';

export const SAVE_NON_GROUP_MEMBERS = 'SAVE_NON_GROUP_MEMBERS';

export const SAVE_GROUP_MANAGERS = 'SAVE_GROUP_MANAGERS';

export const FETCH_GROUP_RANKING_START = 'FETCH_GROUP_RANKING_START';

export const FETCH_GROUP_RANKING_SUCCESS = 'FETCH_GROUP_RANKING_SUCCESS';

export const FETCH_GROUP_RANKING_FAILURE = 'FETCH_GROUP_RANKING_FAILURE';

export const CLEAR_GROUP_RANKING = 'CLEAR_GROUP_RANKING';
