export const RECEIVE_MATCHES = 'RECEIVE_MATCHES';
export const RECEIVE_OFFICIAL_MATCHES = 'RECEIVE_OFFICIAL_MATCHES';
export const RECEIVE_BETS = 'RECEIVE_BETS';
export const CLEAR_MATCHES = 'CLEAR_MATCHES';
export const FETCH_MATCHES_START = 'FETCH_MATCHES_START';
export const FETCH_MATCHES_SUCCESS = 'FETCH_MATCHES_SUCCESS';
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE';
export const SELECT_MATCH_START = 'SELECT_MATCH_START';
export const SELECT_MATCH_SUCCESS = 'SELECT_MATCH_SUCCESS';
export const SELECT_MATCH_FAILURE = 'SELECT_MATCH_FAILURE';
export const UNSELECT_MATCH = 'UNSELECT_MATCH';

export const SAVE_MULTIPLE_PREDICTIONS_START = 'SAVE_MULTIPLE_PREDICTIONS_START ';
export const SAVE_MULTIPLE_PREDICTIONS_SUCCESS = 'SAVE_MULTIPLE_PREDICTIONS_SUCCESS';
export const SAVE_MULTIPLE_PREDICTIONS_FAILURE = 'SAVE_MULTIPLE_PREDICTIONS_FAILURE';

export const FILTER_MATCHES_BY_STATE = 'FILTER_MATCHES_BY_STATE';
