import React from 'react';
import ToggledCustomInput from './ToggledCustomInput';

const PasswordInput = (props) => {
  return (
    <ToggledCustomInput
      icon={<img src={require('../../assets/images/icon-lock.svg')} alt="" />}
      type="password"
      {...props}
    />
  );
};

export default PasswordInput;
