class Paginator {
  currentPage;
  lastPage;

  constructor(config) {
    if (config) this.setConfig(config);
  }

  setConfig(config) {
    this.currentPage = config.current_page;
    this.lastPage = config.last_page;
  }

  hasMorePages() {
    return this.currentPage < this.lastPage;
  }
}

export default Paginator;
