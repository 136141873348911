import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { MdTimer } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { MATCH_STATE_FINISHED } from '../constants/generic';
import PointsWon from './bets/PointsWon';
import Tag from './Tag';

const Match = ({ match, selectedMatchId, showCompetition, onSelect, isFantasyMatch }) => {
  const [points, setPoints] = useState(0);
  const start = dayjs(match?.match?.start);
  const matchStateClassName = match?.match?.state.code.toLowerCase().replace('_', '-');

  useEffect(() => {
    if (isFantasyMatch) {
      setPoints(
        match.current_user_points
          .reduce((carry, record) => {
            carry += parseFloat(record.amount) || 0;
            return carry;
          }, 0)
          .toFixed(2)
      );
    } else {
      const allBets = [...(match?.bets || []), match?.game_score_bet || {}];
      setPoints(
        allBets
          .reduce(
            (carry, bet) => carry + (parseFloat(bet?.current_user_prediction?.points?.amount) || 0),
            0
          )
          .toFixed(2)
      );
    }
  }, [match, isFantasyMatch]);

  const handleOnSelect = () => {
    onSelect(match?.id);
  };

  return (
    <React.Fragment>
      <div
        className={`match ${selectedMatchId === match?.id ? 'selected' : ''}`}
        onClick={handleOnSelect}
      >
        {showCompetition && (
          <div className="competition-wrapper">
            <h2>
              {match?.season?.competition?.name} ({match?.season?.name})
            </h2>
          </div>
        )}

        <span className={`state-indicator ${matchStateClassName}`} />

        <Row className="align-items-center">
          <Col md="4">
            <div className="state-wrapper">
              <div className="start">
                <p className="time">{start.format('HH:mm A')}</p>
                <p className="date">{start.format('DD MMM YYYY')}</p>
              </div>
              <div className="other">
                <div className="mb-2">
                  <Tag className={`state ${matchStateClassName} mr-1`}>
                    {match?.match?.state?.name}
                  </Tag>

                  {match?.match?.state?.code === MATCH_STATE_FINISHED && (
                    <PointsWon points={points} />
                  )}
                </div>

                {match?.match?.minute ? (
                  <div className="minutes">
                    <MdTimer className="icon" /> {match?.match?.minute}:00
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
          <Col md="8" className="col-2nd">
            <div className="teams">
              <div className="top">
                <h3 className="team home-team">
                  <img src={match?.match?.home_team?.logo?.thumb} alt="home team logo" />
                  <span className="team-name" title={match?.match?.home_team?.name}>
                    {match?.match?.home_team?.name}
                  </span>
                </h3>

                <h3 className="score">
                  <span>{match?.match?.home_goals}</span> : <span>{match?.match?.away_goals}</span>
                </h3>

                <h3 className="team away-team">
                  <span className="team-name" title={match?.match?.away_team?.name}>
                    {match?.match?.away_team?.name}
                  </span>
                  <img src={match?.match?.away_team?.logo?.thumb} alt="away team logo" />
                </h3>
              </div>

              <div className="bottom">
                {match?.game_score_bet && match?.game_score_bet?.current_user_prediction && (
                  <div className="prediction">
                    <span className="label">
                      <FormattedMessage id="prediction" />:
                    </span>
                    <span className="value">
                      {match?.game_score_bet?.current_user_prediction?.value?.home_goals}
                    </span>
                    <span className="separator">:</span>
                    <span className="value">
                      {match?.game_score_bet?.current_user_prediction?.value?.away_goals}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="btn-wrapper">
              <button className="btn btn-secondary btn-sm">
                {isFantasyMatch ? (
                  <FormattedMessage id="Match.ViewBreakdown" />
                ) : (
                  <FormattedMessage id="Click to Predict" />
                )}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Match;
