import React, { useEffect } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import ProfileTabs from '../../components/profile/Tabs';
import ProfileSidebar from '../../components/profile/Sidebar';
import { fetchCompetitions } from '../../redux/competitions/thunks';
import { useIntl } from 'react-intl';
import CompetitionCard from '../../components/competitions/CompetitionCard';
import CompetitionList from '../competitions/components/CompetitionList';

function ProfileCompetitionsPage(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { all } = useSelector((s) => s.competitions.competitions.mine);

  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Competitions' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);
    dispatch(fetchCompetitions('mine', 'all'));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile">
          <Row>
            <Col md={3} className="col-sidebar">
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="profile-content">
                <ProfileTabs activeTab="competitions" />

                <TabContent activeTab="competitions">
                  <TabPane tabId="competitions">
                    <CompetitionList
                      competitions={all}
                      type="mine"
                      render={(competitions, entities) => {
                        return (
                          <div className="row">
                            {competitions.items.map((competitionId) => {
                              return (
                                <Col lg={6} key={`mine-col-${competitionId}`}>
                                  <CompetitionCard
                                    competition={entities[competitionId]}
                                    key={competitionId}
                                  />
                                </Col>
                              );
                            })}
                          </div>
                        );
                      }}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default ProfileCompetitionsPage;
