import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isUserAuthenticated } from '../../helpers/auth';
import { Redirect } from 'react-router-dom';
import FormTitle from '../../components/form/FormTitle';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import FacebookLogin from '../../components/FacebookLogin';
import useTrackers from '../../hooks/useTrackers';
import { DEFAULT_LANDING_PAGE } from '../../constants/generic';

const LoginFacebookPage = (props) => {
  const intl = useIntl();
  const trackers = useTrackers();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Login' }));
    trackers.track('Page View', { page: 'Login' });
  }, []);

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <AuthPageWrapper
        className="page-login-facebook"
        description={intl.formatMessage({ id: 'meta.description.login' })}
        showLogo={false}
      >
        <FormTitle>
          <FormattedMessage id="Ready to win prizes?" />
        </FormTitle>

        <FacebookLogin />
      </AuthPageWrapper>
    </React.Fragment>
  );
};

export default LoginFacebookPage;
