import React from 'react';
import Info from './Info';
import { useIntl } from 'react-intl';
import { getEksabFees, getTotalCoins } from '../../../../helpers/generic';
import MatchPreviewList from './MatchPreviewList';

const Step3 = (props) => {
  const { values } = props;
  const intl = useIntl();

  const participants = `${intl.formatMessage({ id: 'From' })} ${values.participants.min} 
                            ${intl.formatMessage({ id: 'to' })} ${values.participants.max}`;
  const entryFee = `${values.entry_fee} ${intl.formatMessage({ id: 'coins' })}`;
  const totalCoins = `${getTotalCoins(values)} ${intl.formatMessage({ id: 'coins' })}`;
  const eksabFees = `${getEksabFees(values)} ${intl.formatMessage({ id: 'coins' })}`;
  const winners = intl.formatMessage({ id: values.winning_strategy });
  const competitionType = intl.formatMessage({
    id: values?.type === '2' ? 'Fantasy' : 'prediction',
  });

  return (
    <React.Fragment>
      <div className="step3">
        <div className="row">
          <div className="col-md-6">
            <Info label={intl.formatMessage({ id: 'Competition Name' })} value={values.name} />

            <Info label={intl.formatMessage({ id: 'Competition Type' })} value={competitionType} />

            {values?.type === '2' && (
              <React.Fragment>
                <Info
                  label={intl.formatMessage({ id: 'Budget' })}
                  value={Number(values.budget).toLocaleString()}
                />
              </React.Fragment>
            )}

            <Info
              label={intl.formatMessage({ id: 'Number of Participants' })}
              value={participants}
            />

            <Info label={intl.formatMessage({ id: 'Entry Fee' })} value={entryFee} />

            <Info label={intl.formatMessage({ id: 'Total Prize' })} value={totalCoins} />

            <Info label={intl.formatMessage({ id: 'Eksab Fees' })} value={eksabFees} />

            <Info label={intl.formatMessage({ id: 'Distribution of Prize' })} value={winners} />
          </div>
          <div className="col-md-6">
            <MatchPreviewList
              matchIds={values.match_ids}
              title={intl.formatMessage({ id: 'Selected Matches' })}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step3;
