import React from 'react';
import { Redirect, Route } from 'react-router';
import { isUserAuthenticated } from '../helpers/auth';

export default ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isUserAuthenticated()) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
