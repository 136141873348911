import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from './Modal';

const Unsubscribe = () => {
  const history = useHistory();
  const url = new URL(window.location);

  const [isUnsubscribed, setIsUnsubscribed] = useState(url.searchParams.has('unsubscribe'));

  const onClosed = () => {
    if (url.searchParams.has('unsubscribe')) {
      url.searchParams.delete('unsubscribe');

      history.push(url.pathname + url.search);
    }
  };

  useEffect(() => {
    setIsUnsubscribed(url.searchParams.has('unsubscribe'));
  }, [url]);

  return (
    <Modal
      id="unsubscribe-modal"
      title="Your email subscription has been successfully cancelled!"
      isOpen={isUnsubscribed}
      toggle={onClosed}
      noModalBody
      centered
      backdrop
    />
  );
};

export default Unsubscribe;
