import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import LatestJoinedCompetitions from '../components/matches/MyCompetitions';
import Matches from '../components/matches/Matches';
import CompetitionPrizes from '../components/competitions/CompetitionPrizes';
import Prediction from '../components/Prediction';
import { StickyContainer, Sticky } from 'react-sticky';
import { useIntl } from 'react-intl';
import { unselectMatch } from '../redux/matches/actions';
import Loader from '../components/Loader';
import withMediaQuery from '../hoc/withMediaQuery';
import { selectMatch } from '../redux/matches/thunks';
import { useLocation } from 'react-router';
import MetaTags from 'react-meta-tags';
import Ads from '../components/Ads';
import useTrackers from '../hooks/useTrackers';

const qs = require('query-string');

const MatchesPage = (props) => {
  const { selectedMatch, selectMatch, flat } = props;
  const intl = useIntl();
  const location = useLocation();
  const trackers = useTrackers();

  const selectedMatchIndex = flat.indexOf(selectedMatch.id);
  const nextMatchIndex = selectedMatchIndex + 1;
  const nextMatchId =
    selectedMatchIndex !== -1 && flat.length > nextMatchIndex ? flat[nextMatchIndex] : null;

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Matches' }));
    trackers.track('Page View', { page: 'Matches' });

    const pqs = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (pqs.match) selectMatch(pqs.match);
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <meta name="description" content={intl.formatMessage({ id: 'meta.description.matches' })} />
      </MetaTags>

      <Container fluid>
        <div className="page page-matches">
          <StickyContainer>
            <Row>
              <Col md={3}>
                <LatestJoinedCompetitions />
                {props.isDesktop && <Ads />}
              </Col>
              <Col md={6}>
                <Matches />
                {props.isMobile && <Ads />}
              </Col>
              <Col md={3}>
                {props.isDesktop && (
                  <Sticky disableCompensation={true}>
                    {({ style }) => (
                      <div style={style} className="sticky-wrapper">
                        {selectedMatch.loading && <Loader />}
                        {selectedMatch.id && (
                          <div className="sticky-inner-wrapper">
                            <PredictionGroup {...props} nextMatchId={nextMatchId} />
                          </div>
                        )}
                      </div>
                    )}
                  </Sticky>
                )}
              </Col>
            </Row>
          </StickyContainer>
        </div>
      </Container>

      {props.isMobile && (
        <React.Fragment>
          {!!(selectedMatch.loading || selectedMatch.id) && (
            <div className="overlay-prediction">
              {selectedMatch.loading && <Loader />}
              {!!selectedMatch.id && <PredictionGroup {...props} nextMatchId={nextMatchId} />}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const PredictionGroup = (props) => {
  const { loadedMatches, nextMatchId, selectedMatch, selectMatch } = props;
  const record = loadedMatches[selectedMatch.id];

  return (
    <React.Fragment>
      <Prediction
        matchId={props.selectedMatch.id}
        onUnselect={props.unselectMatch}
        onNextMatch={() => !!nextMatchId && selectMatch(nextMatchId)}
        userCanPredict={true}
      />
      {!!record.season.competition.prizes.length && (
        <div className="card-competition-prizes">
          <div className="card-header">
            <h2 className="card-title">
              {record.season.competition.logo && (
                <img
                  src={record.season.competition.logo.original}
                  alt={`${record.season.competition.name} competition logo`}
                />
              )}
              {record.season.competition.name}
            </h2>
          </div>
          <div className="card-body">
            <CompetitionPrizes prizes={record.season.competition.prizes} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { loadedMatches, flat } = state.matches;
  const { selectedMatch } = state.matches.matches;
  return { loadedMatches, selectedMatch, flat };
};

export default connect(mapStateToProps, { selectMatch, unselectMatch })(
  withMediaQuery(MatchesPage)
);
