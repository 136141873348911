// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
// @components
import Box from '../Box';
import MemberCard from './MemberCard';

const ListMembers = ({
  id,
  items,
  loadMore,
  hasMore,
  onClick,
  useWindow,
  options,
  onOptionClick,
  ...rest
}) => (
  <Box width="100%" {...rest}>
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      initialLoad={false}
      className="row"
      useWindow={useWindow}
    >
      {items?.map((item) => (
        <Box key={`${id}_group_member_${item?.id}`} className="col-12">
          <MemberCard
            id={item?.id}
            name={item?.name}
            type={item?.type}
            image={item?.photo?.thumb}
            role={item?.role}
            onToggle={item?.can_add && onClick ? () => onClick(item) : null}
            options={!(item?.can_add && onClick) ? options : []}
            onOptionClick={(option) => onOptionClick(option, item)}
          />
        </Box>
      ))}
    </InfiniteScroll>
  </Box>
);

ListMembers.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      photo: PropTypes.shape({
        thumb: PropTypes.string,
      }),
      role: PropTypes.number,
      status: PropTypes.string,
    })
  ),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  onClick: PropTypes.func,
  useWindow: PropTypes.bool,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
};

ListMembers.defaultProps = {
  id: 'members-list',
  items: [],
  loadMore: () => {},
  hasMore: false,
  onClick: null,
  useWindow: true,
  options: [],
  onOptionClick: () => {},
};

export default ListMembers;
