// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../../Box';
import Text from '../../../Text';
import CompetitionPlayerAvatar from '../../CompetitionPlayerAvatar';
// @styles
import { CloseIcon } from './styles';
// @static
import closeIcon from '../../../../assets/images/icon-close.svg';
import modalHeaderImg from '../../../../assets/images/lineup-player-select-header.jpg';

const PlayerScoreBreakdown = ({ player, onClose, ...rest }) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" {...rest}>
      <Box
        position="relative"
        display="flex"
        width="100%"
        height="7.5rem"
        justifyContent="flex-start"
        alignItems="center"
        backgroundImage={`url('${modalHeaderImg}')`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        p="0 1rem"
        pie="4rem"
      >
        <CompetitionPlayerAvatar
          mb="0"
          mt="auto"
          name={player?.common_name}
          image={player?.image?.original}
          number={player?.number}
          teamImg={player?.team?.logo?.original}
          darkTheme
        />
        <CloseIcon src={closeIcon} onClick={onClose} />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflowY="auto"
        height="Calc(100% - 7.5rem)"
        p="2rem 0"
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Box width="80%" border="1px solid #34495e" borderRadius="0.625rem" p="0.75rem">
            {/* Inner Card */}
            <Box
              width="100%"
              border="1px solid #afadb2"
              borderRadius="0.375rem"
              p="0.25rem 0.625rem"
              disply="flex"
              flexDirection="column"
            >
              {/* Item */}
              {player?.points_breakdown?.length ? (
                player?.points_breakdown?.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBlockStart={index !== 0 ? '1px solid #afadb2' : ''}
                    py="0.25rem"
                  >
                    <Text fontSize="0.75rem" color="#000000">
                      <FormattedMessage id={`match-event-types.groups.${item.event_type_group}`} />
                    </Text>
                    <Text fontSize="0.75rem" color="#201f2f">
                      {item.amount}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text width="100%" textAlign="center">
                  <FormattedMessage id="no-records" />
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PlayerScoreBreakdown.propTypes = {
  player: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
};

PlayerScoreBreakdown.defaultProps = {
  onClose: () => {},
};

export default PlayerScoreBreakdown;
