import React, { useEffect, useState } from 'react';
import { login } from '../../redux/auth/thunks';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Row, Alert, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { isUserAuthenticated } from '../../helpers/auth';
import { Link, Redirect } from 'react-router-dom';
import BarSeparator from '../../components/BarSeparator';
import PasswordInput from '../../components/form/PasswordInput';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import FacebookLogin from '../../components/FacebookLogin';
import useRedirectAfterAuth from '../../hooks/useRedirectAfterAuth';
import SkipToLandingPage from '../../components/SkipToLandingPage';
import useTrackers from '../../hooks/useTrackers';
import { DEFAULT_LANDING_PAGE } from '../../constants/generic';
import WelcomeToEksabMessage from '../../components/WelcomeToEksabMessage';
import UserIdentifierInput from '../../components/form/UserIdentifierInput';

const qs = require('query-string');

const LoginPage = (props) => {
  const { location } = props;
  const intl = useIntl();
  const trackers = useTrackers();
  const initialValues = { identifier: '', password: '' };
  const validationSchema = Yup.object().shape({
    identifier: Yup.string().required(),
    password: Yup.string().required(),
  });
  const [error, setError] = useState(null);
  const redirect = useRedirectAfterAuth();

  let emailVerifiedMessage = null;
  if (qs.parse(location.search)['email-verified'] !== undefined) {
    emailVerifiedMessage = intl.formatMessage({ id: 'email-verified' });
  }

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Login' }));
    trackers.track('Page View', { page: 'Login' });
  }, []);

  const onSubmit = (values, setSubmitting) => {
    setError(null);

    props
      .login(values.identifier, values.password)
      .then((attempt) => {
        trackers.track('Login');
        trackers.identify(attempt.user);
        return redirect(attempt);
      })
      .catch((e) => {
        if (e.response.status === 401 || e.response.status === 400) {
          setError(intl.formatMessage({ id: 'invalid_creds' }));
        }
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <AuthPageWrapper
        className="page-login"
        description={intl.formatMessage({ id: 'meta.description.login' })}
      >
        <Alert isOpen={!!emailVerifiedMessage} color="info">
          {emailVerifiedMessage}
        </Alert>

        <WelcomeToEksabMessage />

        <Alert isOpen={!!error} color="danger">
          {error}
        </Alert>

        <FacebookLogin />

        <BarSeparator>
          <FormattedMessage id="Or" />
        </BarSeparator>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className="input-wrapper">
                <FormGroup className="mb-0">
                  <UserIdentifierInput
                    name="identifier"
                    id="identifier"
                    placeholder={intl.formatMessage({ id: 'enter_identifier' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.identifier}
                    invalid={errors.identifier && touched.identifier}
                  />
                </FormGroup>

                <FormGroup>
                  <PasswordInput
                    name="password"
                    id="password"
                    placeholder={intl.formatMessage({ id: 'Password' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    invalid={errors.password && touched.password}
                    autocompelete="new-password"
                    toggle={true}
                  />
                </FormGroup>
              </div>

              <Row className="justify-content-between align-items-center">
                <Col className="mt-1" md="6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="primary"
                    className="btn-lg w-100"
                  >
                    <FormattedMessage id="submit" />
                    {isSubmitting && <Spinner />}
                  </Button>
                </Col>
                <Col className="mt-1" md="6">
                  <p className="mb-0">
                    <FormattedMessage id="Dont have account?" /> <br />
                    <Link to="/register">
                      <FormattedMessage id="Register" />
                    </Link>
                  </p>
                </Col>
              </Row>

              <p className="mb-0 mt-3">
                <FormattedMessage
                  id="forget-password-login-message"
                  values={{
                    cta: (
                      <Link to="/forget-password">
                        <FormattedMessage id="Reset Password" />
                      </Link>
                    ),
                  }}
                />
              </p>

              <BarSeparator>
                <FormattedMessage id="Or" />
              </BarSeparator>

              <SkipToLandingPage className="mt-3" />
            </Form>
          )}
        </Formik>
      </AuthPageWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { login, user } = state.auth;
  return { error: login.error, user };
};

export default connect(mapStateToProps, { login })(LoginPage);
