import React from 'react';
import { locale } from '../helpers/generic';
import FormTitle from './form/FormTitle';

const EnMessage = () => {
  return (
    <>
      We have given away over <br />
      <span className="text-primary">1 million pounds</span> in prizes. <br />
      Are you ready to win?
    </>
  );
};

const ArMessage = () => {
  return (
    <>
      اكسب ارسلت اكثر من <br />
      <span className="text-primary">مليون جنيه</span> من الجواىز. <br />
      مستعد للربح؟
    </>
  );
};

const WelcomeToEksabMessage = () => {
  return (
    <React.Fragment>
      <div id="welcome-to-eksab-message">
        <FormTitle>{locale() === 'en' ? <EnMessage /> : <ArMessage />}</FormTitle>
      </div>
    </React.Fragment>
  );
};

export default WelcomeToEksabMessage;
