import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import { IntlProvider } from 'react-intl';
import en from './assets/translations/en.json';
import ar from './assets/translations/ar.json';
import { changeLocale, locale, defaultLocale } from './helpers/generic';
import ThemeSelector from './themes/ThemeSelector';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import TagManager from 'react-gtm-module';
import dateAr from 'date-fns/locale/ar-SA';
import config from './config';
import { MixPanelProvider } from 'react-mixpanel-provider-component';
import { hotjar } from 'react-hotjar';
import { FirebaseAppProvider } from 'reactfire';
import 'dayjs/locale/ar';
import dayjs from 'dayjs';
import ReactPixel from 'react-facebook-pixel';

const { location } = window;
config.baseUrl = `${location.protocol}//${location.host}`;
config.newsBaseUrl = `${location.protocol}//news.${location.host}`;

ReactPixel.init('445517986570107');
ReactPixel.pageView();

require('./bootstrap');

const messages = { en, ar };
const currentLocale = locale();
if (!currentLocale) {
  changeLocale(defaultLocale());
}

if (currentLocale === 'ar') {
  registerLocale('ar', dateAr);
  setDefaultLocale('ar');
}

dayjs.locale(currentLocale);
let localizedFormat = require('dayjs/plugin/localizedFormat');
let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone');
let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

TagManager.initialize({
  gtmId: config.gtmId,
});

hotjar.initialize(config.hotjar.id, config.hotjar.jsVersion);

window.Moengage = window.moe({
  app_id: 'XP8WE4TPPIU3ZJ3Z4YGQJZF3',
  debug_logs: config.env === 'development' ? 0 : 0,
});

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config.firebase}>
    <IntlProvider locale={currentLocale} messages={messages[currentLocale]}>
      <Provider store={store}>
        <ThemeSelector>
          <MixPanelProvider>
            <App />
          </MixPanelProvider>
        </ThemeSelector>
      </Provider>
    </IntlProvider>
  </FirebaseAppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
