import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FaFacebookF } from 'react-icons/fa';
import Spinner from 'reactstrap/es/Spinner';
import { connect, useDispatch } from 'react-redux';
import useTrackers from '../hooks/useTrackers';
import useAccount from '../hooks/useAccount';
import ReactPixel from 'react-facebook-pixel';
import { clearReferrer, fetchMe } from '../redux/auth/thunks';
import useRedirectAfterAuth from '../hooks/useRedirectAfterAuth';

const FacebookLogin = (props) => {
  const { referrer } = props;
  const trackers = useTrackers();
  const [loading, setLoading] = useState(false);
  const account = useAccount();
  const dispatch = useDispatch();
  const redirect = useRedirectAfterAuth();

  const onLogin = () => {
    setLoading(true);

    trackers.track('Button Clicked', { button: 'auth.facebook_login' });

    window.FB.login(
      function (response) {
        let accessToken = response.authResponse?.accessToken;

        trackers.track('facebook.popup_closed', {
          status: accessToken ? 'success' : 'failed',
        });

        if (accessToken) {
          return account.loginUsingFacebook(accessToken, referrer).then(async (attempt) => {
            let override = null;
            if (attempt.extra.type === 'register') {
              trackers.track('New User');
              ReactPixel.track('fb_mobile_complete_registration');

              override = '/register/review';
            }

            trackers.identify(attempt.user);

            dispatch(clearReferrer());

            await dispatch(fetchMe());

            return redirect(attempt, override);
          });
        }

        setLoading(false);
      },
      { scope: 'email,user_friends', return_scopes: true }
    );
  };

  return (
    <div className="facebook-btn">
      <button
        onClick={onLogin}
        className="btn btn-block btn-lg btn-facebook-login"
        disabled={loading}
      >
        <FaFacebookF className="icon" /> <FormattedMessage id="Login with Facebook" />
        {loading && <Spinner />}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loginError, user, referrer } = state.auth;
  return { loginError, user, referrer };
};

export default connect(mapStateToProps, null)(FacebookLogin);
