import * as actionTypes from '../../constants/action-types/competitions';

const initCompetition = {
  id: null,
  matches: { items: null, loading: false },
  ranking: {
    items: [],
    breakdown: null,
    page: null,
    lastPage: null,
    loading: false,
    pending: false,
  },
  selectedMatch: { id: null, loading: false },
  loading: false,
};

const initCompetitionList = { items: [], page: null, lastPage: null, loading: false };

const initCompetitionLists = {
  featured: { ...initCompetitionList },
  upcoming: { ...initCompetitionList },
  past: { ...initCompetitionList },
  running: { ...initCompetitionList },
};

const INIT_STATE = {
  entities: [],
  competitions: {
    predictions: { ...initCompetitionLists },
    fantasy: { ...initCompetitionLists },
    eSports: { ...initCompetitionLists },
    mine: { all: { ...initCompetitionList } },
    group: { all: { ...initCompetitionList } },
  },
  competition: initCompetition,
  joiningCompetitionIds: [],
  joinedCompetitionIds: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_COMPETITIONS: {
      const { type, list } = action.payload;
      return {
        ...state,
        competitions: {
          ...state.competitions,
          [type]: {
            ...state.competitions[type],
            [list]: { ...initCompetitionList },
          },
        },
      };
    }

    case actionTypes.FETCH_COMPETITIONS_START: {
      const { type, list } = action.payload;
      return {
        ...state,
        competitions: {
          ...state.competitions,
          [type]: {
            ...state.competitions[type],
            [list]: { ...state.competitions[type][list], loading: true },
          },
        },
      };
    }

    case actionTypes.FETCH_COMPETITIONS_SUCCESS: {
      const { type, list, data, meta } = action.payload;

      return {
        ...state,
        entities: mergeEntities(state.entities, data),
        competitions: {
          ...state.competitions,
          [type]: {
            ...state.competitions[type],
            [list]: {
              items: [
                ...state.competitions[type][list].items,
                ...action.payload.data.map((item) => item.id),
              ],
              loading: false,
              page: meta.current_page,
              lastPage: meta.last_page,
            },
          },
        },
      };
    }

    case actionTypes.FETCH_COMPETITIONS_FAILURE: {
      const { type, list } = action.payload;

      return {
        ...state,
        competitions: {
          ...state.competitions,
          [type]: {
            [list]: { ...state[type], loading: false },
          },
        },
      };
    }

    case actionTypes.JOIN_COMPETITION_START:
      return {
        ...state,
        joiningCompetitionIds: [...state.joiningCompetitionIds, action.payload.competitionId],
      };

    case actionTypes.JOIN_COMPETITION_SUCCESS: {
      const { competitionId } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [competitionId]: {
            ...state.entities[competitionId],
            joined: true,
          },
        },
        joiningCompetitionIds: state.joiningCompetitionIds.filter((item) => item !== competitionId),
      };
    }

    case actionTypes.JOIN_COMPETITION_FAILURE:
      return {
        ...state,
        joiningCompetitionIds: state.joiningCompetitionIds.filter(
          (item) => item !== action.payload.competitionId
        ),
      };

    case actionTypes.LEAVE_COMPETITION_SUCCESS: {
      const { competitionId } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [competitionId]: {
            ...state.entities[competitionId],
            joined: false,
          },
        },
      };
    }

    case actionTypes.CLEAR_COMPETITION:
      return { ...state, competition: initCompetition };

    case actionTypes.FETCH_COMPETITION_START:
      return { ...state, competition: { ...state.competition, loading: true } };

    case actionTypes.FETCH_COMPETITION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.competition.id]: action.payload.competition,
        },
        competition: { ...state.competition, id: action.payload.competition.id, loading: false },
      };

    case actionTypes.FETCH_COMPETITION_FAILURE:
      return { ...state, competition: { ...state.competition, loading: false } };

    case actionTypes.FETCH_COMPETITION_MATCHES_START:
      return {
        ...state,
        competition: {
          ...state.competition,
          matches: { ...state.competition.matches, items: [], loading: true },
        },
      };

    case actionTypes.FETCH_COMPETITION_MATCHES_SUCCESS:
      return {
        ...state,
        competition: {
          ...state.competition,
          matches: { items: action.payload.matches.map((match) => match.id), loading: false },
        },
      };

    case actionTypes.FETCH_COMPETITION_MATCHES_FAILURE:
      return {
        ...state,
        competition: {
          ...state.competition,
          matches: { ...state.competition.matches, items: [], loading: false },
        },
      };

    case actionTypes.SELECT_COMPETITION_MATCH_START:
      return {
        ...state,
        competition: { ...state.competition, selectedMatch: { item: null, loading: true } },
      };

    case actionTypes.SELECT_COMPETITION_MATCH_SUCCESS:
      return {
        ...state,
        competition: {
          ...state.competition,
          selectedMatch: { id: action.payload.matchId, loading: false },
        },
      };

    case actionTypes.SELECT_COMPETITION_MATCH_FAILURE:
      return {
        ...state,
        competition: { ...state.competition, selectedMatch: { id: null, loading: false } },
      };

    case actionTypes.UNSELECT_COMPETITION_MATCH:
      return {
        ...state,
        competition: { ...state.competition, selectedMatch: { item: null, loading: false } },
      };

    case actionTypes.CLEAR_COMPETITION_RANKING:
      return {
        ...state,
        competition: { ...state.competition, ranking: { ...initCompetition.ranking } },
      };

    case actionTypes.FETCH_COMPETITION_RANKING_START:
      return {
        ...state,
        competition: {
          ...state.competition,
          ranking: { ...state.competition.ranking, loading: true },
        },
      };

    case actionTypes.FETCH_COMPETITION_RANKING_SUCCESS:
      return {
        ...state,
        competition: {
          ...state.competition,
          ranking: {
            items: [...state.competition.ranking.items, ...action.payload.ranking],
            page: action.payload.meta.current_page,
            lastPage: action.payload.meta.last_page,
            pending: action.payload.meta.pending,
            loading: false,
          },
        },
      };

    case actionTypes.FETCH_COMPETITION_RANKING_FAILURE:
      return {
        ...state,
        competition: {
          ...state.competition,
          ranking: { ...state.competition.ranking, loading: false },
        },
      };

    case actionTypes.RECEIVE_COMPETITION_RANKING_BREAKDOWN:
      return {
        ...state,
        competition: {
          ...state.competition,
          ranking: {
            ...state.competition.ranking,
            breakdown: action.payload.breakdown,
          },
        },
      };

    case actionTypes.RECEIVE_JOINED_COMPETITION_IDS:
      return {
        ...state,
        joinedCompetitionIds: [...state.joinedCompetitionIds, ...action.payload.ids],
      };

    case actionTypes.REMOVE_JOINED_COMPETITION_ID:
      return {
        ...state,
        joinedCompetitionIds: state.joinedCompetitionIds.filter((id) => id !== action.payload.id),
      };

    case actionTypes.INCREASE_COMPETITION_PARTICIPANTS:
      return onChangeCompetitionParticipants(state, action, (participants) => participants + 1);

    case actionTypes.DECREASE_COMPETITION_PARTICIPANTS:
      return onChangeCompetitionParticipants(state, action, (participants) => participants - 1);

    default:
      return { ...state };
  }
};

const mergeEntities = (entities, newEntities) => {
  let merged = { ...entities };

  newEntities.forEach((entity) => (merged[entity.id] = entity));

  return merged;
};

const onChangeCompetitionParticipants = (state, action, callback) => {
  const { competitionId } = action.payload;
  const competition = state.entities[competitionId];

  return {
    ...state,
    entities: {
      ...state.entities,
      [competitionId]: {
        ...competition,
        participants: {
          ...competition.participants,
          current: callback(competition.participants.current),
        },
      },
    },
  };
};
