import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export default function usePhoneNumberVerification() {
  const history = useHistory();
  const user = useSelector((s) => s.auth.user);
  const settings = useSelector((s) => s.generic.settings);

  const isEnabled = () => settings.enable_phone_verification;

  const shouldVerify = () => isEnabled() && user && !user.phone_number;

  const redirect = (from) => {
    history.push({
      pathname: '/verify-phone-number',
      state: { from },
    });
  };

  return { isEnabled, shouldVerify, redirect };
}
