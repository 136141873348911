import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { MdSearch } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Button, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { getDefaultLocale } from 'react-datepicker';
import { useHistory } from 'react-router';

const qs = require('query-string');

const CustomDatePickerInput = ({ value, onClick }) => (
  <InputGroup>
    <Input readOnly={true} value={value} />
    <InputGroupAddon addonType="append">
      <Button color="secondary" onClick={onClick}>
        <FaRegCalendarAlt className="icon" />
      </Button>
    </InputGroupAddon>
  </InputGroup>
);

const MatchFilters = (props) => {
  const { date, q } = props.filters;
  const [search, setSearch] = useState(q);
  const history = useHistory();

  const onCustomSearch = () => {
    if (search) {
      history.push({
        pathname: '/search',
        search: '?' + qs.stringify({ q: search }),
      });
    }
  };

  const onDateSearch = (newDate) => {
    if (date !== newDate) {
      let filters = { ...props.filters, date: dayjs(newDate).format('YYYY-MM-DD') };
      props.onFilter(filters);
    }
  };

  return (
    <React.Fragment>
      <div id="match-filters">
        <div className="row">
          <div className="col">
            <div className="match-search">
              <input
                type="text"
                className="form-control"
                placeholder={useIntl().formatMessage({ id: 'search-message' })}
                onChange={(event) => setSearch(event.target.value)}
              />
              <button className="btn" onClick={onCustomSearch}>
                <MdSearch className="icon" />
              </button>
            </div>
          </div>
          <Col md={{ size: 4, offset: 2 }}>
            <div className="match-date-search">
              <DatePicker
                dateFormat={getDefaultLocale() === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy'}
                selected={dayjs(date).toDate()}
                onSelect={onDateSearch}
                customInput={<CustomDatePickerInput />}
                customInputRef="date-picker"
              />
            </div>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatchFilters;
