import React from 'react';
import { Alert } from 'reactstrap';

const ValidationErrors = ({ errors }) => {
  const hasErrors = !!errors;

  return (
    hasErrors && (
      <Alert isOpen={hasErrors} color="danger">
        <ul className="mb-0 list-unstyled">
          {Object.values(errors)
            .flat()
            .map((e, i) => (
              <li key={i}>{e}</li>
            ))}
        </ul>
      </Alert>
    )
  );
};

export default ValidationErrors;
