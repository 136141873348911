import React from 'react';

function CompetitionPrize(props) {
  const { ranking, prize } = props;

  return (
    <React.Fragment>
      <div className="competition-prize">
        <div className="competition-prize-left">
          {ranking === 1 ? (
            <img
              src={require('../../assets/images/ranking-1st.png')}
              className="prize-ranking"
              alt="first place ranking medal"
            />
          ) : ranking === 2 ? (
            <img
              src={require('../../assets/images/ranking-2nd.png')}
              className="prize-ranking"
              alt="second place ranking medal"
            />
          ) : (
            <img
              src={require('../../assets/images/ranking-3rd.png')}
              className="prize-ranking"
              alt="third place ranking medal"
            />
          )}

          <h4 className="prize-name">{prize.name}</h4>
        </div>

        {prize.icon && <img src={prize.icon.original} className="prize-icon" alt="prize icon" />}
      </div>
    </React.Fragment>
  );
}

export default CompetitionPrize;
