import * as actionTypes from '../../constants/action-types/competitions';

export const clearCompetitions = (type, list) => ({
  type: actionTypes.CLEAR_COMPETITIONS,
  payload: { type, list },
});

export const fetchCompetitionsStart = (type, list) => ({
  type: actionTypes.FETCH_COMPETITIONS_START,
  payload: { type, list },
});

export const fetchCompetitionsSuccess = (type, list, data, meta) => ({
  type: actionTypes.FETCH_COMPETITIONS_SUCCESS,
  payload: { type, list, data, meta },
});

export const fetchCompetitionsFailure = (type) => ({
  type: actionTypes.FETCH_COMPETITIONS_FAILURE,
  payload: { type },
});

export const joinCompetitionStart = (competitionId) => ({
  type: actionTypes.JOIN_COMPETITION_START,
  payload: { competitionId },
});

export const joinCompetitionSuccess = (competitionId) => ({
  type: actionTypes.JOIN_COMPETITION_SUCCESS,
  payload: { competitionId },
});

export const joinCompetitionFailure = (competitionId) => ({
  type: actionTypes.JOIN_COMPETITION_FAILURE,
  payload: { competitionId },
});

export const leaveCompetitionSuccess = (competitionId) => ({
  type: actionTypes.LEAVE_COMPETITION_SUCCESS,
  payload: { competitionId },
});

export const clearCompetition = () => ({
  type: actionTypes.CLEAR_COMPETITION,
});

export const fetchCompetitionStart = () => ({
  type: actionTypes.FETCH_COMPETITION_START,
});

export const fetchCompetitionSuccess = (competition) => ({
  type: actionTypes.FETCH_COMPETITION_SUCCESS,
  payload: { competition },
});

export const fetchCompetitionFailure = () => ({
  type: actionTypes.FETCH_COMPETITION_FAILURE,
});

export const fetchCompetitionMatchesStart = () => ({
  type: actionTypes.FETCH_COMPETITION_MATCHES_START,
});

export const fetchCompetitionMatchesSuccess = (matches) => ({
  type: actionTypes.FETCH_COMPETITION_MATCHES_SUCCESS,
  payload: { matches },
});

export const fetchCompetitionMatchesFailure = () => ({
  type: actionTypes.FETCH_COMPETITION_MATCHES_FAILURE,
});

export const selectCompetitionMatchStart = () => ({
  type: actionTypes.SELECT_COMPETITION_MATCH_START,
});

export const selectCompetitionMatchSuccess = (matchId) => ({
  type: actionTypes.SELECT_COMPETITION_MATCH_SUCCESS,
  payload: { matchId },
});

export const selectCompetitionMatchFailure = () => ({
  type: actionTypes.SELECT_COMPETITION_MATCH_FAILURE,
});

export const unselectCompetitionMatch = () => ({
  type: actionTypes.UNSELECT_COMPETITION_MATCH,
});

export const clearCompetitionRanking = () => ({
  type: actionTypes.CLEAR_COMPETITION_RANKING,
});

export const fetchCompetitionRankingStart = () => ({
  type: actionTypes.FETCH_COMPETITION_RANKING_START,
});

export const fetchCompetitionRankingSuccess = (ranking, meta) => ({
  type: actionTypes.FETCH_COMPETITION_RANKING_SUCCESS,
  payload: { ranking, meta },
});

export const fetchCompetitionRankingFailure = () => ({
  type: actionTypes.FETCH_COMPETITION_RANKING_FAILURE,
});

export const receiveJoinedCompetitionIds = (ids) => ({
  type: actionTypes.RECEIVE_JOINED_COMPETITION_IDS,
  payload: { ids },
});

export const removeJoinedCompetitionId = (id) => ({
  type: actionTypes.REMOVE_JOINED_COMPETITION_ID,
  payload: { id },
});

export const increaseCompetitionParticipants = (competitionId) => ({
  type: actionTypes.INCREASE_COMPETITION_PARTICIPANTS,
  payload: { competitionId },
});

export const decreaseCompetitionParticipants = (competitionId) => ({
  type: actionTypes.DECREASE_COMPETITION_PARTICIPANTS,
  payload: { competitionId },
});

export const receiveCompetitionRankingBreakdown = (breakdown) => ({
  type: actionTypes.RECEIVE_COMPETITION_RANKING_BREAKDOWN,
  payload: { breakdown },
});
