import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal as BaseModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/profile/thunks';
import { toast } from 'react-toastify';
import ValidationRuleFactory from '../../helpers/validation-rule-factory';
import ValidationErrors from '../ValidationErrors';
import FormText from 'reactstrap/es/FormText';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ViewExistingFile from '../ViewExistingFile';

const EditProfile = ({ open, onToggle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [serverErrors, setServerErrors] = useState(null);

  const existingNationalIdScans = useSelector((s) => s.auth.user.national_id_scans);
  const profilePicture = useSelector((s) => s.auth.user.photo);

  const nationalIdVerified = useSelector((s) => s.auth.user.national_id_verified);

  const initialValues = useSelector((s) => {
    const { name, legal_name, email, national_id } = s.auth.user;

    return {
      name: name ?? '',
      legal_name: legal_name ?? '',
      email: email ?? '',
      national_id: national_id ?? '',
      national_id_scans: { front: '', back: '' },
      profile_picture: '',
    };
  });

  const validationSchema = Yup.object().shape({
    name: ValidationRuleFactory.name(),
    legal_name: ValidationRuleFactory.legalName(),
    email: ValidationRuleFactory.email(),
    national_id: ValidationRuleFactory.nationalId(false),
    profile_picture: ValidationRuleFactory.profilePicture(intl),
    national_id_scans: ValidationRuleFactory.nationalIdScans(intl, false),
  });

  const setFile = (event, field, setFieldValue) => {
    const files = Object.values(event.currentTarget.files);

    setFieldValue(field, files.length ? files[0] : null);
  };

  const onSubmit = (values, setSubmitting) => {
    setServerErrors(null);

    dispatch(updateProfile(values))
      .then(() => {
        toast.success(intl.formatMessage({ id: 'profile_updated' }));
      })
      .catch((e) => {
        setServerErrors(e.response.data.errors);
        setSubmitting(false);
      });
  };

  return (
    <BaseModal isOpen={open} toggle={onToggle} centered={true} backdrop="static">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setTouched,
          } = props;

          return (
            <Form onSubmit={handleSubmit}>
              <ModalHeader toggle={onToggle}>
                <FormattedMessage id="Edit Profile" />
              </ModalHeader>
              <ModalBody>
                <ValidationErrors errors={serverErrors} />

                <FormGroup>
                  <Label for="name">
                    <FormattedMessage id="Name" />
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    invalid={errors.name && touched.name}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="legal-name">
                    <FormattedMessage id="Full Legal Name" />
                  </Label>
                  <Input
                    type="text"
                    name="legal_name"
                    id="legal-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.legal_name}
                    invalid={errors.legal_name && touched.legal_name}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="email">
                    <FormattedMessage id="Email" />
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={errors.email && touched.email}
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <Label for="national-id">
                    <FormattedMessage id="National Id" />
                  </Label>
                  <Input
                    type="text"
                    name="national_id"
                    id="national-id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.national_id}
                    invalid={errors.national_id && touched.national_id}
                    disabled={nationalIdVerified}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="national-id-scan-front">
                    <FormattedMessage id="National Id Scans (front and back)" />
                  </Label>
                  <div className="row">
                    <div className="col">
                      <Input
                        type="file"
                        id="national-id-scan-0"
                        invalid={!!errors.national_id_scans && touched.national_id_scans}
                        onChange={(e) => setFile(e, 'national_id_scans.front', setFieldValue)}
                        onBlur={() => setTouched({ ...touched, national_id_scans: true })}
                      />
                      {!!existingNationalIdScans.front && (
                        <ViewExistingFile
                          href={existingNationalIdScans.front.original}
                          target="_blank"
                        >
                          <FormattedMessage id="View uploaded" /> <FaExternalLinkAlt />
                        </ViewExistingFile>
                      )}
                    </div>
                    <div className="col">
                      <Input
                        type="file"
                        id="national-id-scan-back"
                        invalid={!!errors.national_id_scans && touched.national_id_scans}
                        onChange={(e) => setFile(e, 'national_id_scans.back', setFieldValue)}
                        onBlur={() => setTouched({ ...touched, national_id_scans: true })}
                      />
                      {!!existingNationalIdScans.back && (
                        <ViewExistingFile
                          href={existingNationalIdScans.back.original}
                          target="_blank"
                        >
                          <FormattedMessage id="View uploaded" /> <FaExternalLinkAlt />
                        </ViewExistingFile>
                      )}
                    </div>
                  </div>

                  {!!touched.national_id_scans && !!errors.national_id_scans?.length && (
                    <FormText color="danger">{errors.national_id_scans}</FormText>
                  )}
                </FormGroup>

                <hr />

                <FormGroup className="mb-0">
                  <Label for="profile-picture">
                    <FormattedMessage id="Profile Picture" />
                  </Label>
                  <Input
                    type="file"
                    id="profile-picture"
                    invalid={errors.profile_picture && touched.profile_picture}
                    onChange={(e) => setFile(e, 'profile_picture', setFieldValue)}
                    onBlur={() => setTouched({ ...touched, profile_picture: true })}
                  />

                  {!!profilePicture && (
                    <ViewExistingFile href={profilePicture.original} target="_blank">
                      <FormattedMessage id="View uploaded" /> <FaExternalLinkAlt />
                    </ViewExistingFile>
                  )}

                  {touched.profile_picture && !!errors.profile_picture?.length && (
                    <FormText color="danger">{errors.profile_picture}</FormText>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" disabled={isSubmitting}>
                  <FormattedMessage id="Save" />
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

export default EditProfile;
