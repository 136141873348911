import { useHistory, useLocation } from 'react-router';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';
import { Cookies } from 'react-cookie';

const qs = require('query-string');

export default function useStateAwareRedirect() {
  const history = useHistory();
  const location = useLocation();

  const cookies = new Cookies();

  const pqs = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (pqs.redirect) {
    cookies.set('redirect', pqs.redirect);
  }

  return (options = {}) => {
    const { override, fallback = DEFAULT_LANDING_PAGE, state = {} } = options;

    if (override) {
      return history.replace({ pathname: override, state: { ...location.state, ...state } });
    }

    const cookieRedirect = cookies.get('redirect');

    let redirect = null;
    if (cookieRedirect) {
      redirect = cookieRedirect;
      cookies.remove('redirect');
    } else if (location.state && location.state.from) {
      redirect = location.state.from;
    } else {
      redirect = fallback;
    }

    if (options.noRedirect) return redirect;

    history.replace(redirect);
  };
}
