import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hidePurchaseModal } from '../../../redux/store/actions';
import Modal from '../../../components/Modal';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import api from '../../../helpers/api';
import { decreaseCoins } from '../../../redux/generic/actions';

export const PurchaseModal = () => {
  const user = useSelector((s) => s.auth.user);
  const purchaseModal = useSelector((s) => s.store.purchaseModal);
  const dispatch = useDispatch();
  const intl = useIntl();

  const initialValues = {
    product_id: purchaseModal.product.id,
    shipping_info: {
      street_address: '',
      name: user?.name,
      phone_number: user?.phone_number,
    },
  };

  const validationSchema = Yup.object().shape({
    product_id: Yup.string().required(),
    shipping_info: Yup.object().shape({
      name: Yup.string().required(),
      phone_number: Yup.string().required(),
      street_address: Yup.string().required(),
    }),
  });

  const onSubmit = (values) => {
    api.placeOrder(values).then(() => {
      toast.success(intl.formatMessage({ id: 'order_placed' }));
      if (user) {
        dispatch(decreaseCoins(purchaseModal.product.current_price));
      }

      dispatch(hidePurchaseModal());
    });
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
          <Modal
            isOpen={purchaseModal.show}
            toggle={() => dispatch(hidePurchaseModal())}
            title={
              <FormattedMessage id="buy_product" values={{ name: purchaseModal.product.name }} />
            }
            id="purchase-modal"
            backdrop="static"
          >
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">
                  <FormattedMessage id="Name" />
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="shipping_info[name]"
                  value={values.shipping_info.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={!!(touched.shipping_info?.name && errors.shipping_info?.name)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="phone-number">
                  <FormattedMessage id="Phone Number" />
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="phone-number"
                  name="shipping_info[phone_number]"
                  value={values.shipping_info.phone_number}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={
                    !!(touched.shipping_info?.phone_number && errors.shipping_info?.phone_number)
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label for="street-address">
                  <FormattedMessage id="Street Address" />
                </Label>
                <Input
                  type="textarea"
                  className="form-control"
                  id="street-address"
                  name="shipping_info[street_address]"
                  value={values.shipping_info.street_address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={
                    !!(
                      touched.shipping_info?.street_address && errors.shipping_info?.street_address
                    )
                  }
                />
              </FormGroup>

              <Button className="btn btn-block btn-lg" color="primary" disabled={isSubmitting}>
                <FormattedMessage id="Confirm Order" />
              </Button>
            </Form>
          </Modal>
        )}
      </Formik>
    </React.Fragment>
  );
};
