import { useMixPanel } from 'react-mixpanel-provider-component';
import useMoEngage from './useMoEngage';
import useMixPanelProfile from './useMixPanelProfile';

export default function useTrackers() {
  const trackers = useFactory();

  const identify = (user) => {
    trackers.forEach((tracker) => tracker.identify(user));
  };

  const track = (eventName, attributes = {}) => {
    trackers.forEach((tracker) => tracker.track(eventName, attributes));
  };

  return { track, identify };
}

const useFactory = function () {
  const mixpanel = useMixpanelAdapter();
  const moe = useMoEngage();

  return [mixpanel, moe];
};

const useMixpanelAdapter = function () {
  const { mixpanel } = useMixPanel();
  const { identifyUser } = useMixPanelProfile();

  return {
    track: (eventName, attributes) => {
      mixpanel.track(eventName, attributes);
    },
    identify: identifyUser,
  };
};
