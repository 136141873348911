import React from 'react';
import { Button, Col } from 'reactstrap';

const Buttons = (props) => {
  const { onNext, onPrev, step, isSubmitting } = props;

  return (
    <React.Fragment>
      <div className="row">
        <Col xs={6}>
          <Button block={true} onClick={() => onPrev()} disabled={step === 1}>
            Previous
          </Button>
        </Col>
        <Col xs={6}>
          {step !== 3 && (
            <Button block={true} onClick={onNext}>
              Next
            </Button>
          )}
          {step === 3 && (
            <Button color="primary" block={true} disabled={isSubmitting} onClick={onNext}>
              Submit
            </Button>
          )}
        </Col>
      </div>
    </React.Fragment>
  );
};

export default Buttons;
