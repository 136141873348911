import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { fetchCompetitionRanking, leaveCompetition } from '../../../redux/competitions/thunks';
import { clearCompetitionRanking } from '../../../redux/competitions/actions';

const LeaveCompetitionConfirmationModal = (props) => {
  const { competition, onToggle, isOpen } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const leave = () => {
    dispatch(leaveCompetition(competition)).then(() => {
      setLoading(false);
      dispatch(clearCompetitionRanking());
      dispatch(fetchCompetitionRanking(competition.season.id));
      onToggle();
    });
  };

  return (
    <React.Fragment>
      <Modal
        id="modal-leave-competition-confirmation"
        className="modal-confirmation"
        isOpen={isOpen}
        centered={true}
      >
        <ModalBody>
          <p className="text-muted mb-0">
            <FormattedMessage id="leave-competition-confirmation" />{' '}
          </p>
          <h3 className="m-0">
            <b>{competition.name}</b>
          </h3>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-danger btn-block btn-confirm"
            disabled={loading}
            onClick={leave}
          >
            <FormattedMessage id="Yes, I want to leave" />
          </button>

          <button className="btn btn-link text-muted btn-go-back" onClick={onToggle}>
            <FormattedMessage id="No, keep me in" />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default LeaveCompetitionConfirmationModal;
