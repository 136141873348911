// @vendors
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import CreateModal from '../../competitions/components/create/Modal';
import CompetitionList from '../../../components/competitions/CompetitionList';
import GroupOverview from './components/GroupOverview';
import GroupLeaderboard from './components/GroupLeaderboard';
// @redux
import { clearCompetitions } from '../../../redux/competitions/actions';
import { fetchCompetitions } from '../../../redux/competitions/thunks';
import { saveGroupDetails } from '../../../redux/groups/actions';
import { fetchGroupDetails, fetchGroupMember } from '../../../redux/groups/thunks';
import { selectGroupDetails } from '../../../redux/groups/selectors';
import useTrackers from '../../../hooks/useTrackers';
import GroupRulesAr from './components/GroupRulesAr';
import GroupRulesEn from './components/GroupRulesEn';
import { locale } from '../../../helpers/generic';

const GroupDetailsPage = () => {
  const { id: groupId, activeTab = 'overview', isCreate } = useParams();
  const dispatch = useDispatch();
  const trackers = useTrackers();
  const history = useHistory();
  // const [activeTab, setActiveTab] = useState('overview'); // can be 'overview', 'competitions', 'leaderboard', 'rules'
  const [userRole, setUserRole] = useState(0); // can be 0 'not joined', 1 'user', 2 'admin'
  const userId = useSelector((state) => state?.auth?.user?.id);
  const groupDetails = useSelector(selectGroupDetails());
  const groupCompetitions = useSelector((state) => state.competitions.competitions.group.all);
  // Create Competition Modal Form
  const [isCreateCompetitionModal, showCreateCompetitionModal] = useState(false);
  const isCreateCompetitionModalParam = isCreate === 'create';
  const currentLocale = locale();

  const handleFetchGroupDetails = () => {
    if (groupId) dispatch(fetchGroupDetails(groupId));
  };

  const handleFetchRole = () => {
    if (groupDetails?.id) {
      if (groupDetails?.joined) {
        dispatch(fetchGroupMember(groupId, userId))
          .then((memberData) => {
            if (memberData?.role === 1) {
              // Set to user role
              setUserRole(1);
            } else {
              // Set to admin role
              setUserRole(2);
            }
          })
          .catch((e) => {
            // set to not joined role when api fails
            history.push(`/groups/${groupId}/overview`);
            setUserRole(0);
          });
      } else {
        // not joined
        if (groupDetails?.type === 'private') history.push(`/groups/${groupId}/overview`);
        setUserRole(0);
      }
    }
  };

  useEffect(() => {
    // Check to automatically trigger the create modal
    if (isCreateCompetitionModalParam) handleCreateCompetitionModalToggle(true);
  }, []);

  useEffect(() => {
    // Get Group details
    handleFetchGroupDetails();
  }, [groupId]);

  useEffect(() => {
    // Set Privelages/Role
    handleFetchRole();
  }, [groupDetails, userId]);

  useEffect(() => {
    // Initial Loading competitions
    if (activeTab === 'competitions' && groupCompetitions?.items?.length === 0) {
      dispatch(fetchCompetitions('group', 'all', { group: groupId }));
    }
  }, [activeTab]);

  useEffect(() => {
    return () => {
      // Clear state on page leave
      dispatch(saveGroupDetails());
      dispatch(clearCompetitions('group', 'all'));
    };
  }, [dispatch]);

  useEffect(() => {
    trackers.track('Page View', { page: `Group` });
  }, []);

  const handleTabChange = (tabType) => {
    if (activeTab !== tabType && !(userRole === 0 && groupDetails?.type === 'private')) {
      // Set Active tab
      history.push(`/groups/${groupId}/${tabType}`);
    }
  };

  const handleCreateCompetitionModalToggle = (open = false) => {
    // show/hide create competition modal
    showCreateCompetitionModal(open);
    if (open !== isCreateCompetitionModalParam) {
      if (open) history.push(`${history?.location?.pathname}/create`);
      else history.push(`${history?.location?.pathname.replace('/create', '')}`);
    }
  };

  return (
    <Box width="100%" p="1.25rem 2rem">
      {/* Tabs */}
      <Box
        display="flex"
        justifyContent="space-between"
        p={{ xs: '1rem 0.75rem', md: '0.875rem 1.5rem' }}
        bg="#fff"
        boxShadow="0 3px 10px 0 rgba(0, 0, 0, 0.10)"
        borderRadius="0.5rem"
        mb="2rem"
        flexWrap={{ xs: 'wrap', md: 'initial' }}
        filter={userRole === 0 && groupDetails?.type === 'private' ? 'grayscale(1)' : ''}
      >
        <Box flexShrink="0" display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          <Text
            fontSize="1rem"
            color={activeTab === 'overview' ? '#5ad37c' : '#201f2f'}
            cursor={userRole === 0 && groupDetails?.type === 'private' ? 'default' : 'pointer'}
            onClick={() => handleTabChange('overview')}
            p="0.75rem 1.375rem"
            borderInlineEnd={{ xs: 'none', md: '1px solid #e4e8f0' }}
            mie={{ xs: '0', md: '1.375rem' }}
            pis="0"
          >
            <FormattedMessage id="Overview" />
          </Text>

          <Text
            fontSize="1rem"
            color={activeTab === 'competitions' ? '#5ad37c' : '#201f2f'}
            cursor={userRole === 0 && groupDetails?.type === 'private' ? 'default' : 'pointer'}
            onClick={() => handleTabChange('competitions')}
            p="0.75rem 1.375rem"
            borderInlineEnd={{ xs: 'none', md: '1px solid #e4e8f0' }}
            mie={{ xs: '0', md: '1.375rem' }}
            pis="0"
          >
            <FormattedMessage id="Competitions" />
          </Text>
          <Text
            fontSize="1rem"
            color={activeTab === 'leaderboard' ? '#5ad37c' : '#201f2f'}
            cursor={userRole === 0 && groupDetails?.type === 'private' ? 'default' : 'pointer'}
            onClick={() => handleTabChange('leaderboard')}
            p="0.75rem 1.375rem"
            borderInlineEnd={{ xs: 'none', md: '1px solid #e4e8f0' }}
            mie={{ xs: '0', md: '1.375rem' }}
            pis="0"
          >
            <FormattedMessage id="Leaderboard" />
          </Text>
          <Text
            fontSize="1rem"
            color={activeTab === 'rules' ? '#5ad37c' : '#201f2f'}
            cursor={userRole === 0 && groupDetails?.type === 'private' ? 'default' : 'pointer'}
            onClick={() => handleTabChange('rules')}
            p="0.75rem 1.375rem"
            pis="0"
          >
            <FormattedMessage id="Rules" />
          </Text>
        </Box>
        {userRole === 2 && (
          <Box flexShrink="0" width={{ xs: '100%', md: 'auto' }}>
            <button
              className="btn btn-primary w-100"
              onClick={() => handleCreateCompetitionModalToggle(true)}
            >
              <FormattedMessage id="Create new competition" />
            </button>
          </Box>
        )}
      </Box>
      {groupDetails?.type && (
        <>
          {/* Overview tab */}
          {activeTab === 'overview' && (
            <GroupOverview
              groupDetails={groupDetails}
              userRole={userRole}
              userId={userId}
              groupId={groupId}
            />
          )}
          {/* Competitions Tab */}
          {activeTab === 'competitions' && (
            <CompetitionList
              type="group"
              list="all"
              filters={{ group: groupId }}
              competitions={groupCompetitions}
            />
          )}
          {/* Leaderboard Tab */}
          {activeTab === 'leaderboard' && <GroupLeaderboard groupId={groupId} />}
          {/* Rules Tab */}
          {activeTab === 'rules' && (currentLocale === 'ar' ? <GroupRulesAr /> : <GroupRulesEn />)}
        </>
      )}
      {/* Create Competition Modal */}
      <CreateModal
        isOpen={isCreateCompetitionModal}
        onToggle={() => handleCreateCompetitionModalToggle(false)}
        groupId={groupId}
      />
    </Box>
  );
};

export default GroupDetailsPage;
