// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
// @components
import Box from '../../Box';
import Text from '../../Text';
// @styles
import { LineupImage } from './styles';
// @static
import lineupImg442 from '../../../assets/images/lineups/lineup-442.png';
import activeLineupImg442 from '../../../assets/images/lineups/lineup-442-active.png';
import lineupImg433 from '../../../assets/images/lineups/lineup-433.png';
import activeLineupImg433 from '../../../assets/images/lineups/lineup-433-active.png';
import lineupImg352 from '../../../assets/images/lineups/lineup-352.png';
import activeLineupImg352 from '../../../assets/images/lineups/lineup-352-active.png';
import lineupImg532 from '../../../assets/images/lineups/lineup-532.png';
import activeLineupImg532 from '../../../assets/images/lineups/lineup-532-active.png';
import lineupImg451 from '../../../assets/images/lineups/lineup-451.png';
import activeLineupImg451 from '../../../assets/images/lineups/lineup-451-active.png';
import lineupImg343 from '../../../assets/images/lineups/lineup-343.png';
import activeLineupImg343 from '../../../assets/images/lineups/lineup-343-active.png';

export const lineupsList = [
  {
    id: '4-4-2',
    image: {
      default: lineupImg442,
      active: activeLineupImg442,
    },
  },
  {
    id: '4-3-3',
    image: {
      default: lineupImg433,
      active: activeLineupImg433,
    },
  },
  {
    id: '3-5-2',
    image: {
      default: lineupImg352,
      active: activeLineupImg352,
    },
  },
  {
    id: '5-3-2',
    image: {
      default: lineupImg532,
      active: activeLineupImg532,
    },
  },
  {
    id: '4-5-1',
    image: {
      default: lineupImg451,
      active: activeLineupImg451,
    },
  },
  {
    id: '3-4-3',
    image: {
      default: lineupImg343,
      active: activeLineupImg343,
    },
  },
];

const LineupItem = ({ index, value, lineup, lineupView, onSelect }) => (
  <React.Fragment>
    {lineupView === 'field' ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        onClick={() => onSelect(lineup?.id)}
        width="Calc(100% / 4)"
        maxWidth="20rem"
        mie="0.5rem"
        flexShrink="0"
      >
        <LineupImage src={value === lineup?.id ? lineup?.image?.active : lineup?.image?.default} />
        <Text fontSize="1rem" fontWeight="bold" mt="0.75rem">
          {lineup?.id}
        </Text>
      </Box>
    ) : (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="fit-content"
        m="0.375rem 0"
        mie={{
          xs: index === lineupsList?.length - 1 ? '0' : '1.5rem',
          md: index === lineupsList?.length - 1 ? '0' : '2rem',
        }}
        flexShrink="0"
        onClick={() => onSelect(lineup?.id)}
        bg={value === lineup?.id ? '#5ad37c' : '#fff'}
        boxShadow={`0 3px 6px 0 ${
          value === lineup?.id ? 'rgba(90, 211, 124, 0.35)' : 'rgba(0, 0, 0, 0.16)'
        }`}
        cursor="pointer"
        borderRadius="0.25rem"
        p="1.25rem 0.75rem"
      >
        <Text fontSize="0.875rem" fontWeight="normal" color="#201f2f">
          {lineup?.id}
        </Text>
      </Box>
    )}
  </React.Fragment>
);

const CompetitionLineupSelector = ({ value, lineupView, onSelect, isSoftDisable }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ xs: 'space-between', md: 'flex-start' }}
      overflowX="auto"
      overflowY="hidden"
      pointerEvents={isSoftDisable ? 'none' : ''}
    >
      {isSoftDisable ? (
        <>
          <LineupItem
            key={value}
            lineup={find(lineupsList, { id: value })}
            index={0}
            value={value}
            lineupView={lineupView}
            onSelect={() => {}}
          />
        </>
      ) : (
        <>
          {lineupsList.map((lineup, index) => (
            <LineupItem
              key={lineup?.id}
              lineup={lineup}
              index={index}
              value={value}
              lineupView={lineupView}
              onSelect={onSelect}
            />
          ))}
        </>
      )}
    </Box>
  );
};

CompetitionLineupSelector.propTypes = {
  value: PropTypes.string,
  lineupView: PropTypes.string,
  onSelect: PropTypes.func,
  isSoftDisable: PropTypes.bool,
};

CompetitionLineupSelector.defaultProps = {
  value: null,
  lineupView: 'field',
  onSelect: () => {},
  isSoftDisable: false,
};

export default CompetitionLineupSelector;
