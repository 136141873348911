import React, { useState } from 'react';
import { Button, Col, CustomInput, Input, Row, Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { saveMultiplePredictions } from '../redux/matches/thunks';
import Tour from './Tour';
import { MdClose, MdCheck } from 'react-icons/md';
import { updateSetting } from '../redux/generic/thunks';
import YesNoBet from './bets/YesNoBet';
import GameScorePointsToWin from './bets/GameScorePointsToWin';
import useLocalStorage from 'react-use-localstorage';
import {
  QUESTION_TYPE_COUNTER,
  QUESTION_TYPE_SELECT_LIST,
  QUESTION_TYPE_YES_NO,
} from '../constants/generic';
import SelectListBet from './bets/SelectListBet';
import CounterBet from './bets/CounterBet';
import PointsWon from './bets/PointsWon';
import useTrackers from '../hooks/useTrackers';
import { ignoreErrors } from '../helpers/generic';

const getInitBets = (gameScoreBet, preservedPredictions) => {
  let bets = {};

  if (gameScoreBet) {
    bets[gameScoreBet.id] = {
      bet_id: gameScoreBet.id,
      value: {
        home_goals: gameScoreBet?.current_user_prediction?.value?.home_goals || 0,
        away_goals: gameScoreBet?.current_user_prediction?.value?.away_goals || 0,
      },
    };
  }

  preservedPredictions = JSON.parse(preservedPredictions);
  if (preservedPredictions) {
    bets = {
      ...bets,
      ...preservedPredictions.reduce((carry, bet) => {
        carry[bet.bet_id] = bet;
        return carry;
      }, {}),
    };
  }

  return bets;
};

function Prediction(props) {
  const {
    matchId,
    onNextMatch,
    userCanPredict,
    tutorial,
    autoNavigateToNextPrediction,
    updateSetting,
    onAttempt,
  } = props;

  const [preservedPredictions, setPreservedPredictions] = useLocalStorage(
    'preserved_predictions',
    null
  );

  const record = { ...props.loadedMatches[matchId] };
  const match = record.match;
  const gameScoreBet = record.game_score_bet;
  const start = dayjs(match.start);
  const matchStateClassName = match.state.code.toLowerCase().replace('_', '-');

  const hasActiveBets =
    record.bets.filter((bet) => bet.active).length || record.game_score_bet?.active;
  const predictable = match.state.flags.indexOf('predictable') !== -1 && hasActiveBets;
  const saving = props.savingPredictionsForMatchIds.indexOf(matchId) !== -1;
  const [predictionsSaved, setPredictionsSaved] = useState(false);
  const [predictionsFailed, setPredictionsFailed] = useState(false);

  const [bets, setBets] = useState(getInitBets(gameScoreBet, preservedPredictions));
  const [cachedGameScore, setCachedGameScore] = useState(
    gameScoreBet ? { ...bets[gameScoreBet.id].value } : null
  );
  const trackers = useTrackers();

  const onFocus = (field, value) => {
    setCachedGameScore({ ...cachedGameScore, [field]: value });

    setBets({
      ...bets,
      [gameScoreBet.id]: {
        ...bets[gameScoreBet.id],
        value: {
          ...bets[gameScoreBet.id].value,
          [field]: '',
        },
      },
    });
  };

  const onBlur = (field, value) => {
    if (!value.length) {
      setBets({
        ...bets,
        [gameScoreBet.id]: {
          ...bets[gameScoreBet.id],
          value: {
            ...bets[gameScoreBet.id].value,
            [field]: cachedGameScore[field],
          },
        },
      });
    }
  };

  const onChange = (field, value) => {
    const bet = bets[gameScoreBet.id];
    const asInt = parseInt(value);
    const parsed = asInt > 0 ? asInt : 0;

    onBetChange({ ...bet, value: { ...bet.value, [field]: parsed } });
  };

  const onBetChange = (bet) => {
    setBets({ ...bets, [bet.bet_id]: bet });
  };

  const onPredict = () => {
    const predictions = Object.values(bets);

    if (userCanPredict) {
      setPredictionsSaved(false);
      setPredictionsFailed(false);
      props
        .saveMultiplePredictions(matchId, predictions)
        .then(() => {
          setPredictionsSaved(true);
          setPreservedPredictions(null);

          ignoreErrors(() => {
            trackers.track('Predict_on_match', { predictionCount: predictions.length });
          });

          setTimeout(() => {
            setPredictionsSaved(false);
            if (autoNavigateToNextPrediction.value && onNextMatch) {
              onNextMatch();
            }
          }, 1000);
        })
        .catch(() => {
          setPredictionsFailed(true);
        });
    } else {
      setPreservedPredictions(JSON.stringify(predictions));
    }

    onAttempt && onAttempt(matchId, predictions);
  };

  const points = gameScoreBet?.current_user_prediction?.points?.amount;

  return (
    <React.Fragment>
      {tutorial.active &&
        tutorial.shown.sidebarPredictions &&
        tutorial.shown.match &&
        !tutorial.shown.prediction && (
          <Tour
            step="prediction"
            stepNumber="3"
            content="Set you predictions, gain points, and climb up the ranking list."
          />
        )}

      <div id="prediction" className="tutorial-prediction">
        {!!props.onUnselect && (
          <span
            className="close-wrapper"
            onClick={() => {
              props.onUnselect();
            }}
          >
            <MdClose />
          </span>
        )}

        <div className="header">
          <h3 className="time box-title">
            {start.format('DD MMM')}, {start.format('hh:mm A')}
          </h3>
        </div>
        <div className="body">
          <div className="teams">
            <Row>
              <Col>
                <div className="team">
                  {match.home_gamer && match.home_gamer.picture && (
                    <div className="player">
                      <img
                        src={match.home_gamer.picture.thumb}
                        title={match.home_gamer.name}
                        alt={match.home_gamer.name}
                      />
                      <h3>{match.home_gamer.name}</h3>
                    </div>
                  )}
                  <img src={match.home_team.logo?.original} alt="home team logo" />
                  <h3>{match.home_team.name}</h3>
                </div>
              </Col>
              <Col>
                <div className="state-wrapper">
                  <GameScorePointsToWin points={gameScoreBet?.points} />
                  <span className="vs">
                    <FormattedMessage id="vs" />
                  </span>
                  <span className={`state ${matchStateClassName}`}>{match.state.name}</span>
                  {match.minute ? <span className="time">{match.minute}:00</span> : null}
                  <PointsWon points={points} />
                </div>
              </Col>
              <Col>
                <div className="team">
                  {match.away_gamer && match.away_gamer.picture && (
                    <div className="player">
                      <img
                        src={match.away_gamer.picture.thumb}
                        title={match.away_gamer.name}
                        alt={match.away_gamer.name}
                      />
                      <h3>{match.away_gamer.name}</h3>
                    </div>
                  )}
                  <img src={match.away_team.logo?.original} alt="away team logo" />
                  <h3>{match.away_team.name}</h3>
                </div>
              </Col>
            </Row>
          </div>
          {gameScoreBet && (
            <div className="prediction">
              <Row className="align-items-center">
                <Col>
                  <Input
                    value={bets[gameScoreBet.id].value.home_goals}
                    readOnly={!gameScoreBet.active}
                    placeholder={cachedGameScore.home_goals}
                    onFocus={(event) => onFocus('home_goals', event.target.value)}
                    onBlur={(event) => onBlur('home_goals', event.target.value)}
                    onChange={(event) => {
                      onChange('home_goals', event.target.value);
                    }}
                  />
                </Col>
                <Col className="col-score">
                  {match.state.code !== 'NOT_STARTED' ? (
                    <div className="current-score-wrapper">
                      <img src={require('../assets/images/arch.svg')} className="icon" alt="" />
                      <div className="current-score">
                        <h4 className="label">
                          <FormattedMessage id="score" />
                        </h4>
                        <div className="score-wrapper">
                          <span className="scores">
                            <span>{match.home_goals}</span> : <span>{match.away_goals}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="predict-now">
                      <FormattedMessage id="This match has not started, predict now!" />
                    </p>
                  )}
                </Col>
                <Col>
                  <Input
                    value={bets[gameScoreBet.id].value.away_goals}
                    readOnly={!gameScoreBet.active}
                    placeholder={cachedGameScore.away_goals}
                    onFocus={(event) => onFocus('away_goals', event.target.value)}
                    onBlur={(event) => onBlur('away_goals', event.target.value)}
                    onChange={(event) => {
                      onChange('away_goals', event.target.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
          {record.bets.length ? (
            <div className="in-game-bets">
              <h3 className="title">
                <img src={require('../assets/images/slot-machine.svg')} alt="" />
                <FormattedMessage id="bonus-predictions" />
              </h3>

              <div className="bet-list">
                {record.bets.map((bet, i) => {
                  switch (bet.question_type) {
                    case QUESTION_TYPE_YES_NO:
                      return (
                        <YesNoBet
                          key={i}
                          bet={bet}
                          initialValue={bets[bet.id]}
                          onChange={onBetChange}
                        />
                      );

                    case QUESTION_TYPE_SELECT_LIST:
                      return (
                        <SelectListBet
                          key={i}
                          bet={bet}
                          initialValue={bets[bet.id]}
                          onChange={onBetChange}
                        />
                      );

                    case QUESTION_TYPE_COUNTER:
                      return (
                        <CounterBet
                          key={i}
                          bet={bet}
                          initialValue={bets[bet.id]}
                          onChange={onBetChange}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          ) : null}

          {props.authUser && (
            <div className="d-flex">
              <CustomInput
                type="switch"
                id="p-auto-navigate"
                checked={autoNavigateToNextPrediction.value}
                disabled={autoNavigateToNextPrediction.loading}
                onChange={(e) =>
                  updateSetting('auto_navigate_to_next_prediction', e.target.checked)
                }
              />
              <FormattedMessage id="Auto navigate to next prediction" />
            </div>
          )}
        </div>

        {predictable && (
          <div className="footer">
            <Button color="primary" size="lg" disabled={saving} onClick={onPredict} block>
              <FormattedMessage id="Predict Now" />
              {saving && <Spinner />}
              {predictionsSaved && <MdCheck size={24} className="icon-saved" />}
              {predictionsFailed && <MdClose size={24} className="icon-failed" />}
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { tutorial, userSettings } = state.generic;
  const { loadedMatches, savingPredictionsForMatchIds } = state.matches;
  const { joinedCompetitionIds } = state.competitions;
  const { user: authUser } = state.auth;
  return {
    loadedMatches,
    savingPredictionsForMatchIds,
    tutorial,
    autoNavigateToNextPrediction: userSettings.auto_navigate_to_next_prediction,
    joinedCompetitionIds,
    authUser,
  };
};

export default connect(mapStateToProps, { saveMultiplePredictions, updateSetting })(Prediction);
