import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import NotificationTime from './notification-time';

const PrizeRedemptionRequestRejected = ({ notification }) => {
  const { payload } = notification;

  return (
    <div className={`notification ${!notification.read_at ? 'new' : ''}`}>
      <Row>
        <Col md={9}>
          <div className="content">
            <h3 className="notification-header">
              <FormattedMessage id="notifications.prize-redemption-request-rejected.title" />
            </h3>
            <p className="notification-body">
              <FormattedMessage
                id="notifications.prize-redemption-request-rejected.body"
                values={{
                  amount: <strong>{payload.prize.amount}</strong>,
                  reason: (
                    <strong>
                      {payload.reason && (
                        <FormattedMessage
                          id={`prize_redemption.rejection.reasons.${payload.reason}`}
                        />
                      )}
                    </strong>
                  ),
                }}
              />
            </p>
          </div>
        </Col>
        <Col md={3}>
          <NotificationTime notification={notification} />
        </Col>
      </Row>
    </div>
  );
};

export default memo(PrizeRedemptionRequestRejected);
