import React from 'react';
import { FormattedMessage } from 'react-intl';

const GameScorePointsToWin = ({ points }) => {
  return (
    <span className="tag tag-secondary">
      <FormattedMessage id="To win" /> {points?.exact} <FormattedMessage id="Pts" />
    </span>
  );
};

export default GameScorePointsToWin;
