import * as actionTypes from '../../constants/action-types/ranking';

const INIT_STATE = {
  ranking: {
    friends: {
      items: [],
      page: null,
      lastPage: null,
      loading: false,
      myRanking: { item: null, loading: false },
    },
  },
  myRanking: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_RANKING:
      return { ...INIT_STATE };

    case actionTypes.FETCH_MONTHLY_FRIEND_RANKING_START:
      return {
        ...state,
        ranking: { ...state.ranking, friends: { ...state.ranking.friends, loading: true } },
      };
    case actionTypes.FETCH_MONTHLY_FRIEND_RANKING_SUCCESS:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          friends: {
            ...state.ranking.friends,
            items: [...state.ranking.friends.items, ...action.payload.ranking],
            page: action.payload.meta.current_page,
            lastPage: action.payload.meta.last_page,
            loading: false,
          },
        },
      };
    case actionTypes.FETCH_MONTHLY_FRIEND_RANKING_FAILURE:
      return {
        ...state,
        ranking: { ...state.ranking, friends: { ...state.ranking.friends, loading: false } },
      };

    case actionTypes.FETCH_MY_RANKING_START:
      return {
        ...state,
        myRanking: {
          ...state.myRanking,
          [action.payload.type]: { item: null, loading: true },
        },
      };
    case actionTypes.FETCH_MY_RANKING_SUCCESS:
      return {
        ...state,
        myRanking: {
          ...state.myRanking,
          [action.payload.type]: {
            item: action.payload.rankingItem,
            loading: false,
            cacheUntil: action.payload.cacheUntil,
          },
        },
      };
    case actionTypes.FETCH_MY_RANKING_FAILURE:
      return {
        ...state,
        myRanking: {
          [action.payload.type]: { item: null, loading: false },
        },
      };

    default:
      return { ...state };
  }
};
