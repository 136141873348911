// @vendors
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { MdPublic, MdLock } from 'react-icons/md';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Button, Alert, Spinner } from 'reactstrap';
import styled from 'styled-components';
// @components
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import OverviewJoinForm from './OverviewJoinForm';
import GroupMembers from './GroupMembers';
// @static
import config from '../../../../config';
// @redux
import { fetchGroupDetails, joinGroup, removeGroupMember } from '../../../../redux/groups/thunks';

const Line = styled.hr`
  width: 100%;
  border-bottom: 1px solid #707070;
  opacity: 50%;
  margin: 1rem 0;
`;

const GroupOverview = ({ groupDetails, userRole, userId, groupId }) => {
  const dispatch = useDispatch();
  const [joinGroupFormError, setJoinGroupFormError] = useState(false);
  const [leaveGroupFormError, setLeaveGroupFormError] = useState(false);
  const [isSubmittingJoinGroup, setSubmittingJoinGroup] = useState(false);
  const [isSubmittingLeaveGroup, setSubmittingLeaveGroup] = useState(false);
  const [isCopied, setCopied] = useState(false);

  const handleFetchGroupDetails = () => {
    if (groupId) dispatch(fetchGroupDetails(groupId));
  };

  const handleJoinGroup = (entryCode) => {
    setSubmittingJoinGroup(true);
    setJoinGroupFormError(false);
    dispatch(joinGroup(groupDetails?.id, entryCode))
      .then(() => {
        // Get Group details
        handleFetchGroupDetails();
      })
      .catch(() => {
        setJoinGroupFormError(true);
      })
      .finally(() => {
        setSubmittingJoinGroup(false);
      });
  };

  const handleLeaveGroup = () => {
    setSubmittingLeaveGroup(true);
    setLeaveGroupFormError(false);
    dispatch(removeGroupMember(groupDetails?.id, userId))
      .then(() => {
        // Get Group details
        handleFetchGroupDetails();
      })
      .catch(() => {
        setLeaveGroupFormError(true);
      })
      .finally(() => {
        setSubmittingLeaveGroup(false);
      });
  };
  return (
    <>
      <Box width="100%" display="flex" flexDirection="column" maxWidth="23.75rem">
        {/* Group Info */}
        <Text fontSize="1.125rem" fontWeight="bold" color="#201f2f" mb="0.5rem" lineHeight="1.1">
          {groupDetails.name}
        </Text>
        <Text fontSize="0.75rem" color="#201f2f" lineHeight="1.3">
          {groupDetails.description}
        </Text>
        <Text fontSize="0.875rem" color="#000" display="flex" alignItems="center" my="1rem">
          <Box mie="0.5rem">{groupDetails?.type === 'private' ? <MdLock /> : <MdPublic />}</Box>
          {groupDetails?.type === 'private' ? (
            <FormattedMessage id="Private Group" />
          ) : (
            <FormattedMessage id="Public Group" />
          )}
        </Text>
        {/* Join public group / leave group button */}
        {userRole === 0 && groupDetails?.type === 'public' && (
          <Button
            disabled={isSubmittingJoinGroup}
            color="primary"
            className="btn"
            onClick={() => handleJoinGroup()}
          >
            <FormattedMessage id="Join Group" />
            {isSubmittingJoinGroup && <Spinner />}
          </Button>
        )}
        {userRole !== 0 && (
          <Button
            disabled={isSubmittingLeaveGroup}
            color="danger"
            className="btn"
            onClick={() => handleLeaveGroup()}
          >
            <FormattedMessage id="Leave Group" />
            {isSubmittingLeaveGroup && <Spinner />}
          </Button>
        )}
        {/* Join group form */}
        {userRole === 0 && groupDetails?.type === 'private' && (
          <OverviewJoinForm
            isSubmitting={isSubmittingJoinGroup}
            onSubmit={handleJoinGroup}
            isError={joinGroupFormError}
          />
        )}
        {(joinGroupFormError || leaveGroupFormError) && (
          <Box mt="2rem">
            <Alert color="danger">
              <FormattedMessage
                id={`An error occurred while ${
                  joinGroupFormError ? 'joining' : 'leaving'
                } group. Please try again later.`}
              />
            </Alert>
          </Box>
        )}
        {/* Invite Users */}
        {!(groupDetails?.type === 'private' && userRole <= 1) && (
          <>
            <Box display="flex" mt="1rem">
              {groupDetails?.entry_code && (
                <Text mie="1.75rem" fontSize="0.875rem" color="#201f2f">
                  <FormattedMessage id="Code" />: <b>{groupDetails?.entry_code}</b>
                </Text>
              )}
              <Text fontSize="0.875rem" color="#201f2f">
                <FormattedMessage id="ID" />: <b>{groupDetails?.code}</b>
              </Text>
            </Box>
            <Line />
            <Text fontSize="1.25rem" fontWeight="500" color="#000">
              <FormattedMessage id="Share group with your friends" />
            </Text>
            <Box display="flex" mt="1rem">
              <Text
                bg="#5ad37c"
                color="#fff"
                hoverColor="#fff"
                borderRadius="0.375rem"
                width="2rem"
                height="2rem"
                cursor="pointer"
                fontSize="1.125rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
                as="a"
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${config.baseUrl}/groups/${groupDetails?.id}`}
                mie="1rem"
              >
                <FaFacebookF />
              </Text>
              <Text
                bg="#5ad37c"
                color="#fff"
                hoverColor="#fff"
                borderRadius="0.375rem"
                width="2rem"
                height="2rem"
                cursor="pointer"
                fontSize="1.125rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
                as="a"
                target="_blank"
                href={`https://twitter.com/intent/tweet?url=${config.baseUrl}/groups/${groupDetails?.id}`}
                mie="1rem"
              >
                <FaTwitter />
              </Text>
              <Text
                bg="#5ad37c"
                color="#fff"
                hoverColor="#fff"
                borderRadius="0.375rem"
                width="2rem"
                height="2rem"
                cursor="pointer"
                fontSize="1.125rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
                as="a"
                target="_blank"
                href={`whatsapp://send?text=${config.baseUrl}/groups/${groupDetails?.id}`}
              >
                <FaWhatsapp />
              </Text>
            </Box>
            <Text my="0.5rem" fontSize="0.75rem" color="#34495e">
              <Text letterSpacing="-2px" mie="0.5rem">
                ———————————
              </Text>
              <FormattedMessage id="Or" /> <FormattedMessage id="Using Link" />
              <Text letterSpacing="-2px" mis="0.5rem">
                ———————————
              </Text>
            </Text>
            <Box
              display="flex"
              bg="#fff"
              borderRadius="0.375rem"
              alignItems="center"
              justifyContent="center"
              p="0.5rem"
              pis="1rem"
              width="100%"
              maxWidth="17.5rem"
            >
              <input
                id="success_modal_group_link"
                type="text"
                value={`${config.baseUrl}/groups/${groupDetails?.id}`}
                style={{
                  fontSize: '0.75rem',
                  color: '#42414c',
                  textAlign: 'center',
                  marginInlineEnd: '0.5rem',
                  outline: 'none',
                  border: 'none',
                  padding: 0,
                  width: '100%',
                }}
              />
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  document.querySelector('#success_modal_group_link').select();
                  document.execCommand('copy');
                  setCopied(true);
                }}
              >
                <FormattedMessage id={isCopied ? 'Copied' : 'Copy'} />
              </button>
            </Box>
          </>
        )}
        {/* Group Members */}
        {!(groupDetails?.type === 'private' && userRole === 0) && (
          <>
            <Line />
            <Text fontSize="1rem" fontWeight="500" color="#000" my="1rem">
              <FormattedMessage id="Group Managers" />
            </Text>
            <GroupMembers
              groupId={groupId}
              groupType={groupDetails?.type}
              userRole={userRole}
              isManagers
            />
            <Text fontSize="1rem" fontWeight="500" color="#000" my="1rem">
              <FormattedMessage id="Group Members" />
            </Text>
            <GroupMembers groupId={groupId} groupType={groupDetails?.type} userRole={userRole} />
          </>
        )}
      </Box>
    </>
  );
};

export default GroupOverview;
