import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { joinCompetition } from '../../../redux/competitions/thunks';
import { useHistory } from 'react-router';
import { COMPETITION_TYPE_FANTASY } from '../../../constants/generic';
import useTrackers from '../../../hooks/useTrackers';

const JoinCompetitionConfirmationModal = (props) => {
  const { competition, onToggle, isOpen } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const trackers = useTrackers();
  const coins = useSelector((s) => s.profile.coins);
  const history = useHistory();

  const join = () => {
    if (coins < competition.entry_fee) {
      return history.push('/pricing');
    }

    dispatch(joinCompetition(competition)).then(() => {
      setLoading(false);
      onToggle(true);
      trackers.track('Join_competition', { competitionType: competition.type });

      let newLocation;
      let state;
      if (competition.type === COMPETITION_TYPE_FANTASY) {
        newLocation = `/competitions/${competition.id}/team-setup`;
        state = {
          scrollToField: true,
        };
      } else {
        newLocation = `/competitions/${competition.id}/matches`;
        state = {
          loadFirstPrediction: true,
        };
      }

      if (history.location.pathname !== newLocation) {
        history.push({
          pathname: newLocation,
          state,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Modal
        id="modal-join-competition-confirmation"
        className="modal-confirmation"
        isOpen={isOpen}
        centered={true}
      >
        <ModalBody>
          <p className="text-muted mb-0">
            <FormattedMessage id="join-competition-confirmation" />{' '}
          </p>
          <h3 className="my-1">
            <b>{competition.name}</b>
          </h3>
          <p className="text-muted mb-0">
            <FormattedMessage id="This will cost you" />: <b>{competition.entry_fee}</b>{' '}
            <FormattedMessage id="coins" />
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary btn-block btn-confirm"
            disabled={loading}
            onClick={join}
          >
            <FormattedMessage id="Yes, let me in" />
          </button>

          <button className="btn btn-link text-muted btn-go-back" onClick={() => onToggle(false)}>
            <FormattedMessage id="No, I've changed my mind" />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default JoinCompetitionConfirmationModal;
