import {
  StyledBox,
  StyledPlayerGroupTitle,
} from '../competitions/CompetitionTeamSetupFilters/styles';
import Text from '../Text';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { calculateColumns } from './PlayerList';

const PlayerGroupTitle = ({ children, showTeam }) => {
  const columns = calculateColumns(showTeam);

  return (
    <StyledPlayerGroupTitle>
      <StyledBox flex={`calc(${columns.player.width} - ${columns.player.offset})`}>
        <Text>
          <FormattedMessage id={`position.${children}`} />
        </Text>
      </StyledBox>
      {showTeam && (
        <StyledBox flex={`calc(${columns.team.width} - ${columns.team.offset})`} textAlign="center">
          <Text>
            <FormattedMessage id="Team" />
          </Text>
        </StyledBox>
      )}
      <StyledBox flex={`calc(${columns.price.width} - ${columns.price.offset})`} textAlign="center">
        <Text>
          <FormattedMessage id="Price" />
        </Text>
      </StyledBox>
      <StyledBox
        flex={`calc(${columns.points.width} - ${columns.points.offset})`}
        textAlign="center"
      >
        <Text>
          <FormattedMessage id="Pts" />
        </Text>
      </StyledBox>
    </StyledPlayerGroupTitle>
  );
};

export default PlayerGroupTitle;
