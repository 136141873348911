import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BaseTour from 'reactour';
import { markTutorialStepAsDone } from '../redux/generic/actions';

const Tour = (props) => {
  const [isTourOpen, setIsTourOpen] = useState(false);

  const getSteps = () => {
    let steps = [];

    if (props.step) steps.push(props.step);
    if (props.steps) steps = [...steps, ...props.steps];

    return steps;
  };

  const tutorialSteps = [
    {
      selector: '.tutorial-' + getSteps()[0],
      content: props.content,
    },
  ];

  useEffect(() => {
    setTimeout(() => setIsTourOpen(true), 200);
  }, []);

  const onTutorialClose = () => {
    getSteps().forEach((step) => props.markTutorialStepAsDone(step));
    setIsTourOpen(false);
  };

  return (
    <React.Fragment>
      <BaseTour
        steps={tutorialSteps}
        isOpen={isTourOpen}
        onRequestClose={onTutorialClose}
        badgeContent={() => props.stepNumber}
        showButtons={false}
        showNavigation={false}
        className="tour"
      />
    </React.Fragment>
  );
};

export default connect(null, { markTutorialStepAsDone })(Tour);
