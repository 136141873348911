import React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

const TabLink = ({ to, exact, ...rest }) => {
  let match = useRouteMatch({
    path: to,
    exact: exact,
  });

  return (
    <Link to={to} className={`nav-link ${match ? 'active' : ''}`}>
      {rest.children}
    </Link>
  );
};

export default TabLink;
