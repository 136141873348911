import * as actionTypes from '../../constants/action-types/auth';
import * as profileActionTypes from '../../constants/action-types/profile';

const INIT_STATE = {
  user: null,
  accessToken: null,
  registration: {
    successMessage: null,
    errorMessage: null,
    errors: null,
  },
  login: {
    error: null,
  },
  forgetPassword: {
    message: null,
    errors: null,
  },
  resetPassword: {
    message: null,
    errors: null,
  },
  referrer: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return { ...state, login: { ...state.login, error: null } };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, accessToken: action.payload.access_token };
    case actionTypes.LOGIN_FAILURE:
      return { ...state, login: { ...state.login, error: action.payload } };

    case actionTypes.REGISTER_START:
      return { ...state, registration: { successMessage: null, errors: null, errorMessage: null } };
    case actionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        registration: { ...state.registration, successMessage: action.payload.message },
      };
    case actionTypes.REGISTER_FAILURE:
      const { message, errors } = action.payload;
      let parsedErrors = [];
      for (let k in errors) {
        if (errors.hasOwnProperty(k)) {
          parsedErrors = [...parsedErrors, ...errors[k]];
        }
      }

      return {
        ...state,
        registration: { ...state.registration, errors: parsedErrors, errorMessage: message },
      };

    case actionTypes.FETCH_ME_SUCCESS:
      const user = { ...action.payload };
      delete user.settings;
      return { ...state, user };

    case actionTypes.LOGIN_WITH_FACEBOOK_START:
      return { ...state, loginError: null };
    case actionTypes.LOGIN_WITH_FACEBOOK_SUCCESS:
      return { ...state, accessToken: action.payload.access_token };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, accessToken: null, user: null };

    case actionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload.token };

    case actionTypes.FORGET_PASSWORD_START:
      return { ...state, forgetPassword: { errors: null, message: null } };

    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return { ...state, forgetPassword: { errors: null, ...action.payload } };

    case actionTypes.FORGET_PASSWORD_FAILURE:
      return { ...state, forgetPassword: { ...action.payload } };

    case actionTypes.RESET_PASSWORD_START:
      return { ...state, resetPassword: { errors: null, message: null } };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: { errors: null, message: action.payload.message } };

    case actionTypes.RESET_PASSWORD_FAILURE:
      return { ...state, resetPassword: { ...action.payload } };

    case actionTypes.SET_REFERRER:
      return { ...state, referrer: action.payload.referrer };

    case actionTypes.CLEAR_REFERRER:
      return { ...state, referrer: null };

    case profileActionTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload.data } };

    default:
      return { ...state };
  }
};
