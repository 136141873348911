import api from '../../helpers/api';
import { receiveProducts } from './actions';

export function fetchProducts() {
  return async function (dispatch, getState) {
    const products = getState().store.products;

    if (!products) {
      const response = await api.fetchProducts();
      dispatch(receiveProducts(response.data.data));
    }
  };
}
