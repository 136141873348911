import React, { useEffect, useState } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import BetQuestion from './BetQuestion';
import { getInitialBetValue } from '../../helpers/generic';
import PointsWon from './PointsWon';

const YesNoBet = (props) => {
  const { bet, initialValue } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(getInitialBetValue(bet, initialValue));
  }, []);

  const onChange = (value) => {
    setValue(value);
    props.onChange({ bet_id: bet.id, value });
  };

  const disabled = !bet.active;
  const points = bet.current_user_prediction?.points?.amount;

  return (
    <div className="bet bet-yes-no">
      <BetQuestion bet={bet} />

      <div className="options">
        {bet.question.answers.map((answer, i) => {
          return (
            <FormGroup key={i} check inline>
              <Label check>
                <Input
                  type="radio"
                  value={answer.value}
                  onChange={() => onChange(answer.value)}
                  checked={value === answer.value}
                  disabled={disabled}
                />{' '}
                {answer.label}
              </Label>
            </FormGroup>
          );
        })}
      </div>

      {points !== undefined && <PointsWon points={points} />}
    </div>
  );
};

export default YesNoBet;
