import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from '../../components/Loader';
import { EqualHeight } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../redux/store/thunks';
import {
  ProductCard as StyledProductCard,
  ProductImage,
  ProductName,
  ProductOriginalPrice,
  ProductPrice,
  ProductPriceCurrency,
  ProductPriceSection,
} from './styles';
import { showPurchaseModal } from '../../redux/store/actions';
import { PurchaseModal } from './components';
import { useHistory } from 'react-router';
import NumberFormat from 'react-number-format';

const StorePage = (props) => {
  const dispatch = useDispatch();
  const { products, purchaseModal } = useSelector((s) => s.store);
  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Store' }));

    dispatch(fetchProducts());
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-store">
          <h2 className="section-header">
            <FormattedMessage id="Store" />
          </h2>

          {!products ? (
            <Loader />
          ) : (
            <React.Fragment>
              <EqualHeight>
                <div className="row">
                  {products.map((product) => (
                    <div className="col-md-3" key={product.id} style={{ marginBottom: 16 }}>
                      <ProductCard product={product} />
                    </div>
                  ))}
                </div>
              </EqualHeight>
            </React.Fragment>
          )}
        </div>
      </Container>

      {purchaseModal.show && <PurchaseModal />}
    </React.Fragment>
  );
};

const ProductCard = ({ product }) => {
  const user = useSelector((s) => s.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const onBuy = () => {
    if (user && user.coins < product.current_price) {
      history.push('/pricing');
    } else {
      dispatch(showPurchaseModal(product));
    }
  };

  return (
    <StyledProductCard>
      <ProductName>{product.name}</ProductName>
      <ProductImage src={product.image.original} />
      <ProductPriceSection>
        {!!product.discounted_price && (
          <ProductOriginalPrice>
            <NumberFormat displayType="text" value={product.price} thousandSeparator={true} />
          </ProductOriginalPrice>
        )}
        <ProductPrice>
          <NumberFormat displayType="text" value={product.current_price} thousandSeparator={true} />
        </ProductPrice>
        <ProductPriceCurrency>
          <FormattedMessage id="Coins" />
        </ProductPriceCurrency>
      </ProductPriceSection>
      <div>{product.specs}</div>
      <div style={{ flex: 1 }} />
      <button
        className="btn btn-primary btn-block btn-lg"
        style={{ marginTop: 'auto' }}
        onClick={() => onBuy()}
      >
        <FormattedMessage id="Buy" />
      </button>
    </StyledProductCard>
  );
};

export default StorePage;
