// @vendors
import React, { useState, useEffect } from 'react';
import { Input, InputGroupText, InputGroup, InputGroupAddon } from 'reactstrap';
import { useIntl } from 'react-intl';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
// @components
import Box from '../../../../components/Box';
// import Text from '../../../../components/Text';
import ListMembers from '../../../../components/groups/ListMembers';
// @Redux
import {
  saveGroupMembers,
  saveNonGroupMembers,
  saveGroupManagers,
} from '../../../../redux/groups/actions';
import {
  addGroupMember,
  removeGroupMember,
  fetchGroupMembers,
  fetchNonGroupMembers,
  fetchGroupManagers,
  changeGroupMemberRole,
} from '../../../../redux/groups/thunks';
import {
  selectGroupMembers,
  selectNonGroupMembers,
  selectGroupManagers,
} from '../../../../redux/groups/selectors';

const GroupMembers = ({ groupId, groupType, userRole, isManagers, ...rest }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const groupMembers = useSelector(selectGroupMembers());
  const groupManagers = useSelector(selectGroupManagers());
  const nonGroupMembers = useSelector(selectNonGroupMembers());
  const userId = useSelector((state) => state?.auth?.user?.id);
  const [filters, setFilters] = useState({});
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);

  const showUnknownUsers = !!filters.q && canAdd;
  const membersList = showUnknownUsers
    ? [...groupMembers?.items, ...nonGroupMembers?.items]
    : groupMembers?.items;

  const modifyOptionsList = isManagers
    ? [
        {
          key: 'delete',
          label: intl.formatMessage({ id: 'Delete' }),
          isDisabled: (id) => id === userId,
        },
        {
          key: 'downgrade',
          label: intl.formatMessage({ id: 'Downgrade to member' }),
          isDisabled: (id) => id === userId,
        },
      ]
    : [
        {
          key: 'delete',
          label: intl.formatMessage({ id: 'Delete' }),
          isDisabled: (id) => id === userId,
        },
        {
          key: 'upgrade',
          label: intl.formatMessage({ id: 'Upgrade to manager' }),
        },
      ];

  const handleFetchGroupMembers = () => {
    if (groupId) {
      if (isManagers) {
        if (
          groupManagers?.meta?.current_page < groupManagers?.meta?.last_page ||
          groupManagers?.filters?.q !== filters?.q
        ) {
          dispatch(fetchGroupManagers(groupId, filters));
        }
      } else {
        if (
          groupMembers?.meta?.current_page < groupMembers?.meta?.last_page ||
          groupMembers?.filters?.q !== filters?.q
        ) {
          dispatch(saveNonGroupMembers());
          dispatch(fetchGroupMembers(groupId, filters));
        } else if (
          nonGroupMembers?.meta?.current_page < nonGroupMembers?.meta?.last_page &&
          showUnknownUsers
        ) {
          dispatch(fetchNonGroupMembers(groupId, filters));
        }
      }
    }
  };

  const handleSearchGroupMembersInput = (e) => {
    setFilters({ ...filters, q: e?.target?.value || null });
  };

  useEffect(() => {
    return () => {
      // Clear state on component remove
      dispatch(saveGroupMembers());
      dispatch(saveNonGroupMembers());
      dispatch(saveGroupManagers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (groupType) {
      const isPrivate = groupType === 'private';
      const isUser = userRole === 1;
      const isAdmin = userRole === 2;
      if ((isPrivate && isAdmin) || (!isPrivate && (isUser || isAdmin))) setCanAdd(true);
      else setCanAdd(false);
      if (isAdmin) setCanModify(true);
    }
  }, [groupType, userRole]);

  useEffect(() => {
    // Get Group members
    handleFetchGroupMembers();
  }, [filters, groupId]);

  useEffect(() => {
    if (!groupMembers?.meta?.current_page < groupMembers?.meta?.last_page) {
      // Get Non Group members if the list of group members is too small
      handleFetchGroupMembers();
    }
  }, [groupMembers]);

  const handleMemberAdd = (member) => {
    if (groupId && member?.id) {
      setFilters({ ...filters, q: null });
      dispatch(addGroupMember(groupId, member?.id, 1));
    }
  };

  const handleMemberOptionClick = (type, member) => {
    if (member?.id)
      switch (type) {
        case 'delete':
          dispatch(removeGroupMember(groupId, member?.id));
          break;
        case 'upgrade':
          dispatch(changeGroupMemberRole(groupId, member?.id, 2));
          break;
        case 'downgrade':
          dispatch(changeGroupMemberRole(groupId, member?.id, 1));
          break;
        default:
      }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" {...rest}>
      {!isManagers && (
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FiSearch />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={intl.formatMessage({
              id: 'Search group members or add new ones...',
            })}
            onChange={handleSearchGroupMembersInput}
            value={filters?.q || ''}
          />
        </InputGroup>
      )}
      <ListMembers
        id={`group-${isManagers ? 'managers' : 'members'}-list`}
        items={(isManagers ? groupManagers?.items : membersList) || []}
        loadMore={handleFetchGroupMembers}
        hasMore
        useWindow={false}
        height={`Calc(3.625rem * ${(isManagers ? groupManagers?.items : new Array(5)).length + 1})`}
        maxHeight="18.1rem"
        overflowY="auto"
        overflowX="hidden"
        mt="0.5rem"
        onClick={handleMemberAdd}
        options={canModify ? modifyOptionsList : []}
        onOptionClick={handleMemberOptionClick}
      />
    </Box>
  );
};

export default GroupMembers;
