import React from 'react';

const BarSeparator = ({ children }) => {
  return (
    <React.Fragment>
      <div className="bar-separator">
        <span className="bar-separator-message">{children}</span>
      </div>
    </React.Fragment>
  );
};

export default BarSeparator;
