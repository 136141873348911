// @vendors
import React from 'react';
import { Form, Input, Button, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';

const OverviewJoinForm = ({ onSubmit, isSubmitting, ...rest }) => {
  const validationSchema = Yup.object().shape({
    entry_code: Yup.string().required(),
  });
  const initialValues = {
    entry_code: '',
  };

  const handleOnSubmit = (values) => {
    onSubmit(values?.entry_code || '');
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" {...rest}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box mb="1.5rem">
              <Text
                mb="0.5rem"
                fontSize="0.875rem"
                fontWeight="bold"
                color="#201f2f"
                for="entry_code"
              >
                <FormattedMessage id="Code" />
              </Text>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.entry_code}
                invalid={errors.entry_code && touched.entry_code}
                type="text"
                name="entry_code"
                id="entry_code"
              />
            </Box>
            <Button type="submit" disabled={isSubmitting} color="primary" className="btn">
              <FormattedMessage id="Join Group" />
              {isSubmitting && <Spinner />}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default OverviewJoinForm;
