import axios from 'axios';
import { clearToken, getToken, setToken, getRefreshToken, setRefreshToken } from './auth';
import { formatNationalIdScans, getFileInfo, locale, toBase64 } from './generic';
import config from '../config';

class Api {
  constructor() {
    this.disableUnauthInterceptor = false;

    this.axios = axios.create({
      baseURL: config.apiUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    this.axios.interceptors.request.use(async (config) => {
      let token = getToken();
      if (token) config.headers.Authorization = 'Bearer ' + token;

      config.headers['X-Locale'] = locale();

      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response?.status === 401 && error.response.data.message === 'Unauthenticated.') {
          const refreshToken = getRefreshToken();

          try {
            const res = await this.axios.post('/v2/oauth/token', {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              client_id: process.env.REACT_APP_CLIENT_ID,
              scope: process.env.REACT_APP_SCOPE,
            });
            const { access_token, refresh_token } = res.data;

            setToken(access_token);
            setRefreshToken(refresh_token);

            return this.axios.request(error.config);
          } catch (_) {
            clearToken();
            window.location = '/login';
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  login(identifier, password) {
    return this.withoutUnauthInterceptor(async () => {
      return this.axios.post('/v2/oauth/token', {
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: process.env.REACT_APP_SCOPE,
        username: identifier,
        password,
      });
    });
  }

  async withoutUnauthInterceptor(callback) {
    this.disableUnauthInterceptor = true;

    const promise = await callback();

    this.disableUnauthInterceptor = false;

    return promise;
  }

  loginUsingFacebook(accessToken, referrer) {
    return this.axios.post('/v2/oauth/token', {
      grant_type: 'facebook',
      client_id: process.env.REACT_APP_CLIENT_ID,
      scope: process.env.REACT_APP_SCOPE,
      access_token: accessToken,
      referrer,
    });
  }

  registerUsingEmail(data) {
    return this.axios.post('/v2/register/email', data);
  }

  forgetPassword(values) {
    return this.axios.post('/v2/forget-password', values);
  }

  resetPassword(otp, identifier, newPassword) {
    return this.axios.post('/v2/reset-password', { otp, identifier, new_password: newPassword });
  }

  sendVerificationEmail() {
    return this.axios.post('/v2/send-verification-email');
  }

  fetchMe() {
    return this.axios.get('/v2/profile/me');
  }

  fetchMyPredictions() {
    return this.axios.get('/v2/me/predictions');
  }

  fetchMyFriends() {
    return this.axios.get('/v2/me/friends');
  }

  fetchNotifications(filters = {}) {
    return this.axios.get('/v2/me/notifications', { params: filters });
  }

  markNotificationsAsRead() {
    return this.axios.post('/v2/me/notifications/mark-as-read');
  }

  fetchMyPrizes() {
    return this.axios.get('/v2/me/prizes');
  }

  fetchMyNewPrizeCount() {
    return this.axios.get('/v2/me/prizes/new-prize-count');
  }

  markMyPrizesAsRead() {
    return this.axios.post('/v2/me/prizes/mark-as-read');
  }

  fetchMyOrders() {
    return this.axios.get('/v2/me/orders');
  }

  fetchMatches(params) {
    return this.axios.get('/v2/games', { params });
  }

  fetchMatch(id) {
    return this.axios.get(`/v2/games/${id}`);
  }

  fetchCompetitions(params) {
    return this.axios.get(`/v2/competitions`, { params });
  }

  fetchCompetition(competitionId) {
    return this.axios.get(`/v2/competitions/${competitionId}`);
  }

  joinCompetition(competitionId) {
    return this.axios.post(`/v2/competitions/${competitionId}/join`);
  }

  leaveCompetition(competitionId) {
    return this.axios.post(`/v2/competitions/${competitionId}/leave`);
  }

  createCompetition(input) {
    return this.axios.post(`/v2/competitions`, input);
  }

  saveMultiplePredictions(gameId, bets) {
    return this.axios.post(`/v2/predictions/multiple`, { game_id: gameId, bets });
  }

  fetchRanking(params) {
    return this.axios.get(`/rankings`, { params });
  }

  fetchMyRanking(params) {
    return this.axios.get(`/rankings/mine`, { params });
  }

  updateSetting(setting) {
    return this.axios.post(`/me/settings`, setting);
  }

  fetchSettings() {
    return this.axios.get(`/settings`);
  }

  getUpcomingDateWithMatches() {
    return this.axios.get(`/misc/upcoming-date-with-matches`);
  }

  chargePrepaidCard(code) {
    return this.axios.post('/prepaid-cards/charge', { code });
  }

  redeemVoucher(code) {
    return this.axios.post('/v2/vouchers/redeem', { code });
  }

  fetchPrizes() {
    return this.axios.get('/v2/prizes');
  }

  fetchGames(params) {
    return this.axios.get('/v2/games', { params });
  }

  fetchOfficialMatches(params) {
    return this.axios.get('/v2/matches', { params });
  }

  fetchCreators() {
    return this.axios.get('/v2/users', { params: { creator: true } });
  }

  fetchJoinedCompetitionIds() {
    return this.axios.get('/me/joined-competition-ids');
  }

  generateFawryReferenceCode(packageId, phoneNumber) {
    return this.axios.post(`/v2/payments/fawry/generate-reference-code`, {
      package_id: packageId,
      phone_number: phoneNumber,
    });
  }

  fetchTransactions() {
    return this.axios.get('/v2/transactions');
  }

  fetchPackages() {
    return this.axios.get('/v2/packages');
  }

  async updateProfile(values) {
    await Promise.all(
      ['front', 'back'].map(async (side) => {
        let file = values.national_id_scans?.[side];

        return file ? { side, file, base64: await toBase64(file) } : null;
      })
    ).then((parsed) => {
      parsed.forEach((item) => {
        if (item) {
          values.national_id_scans[item.side] = { data: item.base64, ...getFileInfo(item.file) };
        }
      });
    });

    if (values.profile_picture) {
      const profilePictureBase64 = await toBase64(values.profile_picture);
      values.profile_picture = {
        data: profilePictureBase64,
        ...getFileInfo(values.profile_picture),
      };
    }

    return this.axios.post('/v2/profile', values);
  }

  verifyPhoneNumber(phoneNumber) {
    return this.axios.post(`/v2/profile/phone-number/send-otp`, { phone_number: phoneNumber });
  }

  updatePhoneNumber(data) {
    return this.axios.post(`/v2/profile/phone-number/change`, data);
  }

  async generatePrizeRedemptionForms(values) {
    return this.axios.post('/v2/prize-redemption/generate-redemption-forms', values);
  }

  async submitPrizeRedemptionRequest(values) {
    values.national_id_scans = await formatNationalIdScans(values.national_id_scans);

    delete values.forms;

    return this.axios.post('/v2/prize-redemption/submit-redemption-request', values);
  }

  fetchPlayers(filters) {
    return this.axios.get('/v2/players', { params: filters });
  }

  fetchTeams(filters) {
    return this.axios.get('/v2/teams', { params: filters });
  }

  getPlayers(filters = {}) {
    return this.axios.get(`/v2/players`, { params: filters });
  }

  getCompetitionTeam(competitionId, userId) {
    return this.axios.get(`/v2/competitions/${competitionId}/team-setup`, {
      params: { user: userId },
    });
  }

  saveCompetitionTeam(competitionId, team) {
    return this.axios.post(`/v2/competitions/${competitionId}/team-setup`, team);
  }

  fetchMyGroupsList(filters = {}) {
    return this.axios.get(`/v2/groups?mine`, { params: { ...filters, per_page: 10 } });
  }

  fetchAllGroupsList(filters = {}) {
    return this.axios.get(`/v2/groups`, { params: { ...filters, per_page: 10 } });
  }

  fetchRecommendedGroupsList(filters = {}) {
    return this.axios.get(`/v2/groups?recommended`, { params: { ...filters, per_page: 6 } });
  }

  joinGroup(groupId = '', entryCode) {
    return this.withoutUnauthInterceptor(() => {
      return this.axios.post(`/v2/groups/${groupId}/join`, { entry_code: entryCode || null });
    });
  }

  addGroupMember(groupId = '', userId, role) {
    // role can be '1' manager or '2' user
    return this.axios.post(`/v2/groups/${groupId}/members`, {
      user_id: userId,
      role,
    });
  }

  removeGroupMember(groupId = '', userId) {
    return this.axios.delete(`/v2/groups/${groupId}/members/${userId}`);
  }

  fetchUsersList(filters = {}) {
    return this.axios.get('/v2/users', { params: { ...filters, per_page: 10 } });
  }

  createGroup(payload = {}) {
    return this.axios.post('/v2/groups', payload);
  }

  addGroupMembers(groupId = '', members = []) {
    return this.axios.post(`/v2/groups/${groupId}/members/bulk`, {
      members,
    });
  }

  fetchGroupDetails(groupId) {
    return this.axios.get(`/v2/groups/${groupId}`);
  }

  fetchGroupMembers(groupId, filters = {}) {
    return this.axios.get(`/v2/groups/${groupId}/members`, {
      params: { ...filters, per_page: 10 },
    });
  }

  fetchNonGroupMembers(groupId, filters = {}) {
    return this.axios.get(`/v2/users`, {
      params: { ...filters, per_page: 10, 'skip-group': groupId },
    });
  }

  fetchGroupMember(groupId, memberId) {
    return this.axios.get(`/v2/groups/${groupId}/members/${memberId}`);
  }

  changeGroupMemberRole(groupId, memberId, role) {
    return this.axios.patch(`/v2/groups/${groupId}/members/${memberId}`, {
      role,
    });
  }

  fetchUserPaymentCards() {
    return this.axios.get('/v2/payments/fawry/user-payment-cards');
  }

  deleteUserPaymentCard(token) {
    return this.axios.delete('/v2/payments/fawry/user-payment-cards', { data: { token } });
  }

  generatePaymentLink(payload) {
    return this.axios.post('/v2/payments/fawry/generate-payment-link', payload);
  }

  getCompetitionForUserCreatedFantasy() {
    return this.axios.get('/v2/competitions?for-user-created-fantasy=1');
  }

  fetchProducts() {
    return this.axios.get('/v2/store/products');
  }

  placeOrder(input) {
    return this.axios.post('/v2/store/place-order', input);
  }
}

export default new Api();
