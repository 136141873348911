import {
  fetchCompetitionsStart,
  fetchCompetitionsSuccess,
  fetchCompetitionsFailure,
  fetchCompetitionStart,
  fetchCompetitionSuccess,
  fetchCompetitionFailure,
  joinCompetitionStart,
  joinCompetitionSuccess,
  joinCompetitionFailure,
  fetchCompetitionMatchesStart,
  fetchCompetitionMatchesSuccess,
  fetchCompetitionMatchesFailure,
  selectCompetitionMatchStart,
  selectCompetitionMatchSuccess,
  selectCompetitionMatchFailure,
  fetchCompetitionRankingStart,
  fetchCompetitionRankingSuccess,
  fetchCompetitionRankingFailure,
  leaveCompetitionSuccess,
  receiveJoinedCompetitionIds,
  removeJoinedCompetitionId,
  increaseCompetitionParticipants,
  decreaseCompetitionParticipants,
  receiveCompetitionRankingBreakdown,
} from './actions';
import api from '../../helpers/api';
import { receiveMatches } from '../matches/actions';
import { decreaseCoins, increaseCoins } from '../generic/actions';

const getListFilters = (type, list) => {
  let filters = {};

  if (type === 'predictions') filters.type = 1;
  if (type === 'fantasy') filters.type = 2;
  if (type === 'e-sports') filters['e-sports'] = true;

  if (type === 'mine') filters['mine'] = true;

  if (list === 'featured') {
    filters = { ...filters, featured: true, state: 'upcoming' };
  }

  if (list === 'upcoming') {
    filters = { ...filters, state: list, featured: false, 'expected-first': true };
  }

  if (['running', 'past'].indexOf(list) !== -1) {
    filters = { ...filters, state: list, sort: { 'start-date': 'desc' }, joined: true };
  }

  return filters;
};

export function fetchCompetitions(type, list, filters = {}) {
  return async function (dispatch, getState) {
    dispatch(fetchCompetitionsStart(type, list));

    filters = { ...filters, ...getListFilters(type, list) };
    filters.page = getState().competitions.competitions[type][list].page + 1;

    api
      .fetchCompetitions(filters)
      .then((response) => response.data)
      .then((json) => dispatch(fetchCompetitionsSuccess(type, list, json.data, json.meta)))
      .catch(() => dispatch(fetchCompetitionsFailure(type, list)));
  };
}

export function fetchCompetition(competitionId) {
  return async function (dispatch) {
    dispatch(fetchCompetitionStart());

    return await api
      .fetchCompetition(competitionId)
      .then((response) => response.data.data)
      .then((json) => dispatch(fetchCompetitionSuccess(json)))
      .catch(() => dispatch(fetchCompetitionFailure()));
  };
}

export function joinCompetition(competition) {
  return async function (dispatch) {
    dispatch(joinCompetitionStart(competition.id));

    await api
      .joinCompetition(competition.id)
      .then(() => {
        dispatch(joinCompetitionSuccess(competition.id));
        dispatch(decreaseCoins(competition.entry_fee));
        dispatch(increaseCompetitionParticipants(competition.id));
        dispatch(receiveJoinedCompetitionIds([competition.id]));
      })
      .catch(() => dispatch(joinCompetitionFailure(competition.id)));
  };
}

export function leaveCompetition(competition) {
  return async function (dispatch) {
    api.leaveCompetition(competition.id).then(() => {
      dispatch(increaseCoins(competition.entry_fee));
      dispatch(leaveCompetitionSuccess(competition.id));
      dispatch(decreaseCompetitionParticipants(competition.id));
      dispatch(removeJoinedCompetitionId(competition.id));
    });
  };
}

export function createCompetition(input = {}) {
  return async function () {
    const body = { ...input };
    body.entry_fee = Number(body.entry_fee);
    body.group_id = Number(body.group_id);
    body.match_ids = body.match_ids?.map((matchId) => Number(matchId));
    body.type = Number(body.type);
    return api.createCompetition(body).then((r) => r.data.data);
  };
}

export function fetchJoinedCompetitionIds() {
  return async function (dispatch) {
    api.fetchJoinedCompetitionIds().then((response) => {
      dispatch(receiveJoinedCompetitionIds(response.data.data));
    });
  };
}

export function fetchCompetitionMatches(seasonId, filters) {
  return async function (dispatch) {
    dispatch(fetchCompetitionMatchesStart());

    api
      .fetchMatches({ season: seasonId, ...filters })
      .then((response) => response.data)
      .then((json) => {
        dispatch(receiveMatches(json.data));
        dispatch(fetchCompetitionMatchesSuccess(json.data));
      })
      .catch(() => dispatch(fetchCompetitionMatchesFailure()));
  };
}

export function selectCompetitionMatch(matchId) {
  return async function (dispatch) {
    dispatch(selectCompetitionMatchStart());

    api
      .fetchMatch(matchId)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveMatches([json]));
        dispatch(selectCompetitionMatchSuccess(matchId));
      })
      .catch(() => dispatch(selectCompetitionMatchFailure(matchId)));
  };
}

export function fetchCompetitionRanking(seasonId) {
  return async function (dispatch, getState) {
    dispatch(fetchCompetitionRankingStart());

    const page = getState().competitions.competition.ranking.page + 1;
    await api
      .fetchRanking({ 'season-id': seasonId, page })
      .then((response) => response.data)
      .then((json) => {
        let data = json.data || [];

        let meta = json.meta || {};
        meta.pending = !json.data;

        dispatch(fetchCompetitionRankingSuccess(data, meta));
      })
      .catch(() => {
        dispatch(fetchCompetitionRankingFailure());
      });
  };
}

export function fetchCompetitionRankingBreakdown(competitionId, rankingItem) {
  return async function (dispatch) {
    const breakdown = await api
      .getCompetitionTeam(competitionId, rankingItem.user.id)
      .then((response) => response.data.data)
      .then((json) => {
        return {
          user: {
            name: rankingItem.user.name,
            photo: rankingItem.user.photo,
            ranking: rankingItem.ranking,
            points: rankingItem.points,
          },
          formation: json.formation,
          players: json.players
            .filter((p) => p.status === 'played')
            .map((player) => {
              return { ...player, fantasy_points: player.scored_fantasy_points };
            }),
        };
      });

    dispatch(receiveCompetitionRankingBreakdown(breakdown));
  };
}
