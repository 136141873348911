import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FaCheck } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import config from '../../../../config';
import JoinCompetitionConfirmationModal from '../JoinCompetitionConfirmationModal';

const ConfirmationModal = (props) => {
  const { competition, isOpen } = props;
  const history = useHistory();
  const coins = useSelector((s) => s.profile.coins);
  const [link] = useState(`${config.baseUrl}/competitions/${competition.id}`);
  const [joinCompetitionCheck, setJoinCompetitionCheck] = useState(true);
  const [
    joinCompetitionConfirmationModalIsOpen,
    setJoinCompetitionConfirmationModalIsOpen,
  ] = useState(false);

  const handleRedirection = () => {
    history.push(
      `/competitions/${competition.id}/${competition?.type === 2 ? 'team-setup' : 'matches'}`
    );
  };

  const handleNext = () => {
    if (coins >= competition?.entry_fee && joinCompetitionCheck) {
      setJoinCompetitionConfirmationModalIsOpen(true);
    } else {
      handleRedirection();
    }
  };

  return (
    <React.Fragment>
      <Modal
        id="modal-competition-created"
        className="modal-confirmation"
        isOpen={isOpen}
        centered={true}
      >
        <ModalBody>
          <FaCheck className="icon-confirmed mb-5" />

          <h3 className="mb-3">
            <b>{competition.name}</b>
          </h3>

          <p className="text-muted mb-5">
            <FormattedMessage id="competition-created" />{' '}
          </p>

          <Label>
            <FormattedMessage id="Share and get more participants" />
          </Label>
          <InputGroup>
            <Input value={link} readonly={true} disabled={true} />
            <InputGroupAddon addonType="append">
              <CopyToClipboard text={link}>
                <Button>
                  <FormattedMessage id="Copy" />
                </Button>
              </CopyToClipboard>
            </InputGroupAddon>
          </InputGroup>
          {coins >= competition?.entry_fee && (
            <div className="mt-3">
              <Label check>
                <Input
                  type="checkbox"
                  checked={joinCompetitionCheck}
                  onChange={() => setJoinCompetitionCheck(!joinCompetitionCheck)}
                />{' '}
                <FormattedMessage id="Automatically join competition" />
              </Label>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary btn-block btn-confirm" onClick={handleNext}>
            <FormattedMessage id="Go to Competition" />
          </button>
        </ModalFooter>
      </Modal>
      <JoinCompetitionConfirmationModal
        competition={competition}
        isOpen={joinCompetitionConfirmationModalIsOpen}
        onToggle={(status) => {
          setJoinCompetitionConfirmationModalIsOpen(false);
          if (!status) handleRedirection();
        }}
      />
    </React.Fragment>
  );
};

export default ConfirmationModal;
