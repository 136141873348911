import * as actionTypes from '../../constants/action-types/generic';

export const setDocumentTitle = (title) => ({
  type: actionTypes.SET_DOCUMENT_TITLE,
  payload: { title },
});

export const setTutorialStatus = (status) => ({
  type: actionTypes.SET_TUTORIAL_STATUS,
  payload: { status },
});

export const markTutorialStepAsDone = (step) => ({
  type: actionTypes.MARK_TUTORIAL_STEP_AS_DONE,
  payload: { step },
});

export const updateSettingStart = (key, value) => ({
  type: actionTypes.UPDATE_SETTING_START,
  payload: { key, value },
});

export const updateSettingSuccess = (key, value) => ({
  type: actionTypes.UPDATE_SETTING_SUCCESS,
  payload: { key, value },
});

export const updateSettingFailure = (key) => ({
  type: actionTypes.UPDATE_SETTING_FAILURE,
  payload: { key },
});

export const fetchSettingsSuccess = (settings) => ({
  type: actionTypes.FETCH_SETTINGS_SUCCESS,
  payload: { settings },
});

export const increaseCoins = (coins) => ({
  type: actionTypes.INCREASE_COINS,
  payload: { coins },
});

export const decreaseCoins = (coins) => ({
  type: actionTypes.DECREASE_COINS,
  payload: { coins },
});

export const receiveCreators = (creators) => ({
  type: actionTypes.RECEIVE_CREATORS,
  payload: { creators },
});

export const receiveRecentNotifications = (notifications) => ({
  type: actionTypes.RECEIVE_RECENT_NOTIFICATIONS,
  payload: { notifications },
});

export const receiveNewNotificationCount = (count) => ({
  type: actionTypes.RECEIVE_NEW_NOTIFICATION_COUNT,
  payload: { count },
});

export const receivePackages = (packages) => ({
  type: actionTypes.RECEIVE_PACKAGES,
  payload: { packages },
});
