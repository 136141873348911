import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import BetQuestion from './BetQuestion';
import { getInitialBetValue } from '../../helpers/generic';
import PointsWon from './PointsWon';

const CounterBet = (props) => {
  const { bet, initialValue } = props;
  const [value, setValue] = useState(bet.current_user_prediction?.value || '');

  useEffect(() => {
    setValue(getInitialBetValue(bet, initialValue));
  }, []);

  const onChange = (value) => {
    setValue(value);
    props.onChange({ bet_id: bet.id, value });
  };

  const disabled = !bet.active;
  const points = bet.current_user_prediction?.points?.amount;

  return (
    <div className="bet bet-counter">
      <BetQuestion bet={bet} />

      <div className="options">
        <Input
          type="number"
          bsSize="sm"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
        />
      </div>

      <PointsWon points={points} />
    </div>
  );
};

export default CounterBet;
