import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import { useIntl } from 'react-intl';
import Results from '../components/search/Results';
import { clearSearchResults } from '../redux/search/actions';
import { fetchSearchResults } from '../redux/search/thunks';
import Filters from '../components/search/Filters';
import { useLocation } from 'react-router';
import Loader from '../components/Loader';
import Prediction from '../components/Prediction';

const qs = require('query-string');

function SearchPage(props) {
  const intl = useIntl();
  const location = useLocation();
  const initialFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [filters, setFilters] = useState(initialFilters);
  const { selectedMatch } = props;

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Search Results' }));
    props.clearSearchResults();
  }, []);

  useEffect(() => {
    props.fetchSearchResults(filters);
  }, [filters]);

  const onFilter = (filters) => {
    props.clearSearchResults();

    props.history.push({
      pathname: '/search',
      search: '?' + qs.stringify(filters),
    });

    setFilters(filters);
  };

  const onLoadMore = () => {
    props.fetchSearchResults(filters);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-search">
          <StickyContainer>
            <Row>
              <Col md={9}>
                <Filters onFilter={onFilter} initialFilters={initialFilters} />
                <Results onLoadMore={onLoadMore} />
              </Col>
              <Col md={3}>
                <Sticky disableCompensation={true}>
                  {({ style }) => (
                    <div style={style} className="sticky-wrapper">
                      {selectedMatch.loading && <Loader />}
                      {selectedMatch.id && (
                        <div className="sticky-inner-wrapper">
                          <Prediction
                            matchId={props.selectedMatch.id}
                            onUnselect={props.unselectMatch}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Sticky>
              </Col>
            </Row>
          </StickyContainer>
        </div>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { loadedMatches } = state.matches;
  const { selectedMatch } = state.search.results;
  return { loadedMatches, selectedMatch };
};

export default connect(mapStateToProps, { clearSearchResults, fetchSearchResults })(SearchPage);
