import useStateAwareRedirect from './useStateAwareRedirect';
import { useSelector } from 'react-redux';

export default function useRedirectAfterAuth() {
  const redirect = useStateAwareRedirect();
  const settings = useSelector((s) => s.generic.settings);

  return (attempt, override = null) => {
    const state = { attempt };
    if (override) {
      return redirect({ override, state });
    }

    // TODO: chech phone_number instead of phone_verified
    if (settings.enable_phone_verification && !attempt.user.phone_verified) {
      return redirect({ override: '/verify-phone-number', state });
    }

    return redirect();
  };
}
