import React, { useEffect, useState } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import ProfileTabs from '../../components/profile/Tabs';
import ProfileSidebar from '../../components/profile/Sidebar';
import { fetchTransactions } from '../../redux/profile/thunks';
import { /*FormattedMessage,*/ useIntl } from 'react-intl';
import Loader from '../../components/Loader';
import Transaction from '../../components/Transaction';
// import dayjs from 'dayjs';

// const TransactionFilters = ({ onSort }) => {
//   const [sort, setSort] = useState('date');

//   const onSortChange = (name, checked) => {
//     checked && setSort(name);
//   };

//   useEffect(() => {
//     onSort(sort);
//   }, [sort]);

//   return (
//     <div className="transaction-filters">
//       <h2 className="transaction-filters-label">
//         <FormattedMessage id="Sort by:" />
//       </h2>

//       <div className="sort-options">
//         <div className="form-check form-check-inline">
//           <input
//             className="form-check-input"
//             type="radio"
//             id="filter-payment"
//             value="payment"
//             onChange={(e) => onSortChange('payment', e.target.checked)}
//             checked={sort === 'payment'}
//           />
//           <label className="form-check-label" htmlFor="filter-payment">
//             <FormattedMessage id="Payment" />
//           </label>
//         </div>
//         <div className="form-check form-check-inline">
//           <input
//             className="form-check-input"
//             type="radio"
//             id="filter-provider"
//             value="provider"
//             onChange={(e) => onSortChange('provider', e.target.checked)}
//             checked={sort === 'provider'}
//           />
//           <label className="form-check-label" htmlFor="filter-provider">
//             <FormattedMessage id="Provider" />
//           </label>
//         </div>
//         <div className="form-check form-check-inline">
//           <input
//             className="form-check-input"
//             type="radio"
//             id="filter-date"
//             value="date"
//             onChange={(e) => onSortChange('date', e.target.checked)}
//             checked={sort === 'date'}
//           />
//           <label className="form-check-label" htmlFor="filter-date">
//             <FormattedMessage id="Date" />
//           </label>
//         </div>
//       </div>
//     </div>
//   );
// };

const ProfileWalletPage = (props) => {
  const [transactions, setTransactions] = useState(null);

  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Wallet' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);

    props.fetchTransactions().then((records) => {
      setTransactions(records);
    });
  }, []);

  // const onSort = (field) => {
  //   const sorted = transactions.sort((a, b) => {
  //     if (field === 'date') {
  //       return dayjs(b.created_at).unix() - dayjs(a.created_at).unix();
  //     } else if (field === 'payment') {
  //       return b.coins - a.coins;
  //     } else if (field === 'provider') {
  //       return b.provider.code !== a.provider.code;
  //     }

  //     return true;
  //   });

  //   setTransactions(sorted);
  // };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile page-profile-wallet">
          <Row>
            <Col md={3} className="col-sidebar">
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="profile-content">
                <ProfileTabs activeTab="wallet" />

                <TabContent activeTab="wallet">
                  <TabPane tabId="wallet">
                    {!transactions ? (
                      <Loader />
                    ) : (
                      <React.Fragment>
                        {/*<TransactionFilters onSort={onSort}/>*/}

                        {transactions.map((item) => {
                          return <Transaction transaction={item} key={item.id} />;
                        })}
                      </React.Fragment>
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default connect(null, { fetchTransactions })(ProfileWalletPage);
