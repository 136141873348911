import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const ProfileTabs = (props) => {
  const history = useHistory();
  const { activeTab } = props;

  return (
    <React.Fragment>
      <div className="profile-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === 'competitions' ? 'active' : ''}
              onClick={() => history.push('/profile')}
            >
              <FormattedMessage id="Competitions" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'predictions' ? 'active' : ''}
              onClick={() => history.push('/profile/predictions')}
            >
              <FormattedMessage id="Predictions" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'friends' ? 'active' : ''}
              onClick={() => history.push('/profile/friends')}
            >
              <FormattedMessage id="Friends" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'wallet' ? 'active' : ''}
              onClick={() => history.push('/profile/wallet')}
            >
              <FormattedMessage id="Wallet" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 'notifications' ? 'active' : ''}
              onClick={() => history.push('/profile/notifications')}
            >
              <FormattedMessage id="Notifications" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={activeTab === 'orders' ? 'active' : ''}
                onClick={() => history.push('/profile/orders')}
            >
              <FormattedMessage id="Orders" />
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </React.Fragment>
  );
};

export default ProfileTabs;
