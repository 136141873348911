import styled from 'styled-components';

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 5px $gray-500;
  margin-bottom: 1rem;
  text-align: center;
  height: 100%;
`;

export const ProductName = styled.h2`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
`;

export const ProductImage = styled.img`
  width: 70%;
  display: block;
  margin: 0 auto;
`;

export const ProductPriceSection = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 1rem 0;
`;

export const ProductPriceCurrency = styled.span`
  font-size: 0.8rem;
  align-self: flex-end;
`;

export const ProductPrice = styled.span`
  line-height: 2rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0.3rem;
`;

export const ProductOriginalPrice = styled.span.attrs(() => ({
  className: 'text-muted',
}))`
  font-size: 1rem;
  align-self: flex-start;
  text-decoration: line-through;
`;
