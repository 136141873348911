import usePhoneNumberVerification from './usePhoneNumberVerification';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useTrackers from './useTrackers';
import { useHistory, useLocation } from 'react-router';
import { useHasSufficientFunds } from './useHasSufficientFunds';

export default function useJoinCompetition(competition, openInsufficientFundsModal) {
  const authUser = useSelector((s) => s.auth.user);
  const coins = useSelector((s) => s.profile.coins);
  const history = useHistory();
  const location = useLocation();
  const phoneNumberVerification = usePhoneNumberVerification();
  const trackers = useTrackers();
  let [canJoin, setCanJoin] = useState(0);
  const isJoined = competition.joined;
  const { hasSufficientFunds, getRemaining } = useHasSufficientFunds();
  let hasEnoughCredit = false;
  if (competition && coins >= competition.entry_fee) {
    hasEnoughCredit = true;
  }

  const onJoinNow = (from = null) => {
    from = parseFrom(from);

    trackers.track('Button Clicked', { button: 'competition.join_new', source: 'competition' });

    if (!authUser) {
      redirectToAuth(from);
    } else if (phoneNumberVerification.shouldVerify()) {
      phoneNumberVerification.redirect(from);
    } else if (!isJoined && !hasSufficientFunds(competition.entry_fee)) {
      return openInsufficientFundsModal({ remaining: getRemaining(competition.entry_fee) });
    } else if (hasEnoughCredit) {
      setCanJoin(++canJoin);
    } else {
      redirectToPricing();
    }
  };

  const parseFrom = (from) => {
    if (!from) {
      from = location.pathname;
    }

    return from;
  };

  const redirectToAuth = (from) => {
    history.push({
      pathname: '/register',
      search: '?redirect=' + from,
      state: { from },
    });
  };

  const redirectToPricing = () => {
    history.push({ pathname: '/pricing' });
  };

  return { onJoinNow, canJoin };
}
