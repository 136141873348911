import React, { useState } from 'react';
import { Modal as BaseModal, ModalBody, ModalFooter } from 'reactstrap';
import Steps from './Steps';
import Buttons from './Buttons';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createCompetition } from '../../../../redux/competitions/thunks';
import ConfirmationModal from './ConfirmationModal';
import { COMPETITION_TYPE_FANTASY } from '../../../../constants/generic';

const initialValuesTemplate = {
  name: '',
  entry_fee: '10',
  participants: { min: 10, max: 30 },
  match_ids: [],
  winning_strategy: '',
  type: '',
  budget: '0',
  fantasy_competition_id: '',
};

const Modal = (props) => {
  const { isOpen, onToggle } = props;
  const [step, setStep] = useState(1);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [competition, setCompetition] = useState(null);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    entry_fee: Yup.number().required(),
    participants: Yup.object().shape({
      min: Yup.number().min(0),
      max: Yup.number().max(100),
    }),
    match_ids: Yup.array().of(Yup.number().integer()),
    winning_strategy: Yup.string().required(),
    type: Yup.number().required(),
    budget: Yup.number().when('type', {
      is: COMPETITION_TYPE_FANTASY,
      then: Yup.number().required(),
    }),
    fantasy_competition_id: Yup.number().when('type', {
      is: COMPETITION_TYPE_FANTASY,
      then: Yup.number().required(),
    }),
  });

  const onNext = (validateForm, handleSubmit) => {
    handleSubmit();
    if (step !== 3) {
      validateForm().then((errors) => {
        if (!Object.keys(errors).length) setStep(step + 1);
      });
    }
  };

  const onSubmit = (values, setSubmitting, resetForm) => {
    if (step === 3) {
      const finalValues = { ...values };
      delete finalValues.fantasy_competition_id;
      if (props?.groupId) finalValues.group_id = props.groupId;
      dispatch(createCompetition(finalValues))
        .then((c) => {
          setCompetition(c);
          setStep(1);
          resetForm();
          setTimeout(() => setConfirmationModalIsOpen(true), 300);

          onToggle();
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValuesTemplate}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          onSubmit(values, setSubmitting, resetForm)
        }
      >
        {(props) => (
          <BaseModal
            isOpen={isOpen}
            toggle={onToggle}
            backdrop="static"
            size="lg"
            id="modal-create-competition"
            unmountOnClose={true}
            centered={true}
          >
            <div className="modal-header">
              <div className="modal-title">
                <h2>
                  <FormattedMessage id="Create New Competition" />
                </h2>
                <p>
                  <FormattedMessage id="Setup your competition" />
                </p>
              </div>
              <button type="button" className="close" onClick={onToggle}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <ModalBody>
              <Steps step={step} {...props} />
            </ModalBody>
            <ModalFooter>
              <Buttons
                step={step}
                onNext={() => onNext(props.validateForm, props.handleSubmit)}
                onPrev={() => setStep(step - 1)}
                {...props}
              />
            </ModalFooter>
          </BaseModal>
        )}
      </Formik>

      {confirmationModalIsOpen && (
        <ConfirmationModal isOpen={confirmationModalIsOpen} competition={competition} />
      )}
    </React.Fragment>
  );
};

export default Modal;
