import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import { isUserAuthenticated } from '../helpers/auth';
import { Redirect } from 'react-router';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

function CompetitionTypesPage(props) {
  const intl = useIntl();
  useEffect(() => {
    new Cookies().set('competition-type-viewed', true);

    props.setDocumentTitle(intl.formatMessage({ id: 'Competition Types' }));
  }, []);

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <div className="page page-competition-types">
        <Container fluid={true}>
          <Row className="vh-100">
            <Col className="col-predictions">
              <div className="competition-type predictions">
                <div className="img-wrapper">
                  <img src={require('../assets/images/darts.png')} className="mb-2 mb-2" alt="" />
                </div>

                <div className="competition-type-content">
                  <h1 className="competition-type-name">Prediction Competitions</h1>

                  <p className="competition-type-description">
                    Join Daily, Weekly, Monthly and Season-long competitions to predict the outcomes
                    of football matches. Collect points, climb the leaderboard and win exciting
                    prizes!
                  </p>
                </div>

                <Button
                  color="white"
                  className="competition-type-link"
                  size="lg"
                  onClick={() => {
                    props.history.push('/onboarding');
                  }}
                >
                  Play Now
                </Button>
              </div>
            </Col>
            <Col className="vh-100 text-center">
              <div className="competition-type charity">
                <div className="img-wrapper">
                  <img
                    src={require('../assets/images/icon-charity-competitions.png')}
                    className="mb-2 mb-2"
                    alt=""
                  />
                </div>

                <div className="competition-type-content">
                  <h1 className="competition-type-name">Charity Competitions</h1>

                  <p className="competition-type-description">
                    Join our charity competitions to donate to good causes for a chance to win a
                    unique prize!
                  </p>
                </div>

                <Button
                  color="white"
                  className="competition-type-link"
                  size="lg"
                  onClick={() => {
                    props.history.push('/onboarding');
                  }}
                >
                  Play Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CompetitionTypesPage;
