import React from 'react';
import PointsToWin from './PointsToWin';
import ActiveTo from './ActiveTo';

const BetQuestion = (props) => {
  const { bet } = props;

  return (
    <div className="bet-question-wrapper">
      <div className="bet-meta">
        <div className="left">
          <ActiveTo activeTo={bet.active_to} />
        </div>
        <div className="right">
          <PointsToWin points={bet.points} />
        </div>
      </div>
      <h3 className="bet-question">{bet.question.body}</h3>
    </div>
  );
};

export default BetQuestion;
