import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import RedeemVoucher from '../components/RedeemVoucher';
import { useHistory } from 'react-router';
import Loader from '../components/Loader';
import { fetchPackages } from '../redux/generic/thunks';
import PricingPackage from '../components/PricingPackage';
import Ads from '../components/Ads';
import ReferringCard from '../components/ReferringCard';
import useTrackers from '../hooks/useTrackers';
import { locale } from '../helpers/generic';

const PricingPage = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const trackers = useTrackers();
  const packages = useSelector((state) => state.generic.packages);
  const dispatch = useDispatch();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Pricing' }));
    trackers.track('Page View', { page: 'Pricing' });

    dispatch(fetchPackages());
  }, []);

  const onSelectPackage = (selectedPackage) => {
    history.push({
      pathname: '/payment',
      state: { selectedPackage },
    });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-pricing">
          <Row>
            <Col md={8}>
              {!packages ? (
                <Loader />
              ) : (
                <div className="packages">
                  {locale() === 'en' ? <AlertEn /> : <AlertAr />}

                  <Row noGutters={true} className="justify-content-center">
                    {packages.map((p) => {
                      return (
                        <Col md={4} className="align-self-center" key={p.id}>
                          <div className="mb-3">
                            <PricingPackage pricingPackage={p} onSelect={onSelectPackage} />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}

              <div className="mb-3">
                <RedeemVoucher />
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-5">
                <div className="mb-3">
                  <ReferringCard />
                </div>
                <Ads />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

const AlertEn = () => (
  <Alert color="info">
    Eksab Coins are a way to enter competitions similar to the coins at an arcade or to buy items at
    our store. You can either earn coins by referring friends, or to save some time, you can buy
    coins through Fawry or Credit Card.
  </Alert>
);

const AlertAr = () => (
  <Alert color="info">
    اكسب كوينز تستخدم لدخول المسباقات او شراء المنتجات من متجرنا. يمكنك ربح الكوينز عن طريق دعوة
    صديق او شرائها باستخدام فورى او الكروت البنكية.
  </Alert>
);

export default PricingPage;
