import { createSelector } from 'reselect';

// Select state namespace
const selectState = (state) => state.groups;

const selectMyGroupsList = () => createSelector(selectState, (groups) => groups?.myGroupsList);

const selectAllGroupsList = () => createSelector(selectState, (groups) => groups?.allGroupsList);

const selectRecommendedGroupsList = () =>
  createSelector(selectState, (groups) => groups?.recommendedGroupsList);

const selectGroupDetails = () =>
  createSelector(selectState, (groups) => groups?.groupDetails?.info);

const selectGroupMembers = () =>
  createSelector(selectState, (groups) => groups?.groupDetails?.members);

const selectNonGroupMembers = () =>
  createSelector(selectState, (groups) => groups?.groupDetails?.nonMembers);

const selectGroupManagers = () =>
  createSelector(selectState, (groups) => groups?.groupDetails?.managers);

const selectGroupRanking = () =>
  createSelector(selectState, (groups) => groups?.groupDetails?.ranking);

export {
  selectMyGroupsList,
  selectAllGroupsList,
  selectRecommendedGroupsList,
  selectGroupDetails,
  selectGroupMembers,
  selectNonGroupMembers,
  selectGroupManagers,
  selectGroupRanking,
};
