import Box from '../Box';
import { PlayerImage } from '../competitions/CompetitionTeamSetupFilters/styles';
import Text from '../Text';
import React from 'react';

const PlayerName = ({ player }) => {
  return (
    <Box display="flex" justifyConten="space-between">
      <Box pr="5px">
        <PlayerImage src={player.image.thumb} />
      </Box>
      <Box>
        <Text display="block" fontWeight="bold" lineHeight="1.2em">
          {player.common_name}
        </Text>{' '}
        <br />
        <Text display="block" color="#999" fontSize=".8em" lineHeight=".8em" mt="-20px">
          {player.team.name}
        </Text>
      </Box>
    </Box>
  );
};

export default PlayerName;
