import React from 'react';
import useCCPaymentStatus from '../hooks/useCCPaymentStatus';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

const CCPaymentStatusPage = () => {
  const history = useHistory();
  const ccPayment = useCCPaymentStatus().getStatus();

  const onExploreCompetition = () => history.push(DEFAULT_LANDING_PAGE);

  const onTryAgain = () => history.push('/pricing');

  return (
    <div className="container-fluid">
      <div className="page page-payment-cc-status">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <img
              className="status-icon"
              src={
                ccPayment.status === 'success'
                  ? require('../assets/images/icon-check.png')
                  : require('../assets/images/icon-close.png')
              }
              alt=""
            />

            <h2 className="status-message">{ccPayment.message}</h2>

            {ccPayment.status === 'success' ? (
              <button className="btn btn-secondary" onClick={onExploreCompetition}>
                <FormattedMessage id="Explore our competitions" />
              </button>
            ) : (
              <button className="btn btn-secondary" onClick={onTryAgain}>
                <FormattedMessage id="Try again" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCPaymentStatusPage;
