import React, { useEffect, useState } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import ProfileSidebar from '../../components/profile/Sidebar';
import ProfileTabs from '../../components/profile/Tabs';
import Loader from '../../components/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../redux/profile/thunks';
import dayjs from 'dayjs';

const OrdersPage = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const orders = useSelector((s) => s.profile.orders);

  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Orders' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);

    dispatch(fetchOrders()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile page-profile-orders">
          <Row>
            <Col md={3} className="col-sidebar">
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="profile-content">
                <ProfileTabs activeTab="orders" />

                <TabContent activeTab="orders">
                  <TabPane tabId="orders">
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-stripped table-bordered">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="Id" />
                              </th>
                              <th>
                                <FormattedMessage id="Product" />
                              </th>
                              <th>
                                <FormattedMessage id="Price" />
                              </th>
                              <th>
                                <FormattedMessage id="Date Created" />
                              </th>
                              <th>
                                <FormattedMessage id="Status" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders.map((item) => (
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.product.name}</td>
                                <td>
                                  {item.price} <FormattedMessage id="Coins" />
                                </td>
                                <td>{dayjs(item.created_at).format('YYYY-MM-DD')}</td>
                                <td>
                                  <FormattedMessage id={`orders.status.${item.status}`} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default OrdersPage;
