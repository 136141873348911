// @vendors
import React from 'react';
import PropTypes from 'prop-types';
// @components
import Box from '../../Box';
import Text from '../../Text';
import CompetitionPlayerAvatar from '../CompetitionPlayerAvatar';
// @styles
// import { ActionImg } from './styles';
// @static
// import plusIcon from '../../../assets/images/icon-plus.svg';
// import minusIcon from '../../../assets/images/icon-minus.svg';
import { FormattedMessage } from 'react-intl';

const CompetitionPlayerCard = ({
  name,
  image,
  number,
  price,
  metrics,
  teamImg,
  selected,
  onSelect,
  lastItem,
  // canSelect,
  ...rest
}) => (
  <Box
    position="relative"
    width="100%"
    display="flex"
    borderBottom={!lastItem ? '1px solid #afadb2' : ''}
    cursor="pointer"
    onClick={onSelect}
    {...rest}
  >
    <CompetitionPlayerAvatar image={image} name={name} number={number} teamImg={teamImg} />
    <Box
      width={{ xs: 'calc(100% - 7.75rem)', md: 'calc(100% - 8.75rem)' }}
      height={{ xs: '4.75rem', md: '5.75rem' }}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      pb="0.5rem"
    >
      <Text fontSize="1.25rem" fontWeight="bold" color="#2c9eff" mb="0.5rem">
        ${price}
        <FormattedMessage id="M" />
      </Text>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        overflowX="auto"
        overflowY="hidden"
        width="100%"
        height="100%"
      >
        {metrics &&
          Object.keys(metrics).map((metricKey) =>
            metrics[metricKey]?.value !== undefined &&
            metrics[metricKey]?.value !== null &&
            metrics[metricKey]?.featured ? (
              <Box
                key={metricKey}
                display="flex"
                flexDirection="column"
                alignItems="center"
                mis={{ xs: '0.5rem', md: '1rem' }}
              >
                <Text fontSize="0.75rem" fontWeight="600" color="#000" mb="0.5rem">
                  <FormattedMessage id={`CompetitionPlayerCard.Stats.${metricKey}`} />
                </Text>
                <Text fontSize="0.75rem" fontWeight="400" color="#201f2f">
                  {metrics[metricKey]?.value}
                </Text>
              </Box>
            ) : null
          )}
      </Box>
      {/* <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="3.5rem"
        height="100%"
      >
        <Text fontSize="1.25rem" fontWeight="bold" color="#2c9eff">
          ${price}
          <FormattedMessage id="M" />
        </Text>
        {canSelect && <ActionImg src={selected ? minusIcon : plusIcon} />}
      </Box> */}
    </Box>
  </Box>
);

CompetitionPlayerCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  number: PropTypes.number,
  price: PropTypes.number.isRequired,
  metrics: PropTypes.shape({}).isRequired,
  teamImg: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  lastItem: PropTypes.bool,
  // canSelect: PropTypes.bool,
};

CompetitionPlayerCard.defaultProps = {
  selected: false,
  onSelect: () => {},
  lastItem: false,
  // canSelect: false,
};

export default CompetitionPlayerCard;
