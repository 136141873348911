import React from 'react';
import { useIntl } from 'react-intl';

export default (WrappedComponent) => {
  return (props) => {
    const intl = useIntl();

    const setDocumentTitle = (title) => {
      let segments = [];

      if (Array.isArray(title)) {
        title.forEach((t) => segments.push(t));
      } else if (title) {
        if (title) segments.push(title);
      }

      segments.push(intl.formatMessage({ id: 'Eksab' }));

      document.title = segments.join(' | ');
    };

    return <WrappedComponent {...props} setDocumentTitle={setDocumentTitle} />;
  };
};
