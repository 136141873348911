import styled from 'styled-components';
import theme from '../constants/theme';

const ViewExistingFile = styled.a`
  display: inline-block;
  margin-top: 5px;
  color: ${theme.colors.secondary};
  font-size: 0.9rem;
`;

export default ViewExistingFile;