// @vendors
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTrackers from '../../../hooks/useTrackers';
import { useHistory, useParams, useLocation } from 'react-router';
import { Sticky, StickyContainer } from 'react-sticky';
// @redux
import {
  fetchCompetitionMatches,
  selectCompetitionMatch,
} from '../../../redux/competitions/thunks';
import { unselectCompetitionMatch } from '../../../redux/competitions/actions';
// @hoc
import withMediaQuery from '../../../hoc/withMediaQuery';
// @components
import Match from '../../../components/Match';
import Loader from '../../../components/Loader';
import Prediction from '../../../components/Prediction';
import { CompetitionContext } from '../show';
import MatchScoreBreakdown from './MatchScoreBreakdown';
import useJoinCompetition from '../../../hooks/useJoinCompetition';
import { InsufficientFundsModal } from './InsufficientFundsModal';

const Matches = (props) => {
  const dispatch = useDispatch();
  const trackers = useTrackers();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(false);
  const [remainingToJoinCompetition, setRemainingToJoinCompetition] = useState();
  const openInsufficientFundsModal = ({ remaining }) => {
    setInsufficientFundsModalOpen(true);
    setRemainingToJoinCompetition(remaining);
  };

  const { setOpenJoiningModal } = useContext(CompetitionContext);

  const { competitionDescriptor } = props;
  const competition = useSelector((s) => s.competitions.entities[competitionDescriptor.id]);
  const { items: matches } = competitionDescriptor.matches;
  const loadedMatches = useSelector((s) => s.matches.loadedMatches);
  const authUser = useSelector((s) => s.auth.user);

  const { selectedMatch } = competitionDescriptor;

  let nextMatchId = null;
  if (matches) {
    const selectedMatchIndex = matches.indexOf(selectedMatch.id);
    const nextMatchIndex = selectedMatchIndex + 1;
    nextMatchId =
      selectedMatchIndex !== -1 && matches.length > nextMatchIndex ? matches[nextMatchIndex] : null;
  }

  const { canJoin, onJoinNow } = useJoinCompetition(competition, openInsufficientFundsModal);

  useEffect(() => {
    trackers.track('Page View', { page: `Competition`, name: competition.name, tab: 'Matches' });

    if (params.gameId) {
      onSelect(params.gameId);
    }
  }, []);

  useEffect(() => {
    onFilter();
  }, [competition?.id, competition?.joined]);

  useEffect(() => {
    if (location.state?.loadFirstPrediction && matches?.length) {
      onSelect(matches[0]);
    }
  }, [matches]);

  const onFilter = () => {
    if (selectedMatch) {
      onUnselect(selectedMatch.id);
    }
    competition && dispatch(fetchCompetitionMatches(competition.season.id));
  };

  const onSelect = (matchId) => {
    history.push(history.location.pathname.replace(/\/matches.*/, '/matches') + `/${matchId}`);
    dispatch(selectCompetitionMatch(matchId));
  };

  const onUnselect = () => {
    // history.push(history.location.pathname.replace(/\/matches.*/, '/matches'));
    dispatch(unselectCompetitionMatch());
  };

  const isLoggedIn = !!authUser;
  const isJoined = competition.joined;

  const onAttempt = (gameId) => {
    if (!canJoin) {
      onJoinNow(`/competitions/${competition.id}/matches/${gameId}`);
    }
  };

  useEffect(() => {
    if (canJoin) setOpenJoiningModal(true);
  }, [canJoin]);

  return (
    <React.Fragment>
      <StickyContainer>
        <div className="row">
          <div className="col-md-8">
            {competitionDescriptor.matches.loading || !matches ? (
              <Loader />
            ) : (
              matches.map((matchId) => {
                const match = loadedMatches[matchId] || {};

                return (
                  <Match
                    match={match}
                    selectedMatchId={selectedMatch.id}
                    key={matchId}
                    onSelect={onSelect}
                    isFantasyMatch={competition?.type === 2}
                  />
                );
              })
            )}
          </div>
          <div className="col-md-4">
            {props.isDesktop && (
              <Sticky>
                {({ style }) => (
                  <div style={style} className="sticky-wrapper">
                    {selectedMatch.loading && <Loader />}
                    {selectedMatch.id && (
                      <div className="sticky-inner-wrapper">
                        {competition?.type === 1 && (
                          <Prediction
                            matchId={selectedMatch.id}
                            onNextMatch={() => !!nextMatchId && onSelect(nextMatchId)}
                            userCanPredict={isLoggedIn && isJoined}
                            onAttempt={onAttempt}
                          />
                        )}
                        {competition?.type === 2 && (
                          <MatchScoreBreakdown
                            matchId={selectedMatch.id}
                            competition={competition}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Sticky>
            )}
          </div>
        </div>
      </StickyContainer>

      {props.isMobile && (
        <React.Fragment>
          {!!(selectedMatch.loading || selectedMatch.id) && (
            <div className="overlay-prediction">
              {selectedMatch.loading && <Loader />}
              {!!selectedMatch.id && (
                <>
                  {competition?.type === 1 && (
                    <Prediction
                      matchId={selectedMatch.id}
                      onUnselect={onUnselect}
                      onNextMatch={() => !!nextMatchId && onSelect(nextMatchId)}
                      userCanPredict={isLoggedIn && isJoined}
                      onAttempt={onAttempt}
                    />
                  )}
                  {competition?.type === 2 && (
                    <MatchScoreBreakdown
                      matchId={selectedMatch.id}
                      onClose={onUnselect}
                      competition={competition}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </React.Fragment>
      )}

      <InsufficientFundsModal
        remainingToJoin={remainingToJoinCompetition}
        open={insufficientFundsModalOpen}
        setOpen={setInsufficientFundsModalOpen}
      />
    </React.Fragment>
  );
};

export default withMediaQuery(Matches);
