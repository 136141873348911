import { loginUsingFacebook as loginUsingFacebookThunk } from '../redux/auth/thunks';
import { useDispatch } from 'react-redux';
import useTrackers from './useTrackers';

export default function useAccount() {
  const dispatch = useDispatch();
  const trackers = useTrackers();

  const getFacebookLoginStatus = () => {
    return new Promise((resolve) => {
      let loginResponse = { status: false };

      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          loginResponse.status = true;
          loginResponse.accessToken = authResponse.accessToken;
        }

        resolve(loginResponse);
      });
    });
  };

  const loginUsingFacebook = async (accessToken, referrer = null) => {
    return dispatch(loginUsingFacebookThunk(accessToken, referrer)).then((attempt) => {
      trackers.track('Login', { provider: 'facebook' });

      return attempt;
    });
  };

  return { loginUsingFacebook, getFacebookLoginStatus };
}
