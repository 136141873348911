import React from 'react';
import { FormattedMessage } from 'react-intl';

import ProfileIcon from '../../assets/images/user.png';

const MyRanking = ({ item: { ranking, points, user } = {} }) => (
  <>
    {user && (
      <div className="my-ranking">
        <div className="col-left">
          <span className="ranking">{ranking}</span>

          <img
            className="user-picture"
            src={user.photo?.thumb ?? user.photo?.original ?? ProfileIcon}
            alt={`${user.name}'s avatar`}
          />

          <h2 className="user-name">{user.name}</h2>
        </div>

        <h2 className="points">
          {points} <FormattedMessage id="Pts" />
        </h2>
      </div>
    )}
  </>
);

export default MyRanking;
