import React, { useEffect, useState } from 'react';
import { CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const Filters = (props) => {
  const [filters, setFilters] = useState({ affordable: false, creator: '' });
  const [menus, setMenus] = useState({ creator: false });
  const intl = useIntl();
  const creators = useSelector((s) => s.generic.creators);

  const toggle = (menu) => {
    setMenus({ ...menus, [menu]: !menus[menu] });
  };

  useEffect(() => {
    let parsed = {};

    Object.keys(filters).forEach((f) => {
      if (filters[f]) parsed[f] = filters[f];
    });

    props.onFilter(parsed);
  }, [filters]);

  const getSortDirection = (field) => {
    if (!filters.sort) return null;

    const exists = filters.sort[field];

    if (exists) {
      const message =
        exists === 'asc' ? <FormattedMessage id="ASC" /> : <FormattedMessage id="DESC" />;

      return <React.Fragment>({message})</React.Fragment>;
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="competition-filters">
        <div className="row align-items-center">
          <div className="col-auto">
            <h3 className="filter-label">
              <FormattedMessage id="Sort by" />:
            </h3>
          </div>
          <div className="col-auto">
            <Dropdown isOpen={menus['start-date']} toggle={() => toggle('start-date')} size="sm">
              <DropdownToggle caret>
                <FormattedMessage id="Start Date" /> {getSortDirection('start-date')}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'start-date': 'asc' } })}
                >
                  <FormattedMessage id="Ascending" />
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'start-date': 'desc' } })}
                >
                  <FormattedMessage id="Descending" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="col-auto">
            <Dropdown isOpen={menus['end-date']} toggle={() => toggle('end-date')} size="sm">
              <DropdownToggle caret>
                <FormattedMessage id="End Date" /> {getSortDirection('end-date')}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'end-date': 'asc' } })}
                >
                  <FormattedMessage id="Ascending" />
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'end-date': 'desc' } })}
                >
                  <FormattedMessage id="Descending" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="col-auto">
            <Dropdown isOpen={menus['entry-fee']} toggle={() => toggle('entry-fee')} size="sm">
              <DropdownToggle caret>
                <FormattedMessage id="Entry Fee" /> {getSortDirection('entry-fee')}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'entry-fee': 'asc' } })}
                >
                  <FormattedMessage id="Ascending" />
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFilters({ ...filters, sort: { 'entry-fee': 'desc' } })}
                >
                  <FormattedMessage id="Descending" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="col-auto">
            <Dropdown isOpen={menus['reward']} toggle={() => toggle('reward')} size="sm">
              <DropdownToggle caret>
                <FormattedMessage id="Reward" /> {getSortDirection('reward')}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setFilters({ ...filters, sort: { reward: 'asc' } })}>
                  <FormattedMessage id="Ascending" />
                </DropdownItem>
                <DropdownItem onClick={() => setFilters({ ...filters, sort: { reward: 'desc' } })}>
                  <FormattedMessage id="Descending" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="col-auto">
            <h3 className="filter-label">
              <FormattedMessage id="Filter by" />:
            </h3>
          </div>
          <div className="col-auto">
            <CustomInput
              type="checkbox"
              id="filters-affordable"
              label={intl.formatMessage({ id: 'Affordable' })}
              checked={filters.affordable}
              onChange={(e) => {
                setFilters({ ...filters, affordable: e.target.checked });
              }}
            />
          </div>
          <div className="col-auto">
            <Dropdown isOpen={menus['creator']} toggle={() => toggle('creator')} size="sm">
              <DropdownToggle caret>
                {filters.creator ? (
                  creators.filter((c) => c.id === filters.creator)[0].name
                ) : (
                  <FormattedMessage id="Creator" />
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setFilters({ ...filters, creator: '' })}>
                  <FormattedMessage id="All" />
                </DropdownItem>
                {creators.map((creator) => (
                  <DropdownItem
                    key={creator.id}
                    onClick={() => setFilters({ ...filters, creator: creator.id })}
                  >
                    {creator.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Filters;
