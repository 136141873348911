import * as Yup from 'yup';
import { IMAGE_FILE_FORMATS } from '../constants/generic';

export default class ValidationRuleFactory {
  static name() {
    return Yup.string().required();
  }

  static legalName() {
    return Yup.string();
  }

  static email() {
    return Yup.string().email().required();
  }

  static nationalId(required = false) {
    let rules = Yup.string()
      .length(14)
      .matches(
        '(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\\d\\d\\d\\d\\d'
      );

    if (required) rules.required();

    return rules;
  }

  static phoneNumber() {
    return Yup.string().matches(/^(01)[0-9]{9}$/);
  }

  static profilePicture(intl) {
    const FILE_SIZE = 1024 * 1024 * 10;

    return Yup.mixed()
      .test(
        'fileSize',
        intl.formatMessage({ id: 'invalid-file-size' }),
        (value) => !value || value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'invalid-file-format' }),
        (value) => !value || IMAGE_FILE_FORMATS.includes(value.type)
      );
  }

  static nationalIdScans(intl, frontRequired = true, backRequired = true) {
    const FILE_SIZE = 1024 * 1024 * 50;

    const nationalIdScanRules = (required) => {
      let rules = Yup.mixed()
        .test(
          'fileSize',
          intl.formatMessage({ id: 'invalid-file-size' }),
          (value) => !value || value.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          intl.formatMessage({ id: 'invalid-file-format' }),
          (value) => !value || IMAGE_FILE_FORMATS.includes(value.type)
        );

      if (required) rules.required();

      return rules;
    };

    return Yup.object().shape({
      front: nationalIdScanRules(frontRequired),
      back: nationalIdScanRules(backRequired),
    });
  }
}
