import styled from 'styled-components';
import { Button } from 'reactstrap';
import Box from '../../Box';

export const StyledBox = styled(Box)``;

export const PlayerImage = styled.div`
  width: 30px;
  height: 30px;
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
  border: 1px solid #999;
  border-radius: 50%;
`;

// export const FilterButton = styled(Button).attrs((props) => ({
//   color: 'primary',
//   size: 'sm',
//   block: true,
// }))`
//   margin-bottom: 1rem;
// `;

export const InfoButton = styled(Button).attrs((props) => ({
  size: 'xs',
}))`
  border-color: #999;
  background-color: #999;
  color: #333;
`;

export const SelectButton = styled(Button).attrs(() => ({
  size: 'xs',
  color: 'primary',
}))`
  line-height: 0.9em;
`;

export const DeselectButton = styled(Button).attrs(() => ({
  size: 'xs',
  color: 'danger',
}))`
  line-height: 0.9em;
`;

export const StyledPlayerGroupTitle = styled.h2`
  color: #fff;
  background-color: #34495e;
  margin: 0.5rem 0 0;
  font-size: 1rem;
  padding: 3px 5px;
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
`;
