import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import api from '../../../../helpers/api';
import Loader from '../../../../components/Loader';

const Steps = (props) => {
  const { step } = props;
  const [loading, setLoading] = useState(true);
  const [fantasyCompetitions, setFantasyCompetitions] = useState([]);

  useEffect(() => {
    api.getCompetitionForUserCreatedFantasy().then((r) => {
      setFantasyCompetitions(r.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Navigation step={step} />

          {step === 1 && <Step1 fantasyCompetitions={fantasyCompetitions} {...props} />}
          {step === 2 && <Step2 {...props} />}
          {step === 3 && <Step3 {...props} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Steps;
