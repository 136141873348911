import styled from 'styled-components';

export const ResetButton = styled.button`
  min-height: 2.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #5ad37c;
  color: #34495e;
  display: inline-block;
  padding: 0.625rem 1rem;
  border: none;
  outline: none;
  :focus {
    outline: none;
    border: none;
  }
  :disabled {
    background-color: #afadb2;
    color: #727272;
  }
  width: 25%;
`;
