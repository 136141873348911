// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import { FaEllipsisV, FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa';
// @components
import Box from '../Box';
import Text from '../Text';
// @assets
import userPlaceholderImg from '../../assets/images/user-placeholder.png';
// @hocs
import withMediaQuery from '../../hoc/withMediaQuery';

const MemberImg = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  display: inline-block;
  object-fit: cover;
  border-radius: 0.25rem;
`;

const MemberCard = withMediaQuery(
  ({ id, image, name, role, isAdded, onToggle, options, onOptionClick, isMobile, ...rest }) => (
    <Box
      id={`member_${id}`}
      display="flex"
      width="100%"
      bg={role === 2 ? '#5ad37c' : '#ffffff'}
      position="relative"
      py="0.625rem"
      px={{ xs: '0.5rem', md: '1.5rem' }}
      alignItems="center"
      borderBottom="2px solid rgba(175, 173, 178, 0.3)"
      {...rest}
    >
      <MemberImg
        src={image || userPlaceholderImg}
        onError={(e) => {
          e.target.src = userPlaceholderImg;
        }}
      />
      <Text
        id={`member_${id}_name`}
        fontSize="1rem"
        fontWeight="500"
        color="#201f2f"
        lineHeight="1.2"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        mis={{ xs: '0.75rem', md: '1.75rem' }}
        mie={{ xs: '0.5rem', md: '1rem' }}
      >
        {name}
      </Text>

      <UncontrolledTooltip placement="top" target={`member_${id}_name`}>
        {name}
      </UncontrolledTooltip>

      {onToggle && (
        <button
          className={`btn btn-sm btn-${isAdded ? 'danger' : 'primary'}`}
          style={{ marginInlineStart: 'auto' }}
          onClick={onToggle}
        >
          {isMobile ? (
            <>{isAdded ? <FaRegMinusSquare /> : <FaRegPlusSquare />}</>
          ) : (
            <FormattedMessage id={isAdded ? 'Remove' : 'Add'} />
          )}
        </button>
      )}

      {options?.length > 0 && (
        <Box mis={onToggle ? '1rem' : 'auto'}>
          <UncontrolledDropdown direction="right">
            <DropdownToggle tag="div">
              <Text fontSize="1.125rem" color={role === 2 ? '#ffffff' : '#5ad37c'} cursor="pointer">
                <FaEllipsisV />
              </Text>
            </DropdownToggle>
            <DropdownMenu>
              {options.map((option, key) => (
                <React.Fragment key={`${id}_option-${option.key}`}>
                  {key !== 0 && <DropdownItem divider />}
                  <DropdownItem
                    onClick={() => onOptionClick(option.key)}
                    disabled={option.isDisabled ? option.isDisabled(id) : false}
                  >
                    {option.label}
                  </DropdownItem>
                </React.Fragment>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Box>
      )}
    </Box>
  )
);

MemberCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.oneOf([1, 2]),
  isAdded: PropTypes.bool,
  onToggle: PropTypes.func,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
};

MemberCard.defaultProps = {
  image: '',
  name: '',
  role: 1,
  isAdded: false,
  onToggle: null,
  options: [],
  onOptionClick: () => {},
};

export default MemberCard;
