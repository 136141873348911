// @vendors
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// @components
import MyRanking from '../../../../components/ranking/MyRanking';
import RankingList from '../../../../components/ranking/RankingList';
// @helpers
import Paginator from '../../../../helpers/paginator';
// @redux
import { fetchGroupRanking } from '../../../../redux/groups/thunks';
import { fetchMyRanking } from '../../../../redux/ranking/thunks';
import { selectGroupRanking } from '../../../../redux/groups/selectors';
import {clearGroupRanking} from "../../../../redux/groups/actions";

const GroupLeaderboard = ({ groupId }) => {
  const dispatch = useDispatch();
  const ranking = useSelector(selectGroupRanking());
  const { competition } = useSelector((state) => state.competitions);
  const { myRanking } = useSelector((state) => state.ranking);
  const rankingType = `competition-${competition.id}`;

  useEffect(() => {
    dispatch(clearGroupRanking());
    dispatch(fetchGroupRanking(groupId));
    dispatch(fetchMyRanking(rankingType, { group: groupId }));
  }, []);

  const onLoadMore = () => {
    dispatch(fetchGroupRanking(groupId));
  };

  const { page: current_page, lastPage: last_page } = ranking;
  const paginator = new Paginator({ current_page, last_page });

  return (
    <>
      {ranking.pending ? (
        <p className="text-muted">
          <FormattedMessage id="pending-ranking-list-message" />
        </p>
      ) : (
        <div className="group-ranking">
          {myRanking[rankingType]?.item && (
            <div className="my-ranking-wrapper">
              <MyRanking item={myRanking[rankingType].item} />
            </div>
          )}

          <RankingList
            items={ranking.items}
            loading={ranking.loading}
            paginator={paginator}
            onLoadMore={onLoadMore}
          />
        </div>
      )}
    </>
  );
};

export default GroupLeaderboard;
