import api from '../../helpers/api';

export function getCompetitionTeam(competitionId) {
  return async function () {
    return api.getCompetitionTeam(competitionId).then((response) => response.data);
  };
}

export function saveCompetitionTeam(competitionId, team = {}) {
  return async function () {
    if (team?.formation && team?.players?.length === 15)
      return api.saveCompetitionTeam(competitionId, team).then((response) => response.data);
    return false;
  };
}
