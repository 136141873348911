import * as actionTypes from '../../constants/action-types/prizes';

const INIT_STATE = {
  fetched: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_PRIZES:
      return {
        ...state,
        fetched: action.payload.prizes.reduce((c, p) => {
          c[p.id] = p;
          return c;
        }, {}),
      };

    default:
      return { ...state };
  }
};
