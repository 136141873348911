import React from 'react';
import NumberFormat from 'react-number-format';

const EGP = ({ egp }) => {
  return (
    <NumberFormat value={egp} displayType={'text'} thousandSeparator={true} decimalScale={2} />
  );
};

export default EGP;
