import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Col, CustomInput, Input, Row } from 'reactstrap';

import EditProfile from './EditProfile';
import Loader from '../Loader';
import { updateSetting } from '../../redux/generic/thunks';
import InlineEditButton from '../InlineEditButton';
import theme from '../../constants/theme';
import ProfileIcon from '../../assets/images/user.png';

const ProfileSidebar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { user, settings } = props;

  const onSettingChange = (key, value) => {
    props.updateSetting(key, value);
  };

  const [modals, setModals] = useState({
    profile: false,
  });

  const onToggle = (name) => {
    setModals({ ...modals, [name]: !modals[name] });
  };

  const onChangePhoneNumber = () => {
    history.push('/verify-phone-number', { from: location.pathname });
  };

  return (
    <React.Fragment>
      <div className="profile-sidebar">
        <div className="sidebar-head">
          <div className="user-picture-wrapper">
            <img
              src={user.photo?.thumb ?? user.photo?.original ?? ProfileIcon}
              className="user-picture"
              alt={`${user.name}'s avatar`}
            />
            <StyledProfilePictureInlineEditButton onClick={() => onToggle('profile')} />
          </div>

          <div className="user-data">
            <h2 className="user-name">
              {user.name} <StyledInlineEditButton onClick={() => onToggle('profile')} />
            </h2>
            <h3 className="user-id">
              <FormattedMessage id="ID" />: {user.id}
            </h3>
          </div>

          <div className="user-points-wrapper">
            <div className="top-row">
              <img src={require('../../assets/images/icon-ranking.svg')} alt="" />
              <h3 className="points">{user.points}</h3>
              <div className="points-label">
                <FormattedMessage id="Pts" />
              </div>
            </div>
            <p className="since">
              <FormattedMessage id="Since you joined Eksab" />
            </p>
          </div>

          <ProfileInfo>
            <ProfileInfoLabel>
              <FormattedMessage id="Email" />
            </ProfileInfoLabel>
            <ProfileInfoValue>
              {user.email} <StyledInlineEditButton onClick={() => onToggle('profile')} />
            </ProfileInfoValue>
          </ProfileInfo>

          <ProfileInfo>
            <ProfileInfoLabel>
              <FormattedMessage id="Phone Number" />
            </ProfileInfoLabel>
            <ProfileInfoValue>
              {user.phone_number} <StyledInlineEditButton onClick={() => onChangePhoneNumber()} />
            </ProfileInfoValue>
          </ProfileInfo>

          <ProfileInfo>
            <ProfileInfoLabel>
              <FormattedMessage id="Full Legal Name" />
            </ProfileInfoLabel>
            <ProfileInfoValue>
              {user.legal_name} <StyledInlineEditButton onClick={() => onToggle('profile')} />
            </ProfileInfoValue>
          </ProfileInfo>

          <ProfileInfo>
            <ProfileInfoLabel>
              <FormattedMessage id="National Id" />
            </ProfileInfoLabel>
            <ProfileInfoValue>
              {user.national_id}{' '}
              {!user.national_id_verified && (
                <StyledInlineEditButton onClick={() => onToggle('profile')} />
              )}
            </ProfileInfoValue>
          </ProfileInfo>
        </div>

        <div className="settings-container">
          <div className="setting setting-languages">
            <h3 className="setting-label">
              <FormattedMessage id="Language" />
            </h3>

            <Row>
              <Col md={8}>
                <Input
                  type="select"
                  disabled={settings.language.loading}
                  onChange={(e) => onSettingChange('language', e.target.value)}
                  defaultValue={settings.language.value}
                >
                  <option value="en">English</option>
                  <option value="ar">العربية</option>
                </Input>
              </Col>
              <Col md={4}>{settings.language.loading && <Loader size="md" />}</Col>
            </Row>
          </div>

          <div className="setting setting-notifications">
            <h3 className="setting-label">
              <FormattedMessage id="Show notifications" />
            </h3>

            <div className="options-list">
              <FormattedMessage id="No" />
              <CustomInput
                type="switch"
                id="notifications-switch"
                checked={settings.receive_notifications.value}
                onChange={(e) => onSettingChange('receive_notifications', e.target.checked)}
              />
              <FormattedMessage id="Yes" />
            </div>
          </div>
        </div>
      </div>

      <EditProfile open={modals.profile} onToggle={() => onToggle('profile')} />
    </React.Fragment>
  );
};

const ProfileInfo = styled.div`
  margin-bottom: 1rem;
`;

const ProfileInfoLabel = styled.h3`
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 1rem;
  margin: 0 0 0.2rem 0;
`;

const ProfileInfoValue = styled.p`
  color: ${theme.colors.grey.light};
  margin-bottom: 0;
`;

const StyledInlineEditButton = styled(InlineEditButton)`
  color: ${theme.colors.secondary};
  margin-inline-start: 0.2rem;
`;

const StyledProfilePictureInlineEditButton = styled(StyledInlineEditButton)`
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 1.2rem;
`;

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { userSettings } = state.generic;
  return { user, settings: userSettings };
};

export default connect(mapStateToProps, { updateSetting })(ProfileSidebar);
