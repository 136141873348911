// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
// @components
import Box from '../Box';
import GroupCard from './GroupCard';

const ListGroups = ({ id, items, loadMore, hasMore, onClick, ...rest }) => (
  <Box width="100%" {...rest}>
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      initialLoad={false}
      className="row"
    >
      {items?.map((item) => (
        <Box key={`${id}_group_${item?.id}`} className="col-sm-12 col-md-6 col-lg-4 mb-4">
          <GroupCard
            id={item?.id}
            name={item?.name}
            type={item?.type}
            image={item?.cover}
            description={item?.description}
            badge={item?.notifications}
            joined={item?.joined}
            members={item?.latest_members}
            membersCount={item?.member_count}
            onClick={() => {
              onClick(item);
            }}
            height="100%"
          />
        </Box>
      ))}
    </InfiniteScroll>
  </Box>
);

ListGroups.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      type: PropTypes.string,
      cover: PropTypes.string,
      description: PropTypes.string,
      notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      joined: PropTypes.bool,
      latest_members: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  onClick: PropTypes.func,
};

ListGroups.defaultProps = {
  id: 'groups-list',
  items: [],
  loadMore: () => {},
  hasMore: false,
  onClick: () => {},
};

export default ListGroups;
