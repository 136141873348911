import * as actionTypes from '../../constants/action-types/predictions';

export const selectMatchStart = () => ({
  type: actionTypes.SELECT_MATCH_FOR_PREDICTION_START,
});

export const selectMatchSuccess = (matchId) => ({
  type: actionTypes.SELECT_MATCH_FOR_PREDICTION_SUCCESS,
  payload: { matchId },
});

export const selectMatchFailure = () => ({
  type: actionTypes.SELECT_MATCH_FOR_PREDICTION_FAILURE,
});
