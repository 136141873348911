import { Cookies } from 'react-cookie';

const getToken = () => {
  const cookies = new Cookies();

  const token = cookies.get('token');

  return token || null;
};

const setToken = (accessToken) => {
  const cookieManager = new Cookies();
  cookieManager.set('token', accessToken);
};

const getRefreshToken = () => {
  const cookies = new Cookies();

  const token = cookies.get('refresh-token');

  return token || null;
};

const setRefreshToken = (refreshToken) => {
  const cookieManager = new Cookies();
  cookieManager.set('refresh-token', refreshToken);
};

const isUserAuthenticated = () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    return true;
  } else {
    return false;
  }
};

const clearToken = () => {
  const cookieManager = new Cookies();
  cookieManager.remove('token');
  cookieManager.remove('refresh-token');
};

export { isUserAuthenticated, getToken, setToken, clearToken, setRefreshToken, getRefreshToken };
