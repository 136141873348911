import { Cookies } from 'react-cookie';
import dayjs from 'dayjs';
import detectBrowserLanguage from 'detect-browser-language';
import store from '../redux/store';

export const reload = () => {
  return window.location.reload();
};

export const locale = () => {
  return new Cookies().get('locale');
};

export const defaultLocale = () => {
  return detectBrowserLanguage().indexOf('ar') !== -1 ? 'ar' : 'en';
};

export const changeLocale = (locale) => {
  new Cookies().set('locale', locale);
  reload();
};

export const clean = (obj) => {
  for (let prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
      delete obj[prop];
    }
  }

  return obj;
};

export const formatDate = (time) => {
  return dayjs(time).format('D MMMM YYYY');
};

export const formatTime = (time) => {
  return dayjs(time).format('h A');
};

export const formatDatetime = (time) => {
  return dayjs(time).format('D MMMM YYYY - h A');
};

export const getInitSelectedMatches = (selectedMatchIds, fetchedMatches) => {
  selectedMatchIds = selectedMatchIds.map((id) => parseInt(id));

  const selectedMatches = {};
  Object.values(fetchedMatches)
    .filter((m) => selectedMatchIds.indexOf(m.id) !== -1)
    .forEach((m) => {
      selectedMatches[m.id] = m;
    });

  return selectedMatches;
};

export const getTotalCoins = (values) => {
  return values.entry_fee * values.participants.max;
};

export const getEksabFees = (values) => {
  return getTotalCoins(values) * store.getState().generic.settings.eksab_share;
};

export const mergeFetched = (first, second) => {
  return {
    ...first,
    ...second.reduce((c, n) => {
      c[n.id] = n;
      return c;
    }, {}),
  };
};

export const getInitialBetValue = (bet, initialValue) => {
  if (bet.current_user_prediction) {
    return bet.current_user_prediction?.value;
  } else if (initialValue) {
    return initialValue.value;
  }

  return '';
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileInfo = (file) => {
  return {
    filename: file.name,
  };
};

export const getCookie = (key) => {
  return new Cookies().get(key);
};

export const setCookie = (key, value) => {
  new Cookies().set(key, value);
};

export const removeCookie = (key) => {
  new Cookies().remove(key);
};

export const truncate = (str = '', n, ellipsis = '...') =>
  str.length > n ? str.substr(0, n - 1) + ellipsis : str;

export const formatNationalIdScans = async (scans) => {
  let parsedScans = {};

  await Promise.all(
    ['front', 'back'].map(async (side) => {
      let file = scans[side];

      return file ? { side, file, base64: await toBase64(file) } : null;
    })
  ).then((parsed) => {
    parsed.forEach((item) => {
      if (item) {
        parsedScans[item.side] = { data: item.base64, ...getFileInfo(item.file) };
      }
    });
  });

  return parsedScans;
};

export const isElementInViewport = (el) => {
  if (!el) return false;

  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
};

export const ignoreErrors = (callback) => {
  try {
    callback();
  } catch (e) {}
};
