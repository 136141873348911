import React from 'react';
import RankingItem from './RankingItem';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Loader from '../Loader';

function RankingList(props) {
  const loadMore = () => props.onLoadMore();
  const hideRanking = !!props.hideRanking;
  const { action } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="ranking-list col-md-6">
          {props.items.map((item, i) => (
            <RankingItem item={item} hideRanking={hideRanking} key={i} action={action} />
          ))}

          {props.loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {props.paginator.hasMorePages() && (
                <Row>
                  <Col md={{ size: 4, offset: 4 }}>
                    <Button color="secondary btn-block btn-load-more" onClick={loadMore}>
                      <FormattedMessage id="Load More" />
                    </Button>
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}
        </div>
        {!!props.breakdown && <div className="col-md-5 offset-md-1">{props.breakdown}</div>}
      </div>
    </React.Fragment>
  );
}

export default RankingList;
