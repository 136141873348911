export const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';
export const SET_TUTORIAL_STATUS = 'SET_TUTORIAL_STATUS';
export const MARK_TUTORIAL_STEP_AS_DONE = 'MARK_TUTORIAL_STEP_AS_DONE';
export const UPDATE_SETTING_START = 'UPDATE_SETTING_START';
export const UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_FAILURE = 'UPDATE_SETTING_FAILURE';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const INCREASE_COINS = 'INCREASE_COINS';
export const DECREASE_COINS = 'DECREASE_COINS';
export const RECEIVE_CREATORS = 'RECEIVE_CREATORS';
export const RECEIVE_RECENT_NOTIFICATIONS = 'RECEIVE_RECENT_NOTIFICATIONS';
export const RECEIVE_NEW_NOTIFICATION_COUNT = 'RECEIVE_NEW_NOTIFICATION_COUNT';
export const RECEIVE_PACKAGES = 'RECEIVE_PACKAGES';
