import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import LeaveCompetitionConfirmationModal from './LeaveCompetitionConfirmationModal';
import JoinCompetitionConfirmationModal from './JoinCompetitionConfirmationModal';
import JoinCompetitionConfirmedModal from './JoinCompetitionConfirmedModal';
import { FormattedMessage } from 'react-intl';
import { CompetitionContext } from '../show';
import {
  COMPETITION_STATE_RUNNING,
  COMPETITION_STATE_UPCOMING,
  COMPETITION_TYPE_PREDICTIONS,
} from '../../../constants/generic';
import useJoinCompetition from '../../../hooks/useJoinCompetition';

import { InsufficientFundsModal } from './InsufficientFundsModal';
const qs = require('query-string');

const JoinCompetition = (props) => {
  const { competition } = props;
  const { setOpenJoiningModal, openJoiningModal } = useContext(CompetitionContext);
  const history = useHistory();
  const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(false);
  const [remainingToJoinCompetition, setRemainingToJoinCompetition] = useState();
  const openInsufficientFundsModal = ({ remaining }) => {
    setInsufficientFundsModalOpen(true);
    setRemainingToJoinCompetition(remaining);
  };

  const { canJoin, onJoinNow } = useJoinCompetition(competition, openInsufficientFundsModal);

  const [
    leaveCompetitionConfirmationModalIsOpen,
    setLeaveCompetitionConfirmationModalIsOpen,
  ] = useState(false);
  const [
    joinCompetitionConfirmationModalIsOpen,
    setJoinCompetitionConfirmationModalIsOpen,
  ] = useState(openJoiningModal);
  const [joinCompetitionConfirmedModalIsOpen, setJoinCompetitionConfirmedModalIsOpen] = useState(
    false
  );

  useEffect(() => {
    if (canJoin) setJoinCompetitionConfirmationModalIsOpen(true);
  }, [canJoin]);

  const onConfirmationModalToggle = () => {
    setJoinCompetitionConfirmedModalIsOpen(false);
  };

  useEffect(() => {
    !competition.joined && setJoinCompetitionConfirmationModalIsOpen(openJoiningModal);
  }, [openJoiningModal]);

  useEffect(() => {
    let pqs = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    if (!competition.joined && pqs['joining'] !== undefined) {
      setJoinCompetitionConfirmationModalIsOpen(true);
    }
  }, []);

  useEffect(() => {
    setOpenJoiningModal(joinCompetitionConfirmationModalIsOpen);
  }, [joinCompetitionConfirmationModalIsOpen]);

  const onJoinNewCompetitions = () => {
    history.push({
      pathname: competition.type === COMPETITION_TYPE_PREDICTIONS ? '/predictions' : '/fantasy',
    });
  };

  return (
    <React.Fragment>
      <InsufficientFundsModal
        remainingToJoin={remainingToJoinCompetition}
        open={insufficientFundsModalOpen}
        setOpen={setInsufficientFundsModalOpen}
      />
      {competition.state === COMPETITION_STATE_RUNNING && (
        <React.Fragment>
          <div className="my-3">
            <p>
              <span className="text-muted">
                <FormattedMessage id="competition-already-started" />
              </span>
            </p>
            <div className="competition-action competition-action-join">
              <button className="btn btn-primary btn-mw" onClick={onJoinNewCompetitions}>
                <FormattedMessage id="Join a different competition" />
              </button>
            </div>
          </div>
        </React.Fragment>
      )}

      {competition.state === COMPETITION_STATE_UPCOMING && (
        <React.Fragment>
          {!competition.joined &&
            competition.participants.max - competition.participants.current > 0 && (
              <div className="my-3">
                <p>
                  <FormattedMessage id="Ready to join?" /> <br />
                  <span className="text-muted">
                    <FormattedMessage id="Enter to get a chance to win awesome prizes!" />
                  </span>
                </p>
                <div className="competition-action competition-action-join">
                  <button className="btn btn-primary btn-mw" onClick={() => onJoinNow()}>
                    <FormattedMessage id="Join Now" />
                  </button>
                </div>
              </div>
            )}

          {competition.joined && (
            <div className="my-3">
              <p>
                <FormattedMessage id="Changed your mind" /> <br />
                <span className="text-muted">
                  <FormattedMessage id="You still can withdraw your entry fee" />
                </span>
              </p>
              <div className="competition-action competition-action-withdraw">
                <button
                  className="btn btn-danger btn-mw"
                  onClick={() => setLeaveCompetitionConfirmationModalIsOpen(true)}
                >
                  <FormattedMessage id="Withdraw & Leave" />
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      <LeaveCompetitionConfirmationModal
        competition={competition}
        isOpen={leaveCompetitionConfirmationModalIsOpen}
        onToggle={() => setLeaveCompetitionConfirmationModalIsOpen(false)}
      />

      <JoinCompetitionConfirmationModal
        competition={competition}
        isOpen={joinCompetitionConfirmationModalIsOpen}
        onToggle={(status) => {
          setJoinCompetitionConfirmationModalIsOpen(false);
          status && setJoinCompetitionConfirmedModalIsOpen(true);
        }}
      />

      <JoinCompetitionConfirmedModal
        competition={competition}
        isOpen={joinCompetitionConfirmedModalIsOpen}
        onToggle={() => onConfirmationModalToggle()}
      />
    </React.Fragment>
  );
};

export default JoinCompetition;
