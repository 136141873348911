// @vendors
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { findIndex, isEqual, differenceWith } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'reactstrap';
// @components
import Text from '../Text';
import Box from '../Box';
import MemberCard from './MemberCard';

const RenderedMemberCard = ({ value, item, onChange, lastItem }) => (
  <MemberCard
    id={item?.id}
    name={item?.name}
    role={item?.role || 1}
    image={item?.photo?.thumb}
    isAdded={findIndex(value, { id: item?.id }) !== -1}
    onToggle={() => {
      const newValue = [...value];
      const indexInValue = findIndex(value, { id: item?.id });
      if (indexInValue !== -1) {
        // Remove item
        newValue.splice(indexInValue, 1);
      } else {
        // Add item
        newValue.push(item);
      }
      onChange(newValue);
    }}
    borderBottom={lastItem ? '' : '1px solid rgba(175, 173, 178, 0.30)'}
  />
);

const GroupMembersSelector = ({ items, value, loadMore, hasMore, onChange, onSearch, ...rest }) => {
  const intl = useIntl();
  return (
    <Box
      width="100%"
      bg="#f4f6fc"
      p={{ xs: '0', md: '0.625rem 1.125rem' }}
      display="grid"
      gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gridColumnGap="1.5rem"
      border={{ xs: 'none', md: '1px solid rgba(175, 173, 178, 0.5)' }}
      borderRadius="0.375rem"
      {...rest}
    >
      <Box
        width="100%"
        height="100%"
        bg="#fff"
        display="flex"
        flexDirection="column"
        border="1px solid rgba(175, 173, 178, 0.33)"
        borderRadius="0.375rem"
        py="0.5rem"
        px={{ xs: '0.25rem', md: '0.875rem' }}
        minHeight="37vh"
        maxHeight="37vh"
        overflowY="auto"
      >
        <Input
          className="mb-1"
          type="text"
          placeholder={intl.formatMessage({
            id: 'Search with name or email',
          })}
          onChange={onSearch}
        />
        <Text
          fontSize="0.75rem"
          fontWeight="bold"
          color="#5ad37c"
          cursor="pointer"
          my="0.25rem"
          mis="auto"
          textDecoration="underline"
          onClick={() => {
            const newValue = [...value];
            const unAddedItems = differenceWith(items, value, isEqual);
            onChange([...new Set([...newValue, ...unAddedItems])]);
          }}
        >
          <FormattedMessage id="Add All" />
        </Text>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          initialLoad={false}
          useWindow={false}
        >
          {differenceWith(items, value, isEqual)?.map((item, index) => (
            <RenderedMemberCard
              key={`group_member_${item?.id}`}
              item={item}
              value={value}
              onChange={onChange}
              lastItem={index === differenceWith(items, value, isEqual)?.length - 1}
            />
          ))}
        </InfiniteScroll>
        {hasMore && (
          <button className="btn btn-primary btn-sm" onClick={loadMore}>
            <FormattedMessage id="Load More" />
          </button>
        )}
      </Box>
      <Box
        width="100%"
        height="100%"
        bg="#fff"
        display="flex"
        flexDirection="column"
        border="1px solid rgba(175, 173, 178, 0.33)"
        borderRadius="0.375rem"
        py="0.5rem"
        px={{ xs: '0.25rem', md: '0.875rem' }}
        minHeight="37vh"
        maxHeight="37vh"
        overflowY="auto"
        mt={{ xs: '1rem', md: '0' }}
      >
        <Text
          fontSize="0.75rem"
          fontWeight="bold"
          color="#fc2c5d"
          cursor="pointer"
          my="0.25rem"
          mis="auto"
          textDecoration="underline"
          onClick={() => {
            onChange([]);
          }}
        >
          <FormattedMessage id="Remove All" />
        </Text>
        {value?.map((item, index) => (
          <RenderedMemberCard
            key={`group_member_${item?.id}`}
            item={item}
            value={value}
            onChange={onChange}
            lastItem={index === value?.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
};

GroupMembersSelector.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      email: PropTypes.string,
      photo: PropTypes.shape({
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      email: PropTypes.string,
      photo: PropTypes.shape({
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    })
  ),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

GroupMembersSelector.defaultProps = {
  items: [],
  loadMore: () => {},
  hasMore: false,
  onChange: () => {},
  onSearch: () => {},
};

export default GroupMembersSelector;
