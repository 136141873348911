import * as actionTypes from '../../constants/action-types/store';

export const receiveProducts = (products) => ({
  type: actionTypes.RECEIVE_PRODUCTS,
  payload: { products },
});

export const showPurchaseModal = (product) => ({
  type: actionTypes.SHOW_PURCHASE_MODAL,
  payload: { product },
});

export const hidePurchaseModal = () => ({
  type: actionTypes.HIDE_PURCHASE_MODAL,
});
