import ReactPixel from 'react-facebook-pixel';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Form, FormGroup, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { clearReferrer, registerUsingEmail } from '../../redux/auth/thunks';
import BarSeparator from '../../components/BarSeparator';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import PasswordInput from '../../components/form/PasswordInput';
import EmailInput from '../../components/form/EmailInput';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import { toast } from 'react-toastify';
import useRedirectAfterAuth from '../../hooks/useRedirectAfterAuth';
import config from '../../config';
import { locale } from '../../helpers/generic';
import ValidationErrors from '../../components/ValidationErrors';
import ReCAPTCHA from 'react-google-recaptcha';
// import FacebookLogin from '../../components/FacebookLogin';
import { setReferrer } from '../../redux/auth/actions';
import SkipToLandingPage from '../../components/SkipToLandingPage';
import useTrackers from '../../hooks/useTrackers';
import WelcomeToEksabMessage from '../../components/WelcomeToEksabMessage';

const RegisterPage = (props) => {
  const { referrer } = props;
  const trackers = useTrackers();
  const initialValues = { name: '', email: '', password: '', recaptcha: null, referrer };
  const redirect = useRedirectAfterAuth();
  const currentLocale = locale();
  const [errors, setErrors] = useState(null);
  const recaptchaEl = useRef(null);

  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Register' }));
    trackers.track('Page View', { page: 'Register' });
  }, []);

  const onSubmit = (values, formik) => {
    setErrors(null);

    trackers.track('Register.Confirm');

    props
      .registerUsingEmail(values)
      .then((attempt) => {
        formik.resetForm({});

        toast.success(intl.formatMessage({ id: 'account_created' }));

        trackers.identify(attempt.user);

        props.clearReferrer();

        trackers.track('Register');
        trackers.track('New User');
        ReactPixel.track('fb_mobile_complete_registration');

        return redirect(attempt);
      })
      .catch((r) => {
        recaptchaEl.current.reset();
        setErrors(r.response.data.errors);
        formik.setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    recaptcha: Yup.string().required(),
  });

  return (
    <AuthPageWrapper
      className="page-register"
      description={intl.formatMessage({ id: 'meta.description.register' })}
    >
      <WelcomeToEksabMessage />

      {/*<FacebookLogin />*/}

      {/*<BarSeparator>*/}
      {/*  <FormattedMessage id="Or" />*/}
      {/*</BarSeparator>*/}

      <ValidationErrors errors={errors} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => onSubmit(values, formik)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <FormGroup>
                <CustomInput
                  icon={<img src={require('../../assets/images/icon-user.svg')} alt="" />}
                  type="text"
                  name="name"
                  id="name"
                  placeholder={intl.formatMessage({ id: 'Name' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  invalid={errors.name && touched.name}
                />
              </FormGroup>

              <FormGroup>
                <EmailInput
                  name="email"
                  id="email"
                  placeholder={intl.formatMessage({ id: 'Email address' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  invalid={errors.email && touched.email}
                />
              </FormGroup>

              <FormGroup>
                <PasswordInput
                  name="password"
                  id="password"
                  placeholder={intl.formatMessage({ id: 'Password' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  invalid={errors.password && touched.password}
                  autocompelete="new-password"
                  toggle={true}
                />
              </FormGroup>
            </div>

            <div className="mb-3 w-100">
              <ReCAPTCHA
                sitekey={config.recaptcha.siteKey}
                onChange={(token) => setFieldValue('recaptcha', token)}
                hl={currentLocale}
                ref={recaptchaEl}
              />
              {errors.recaptcha && touched.recaptcha && (
                <p className="text-danger mb-0">{intl.formatMessage({ id: 'recaptcha-error' })}</p>
              )}
            </div>

            <Row className="justify-content-between align-items-center">
              <Col className="mt-1" md="6">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="btn-lg w-100"
                >
                  <FormattedMessage id="submit" />
                  {isSubmitting && <Spinner />}
                </Button>
              </Col>
              <Col className="mt-1" md="6">
                <p className="mb-0">
                  <FormattedMessage id="Have account?" /> <br />
                  <Link to="/login">
                    <FormattedMessage id="Login Now" />
                  </Link>
                </p>
              </Col>
            </Row>

            <BarSeparator>
              <FormattedMessage id="Or" />
            </BarSeparator>

            <SkipToLandingPage className="mt-3" />
          </Form>
        )}
      </Formik>
    </AuthPageWrapper>
  );
};

const mapStateToProps = (state) => {
  const { registration, referrer } = state.auth;
  return { registration, referrer };
};

export default connect(mapStateToProps, { registerUsingEmail, setReferrer, clearReferrer })(
  RegisterPage
);
