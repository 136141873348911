import * as actionTypes from '../../constants/action-types/matches';

export const fetchMatchesStart = () => ({
  type: actionTypes.FETCH_MATCHES_START,
});

export const fetchMatchesSuccess = (matches, dates) => ({
  type: actionTypes.FETCH_MATCHES_SUCCESS,
  payload: { matches, dates },
});

export const fetchMatchesFailure = () => ({
  type: actionTypes.FETCH_MATCHES_FAILURE,
});

export const selectMatchStart = () => ({
  type: actionTypes.SELECT_MATCH_START,
});

export const selectMatchSuccess = (matchId) => ({
  type: actionTypes.SELECT_MATCH_SUCCESS,
  payload: { matchId },
});

export const selectMatchFailure = () => ({
  type: actionTypes.SELECT_MATCH_FAILURE,
});

export const unselectMatch = () => ({
  type: actionTypes.UNSELECT_MATCH,
});

export const receiveMatches = (matches) => ({
  type: actionTypes.RECEIVE_MATCHES,
  payload: { matches },
});

export const receiveOfficialMatches = (matches) => ({
  type: actionTypes.RECEIVE_OFFICIAL_MATCHES,
  payload: { matches },
});

export const receiveBets = (matchId, bets) => ({
  type: actionTypes.RECEIVE_BETS,
  payload: { matchId, bets },
});

export const filterMatchesByState = (stateFilter) => ({
  type: actionTypes.FILTER_MATCHES_BY_STATE,
  payload: { stateFilter },
});

export const saveMultiplePredictionsStart = (matchId) => ({
  type: actionTypes.SAVE_MULTIPLE_PREDICTIONS_START,
  payload: { matchId },
});

export const saveMultiplePredictionsSuccess = (matchId) => ({
  type: actionTypes.SAVE_MULTIPLE_PREDICTIONS_SUCCESS,
  payload: { matchId },
});

export const saveMultiplePredictionsFailure = (matchId) => ({
  type: actionTypes.SAVE_MULTIPLE_PREDICTIONS_FAILURE,
  payload: { matchId },
});
