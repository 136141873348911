import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { useIntl } from 'react-intl';
import BetQuestion from './BetQuestion';
import { getInitialBetValue } from '../../helpers/generic';
import PointsWon from './PointsWon';

const SelectListBet = (props) => {
  const intl = useIntl();
  const { bet, initialValue } = props;
  const [value, setValue] = useState(bet.current_user_prediction?.value || '');

  useEffect(() => {
    setValue(getInitialBetValue(bet, initialValue));
  }, []);

  const onChange = (value) => {
    setValue(value);
    props.onChange({ bet_id: bet.id, value });
  };

  const disabled = !bet.active;
  const points = bet.current_user_prediction?.points?.amount;

  return (
    <div className="bet bet-select-list">
      <BetQuestion bet={bet} />

      <div className="options">
        <Input
          type="select"
          bsSize="sm"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
        >
          <option value="">:: {intl.formatMessage({ id: 'Select Option' })} ::</option>
          {bet.question.answers.map((answer, i) => (
            <option value={answer.value} key={i}>
              {answer.label}
            </option>
          ))}
        </Input>
      </div>

      <PointsWon points={points} />
    </div>
  );
};

export default SelectListBet;
