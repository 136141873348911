import {
  fetchSearchResultsStart,
  fetchSearchResultsSuccess,
  fetchSearchResultsFailure,
  selectSearchResultStart,
  selectSearchResultSuccess,
  selectSearchResultFailure,
} from './actions';
import { receiveMatches } from '../matches/actions';
import api from '../../helpers/api';
import Paginator from '../../helpers/paginator';

export function fetchSearchResults(filters) {
  return async function (dispatch, getState) {
    dispatch(fetchSearchResultsStart());

    const page = getState().search.results.paginator.currentPage + 1;
    await api
      .fetchMatches({ ...filters, page })
      .then((response) => response.data)
      .then((json) => {
        dispatch(receiveMatches(json.data));
        dispatch(fetchSearchResultsSuccess(json.data, new Paginator(json.meta)));
      })
      .catch(() => dispatch(fetchSearchResultsFailure()));
  };
}

export function selectSearchResult(matchId) {
  return async function (dispatch) {
    dispatch(selectSearchResultStart());

    api
      .fetchMatch(matchId)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveMatches([json]));
        dispatch(selectSearchResultSuccess(matchId));
      })
      .catch(() => dispatch(selectSearchResultFailure(matchId)));
  };
}
