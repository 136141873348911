import {useLocation} from "react-router";
import {getCookie} from "../helpers/generic";

const qs = require('query-string');

export default function useReferrer() {
    const location = useLocation();
    const pqs = qs.parse(location.search, {ignoreQueryPrefix: true});
    const {referrer: queryReferrer} = pqs;

    const cookieReferrer = getCookie('referrer');

    return queryReferrer || cookieReferrer;
}