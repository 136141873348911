import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tag from '../Tag';

const PointsToWin = ({ points }) => {
  return (
    points !== undefined && (
      <React.Fragment>
        <Tag>
          <FormattedMessage id="To win" /> {points} <FormattedMessage id="Pts" />
        </Tag>
      </React.Fragment>
    )
  );
};
export default PointsToWin;
