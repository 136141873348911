import styled from 'styled-components';
import Box from '../../../components/Box';

export const SearchBoxContainer = styled(Box)`
  .input-group-text {
    background-color: #e9ecef;
  }
  input {
    border-inline-start: 0;
    background-color: #e9ecef;
    padding-inline-start: 0;
    :focus {
      background-color: #e9ecef;
      box-shadow: none;
      border-color: #ced4da;
    }
  }
`;
