import styled from 'styled-components';

export const CloseIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`;
