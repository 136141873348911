export default function useMoEngage() {
  const track = (event, attributes = {}) => {
    window.Moengage.track_event(event, attributes);
  };

  const identify = (user) => {
    window.Moengage.add_unique_user_id(user.id);
    window.Moengage.add_user_name(user.name);
    window.Moengage.add_email(user.email);
    window.Moengage.add_user_attribute('friendCount', user.friend_count);
    window.Moengage.add_user_attribute('coins', user.coins);
  };

  return { track, identify };
}
