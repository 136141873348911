import React, { useEffect, useState } from 'react';
import withMediaQuery from '../hoc/withMediaQuery';
import Prediction from '../components/Prediction';
import { connect } from 'react-redux';
import { selectMatch } from '../redux/predictions/thunks';
import Loader from '../components/Loader';
import CompetitionPrizes from '../components/competitions/CompetitionPrizes';
import { useIntl } from 'react-intl';

function PredictionPage(props) {
  const { matchId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const match = props.loadedMatches[matchId];
  const intl = useIntl();

  useEffect(() => {
    props.selectMatch(matchId).then(() => setLoading(false));
    props.setDocumentTitle(intl.formatMessage({ id: 'Set Your Prediction' }));
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page page-prediction">
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="row">
                <div className="col-md-4 offset-md-4">
                  <Prediction matchId={matchId} />
                  <div className="card-competition-prizes">
                    <div className="card-header">
                      <h2 className="card-title">
                        {match.season.competition.logo && (
                          <img
                            src={match.season.competition.logo.original}
                            alt={`${match.season.competition.name} competition logo`}
                          />
                        )}
                        {match.season.competition.name}
                      </h2>
                    </div>
                    <div className="card-body">
                      <CompetitionPrizes prizes={match.season.competition.prizes} />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { loadedMatches } = state.matches;
  return { loadedMatches };
};

export default connect(mapStateToProps, { selectMatch })(withMediaQuery(PredictionPage));
