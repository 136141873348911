import * as actionTypes from '../../constants/action-types/auth';

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = (payload) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});

export const registerStart = (data) => ({
  type: actionTypes.REGISTER_START,
  payload: data,
});

export const registerSuccess = (message) => ({
  type: actionTypes.REGISTER_SUCCESS,
  payload: { message },
});

export const registerFailure = (message, errors) => ({
  type: actionTypes.REGISTER_FAILURE,
  payload: { message, errors },
});

export const forgetPasswordStart = () => ({
  type: actionTypes.FORGET_PASSWORD_START,
});

export const forgetPasswordSuccess = (payload) => ({
  type: actionTypes.FORGET_PASSWORD_SUCCESS,
  payload,
});

export const forgetPasswordFailure = (message, errors) => ({
  type: actionTypes.FORGET_PASSWORD_FAILURE,
  payload: { message, errors },
});

export const resetPasswordStart = (data) => ({
  type: actionTypes.RESET_PASSWORD_START,
  payload: data,
});

export const resetPasswordSuccess = (message) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: { message },
});

export const resetPasswordFailure = (message, errors) => ({
  type: actionTypes.RESET_PASSWORD_FAILURE,
  payload: { message, errors },
});

export const fetchMeSuccess = (user) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  payload: user,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const setAccessToken = (token) => ({
  type: actionTypes.SET_ACCESS_TOKEN,
  payload: { token },
});

export const setReferrer = (referrer) => ({
  type: actionTypes.SET_REFERRER,
  payload: { referrer },
});

export const clearReferrer = () => ({
  type: actionTypes.CLEAR_REFERRER,
});
