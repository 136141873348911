import React, { useEffect } from 'react';
import useTrackers from "../../../hooks/useTrackers";

const FantasyCompetitionRulesEn = ({ competition }) => {
  const trackers = useTrackers();

  useEffect(() => {
    trackers.track('Page View', {
      page: `Competition`,
      type: 'Fantasy',
      name: competition.name,
      tab: 'Rules',
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-rules">
        <p>
          In fantasy contests, participants will setup a team and gain points based on player
          performance.
        </p>

        <p>
          Winners will be judged and ranked by the total number of points accumulated by each
          participant (scoring below).
        </p>

        <div className="rule-section">
          <h3 className="rule-header">Scoring</h3>

          <div className="row">
            <div className="col-md-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="2">Points System</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>For playing up to 60 minutes</td>
                    <td>+1 Pts</td>
                  </tr>
                  <tr>
                    <td>For playing 60 minutes or more</td>
                    <td>+2 Pts</td>
                  </tr>
                  <tr>
                    <td>For each goal scored by a goalkeeper or defender</td>
                    <td>+6 Pts</td>
                  </tr>
                  <tr>
                    <td>For each goal scored by a midfielder</td>
                    <td>+5 Pts</td>
                  </tr>
                  <tr>
                    <td>For each goal scored by a forward</td>
                    <td>+4 Pts</td>
                  </tr>
                  <tr>
                    <td>For each goal assist</td>
                    <td>+3 Pts</td>
                  </tr>
                  <tr>
                    <td>For a clean sheet by a goalkeeper or defender (played 60 minutes or more)</td>
                    <td>+4 Pts</td>
                  </tr>
                  <tr>
                    <td>For a clean sheet by a midfielder (played 60 minutes or more)</td>
                    <td>+1 Pts</td>
                  </tr>
                  <tr>
                    <td>For each penalty save</td>
                    <td>+5 Pts</td>
                  </tr>
                  <tr>
                    <td>For each penalty miss</td>
                    <td>-2 Pts</td>
                  </tr>
                  <tr>
                    <td>For each yellow card</td>
                    <td>-1 Pts</td>
                  </tr>
                  <tr>
                    <td>For each red card</td>
                    <td>-3 Pts</td>
                  </tr>
                  <tr>
                    <td>For each own goal</td>
                    <td>-2 Pts</td>
                  </tr>
                  <tr>
                    <td>Successful pass</td>
                    <td>+0.02 Pts</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">General Rules</h3>

          <ul>
            <li>A user may not have more than one Eksab account.</li>
            <li>
              In order to receive your prize you will need to provide proof of identity in the form
              of government issued identification.
            </li>
            <li>
              You may change your team setup at any time until the start of the first game of the
              competition.
            </li>
            <li>You must join the competition before the start of the first match.</li>
            <li>
              You may withdraw your entry at any time before the start of the first match within a
              competition.
            </li>
          </ul>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">Prize Distribution</h3>

          <ul>
            <li>Prizes will be awarded as shown on the Overview section of the contest.</li>
            <li>
              If more than one person shares a position, then the prize value will be shared between
              the winners. For example, if two people tie for 1st place, there will be no 2nd place.
              There will be two 1st place winners and one 3rd place winner.
            </li>
            <li>
              If there are two 1st place winners, then the two placers will split the prize value of
              1st place and 2nd place.
            </li>
            <li>
              Prizes will be distributed up to one month after the completion of a competition.
            </li>
            <li>
              If the prize for 1st place is 6,000 and the prize for 2nd place is 3,000 and the prize
              for 3rd place is 1,000, then if there are two first place winners, they will share the
              prize for the 1st place and 2nd place, 50/50 [i.e.( 6,000 + 3,000)/2] which means that
              the 1st 2 people in the rankings will get 4,500 there will be no 2nd place. The person
              in 3rd place will get 1,000.
            </li>
          </ul>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">Score Update and Competition Closing</h3>

          <p>
            Eksab uses an automated system to populate scores based on live updates. In some
            instances there will be updates in reporting statistics. Before distributing prizes,
            Eksab will verify the scores with the users then distribute the prize shortly after
            verification.
          </p>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">Rankings and Points Calculations</h3>

          <p>
            Due to the large number of users, rankings may take up to 24 hours to finish
            calculating. We will always do our best to ensure that the points and rankings are
            updated as quickly and accurately as possible.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FantasyCompetitionRulesEn;
