import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { COMPETITION_TYPE_PREDICTIONS } from '../../../constants/generic';

const JoinCompetitionConfirmedModal = (props) => {
  const { competition } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const history = useHistory();

  const onShowMatches = () => {
    setIsOpen(false);

    if (history.location.pathname.indexOf('matches') === -1) {
      history.push({
        pathname: `/competitions/${competition.id}/matches`,
      });
    }
  };

  const onSeeMoreCompetitions = () => {
    return history.push(
      competition.type === COMPETITION_TYPE_PREDICTIONS ? '/predictions' : '/fantasy'
    );
  };

  return (
    <React.Fragment>
      <Modal
        id="modal-join-competition-confirmation"
        className="modal-confirmation text-center"
        isOpen={isOpen}
        centered={true}
      >
        <ModalBody>
          <FaCheck className="icon-confirmed mb-3" />

          <p className="text-muted">
            <FormattedMessage id="join-competition-confirmed" />
          </p>

          <h3>
            <b>{competition.name}</b>
          </h3>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary btn-block " onClick={onShowMatches}>
            <FormattedMessage id="Show matches" />
          </button>

          <button className="btn btn-link text-muted" onClick={() => onSeeMoreCompetitions()}>
            <FormattedMessage id="See more competitions" />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default JoinCompetitionConfirmedModal;
