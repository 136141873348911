import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';

const qs = require('query-string');

export default function useCCPaymentStatus() {
  const intl = useIntl();
  const location = useLocation();

  const statuses = {
    'cc-payment-succeeded': {
      status: 'success',
      message: intl.formatMessage({ id: 'cc-payment-succeeded' }),
    },
    'cc-payment-failed': {
      status: 'failed',
      message: intl.formatMessage({ id: 'cc-payment-failed' }),
    },
  };

  const pqs = qs.parse(location.search);

  const getStatus = () => {
    let keys = Object.keys(statuses);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (pqs[key] !== undefined) {
        const { status, message } = statuses[key];

        return { status, message };
      }
    }
  };

  return { getStatus };
}
