import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Spinner, Alert } from 'reactstrap';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import withRedirect from '../../hoc/withRedirect';
import { updatePhoneNumber } from '../../redux/profile/thunks';
import FormTitle from '../../components/form/FormTitle';
import FormCaption from '../../components/form/FormCaption';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import OtpInput from '../../components/form/OtpInput';
import { toast } from 'react-toastify';
import SkipToLandingPage from '../../components/SkipToLandingPage';
import useTrackers from '../../hooks/useTrackers';
import useStateAwareRedirect from '../../hooks/useStateAwareRedirect';

const VerifyPhoneNumberPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const trackers = useTrackers();
  const initialValues = { phone_number: '', otp: '' };
  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required(),
    otp: Yup.string().required(),
  });
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [throttled, setThrottled] = useState(false);
  const redirect = useStateAwareRedirect();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Verify Phone Number' }));
    trackers.track('Page View', { page: 'Verify Phone Number' });
  }, []);

  const onSubmit = (values, setSubmitting) => {
    setOtpError(null);
    dispatch(updatePhoneNumber(values))
      .then(() => {
        toast.success(intl.formatMessage({ id: 'phone_number_updated' }));
        redirect();
      })
      .catch((e) => {
        if (e.response.status === 429) {
          setThrottled(true);
        } else {
          setThrottled(false);
          setOtpError(intl.formatMessage({ id: 'otp-error' }));
        }

        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page page-auth page-verify-phone-number">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <img
                src={require('../../assets/images/illustration-signup.svg')}
                className="illustration"
                alt=""
              />
            </Col>
            <Col md={{ size: 4, offset: 1 }}>
              <FormTitle>
                <FormattedMessage id="Verify your phone number" />
              </FormTitle>

              <FormCaption>
                <FormattedMessage id="verify_phone_number_steps" />
              </FormCaption>

              <Alert color="warning" isOpen={true} className="mt-3">
                <FormattedMessage id="phone_number_verification_is_required" />
              </Alert>

              {throttled && (
                <Alert color="danger" isOpen={true} className="mt-3">
                  {intl.formatMessage({ id: 'otp-throttle' })}
                </Alert>
              )}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
              >
                {({ setFieldValue, handleSubmit, isSubmitting }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="my-3">
                        <PhoneNumberInput
                          onSent={() => {
                            setOtpSent(true);
                            setThrottled(false);
                          }}
                          onChange={(v) => setFieldValue('phone_number', v)}
                          onThrottle={() => setThrottled(true)}
                        />
                      </FormGroup>

                      <div dir="ltr">
                        <FormGroup>
                          <OtpInput onEntered={(v) => setFieldValue('otp', v)} />
                        </FormGroup>
                      </div>

                      <Button
                        type="submit"
                        disabled={!otpSent || isSubmitting}
                        color="primary"
                        className="btn-lg mt-1"
                      >
                        <FormattedMessage id="submit" />
                        {isSubmitting && <Spinner />}
                      </Button>
                      {otpError && <p className="mt-1 mb-0 text-danger">{otpError}</p>}
                    </Form>
                  );
                }}
              </Formik>

              <hr />

              <SkipToLandingPage className="mt-3" redirectTo={redirect({ noRedirect: true })} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRedirect(VerifyPhoneNumberPage);
