import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import MetaTags from 'react-meta-tags';

const AuthPageWrapper = ({ children, description, className, showLogo = true }) => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta name="description" content={description} />
      </MetaTags>

      <div className={`page page-auth ${className}`}>
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <img
                src={require('../assets/images/illustration-signup.svg')}
                className="illustration"
                alt=""
              />
            </Col>

            <Col md={{ size: 4, offset: 1 }}>
              {showLogo && (
                <div className="logo-wrapper">
                  <img
                    src={require('../assets/images/logo.svg')}
                    className="logo"
                    alt="site logo"
                  />
                </div>
              )}

              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuthPageWrapper;
