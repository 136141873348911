import React, { useEffect, useState } from 'react';
import { Col, Row, CustomInput, Button } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { locale, changeLocale } from '../helpers/generic';
import { Cookies } from 'react-cookie';
import { isUserAuthenticated } from '../helpers/auth';
import { Redirect } from 'react-router';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

function OnboardingPage(props) {
  const intl = useIntl();
  useEffect(() => {
    new Cookies().set('onboarding-viewed', true);

    props.setDocumentTitle(intl.formatMessage({ id: 'Onboarding' }));
  }, []);

  const [selectedSlide, setSelectedSlide] = useState(0);
  const [currentLocale, setCurrentLocale] = useState(locale());

  const onChangeLocale = (locale) => {
    setCurrentLocale(locale);
    return changeLocale(locale);
  };

  const slides = [
    {
      title: 'Prediction Competitions',
      description: `
                Eksab offers a variety of competitions which include matches 
                from the English Premier League, Egyptian Premier League, La Liga, Serie A, 
                French League 1, Bundesliga, World Cup, Europa League, UEFA Champions League, 
                AFCON and many more! 
            `,
      image: require('../assets/images/onboarding/slide1.png'),
    },
    {
      title: 'Predict football matches ',
      description: `
                After joining a competition, your goal is to accurately predict the outcome of as many match events as possible. 
                Each correct prediction will result in additional points collected.
            `,
      image: require('../assets/images/onboarding/slide2.png'),
    },
    {
      title: 'Climb up the rankings and win prizes',
      description: `
                Each competition has its own rankings page. Top users will be able to redeem their points for prizes. 
                Eksab’s prizes range from football jerseys and smartphones to fully-paid trips to 
                watch your favorite teams play in Europe! Join the fun now and you could be the next winner! 
            `,
      image: require('../assets/images/onboarding/slide3.png'),
    },
  ];

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <div id="onboarding">
        <div className="languages">
          <h2>Language</h2>
          <div className="language-list">
            <img src={require('../assets/images/united-kingdom.svg')} alt="united kingdom flag" />
            <CustomInput
              type="switch"
              id="switch"
              checked={currentLocale === 'ar'}
              onChange={(e) => {
                const locale = e.target.checked === false ? 'en' : 'ar';
                onChangeLocale(locale);
              }}
            />
            <img src={require('../assets/images/egypt.svg')} alt="egyptian flag" />
          </div>
        </div>

        <button
          className="skip btn btn-link p-0"
          onClick={() => {
            props.history.push(isUserAuthenticated() ? DEFAULT_LANDING_PAGE : '/login');
          }}
        >
          <FormattedMessage id="skip" />
        </button>

        <MdKeyboardArrowLeft
          className={`arrow arrow-prev ${selectedSlide === 0 && 'hidden'}`}
          onClick={() => setSelectedSlide(selectedSlide - 1)}
        />

        <Carousel
          selectedItem={selectedSlide}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
        >
          {slides.map((slide, index) => (
            <div className={`slide-wrapper slide-${index + 1}`} key={index}>
              <Row noGutters={true}>
                <Col md={5}>
                  <div className="content">
                    <div
                      className="img-wrapper"
                      style={{ backgroundImage: `url(${slide.image})` }}
                    />

                    <div className="inner-wrapper">
                      <h1 className="title">{slide.title}</h1>
                      <p className="description">{slide.description}</p>

                      {index + 1 === slides.length ? (
                        <Button color="primary" onClick={() => props.history.push('/login')}>
                          <FormattedMessage id="Play & Win" />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col className="col-img" md={7}>
                  <div className="img-wrapper">
                    <img src={slide.image} alt={slide.title} />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>

        <MdKeyboardArrowRight
          className={`arrow arrow-next ${selectedSlide === slides.length - 1 && 'hidden'}`}
          onClick={() => setSelectedSlide(selectedSlide + 1)}
        />
      </div>
    </React.Fragment>
  );
}

export default OnboardingPage;
