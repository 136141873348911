import React from 'react';
import { FormattedMessage } from 'react-intl';

function Ad(props) {
  return (
    <React.Fragment>
      <div className="ad">
        <h2 className="title">{props.title}</h2>
        <p className="description">{props.body}</p>

        <div className="footer">
          <a
            href="https://www.facebook.com/groups/eksabapp"
            className="btn btn-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="Join Now" />
          </a>
          <img src={require('../assets/images/ad-illustration.svg')} alt="" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Ad;
