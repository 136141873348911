import { selectMatchFailure, selectMatchSuccess, selectMatchStart } from './actions';
import api from '../../helpers/api';
import { receiveMatches } from '../matches/actions';

export function selectMatch(matchId) {
  return async function (dispatch) {
    dispatch(selectMatchStart());

    await api
      .fetchMatch(matchId)
      .then((response) => response.data.data)
      .then((json) => {
        dispatch(receiveMatches([json]));
        dispatch(selectMatchSuccess(matchId));
      })
      .catch(() => dispatch(selectMatchFailure(matchId)));
  };
}
