import React, { useEffect } from 'react';
import CompetitionLists from '../components/competitions/CompetitionLists';
import { useIntl } from 'react-intl';
import MetaTags from 'react-meta-tags';
import useTrackers from "../hooks/useTrackers";

const PredictionsPage = (props) => {
  const trackers = useTrackers();
  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Predictions' }));

      trackers.track('Page View', { page: 'Lobby', type: 'predictions' });
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'meta.description.competitions-predictions' })}
        />
      </MetaTags>

      <CompetitionLists type="predictions" />
    </React.Fragment>
  );
};

export default PredictionsPage;
