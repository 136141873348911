import React from 'react';
import PlayerList from '../../../components/players/PlayerList';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import withMediaQuery from '../../../hoc/withMediaQuery';
import { receiveCompetitionRankingBreakdown } from '../../../redux/competitions/actions';
import closeIcon from '../../../assets/images/icon-close.svg';

const CompetitionRankingBreakdown = ({ isMobile }) => {
  const dispatch = useDispatch();
  const breakdown = useSelector((s) => s.competitions.competition.ranking.breakdown);

  return (
    <React.Fragment>
      <Card isMobile={isMobile}>
        {isMobile && (
          <CloseButton
            src={closeIcon}
            onClick={() => {
              dispatch(receiveCompetitionRankingBreakdown(null));
            }}
          />
        )}
        <User>
          {breakdown.user.photo && <UserImage src={breakdown.user.photo.thumb} />}
          <UserName>{breakdown.user.name}</UserName>
        </User>

        <Stats>
          <Stat>
            <StatLabel>
              <FormattedMessage id="Ranking" />:
            </StatLabel>
            <StatValue>{breakdown.user.ranking}</StatValue>
          </Stat>

          <Stat>
            <StatLabel>
              <FormattedMessage id="Points" />:
            </StatLabel>
            <StatValue>{breakdown.user.points}</StatValue>
          </Stat>

          <Stat>
            <StatLabel>
              <FormattedMessage id="Formation" />:
            </StatLabel>
            <StatValue>{breakdown.formation}</StatValue>
          </Stat>
        </Stats>

        <PlayerList players={breakdown.players} showTeam={true} />
      </Card>
      {isMobile && <MobileOverlay />}
    </React.Fragment>
  );
};

const CloseButton = styled.img`
  width: 28px;
  height: 28px;
  position: fixed;
  top: 10px;
  right: 4px;
  background-color: #d20b14;
  cursor: pointer;
  border-radius: 100px;
  padding: 3px;
`;

const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 13;
  content: '';
`;

const Card = styled.div`
  padding: 0.5rem;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  ${({ isMobile }) =>
    isMobile &&
    `
      z-index: 14;
      position: fixed;
      top: 15px;
      left: 7.5px;
      width: Calc(100% - 15px);
      height: Calc(100vh - 30px);
      overflow-y: auto;
  `}
`;

const User = styled.div`
  text-align: center;
  padding: 2rem 0;
`;

const UserImage = styled.img`
  width: 100px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
`;

const UserName = styled.p`
  margin: 0;
`;

const Stats = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 2rem;
`;

const Stat = styled.span``;

const StatLabel = styled.span`
  color: #ccc;
  margin-inline-end: 0.3rem;
`;

const StatValue = styled.span`
  font-weight: bold;
`;

export default withMediaQuery(CompetitionRankingBreakdown);
