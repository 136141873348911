import * as actionTypes from '../../constants/action-types/store';

const INIT_STATE = {
  orders: null,
  products: null,
  purchaseModal: {
    show: false,
    product: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_PRODUCTS:
      return { ...state, products: action.payload.products };

    case actionTypes.SHOW_PURCHASE_MODAL:
      return { ...state, purchaseModal: { product: action.payload.product, show: true } };

    case actionTypes.HIDE_PURCHASE_MODAL:
      return { ...state, purchaseModal: { product: null, show: false } };

    default:
      return { ...state };
  }
};
