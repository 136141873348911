export default {
  env: process.env.NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  newsBaseUrl: process.env.REACT_APP_NEWS_BASE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  facebook: {
    appId: process.env.REACT_APP_FACEBOOK_APP_ID,
  },
  gtmId: process.env.REACT_APP_GTM_ID,
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
  },
  hotjar: {
    id: process.env.REACT_APP_HOT_JAR_ID,
    jsVersion: process.env.REACT_APP_HOT_JAR_JS_VERSION,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
  fawry: {
    merchantCode: process.env.REACT_APP_FAWRY_MERCHANT_CODE,
    addCardUrl: `${process.env.REACT_APP_FAWRY_PLUGIN_URL}/addCard/creatCardToken.jsp`,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
};
