import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import useStateAwareRedirect from '../hooks/useStateAwareRedirect';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

const SkipToLandingPage = ({ className, redirectTo }) => {
  const redirect = useStateAwareRedirect();
  redirectTo = redirectTo ? redirectTo : DEFAULT_LANDING_PAGE;

  return (
    <p className={`mb-0 ${className}`}>
      <FormattedMessage
        id="skip-to-landing-page-message"
        values={{
          cta: (
            <Button onClick={() => redirect({ override: redirectTo })} className="ml-1">
              <FormattedMessage id="Start playing" />
            </Button>
          ),
        }}
      />
    </p>
  );
};

export default SkipToLandingPage;
