import React, { useEffect } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import ProfileTabs from '../../components/profile/Tabs';
import ProfileSidebar from '../../components/profile/Sidebar';
import { fetchMyPredictions, selectMyPredictionMatch } from '../../redux/profile/thunks';
import Match from '../../components/Match';
import Prediction from '../../components/Prediction';
import { StickyContainer, Sticky } from 'react-sticky';
import { useIntl } from 'react-intl';
import { unselectMyPredictionsMatch } from '../../redux/profile/actions';
import Loader from '../../components/Loader';
import withMediaQuery from '../../hoc/withMediaQuery';

function ProfilePredictionsPage(props) {
  const { myPredictions } = props;
  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Predictions' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);

    props.fetchMyPredictions();
  }, []);

  const onSelect = (matchId) => {
    props.selectMyPredictionMatch(matchId);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile page-profile-predictions">
          <StickyContainer>
            <Row>
              <Col md={3} className="col-sidebar">
                <ProfileSidebar />
              </Col>
              <Col md={9}>
                <div className="profile-content">
                  <ProfileTabs activeTab="predictions" />

                  <TabContent activeTab="predictions">
                    <TabPane tabId="predictions">
                      <Row>
                        <Col md={8}>
                          {myPredictions.loading ? (
                            <Loader />
                          ) : (
                            myPredictions.items.map((matchId, i) => {
                              const match = props.loadedMatches[matchId];

                              return (
                                <Match
                                  match={match}
                                  key={i}
                                  selectedMatchId={myPredictions.selectedMatch.id}
                                  onSelect={onSelect}
                                />
                              );
                            })
                          )}
                        </Col>
                        <Col md={4}>
                          {props.isDesktop && (
                            <Sticky>
                              {({ style, isSticky }) => (
                                <div
                                  style={style}
                                  className={isSticky ? 'sticky-wrapper prediction-sticky' : ''}
                                >
                                  {myPredictions.selectedMatch.loading && <Loader />}
                                  {myPredictions.selectedMatch.id && (
                                    <div className="sticky-inner-wrapper">
                                      <Prediction
                                        matchId={myPredictions.selectedMatch.id}
                                        onUnselect={props.unselectMyPredictionsMatch}
                                        userCanPredict={true}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Sticky>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </StickyContainer>
        </div>
      </Container>

      {props.isMobile && (
        <React.Fragment>
          {!!(myPredictions.selectedMatch.loading || myPredictions.selectedMatch.id) && (
            <div className="overlay-prediction">
              {myPredictions.selectedMatch.loading && <Loader />}
              {!!myPredictions.selectedMatch.id && (
                <Prediction
                  matchId={myPredictions.selectedMatch.id}
                  onUnselect={props.unselectMyPredictionsMatch}
                  userCanPredict={true}
                />
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { myPredictions } = state.profile;
  const { loadedMatches } = state.matches;
  return { loadedMatches, myPredictions };
};

export default connect(mapStateToProps, {
  fetchMyPredictions,
  selectMyPredictionMatch,
  unselectMyPredictionsMatch,
})(withMediaQuery(ProfilePredictionsPage));
