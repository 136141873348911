import * as actionTypes from '../../constants/action-types/profile';
import { INCREASE_COINS, DECREASE_COINS } from '../../constants/action-types/generic';
import { FETCH_ME_SUCCESS } from '../../constants/action-types/auth';

const INIT_STATE = {
  userId: null,
  myPredictions: { items: [], selectedMatch: { id: null, loading: false }, loading: false },
  notifications: { items: [], loading: false },
  orders: null,
  coins: 0,
  friends: [],
  prizes: null,
  newPrizeCount: 0,
  prizeRedemption: {
    userPrize: null,
    showModal: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ME_SUCCESS:
      return { ...state, coins: action.payload.coins, userId: action.payload.id };

    case actionTypes.FETCH_MY_PREDICTIONS_START:
      return {
        ...state,
        myPredictions: {
          ...state.myPredictions,
          items: [],
          selectedMatch: { id: null, loading: false },
          loading: true,
        },
      };
    case actionTypes.FETCH_MY_PREDICTIONS_SUCCESS:
      return {
        ...state,
        myPredictions: {
          ...state.myPredictions,
          items: [
            ...state.myPredictions.items,
            ...action.payload.predictions.map((prediction) => prediction.bet.game.id),
          ],
          loading: false,
        },
      };
    case actionTypes.FETCH_MY_PREDICTIONS_FAILURE:
      return { ...state, myPredictions: { ...state.myPredictions, loading: false } };

    case actionTypes.SELECT_MY_PREDICTIONS_MATCH_START:
      return {
        ...state,
        myPredictions: { ...state.myPredictions, selectedMatch: { id: null, loading: true } },
      };
    case actionTypes.SELECT_MY_PREDICTIONS_MATCH_SUCCESS:
      return {
        ...state,
        myPredictions: {
          ...state.myPredictions,
          selectedMatch: { id: action.payload.matchId, loading: false },
        },
      };
    case actionTypes.SELECT_MY_PREDICTIONS_MATCH_FAILURE:
      return {
        ...state,
        myPredictions: { ...state.myPredictions, selectedMatch: { id: null, loading: false } },
      };

    case actionTypes.UNSELECT_MY_PREDICTIONS_MATCH:
      return {
        ...state,
        myPredictions: { ...state.myPredictions, selectedMatch: { item: null, loading: false } },
      };

    case actionTypes.ADD_MATCH_TO_MY_PREDICTIONS:
      return {
        ...state,
        myPredictions: {
          ...state.myPredictions,
          items: [
            action.payload.matchId,
            ...state.myPredictions.items.filter((matchId) => action.payload.matchId !== matchId),
          ],
        },
      };

    case actionTypes.FETCH_NOTIFICATIONS_START:
      return { ...state, notifications: { ...state.notifications, loading: true } };
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          items: action.payload.notifications,
          loading: false,
        },
      };
    case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, notifications: { ...state.notifications, loading: false } };

    case INCREASE_COINS:
      return { ...state, coins: state.coins + action.payload.coins };

    case DECREASE_COINS:
      return { ...state, coins: state.coins - action.payload.coins };

    case actionTypes.RECEIVE_FRIENDS:
      return { ...state, friends: action.payload.friends };

    case actionTypes.RECEIVE_USER_PRIZES:
      return { ...state, prizes: action.payload.prizes };

    case actionTypes.RECEIVE_USER_NEW_PRIZE_COUNT:
      return { ...state, newPrizeCount: action.payload.count };

    case actionTypes.SHOW_PRIZE_REDEMPTION_MODAL:
      return {
        ...state,
        prizeRedemption: { showModal: true, userPrize: action.payload.userPrize },
      };

    case actionTypes.HIDE_PRIZE_REDEMPTION_MODAL:
      return { ...state, prizeRedemption: { showModal: false, userPrize: null } };

    case actionTypes.RECEIVE_ORDERS:
      return { ...state, orders: action.payload.orders };

    default:
      return { ...state };
  }
};
