import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setReferrer } from '../redux/auth/thunks';
import useReferrer from '../hooks/useReferrer';

export default (WrappedComponent) => {
  const dispatch = useDispatch();
  const referrer = useReferrer();

  useEffect(() => {
    if (referrer) dispatch(setReferrer(referrer));
  }, []);

  return <WrappedComponent />;
};
