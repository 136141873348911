import React, { useEffect, useState } from 'react';
import { resetPassword } from '../../redux/auth/thunks';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Row, Alert, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { isUserAuthenticated } from '../../helpers/auth';
import { Redirect, useLocation } from 'react-router-dom';
import FormTitle from '../../components/form/FormTitle';
import PasswordInput from '../../components/form/PasswordInput';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import ValidationErrors from '../../components/ValidationErrors';
import { DEFAULT_LANDING_PAGE } from '../../constants/generic';
import IdentifierInput from '../../components/form/IdentifierInput';
import OtpInput from '../../components/form/OtpInput';

const qs = require('query-string');

function ResetPasswordPage(props) {
  const location = useLocation();

  const pqs = qs.parse(location.search, { ignoreQueryPrefix: true });
  console.log(pqs);

  const initialValues = {
    otp: pqs.otp ?? '',
    identifier: pqs.identifier ?? '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(),
    identifier: Yup.string().required(),
    newPassword: Yup.string().required(),
    newPasswordConfirmation: Yup.string().equalTo('newPassword').required(),
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Reset Password' }));
  }, []);

  const onSubmit = (values, setSubmitting) => {
    values = { ...values };
    setIsSuccess(false);

    delete values.newPasswordConfirmation;

    props
      .resetPassword(values)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <AuthPageWrapper
        className="page-login"
        description={intl.formatMessage({ id: 'meta.description.login' })}
      >
        <FormTitle>
          <FormattedMessage id="Welcome to Eksab" />
        </FormTitle>

        <ValidationErrors errors={props.errors} />

        {props.message && !props.errors && (
          <Alert color={isSuccess ? 'success' : 'danger'}>{props.message}</Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <OtpInput
                  label={<FormattedMessage id="enter_otp" />}
                  onEntered={(v) => setFieldValue('otp', v)}
                  value={values.otp}
                  disabled={!!initialValues.otp}
                />
              </FormGroup>

              <div className="input-wrapper">
                <FormGroup className="mb-0">
                  <IdentifierInput
                    name="identifier"
                    id="identifier"
                    placeholder={intl.formatMessage({ id: 'enter_identifier' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.identifier}
                    invalid={errors.identifier && touched.identifier}
                  />
                </FormGroup>

                <FormGroup className="mb-0">
                  <PasswordInput
                    name="newPassword"
                    id="new-password"
                    placeholder={intl.formatMessage({ id: 'Enter your new password' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    invalid={errors.newPassword && touched.newPassword}
                    toggle={true}
                  />
                </FormGroup>

                <FormGroup>
                  <PasswordInput
                    name="newPasswordConfirmation"
                    id="new-password-confirmation"
                    placeholder={intl.formatMessage({ id: 'Confirm your new password' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPasswordConfirmation}
                    invalid={errors.newPasswordConfirmation && touched.newPasswordConfirmation}
                    toggle={true}
                  />
                </FormGroup>
              </div>

              <Row className="justify-content-between align-items-center">
                <Col className="mt-1" xs="12">
                  {isSuccess ? (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-lg w-100"
                      onClick={() => props.history.push('/login')}
                    >
                      <FormattedMessage id="Go to Login" />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                      className="btn-lg w-100"
                    >
                      <FormattedMessage id="Reset Password" />
                      {isSubmitting && <Spinner />}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </AuthPageWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { errors, message } = state.auth.resetPassword;
  return { errors, message };
};

export default connect(mapStateToProps, { resetPassword })(ResetPasswordPage);
