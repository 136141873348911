import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useIntl } from 'react-intl';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

function SplashPage(props) {
  const intl = useIntl();

  setTimeout(() => {
    goToCompetitionTypes();
  }, 1000);

  useEffect(() => {
    props.setDocumentTitle();
  }, []);

  const goToCompetitionTypes = () => {
    return props.history.push(DEFAULT_LANDING_PAGE);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <meta name="description" content={intl.formatMessage({ id: 'meta.description.splash' })} />
      </MetaTags>

      <div className="page page-splash vh-100 gradient-default">
        <div className="container d-flex h-100 align-items-center justify-content-center">
          <img src={require('../assets/images/logo-white.png')} alt="eksab logo" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default SplashPage;
