import React from 'react';
import NumberFormat from 'react-number-format';

const Coins = ({ coins }) => {
  return (
    <NumberFormat value={coins} displayType={'text'} thousandSeparator={true} decimalScale={0} />
  );
};

export default Coins;
