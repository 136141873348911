import { receivePrizes } from './actions';
import api from '../../helpers/api';

export function fetchPrizes() {
  return async function (dispatch) {
    return await api
      .fetchPrizes()
      .then((response) => response.data)
      .then((json) => {
        dispatch(receivePrizes(json.data));
      });
  };
}
