import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { fetchCompetitions } from '../../redux/competitions/thunks';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import { fetchMyRanking } from '../../redux/ranking/thunks';
import { clearCompetitions } from '../../redux/competitions/actions';

function LatestJoinedCompetitions(props) {
  useEffect(() => {
    props.clearCompetitions('mine');
    props.fetchCompetitions('mine');
  }, []);

  const { myCompetitions } = props;

  return (
    <React.Fragment>
      <div id="my-competitions">
        <h2 className="home-section-header">
          <FormattedMessage id="Latest Joined Competitions" />
        </h2>
        <div className="competition-list">
          {myCompetitions.loading && <Loader />}

          <PerfectScrollbar options={{ suppressScrollX: true }}>
            {myCompetitions.items.map((id, i) => {
              const competition = props.entities[id];

              return (
                <Link
                  to={`competitions/${competition.id}`}
                  className={`competition ${i + 1 === myCompetitions.items.length ? 'last' : ''}`}
                  key={`my-competition-${i}`}
                >
                  <div className="left">
                    <img
                      src={
                        competition.logo
                          ? competition.logo
                          : require('../../assets/images/prize.svg')
                      }
                      alt={competition.logo ? competition.name : ''}
                    />
                    <div className="info">
                      <h3 className="name">{competition.name}</h3>
                      <p className="status">
                        <FormattedMessage id={`competitionStates.${competition.state}`} />
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </PerfectScrollbar>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { entities } = state.competitions;
  const { mine: myCompetitions } = state.competitions.competitions;
  const { myRanking } = state.ranking;
  return { myCompetitions, entities, myRanking };
};

export default connect(mapStateToProps, {
  fetchCompetitions,
  clearCompetitions,
  fetchMyRanking,
})(LatestJoinedCompetitions);
