// @vendors
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircleOutline, IoMdPricetag, IoMdCheckmark } from 'react-icons/io';
import {
  FaUserFriends,
  FaRegCheckCircle,
  FaFacebookF,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { Form, Input, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
// @components
import Text from '../Text';
import Box from '../Box';
import GroupMembersSelector from './GroupMembersSelector';
import MemberCard from './MemberCard';
// @static
import modalHeaderBgImg from '../../assets/images/group-modal-bg.jpg';
import successModalBgImg from '../../assets/images/success-modal-bg.jpg';
import config from '../../config';
// @redux
import { saveUsersList } from '../../redux/users/actions';
import { fetchUsersList } from '../../redux/users/thunks';
import { selectUsersList } from '../../redux/users/selectors';
import { createGroup, addGroupMembers } from '../../redux/groups/thunks';

const BreadcrumbsItem = ({ active, title, icon, ...rest }) => (
  <Box display="flex" flexDirection="column" alignItems="center" zIndex="1" {...rest}>
    <Box
      width="1.875rem"
      height="1.875rem"
      bg={active ? '#5ad3b8' : '#afadb2'}
      color={active ? '#fff' : '#42414c'}
      fontSize="0.875rem"
      lineHeight="1"
      borderRadius="100%"
      mb="0.5rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="0.75rem solid #fff"
      borderTop="0"
      borderBottom="0"
      boxSizing="content-box"
    >
      {icon || ''}
    </Box>
    <Text fontSize="0.875rem" fontWeight="500" color="#34495e" maxWidth="5rem" textAlign="center">
      {title || ''}
    </Text>
  </Box>
);

const CreateGroupModal = ({ onClose, ...rest }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsersList());
  const userId = useSelector((state) => state?.auth?.user?.id);
  const [successModal, setSuccessModal] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2).required(),
    type: Yup.string().required(),
    description: Yup.string(),
    latestMembers: Yup.array(),
  });
  const [formData, setFormData] = useState({
    name: '',
    type: 'public',
    description: '',
    latestMembers: [],
  });
  const [filters, setFilters] = useState({});

  const handleGetUsersList = useCallback(() => {
    dispatch(fetchUsersList({ ...filters, 'exclude-users': userId }));
  }, [filters]);

  useEffect(() => {
    return () => {
      // Clear users list when modal is closed
      dispatch(saveUsersList());
    };
  }, []);

  useEffect(() => {
    handleGetUsersList();
  }, [filters]);

  const handleBackClick = useCallback(() => {
    if (activeStep === 1) onClose();
    else setActiveStep(activeStep - 1);
  }, [activeStep]);

  const handleNextClick = useCallback(() => {
    switch (activeStep) {
      case 1:
        // Trigger Step 1 form submit button
        document.querySelector('#form_1_submit_btn').click();
        break;
      case 2:
        // Go to Confirmation Step
        setActiveStep(3);
        break;
      case 3:
        // Show loading
        setIsSubmitting(true);
        // Handle Create Group endpoint
        const { latestMembers, ...restFormData } = formData;
        dispatch(createGroup(restFormData))
          .then((res) => res.data.data)
          .then((groupData) => {
            dispatch(addGroupMembers(groupData?.id, latestMembers))
              .then(() => {
                setSuccessModal(groupData);
              })
              .finally(() => {
                // Hide loading
                setIsSubmitting(false);
              });
          })
          .catch(() => {
            // Show Toast
            toast.error(intl.formatMessage({ id: 'CreateGroupModal.GroupCreateError' }));
            // Hide loading
            setIsSubmitting(false);
          });
        break;
      default:
    }
  }, [activeStep]);

  const handleSearchMembersInput = useCallback(
    (e) => {
      setFilters({ ...filters, q: e?.target?.value || null });
    },
    [filters]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      borderRadius="0.625rem"
      overflow="hidden"
      {...rest}
    >
      {successModal?.id ? (
        <Box
          width="100%"
          p="1.5rem 2rem"
          background={`url(${successModalBgImg})`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          display="flex"
          flexDirection="column"
          alignItems="center"
          overflowY="auto"
        >
          <Text fontSize="5rem" color="#fff" mb="0.75rem">
            <FaRegCheckCircle />
          </Text>
          <Text fontSize="1.75rem" fontWeight="bold" color="#fff" textAlign="center">
            <FormattedMessage id="group_created_message" />
          </Text>
          <Box display="flex" mt="1rem" mb="1.25rem" flexDirection={{ xs: 'column', md: 'row' }}>
            {successModal?.entry_code && (
              <Text mie="1.75rem" fontSize="0.875rem" color="#fff">
                <FormattedMessage id="Code" />: <b>{successModal?.entry_code}</b>
              </Text>
            )}
            <Text fontSize="0.875rem" color="#fff">
              <FormattedMessage id="ID" />: <b>{successModal?.code}</b>
            </Text>
          </Box>
          <Text fontSize="1.25rem" fontWeight="500" color="#fff" textAlign="center">
            <FormattedMessage id="Share group with your friends" />
          </Text>
          <Box display="flex" mt="1rem">
            <Text
              color="#5ad37c"
              bg="#fff"
              borderRadius="0.375rem"
              width="2rem"
              height="2rem"
              cursor="pointer"
              fontSize="1.125rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              as="a"
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=${config.baseUrl}/groups/${successModal?.id}`}
              mie="1rem"
            >
              <FaFacebookF />
            </Text>
            <Text
              color="#5ad37c"
              bg="#fff"
              borderRadius="0.375rem"
              width="2rem"
              height="2rem"
              cursor="pointer"
              fontSize="1.125rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              as="a"
              target="_blank"
              href={`https://twitter.com/intent/tweet?url=${config.baseUrl}/groups/${successModal?.id}`}
              mie="1rem"
            >
              <FaTwitter />
            </Text>
            <Text
              color="#5ad37c"
              bg="#fff"
              borderRadius="0.375rem"
              width="2rem"
              height="2rem"
              cursor="pointer"
              fontSize="1.125rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              as="a"
              target="_blank"
              href={`whatsapp://send?text=${config.baseUrl}/groups/${successModal?.id}`}
            >
              <FaWhatsapp />
            </Text>
          </Box>
          <Text my="0.5rem" fontSize="0.75rem" color="#fff">
            ___________
            <FormattedMessage id="Or" />
            ___________
          </Text>
          <Text my="0.5rem" fontSize="0.75rem" color="#34495e">
            <FormattedMessage id="Using Link" />
          </Text>
          <Box
            display="flex"
            bg="#fff"
            borderRadius="0.375rem"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            pis="1rem"
            width="100%"
            maxWidth="17.5rem"
            mb="1rem"
          >
            <input
              id="success_modal_group_link"
              type="text"
              value={`${config.baseUrl}/groups/${successModal?.id}`}
              style={{
                fontSize: '0.75rem',
                color: '#42414c',
                textAlign: 'center',
                marginInlineEnd: '0.5rem',
                outline: 'none',
                border: 'none',
                padding: 0,
                width: '100%',
              }}
            />
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                document.querySelector('#success_modal_group_link').select();
                document.execCommand('copy');
                setCopied(true);
              }}
            >
              <FormattedMessage id={isCopied ? 'Copied' : 'Copy'} />
            </button>
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Text
              as={Link}
              width="100%"
              maxWidth="20.5rem"
              color="#34495e"
              bg="#e0e0e0"
              fontWeight="bold"
              fontSize={{ xs: '1rem', md: '1.5rem' }}
              lineHeight="3.25rem"
              borderRadius="0.25rem"
              textAlign="center"
              to={`groups/${successModal?.id}`}
              hoverColor="#34495e"
              textDecoration="none"
              mb={{ xs: '1rem', md: '0' }}
            >
              <FormattedMessage id="Go to group" />
            </Text>
            <Text
              as={Link}
              width="100%"
              maxWidth="20.5rem"
              color="#fff"
              bg="#34495e"
              fontWeight="bold"
              fontSize={{ xs: '1rem', md: '1.5rem' }}
              lineHeight="3.25rem"
              borderRadius="0.25rem"
              textAlign="center"
              to={`/groups/${successModal?.id}/competitions/create`}
              hoverColor="#e0e0e0"
              textDecoration="none"
            >
              <FormattedMessage id="Create a competition" />
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          {/* Header */}
          <Box
            width="100%"
            minHeight="4.875rem"
            p="1.25rem"
            background={`url(${modalHeaderBgImg})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Text fontSize="1.25rem" fontWeight="500" color="#fff" textAlign="center">
              <FormattedMessage id="Create New Group" />
            </Text>
            <Box
              display="inline-block"
              fontSize="1.5rem"
              color="#fc2c5d"
              cursor="pointer"
              onClick={() => {
                onClose();
              }}
              position="absolute"
              top="0.625rem"
              left="0.625rem"
              lineHeight="1"
            >
              <IoMdCloseCircleOutline />
            </Box>
          </Box>
          {/* Content */}
          <Box
            width="100%"
            bg="#fff"
            display="flex"
            flexDirection="column"
            p="1.875rem 1.375rem"
            overflowY="auto"
          >
            {/* Breadcrumbs */}
            <Box display="flex" justifyContent="space-between" position="relative" mb="0.875rem">
              <Box
                bg="rgba(175,173,178,0.5)"
                width="88%"
                height="1px"
                position="absolute"
                top="1rem"
                left="6%"
                zIndex="0"
              ></Box>
              <BreadcrumbsItem
                active={activeStep >= 1}
                title={<FormattedMessage id="Basic Information" />}
                icon={<IoMdPricetag />}
              />
              <BreadcrumbsItem
                active={activeStep >= 2}
                title={<FormattedMessage id="Add Friends" />}
                icon={<FaUserFriends />}
              />
              <BreadcrumbsItem
                active={activeStep >= 3}
                title={<FormattedMessage id="Confirm" />}
                icon={<IoMdCheckmark />}
              />
            </Box>
            {/* Steps */}
            <Box width="100%">
              {/* Step 1 */}
              {activeStep === 1 && (
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setFormData({ ...formData, ...values });
                    setActiveStep(2);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box mb="1.5rem">
                        <Text
                          mb="0.5rem"
                          fontSize="0.875rem"
                          fontWeight="bold"
                          color="#201f2f"
                          htmlFor="name"
                        >
                          <FormattedMessage id="Group Name" />
                        </Text>
                        <Input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          invalid={errors.name && touched.name}
                          type="text"
                          name="name"
                          id="name"
                          placeholder={intl.formatMessage({ id: 'Enter group name...' })}
                        />
                      </Box>
                      <Box mb="1.5rem">
                        <Text
                          mb="0.5rem"
                          fontSize="0.875rem"
                          fontWeight="bold"
                          color="#201f2f"
                          htmlFor="type"
                        >
                          <FormattedMessage id="Privacy" />
                        </Text>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(2, 1fr)"
                          gridColumnGap="1rem"
                        >
                          <div>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value="public"
                              invalid={errors.type && touched.type}
                              type="radio"
                              name="type"
                              id="type_public"
                              checked={values.type === 'public'}
                            />
                            <Text
                              htmlFor="type_public"
                              as="label"
                              fontSize="0.875rem"
                              color="#34495e"
                              mis="0.5rem"
                            >
                              <FormattedMessage id="Public Group" />
                            </Text>
                          </div>
                          <div>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value="private"
                              invalid={errors.type && touched.type}
                              type="radio"
                              name="type"
                              id="type_private"
                              checked={values.type === 'private'}
                            />
                            <Text
                              htmlFor="type_private"
                              as="label"
                              fontSize="0.875rem"
                              color="#34495e"
                              mis="0.5rem"
                            >
                              <FormattedMessage id="Private Group" />
                            </Text>
                          </div>
                        </Box>
                      </Box>
                      <Box>
                        <Text
                          mb="0.5rem"
                          fontSize="0.875rem"
                          fontWeight="bold"
                          color="#201f2f"
                          htmlFor="description"
                        >
                          <FormattedMessage id="Group Description" />
                        </Text>
                        <Input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          invalid={errors.description && touched.description}
                          type="textarea"
                          name="description"
                          id="description"
                          placeholder={intl.formatMessage({ id: 'Enter group description...' })}
                          style={{ resize: 'none' }}
                          rows="4"
                        />
                      </Box>
                      <button id="form_1_submit_btn" type="submit" style={{ display: 'none' }}>
                        submit form 1
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
              {/* Step 2 */}
              {activeStep === 2 && (
                <>
                  <Text
                    fontSize="1rem"
                    fontWeight="bold"
                    color="#201f2f"
                    mb="1rem"
                    textAlign="center"
                    display="block"
                  >
                    <FormattedMessage id="Add users to your group" />
                  </Text>
                  <GroupMembersSelector
                    items={usersList?.items}
                    value={formData?.latestMembers}
                    loadMore={handleGetUsersList}
                    hasMore={usersList?.meta?.current_page < usersList?.meta?.last_page}
                    onChange={(items) => {
                      const newFormData = { ...formData };
                      newFormData.latestMembers = items;
                      setFormData(newFormData);
                    }}
                    onSearch={handleSearchMembersInput}
                  />
                </>
              )}
              {/* Step 3 */}
              {activeStep === 3 && (
                <Box
                  width="100%"
                  display="grid"
                  gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                  gridColumnGap="3rem"
                >
                  {/* Details Column */}
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" my="1.5rem">
                      <Text fontSize="0.875rem" color="#34495e" mie="0.25rem">
                        <FormattedMessage id="Group Name" />:
                      </Text>
                      <Text fontSize="0.875rem" fontWeight="bold" color="#201f2f">
                        {formData?.name}
                      </Text>
                    </Box>
                    {formData?.description && (
                      <Box display="flex" flexDirection="column" mb="1.5rem">
                        <Text fontSize="0.875rem" color="#34495e" mb="0.5rem">
                          <FormattedMessage id="Group Description" />:
                        </Text>
                        <Text fontSize="0.875rem" fontWeight="bold" color="#201f2f">
                          {formData?.description}
                        </Text>
                      </Box>
                    )}
                    <Box display="flex">
                      <Text fontSize="0.875rem" color="#34495e" mie="0.25rem">
                        <FormattedMessage id="Privacy" />:
                      </Text>
                      <Text fontSize="0.875rem" fontWeight="bold" color="#201f2f">
                        {formData?.type === 'public' ? (
                          <FormattedMessage id="Public Group" />
                        ) : (
                          <FormattedMessage id="Private Group" />
                        )}
                      </Text>
                    </Box>
                  </Box>
                  {/* Members Column */}
                  <Box display="flex" flexDirection="column" mt={{ xs: '1.25rem', md: '0' }}>
                    <Text fontSize="0.875rem" color="#34495e" mb="0.75rem">
                      <FormattedMessage id="Members" />:
                    </Text>
                    <Box
                      width="100%"
                      bg="#f4f6fc"
                      p="1.125rem 0.625rem"
                      display="grid"
                      border="1px solid rgba(175, 173, 178, 0.5)"
                      borderRadius="0.375rem"
                    >
                      <Box
                        width="100%"
                        height="100%"
                        bg="#fff"
                        display="flex"
                        flexDirection="column"
                        border="1px solid rgba(175, 173, 178, 0.33)"
                        borderRadius="0.375rem"
                        p="0.5rem 0.875rem"
                        maxHeight="37vh"
                        overflowY="auto"
                      >
                        {formData?.latestMembers?.map((item, index) => (
                          <MemberCard
                            id={item?.id}
                            name={item?.name}
                            role={item?.role || 1}
                            image={item?.photo?.thumb}
                            borderBottom={
                              index === formData?.latestMembers.length - 1
                                ? ''
                                : '1px solid rgba(175, 173, 178, 0.30)'
                            }
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {/* Footer */}
            <Box
              mt="0.875rem"
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)"
              gridColumnGap="1.25rem"
            >
              <button
                className="btn btn-outline-danger w-100"
                onClick={handleBackClick}
                disabled={isSubmitting}
              >
                <FormattedMessage id={activeStep === 1 ? 'Cancel' : 'Back'} />
              </button>
              <button
                className="btn btn-primary w-100"
                onClick={handleNextClick}
                disabled={isSubmitting}
              >
                <FormattedMessage id={activeStep === 3 ? 'Confirm' : 'Next'} />
                {isSubmitting && <Spinner />}
              </button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

CreateGroupModal.propTypes = {
  onClose: PropTypes.func,
};

CreateGroupModal.defaultProps = {
  onClose: () => {},
};

export default CreateGroupModal;
