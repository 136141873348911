import React from 'react';
import CustomInput from './CustomInput';

const IdentifierInput = (props) => {
  return (
    <CustomInput
      icon={<img src={require('../../assets/images/icon-id.png')} alt="" />}
      {...props}
    />
  );
};

export default IdentifierInput;
