import React from 'react';
import CustomInput from './CustomInput';

const UserIdentifierInput = (props) => {
  return (
    <CustomInput icon={<img src={require('../../assets/images/user.png')} alt="" />} {...props} />
  );
};

export default UserIdentifierInput;
