// @vendors
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../components/Box';
import Text from '../../components/Text';
// import Footer from '../../components/Footer';
// @styles
import { HowToImg, WinnerImg, CEOImg, ArticleProvider, StyledICTLogoImg } from './styles';
// @static
import sectionBg from '../../assets/images/bg-signup.svg';
import HowToImgCompetition from '../../assets/images/menu-icon-competitions-active.svg';
import HowToImgMatches from '../../assets/images/menu-icon-matches-active.svg';
import HowToImgWatch from '../../assets/images/arch.svg';
import CEOGroupImg from '../../assets/images/about/ceo-group.jpg';
import ICTLogoImg from '../../assets/images/about/logo-ict.png';
import WinnerImg1 from '../../assets/images/about/1.jpg';
import WinnerImg2 from '../../assets/images/about/2.jpg';
import WinnerImg3 from '../../assets/images/about/3.jpg';
import WinnerImg4 from '../../assets/images/about/4.jpg';
import WinnerImg5 from '../../assets/images/about/5.jpg';
import WinnerImg6 from '../../assets/images/about/6.jpg';
import WinnerImg7 from '../../assets/images/about/7.jpg';
import WinnerImg8 from '../../assets/images/about/8.jpg';
import WinnerImg9 from '../../assets/images/about/9.jpg';
import WinnerImg10 from '../../assets/images/about/10.jpg';
import WinnerImg11 from '../../assets/images/about/11.jpg';
import WinnerImg12 from '../../assets/images/about/12.jpg';
import WinnerImg13 from '../../assets/images/about/13.jpg';
import WinnerImg14 from '../../assets/images/about/14.jpg';
import WinnerImg15 from '../../assets/images/about/15.jpg';
import { locale } from '../../helpers/generic';
import useTrackers from '../../hooks/useTrackers';

const winnersList = [
  WinnerImg1,
  WinnerImg2,
  WinnerImg3,
  WinnerImg4,
  WinnerImg5,
  WinnerImg6,
  WinnerImg7,
  WinnerImg8,
  WinnerImg9,
  WinnerImg10,
  WinnerImg11,
  WinnerImg12,
  WinnerImg13,
  WinnerImg14,
  WinnerImg15,
];

const artilceList = {
  en: [
    {
      provider:
        'https://www.forbesmiddleeast.com/images/e7b498aa52216402ea995e99ca584619-logo-black.svg',
      title:
        'Egypt’s Aly Mahmoud, Co-Founder Of Eksab, Is Kicking-Off Fantasy Football For The Middle East',
      link:
        'https://www.forbesmiddleeast.com/innovation/startups/egypts-aly-mahmoud-co-founder-of-eksab-is-introducing-fantasy-football-to-the-middle-east?fbclid=IwAR2HcxSPDFLOL1QwTZcv2Zr_t-CXuxlHOFv2cOfl1ha4IOcT5imd2SBvvfs',
    },
    {
      provider:
        'https://www.menabytes.com/wp-content/uploads/2017/04/menabyteslogo-1a2930color-2.png',
      title: 'Egypt’s Eksab raises $500,000 for its fantasy sports platform',
      link:
        'https://www.menabytes.com/eksab-investment-2020/?fbclid=IwAR2kAGTKgVuY96ciz5AtYgzEfLYMtQh1_fV84peQPmJDxfe16k-d1Q_GlJ0',
    },
    {
      provider: 'https://enterprise.press/wp-content/themes/enterprise-v3/img/logo.svg',
      title: 'Sports startup Eksab raises USD 500k from VC outfit 4DX',
      link:
        'https://enterprise.press/stories/2020/11/25/sports-startup-eksab-raises-usd-500k-from-vc-outfit-4dx-25081/?fbclid=IwAR0uCYp_SiLr6g9G-UbXCp-7omOmtsy0McoPkY8MA-tU0DKzDJY9YunIQ2A',
    },
    {
      provider: 'https://thestartupscene.me/Content/Images/scene-logo.png',
      title:
        'FANTASY SPORTS PLATFORM EKSAB TO LAUNCH ITS FIRST PAID COMPETITIONS AFTER $500,000 INVESTMENT',
      link:
        'https://thestartupscene.me/INVESTMENTS/Fantasy-Sports-Platform-Eksab-to-Launch-its-First-Paid-Competitions-After-500-000-Investment',
    },
  ],
  ar: [
    {
      provider: 'https://ar.scoopempire.com/wp-content/uploads/sites/4/2020/04/Scoop2020EN-300.png',
      title: 'منصة اكسب جمعت 500 ألف دولار للألعاب الافتراضية',
      link:
        'https://ar.scoopempire.com/%D9%85%D9%86%D8%B5%D8%A9-%D8%A7%D9%83%D8%B3%D8%A8-%D8%AC%D9%85%D8%B9%D8%AA-500-%D8%A3%D9%84%D9%81-%D8%AF%D9%88%D9%84%D8%A7%D8%B1-%D9%84%D9%84%D8%A3%D9%84%D8%B9%D8%A7%D8%A8-%D8%A7%D9%84%D8%A7%D9%81/',
    },
    {
      provider: 'https://www.zawya.com/resources/img/zawya-logo-AR.svg',
      title:
        '"اكسب" تحصل على استثمار أولي من شركة 500 STARTUPS وتستعد لأن تصبح المنصة الرائدة لألعاب الفانتازي اليومية في منطقة الشرق الأدنى وشمال أفريقيا',
      link:
        'https://www.zawya.com/mena/ar/press-releases/story/%D8%A7%D9%83%D8%B3%D8%A8_%D8%AA%D8%AD%D8%B5%D9%84_%D8%B9%D9%84%D9%89_%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1_%D8%A3%D9%88%D9%84%D9%8A_%D9%85%D9%86_%D8%B4%D8%B1%D9%83%D8%A9_500_STARTUPS_%D9%88%D8%AA%D8%B3%D8%AA%D8%B9%D8%AF_%D9%84%D8%A3%D9%86_%D8%AA%D8%B5%D8%A8%D8%AD_%D8%A7%D9%84%D9%85%D9%86%D8%B5%D8%A9_%D8%A7%D9%84%D8%B1%D8%A7%D8%A6%D8%AF%D8%A9_%D9%84%D8%A3%D9%84%D8%B9%D8%A7%D8%A8_%D8%A7%D9%84%D9%81%D8%A7%D9%86%D8%AA%D8%A7%D8%B2%D9%8A_%D8%A7%D9%84%D9%8A%D9%88%D9%85%D9%8A%D8%A9_%D9%81%D9%8A_%D9%85%D9%86%D8%B7%D9%82%D8%A9_%D8%A7%D9%84%D8%B4%D8%B1%D9%82_%D8%A7%D9%84%D8%A3%D8%AF%D9%86%D9%89_%D9%88%D8%B4%D9%85%D8%A7%D9%84_%D8%A3%D9%81%D8%B1%D9%8A%D9%82%D9%8A%D8%A7-ZAWYA20190616101448/',
    },
    {
      provider: 'https://img.youm7.com/images/graphics/logoyoum7.png',
      title:
        'فوربس تكشف قائمة أكثر 30 شخصية مؤثرة بالشرق الأوسط.. 3 شباب مصريين بالقائمة أبرزهم السباحة العالمية فريدة عثمان.. تطوير المجتمعات أهم معايير الأختيار.. وفتاة هندية تحتل المركز الأول بعد تأسيسها شركة بالإمارات',
      link:
        'https://www.youm7.com/story/2020/9/26/%D9%81%D9%88%D8%B1%D8%A8%D8%B3-%D8%AA%D9%83%D8%B4%D9%81-%D9%82%D8%A7%D8%A6%D9%85%D8%A9-%D8%A3%D9%83%D8%AB%D8%B1-30-%D8%B4%D8%AE%D8%B5%D9%8A%D8%A9-%D9%85%D8%A4%D8%AB%D8%B1%D8%A9-%D8%A8%D8%A7%D9%84%D8%B4%D8%B1%D9%82-%D8%A7%D9%84%D8%A3%D9%88%D8%B3%D8%B7-3/4992857',
    },
    {
      provider:
        'https://www.forbesmiddleeast.com/images/e7b498aa52216402ea995e99ca584619-logo-black.svg',
      title:
        'علي محمود يدخل الرياضات‭ ‬الافتراضية‭ ‬إلى‭ ‬المنطقة‭ ‬من‭ ‬خلال‭ ‬تطبيق‭‬‬‬‬‬‬“اكسب”',
      link:
        'https://www.forbesmiddleeast.com/ar/innovation/startups/egypts-aly-mahmoud-co-founder-of-eksab-is-kicking-off-fantasy-football-for-the-middle-east',
    },
  ],
};

const SectionContainer = ({ children, ...rest }) => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    p="4rem 2rem"
    {...rest}
  >
    {children}
  </Box>
);

const Article = ({ article }) => (
  <Box p="1rem">
    <a href={article.link} target="_blank" rel="noopener noreferrer">
      <ArticleProvider src={article.provider} title={article.title} />
    </a>
  </Box>
);

const AboutUsPage = () => {
  const currentLocale = locale();
  const trackers = useTrackers();

  useEffect(() => {
    trackers.track('Page View', { page: 'About Us' });
  }, []);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {/* Intro Section */}
      <SectionContainer
        background={`url('${sectionBg}'), linear-gradient(198deg,#35c55d 0%,#5ad37c 50%,#35c55d 100%)`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
      >
        <Text fontSize="2rem" fontWeight="bold" color="#fff" mb="1rem" textAlign="center">
          <FormattedMessage id="AboutPage.Section1.Title" />
        </Text>
        <Text fontSize="1rem" color="#fff" textAlign="center" lineHeight="1.5" maxWidth="60rem">
          <FormattedMessage id="AboutPage.Section1.SubTitle" />
        </Text>
      </SectionContainer>

      {/* Hiring Section */}
      <SectionContainer background={'#33485e'}>
        <Box className="row" justifyContent="center">
          <Box className="col-8">
            <Text fontSize="2rem" fontWeight="bold" color="#fff" textAlign="center">
              <FormattedMessage id="AboutPage.Hiring.Text" />
            </Text>
          </Box>
          <Box className="col-4">
            <a
              href="https://yallaeksab.freshteam.com/jobs"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary text-nowrap"
            >
              <FormattedMessage id="AboutPage.Hiring.ApplyNow" />
            </a>
          </Box>
        </Box>
      </SectionContainer>

      {/* How To Section */}
      <SectionContainer>
        <Text fontSize="1.5rem" color="#5ad37c" textAlign="center" mb="0.5rem">
          <FormattedMessage id="AboutPage.Section2.Title" />
        </Text>
        <Text fontSize="1rem" color="#6c757d" textAlign="center">
          <FormattedMessage id="AboutPage.Section2.SubTitle" />
        </Text>
        <Box className="row" width="100%" mt="3rem">
          <Box
            className="col-sm-12 col-md-4"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={{ xs: '1rem', md: '0' }}
          >
            <HowToImg src={HowToImgCompetition} />
            <Text
              fontSize="1rem"
              fontWeight="500"
              color="#000"
              mt="1rem"
              mb="0.5rem"
              textAlign="center"
            >
              <FormattedMessage id="AboutPage.Section2.Item1.Title" />
            </Text>
            <Text fontSize="1rem" color="#6c757d" textAlign="center">
              <FormattedMessage id="AboutPage.Section2.Item1.SubTitle" />
            </Text>
          </Box>
          <Box
            className="col-sm-12 col-md-4"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={{ xs: '1rem', md: '0' }}
          >
            <HowToImg src={HowToImgMatches} />
            <Text
              fontSize="1rem"
              fontWeight="500"
              color="#000"
              mt="1rem"
              mb="0.5rem"
              textAlign="center"
            >
              <FormattedMessage id="AboutPage.Section2.Item2.Title" />
            </Text>
            <Text fontSize="1rem" color="#6c757d" textAlign="center">
              <FormattedMessage id="AboutPage.Section2.Item2.SubTitle" />
            </Text>
          </Box>
          <Box
            className="col-sm-12 col-md-4"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={{ xs: '1rem', md: '0' }}
          >
            <HowToImg src={HowToImgWatch} />
            <Text
              fontSize="1rem"
              fontWeight="500"
              color="#000"
              mt="1rem"
              mb="0.5rem"
              textAlign="center"
            >
              <FormattedMessage id="AboutPage.Section2.Item3.Title" />
            </Text>
            <Text fontSize="1rem" color="#6c757d" textAlign="center">
              <FormattedMessage id="AboutPage.Section2.Item3.SubTitle" />
            </Text>
          </Box>
        </Box>
      </SectionContainer>
      {/* CEO */}
      <SectionContainer
        background="linear-gradient(198deg,#35c55d 0%,#5ad37c 50%,#35c55d 100%)"
        padding="3rem 0 0"
      >
        <Text
          fontSize="1rem"
          color="#fff"
          textAlign="center"
          maxWidth="40rem"
          lineHeight="1.5"
          mb=".5rem"
        >
          <FormattedMessage id="AboutPage.Section3.Title" />
        </Text>

        <Box bg="#fff" p=".5rem" mb=".5rem">
          <StyledICTLogoImg src={ICTLogoImg} />
        </Box>

        <CEOImg src={CEOGroupImg} mx="-2rem" mb="-4rem" />
      </SectionContainer>

      {/* Article Section */}
      <SectionContainer>
        <Text fontSize="1.5rem" color="#5ad37c" textAlign="center" mb="3rem">
          <FormattedMessage id="As featured in" />
        </Text>

        <Box className="row">
          {artilceList[currentLocale].map((article, index) => (
            <Box
              key={index}
              className="col-md-3"
              mb={{ xs: '1rem', md: '0' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Article article={article} key={index} />
            </Box>
          ))}
        </Box>
      </SectionContainer>
      {/* Article Section */}

      {/* Winners Section */}
      <SectionContainer>
        <Text fontSize="1.5rem" color="#5ad37c" textAlign="center" mb="0.5rem">
          <FormattedMessage id="AboutPage.Section4.Title" />
        </Text>
        <Text fontSize="1rem" color="#6c757d" textAlign="center">
          <FormattedMessage id="AboutPage.Section4.SubTitle" />
        </Text>
        <Box width="100%" mt="3rem" display="grid" gridTemplateColumns="repeat(5,1fr)">
          {winnersList.map((winner, index) => (
            <WinnerImg key={`winner_img_${index}`} src={winner} />
          ))}
        </Box>
      </SectionContainer>
      {/* Winners Section */}
    </Box>
  );
};

export default AboutUsPage;
