import React from 'react';
import withPage from '../hoc/withPage';
import SplashPage from '../pages/splash';
import { Redirect, Route } from 'react-router';
import CompetitionTypesPage from '../pages/competition-types';
import OnboardingPage from '../pages/onboarding';
import LoginPage from '../pages/auth/login';
import LoginFacebookPage from '../pages/auth/login-facebook';
import RegisterPage from '../pages/auth/register';
import RegisterReviewPage from '../pages/auth/register-review';
import VerifyPhoneNumberPage from '../pages/auth/verify-phone-number';
import ForgetPasswordPage from '../pages/auth/forget-password';
import ResetPasswordPage from '../pages/auth/reset-password';
import PrivateRoute from './PrivateRoute';
import AddCCStatusPage from '../pages/add-cc-status';

export default [
  { path: '/', name: 'splash', component: withPage(SplashPage), route: Route, exact: true },
  { path: '/en', name: 'splash', component: () => <Redirect to="/" />, route: Route, exact: true },
  { path: '/ar', name: 'splash', component: () => <Redirect to="/" />, route: Route, exact: true },
  {
    path: '/competition-types',
    name: 'competition-types',
    component: withPage(CompetitionTypesPage),
    route: Route,
    exact: true,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: withPage(OnboardingPage),
    route: Route,
    exact: true,
  },
  { path: '/login', name: 'auth.login', component: withPage(LoginPage), route: Route, exact: true },
  {
    path: '/login-facebook',
    name: 'auth.login_facebook',
    component: withPage(LoginFacebookPage),
    route: Route,
    exact: true,
  },
  {
    path: '/register',
    name: 'auth.register',
    component: withPage(RegisterPage),
    route: Route,
    exact: true,
  },
  {
    path: '/register/review',
    name: 'auth.register.review',
    component: withPage(RegisterReviewPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/verify-phone-number',
    name: 'auth.verify_phone_number',
    component: withPage(VerifyPhoneNumberPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/forget-password',
    name: 'auth.forget-password',
    component: withPage(ForgetPasswordPage),
    route: Route,
    exact: true,
  },
  {
    path: '/reset-password',
    name: 'auth.reset-password',
    component: withPage(ResetPasswordPage),
    route: Route,
    exact: true,
  },
  {
    path: '/payment/add-cc/status',
    name: 'payment.add_cc.status',
    component: withPage(AddCCStatusPage),
    route: PrivateRoute,
  },
];
