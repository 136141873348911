import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/profile/thunks';
import CustomInput from '../../components/form/CustomInput';
import EmailInput from '../../components/form/EmailInput';
import FormTitle from '../../components/form/FormTitle';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import useRedirectAfterAuth from '../../hooks/useRedirectAfterAuth';
import { useLocation } from 'react-router';
import ValidationErrors from '../../components/ValidationErrors';
import useTrackers from '../../hooks/useTrackers';
import { Link } from 'react-router-dom';

const RegisterReviewPage = (props) => {
  const trackers = useTrackers();
  const profile = useSelector((state) => state.auth.user);
  const initialValues = { name: profile.name, email: profile.email };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
  });
  const dispatch = useDispatch();
  const redirect = useRedirectAfterAuth();
  const location = useLocation();
  const attempt = location.state.attempt;
  const [errors, setErrors] = useState(null);

  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Review your info' }));
    trackers.track('Page View', { page: 'Register Review' });
  }, []);

  const onSubmit = (values, formik) => {
    dispatch(updateProfile(values))
      .then(() => {
        return redirect(attempt);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        formik.setSubmitting(false);
      });
  };

  return (
    <AuthPageWrapper
      className="page-confirm"
      description={intl.formatMessage({ id: 'meta.description.register-confirm' })}
    >
      <FormTitle>
        <FormattedMessage id="Review your info" />
      </FormTitle>

      <ValidationErrors errors={errors} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => onSubmit(values, formik)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <FormGroup className="mb-0">
                <CustomInput
                  icon={<img src={require('../../assets/images/icon-user.svg')} alt="" />}
                  type="text"
                  name="name"
                  id="name"
                  placeholder={intl.formatMessage({ id: 'Name' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  invalid={errors.name && touched.name}
                />
              </FormGroup>

              <FormGroup className="mb-0">
                <EmailInput
                  name="email"
                  id="email"
                  placeholder={intl.formatMessage({ id: 'Email address' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  invalid={errors.email && touched.email}
                />
              </FormGroup>
            </div>

            <Button type="submit" disabled={isSubmitting} color="primary" className="btn-lg mt-1">
              <FormattedMessage id="submit" />
              {isSubmitting && <Spinner />}
            </Button>
          </Form>
        )}
      </Formik>
    </AuthPageWrapper>
  );
};

export default RegisterReviewPage;
