import {
  fetchMonthlyFriendRankingStart,
  fetchMonthlyFriendRankingSuccess,
  fetchMonthlyFriendRankingFailure,
  fetchMyRankingSuccess,
  fetchMyRankingFailure,
  fetchMyRankingStart,
} from './actions';
import api from '../../helpers/api';
import dayjs from 'dayjs';

export function fetchMonthlyFriendRanking() {
  return async function (dispatch, getState) {
    dispatch(fetchMonthlyFriendRankingStart());

    const page = getState().ranking.ranking.friends.page + 1;
    await api
      .fetchRanking({ friends: true, 'this-month': true, page })
      .then((response) => response.data)
      .then((json) => {
        dispatch(fetchMonthlyFriendRankingSuccess(json.data, json.meta));
      })
      .catch(() => {
        dispatch(fetchMonthlyFriendRankingFailure());
      });
  };
}

export function fetchMyRanking(type, params, cacheUntil = null) {
  return async function (dispatch, getState) {
    const ranking = getState().ranking.myRanking[type];

    if (!ranking || !(ranking.cacheUntil && ranking.cacheUntil.isAfter(dayjs()))) {
      dispatch(fetchMyRankingStart(type));

      api
        .fetchMyRanking(params)
        .then((response) => response.data.data)
        .then((json) => {
          dispatch(fetchMyRankingSuccess(type, json, cacheUntil));
        })
        .catch(() => {
          dispatch(fetchMyRankingFailure(type));
        });
    }
  };
}
