export const CLEAR_COMPETITIONS = 'CLEAR_COMPETITIONS';

export const FETCH_COMPETITIONS_START = 'FETCH_COMPETITIONS_START';
export const FETCH_COMPETITIONS_SUCCESS = 'FETCH_COMPETITIONS_SUCCESS';
export const FETCH_COMPETITIONS_FAILURE = 'FETCH_COMPETITIONS_FAILURE';

export const JOIN_COMPETITION_START = 'JOIN_COMPETITION_START';
export const JOIN_COMPETITION_SUCCESS = 'JOIN_COMPETITION_SUCCESS';
export const JOIN_COMPETITION_FAILURE = 'JOIN_COMPETITION_FAILURE';

export const LEAVE_COMPETITION_SUCCESS = 'LEAVE_COMPETITION_SUCCESS';

export const CLEAR_COMPETITION = 'CLEAR_COMPETITION';
export const FETCH_COMPETITION_START = 'FETCH_COMPETITION_START';
export const FETCH_COMPETITION_SUCCESS = 'FETCH_COMPETITION_SUCCESS';
export const FETCH_COMPETITION_FAILURE = 'FETCH_COMPETITION_FAILURE';

export const FETCH_COMPETITION_MATCHES_START = 'FETCH_COMPETITION_MATCHES_START';
export const FETCH_COMPETITION_MATCHES_SUCCESS = 'FETCH_COMPETITION_MATCHES_SUCCESS';
export const FETCH_COMPETITION_MATCHES_FAILURE = 'FETCH_COMPETITION_MATCHES_FAILURE';

export const CLEAR_COMPETITION_RANKING = 'CLEAR_COMPETITION_RANKING';
export const FETCH_COMPETITION_RANKING_START = 'FETCH_COMPETITION_RANKING_START';
export const FETCH_COMPETITION_RANKING_SUCCESS = 'FETCH_COMPETITION_RANKING_SUCCESS';
export const FETCH_COMPETITION_RANKING_FAILURE = 'FETCH_COMPETITION_RANKING_FAILURE';

export const RECEIVE_COMPETITION_RANKING_BREAKDOWN = 'RECEIVE_COMPETITION_RANKING_BREAKDOWN';

export const SELECT_COMPETITION_MATCH_START = 'SELECT_COMPETITION_MATCH_START';
export const SELECT_COMPETITION_MATCH_SUCCESS = 'SELECT_COMPETITION_MATCH_SUCCESS';
export const SELECT_COMPETITION_MATCH_FAILURE = 'SELECT_COMPETITION_MATCH_FAILURE';

export const UNSELECT_COMPETITION_MATCH = 'UNSELECT_COMPETITION_MATCH';

export const RECEIVE_JOINED_COMPETITION_IDS = 'RECEIVE_JOINED_COMPETITION_IDS';
export const REMOVE_JOINED_COMPETITION_ID = 'REMOVE_JOINED_COMPETITION_ID';

export const INCREASE_COMPETITION_PARTICIPANTS = 'INCREASE_COMPETITION_PARTICIPANTS';
export const DECREASE_COMPETITION_PARTICIPANTS = 'DECREASE_COMPETITION_PARTICIPANTS';
