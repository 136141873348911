import styled from 'styled-components';
import { layout, position } from 'styled-system';

export const StyledOverlay = styled.div`
  position: absolute;
  z-index: 999;
  top: -20px;
  left: -5px;
  right: -5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
`;

export const LineupViewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  background-color: #fff;
  border: 1px solid ${({ active }) => (active ? '#5ad37c' : '#fff')};
  box-shadow: 0 3px 6px 0
    ${({ active }) => (active ? 'rgba(90, 211, 124, 0.35)' : 'rgba(52, 73, 94, 0.25)')};
  cursor: pointer;
  border-radius: 0.25rem;
  img {
    width: 2.5rem;
    display: block;
  }
`;

export const VLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #34495e;
  margin: 0 2.5rem;
`;

export const SaveLineupBtn = styled.button`
  ${layout}
  ${position}
`;

export const ToggleFilterBtn = styled(SaveLineupBtn)``;
