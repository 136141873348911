import * as actionTypes from '../../constants/action-types/users';
import { DEFAULT_LIST_SCHEMA } from '../../constants/generic';

const INIT_STATE = {
  usersList: DEFAULT_LIST_SCHEMA,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USERS_LIST:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          items: [...new Set(action?.items || INIT_STATE.usersList.items)],
          meta: action?.meta || INIT_STATE.usersList.meta,
          filters: action?.filters || INIT_STATE.usersList.filters,
        },
      };
    default:
      return { ...state };
  }
};
