import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { generateFawryReferenceCode } from '../redux/generic/thunks';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import api from '../helpers/api';
import { Alert, Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import { locale } from '../helpers/generic';
import config from '../config';
import useTrackers from '../hooks/useTrackers';

const SelectedPackage = ({ selectedPackage }) => {
  return (
    <div className="payment-package">
      <div className="payment-package-wrapper">
        <FormattedMessage
          id="package-description-message"
          values={{
            coins: (
              <NumberFormat
                value={selectedPackage.coins}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={0}
              />
            ),
            price: (
              <NumberFormat
                value={selectedPackage.discounted_price}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

const FawryCC = ({ selectedPackage }) => {
  const [cards, setCards] = useState(null);
  const [addFawryCCModalOpen, setAddFawryCCModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const trackers = useTrackers();

  useEffect(() => {
    fetchUserPaymentCards();
  }, []);

  const fetchUserPaymentCards = () => {
    setCards(null);
    api.fetchUserPaymentCards().then((response) => {
      setCards(response.data.data);
    });
  };

  const onAdd = () => {
    trackers.track('Button Clicked', {
      button: 'payment.add_new_card',
    });

    setAddFawryCCModalOpen(true);
  };

  const onSelect = (card) => {
    setSelectedCard(card);
    setPaymentModalOpen(true);
  };

  const onDelete = (card) => {
    api.deleteUserPaymentCard(card.token).then(() => {
      fetchUserPaymentCards();
    });
  };

  return (
    <div id="fawry-cc" className="payment-method fawry-cc">
      <div className="card-list">
        <h2 className="card-list-title">
          <FormattedMessage id="Card List" />

          <Button onClick={onAdd} className="btn-xs">
            <FormattedMessage id="Add New Card" />
          </Button>
        </h2>

        {!cards ? (
          <Loader />
        ) : cards.length ? (
          cards.map((card) => (
            <PaymentCard key={card.token} card={card} onSelect={onSelect} onDelete={onDelete} />
          ))
        ) : (
          <p className="mb-0 text-muted">
            <FormattedMessage id="no-payment-cards" />
          </p>
        )}
      </div>

      {addFawryCCModalOpen && (
        <AddFawryCCModal
          onToggle={() => {
            fetchUserPaymentCards();
            setAddFawryCCModalOpen(!addFawryCCModalOpen);
          }}
        />
      )}

      {paymentModalOpen && (
        <FawryPaymentModal
          onToggle={() => setPaymentModalOpen(!paymentModalOpen)}
          selectedPackage={selectedPackage}
          selectedCard={selectedCard}
        />
      )}
    </div>
  );
};

const PaymentCard = ({ card, onDelete, ...props }) => {
  const [deleting, setDeleting] = useState(false);
  const trackers = useTrackers();

  const onSelect = (card) => {
    trackers.track('Button Clicked', {
      button: 'payment.cc.pay_now',
    });

    props.onSelect(card);
  };

  return (
    <div className="payment-card">
      <div className="card-info">
        {card.brand === 'Visa Card' && (
          <img
            className="card-logo"
            src={require('../assets/images/logo-visa.png')}
            alt="visa logo"
          />
        )}
        {['BMISCARD', 'MasterCard'].indexOf(card.brand) !== -1 && (
          <img
            className="card-logo"
            src={require('../assets/images/logo-mastercard.png')}
            alt="mastercard logo"
          />
        )}

        <p className="card-last4">xxxx-xxxx-xxxx-{card.last4}</p>
      </div>

      <div className="card-actions">
        <Button className="btn btn-xs btn-info" onClick={() => onSelect(card)}>
          <FormattedMessage id="Pay Now" />
        </Button>{' '}
        <Button
          disabled={deleting}
          className="btn btn-xs btn-danger"
          onClick={() => {
            setDeleting(true);
            onDelete(card);
          }}
        >
          <FormattedMessage id="Delete" />
        </Button>
      </div>
    </div>
  );
};

const AddFawryCCModal = ({ onToggle, ...props }) => {
  const intl = useIntl();
  const currentLocale = locale();
  const userId = useSelector((s) => s.auth.user.id);
  const successPageUrl = `${config.baseUrl}/payment/add-cc/status?succeeded`;
  const failurePageUrl = `${config.baseUrl}/payment/add-cc/status?failed`;

  const src = `${config.fawry.addCardUrl}?merchantCode=${config.fawry.merchantCode}&lang=${currentLocale}&customerProfileId=${userId}&successPageUrl=${successPageUrl}&failerPageUrl=${failurePageUrl}`;

  return (
    <Modal
      id="add-fawry-cc-modal"
      isOpen={true}
      centered={true}
      toggle={onToggle}
      title={intl.formatMessage({ id: 'Add New Card' })}
      {...props}
    >
      <div className="iframe-bg-match" />
      <iframe title="Fawry Payment" src={src} frameBorder="0" width="100%" height="600px" />
    </Modal>
  );
};

const FawryPaymentModal = ({ onToggle, selectedPackage, selectedCard, ...props }) => {
  const intl = useIntl();
  const user = useSelector((s) => s.auth.user);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const initialValues = {
    card: {
      cvv: '',
      token: selectedCard?.token,
    },
    user: {
      name: user.name,
      phone_number: user.phone_number,
      email: user.email,
    },
  };

  const validationSchema = Yup.object().shape({
    card: Yup.object().shape({
      cvv: Yup.string().required(),
    }),
    user: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone_number: Yup.string().required(),
    }),
  });

  const onSubmit = (values, setSubmitting) => {
    setPaymentFailed(false);

    const payload = { ...values, package_id: selectedPackage.id };

    api
      .generatePaymentLink(payload)
      .then((response) => {
        window.location.href = response.data.data.link;
      })
      .catch(() => {
        setPaymentFailed(true);
        setSubmitting(false);
      });
  };

  return (
    <Modal
      id="fawry-payment-modal"
      toggle={onToggle}
      centered={true}
      title={intl.formatMessage({ id: 'Complete Payment' })}
      isOpen={true}
      {...props}
    >
      {paymentFailed && (
        <Alert isOpen={true} color="danger">
          <FormattedMessage id="cc-payment-link-failed" />
        </Alert>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="CVV" />
                </Label>
                <Input
                  name="card.cvv"
                  value={values.card.cvv}
                  id="card-cvv"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.card?.cvv && touched.card?.cvv}
                />
              </FormGroup>

              <hr />

              <FormGroup>
                <Label>
                  <FormattedMessage id="Name" />
                </Label>
                <Input
                  name="user.name"
                  value={values.user.name}
                  id="user-name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.user?.name && touched.user?.name}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <FormattedMessage id="Email" />
                </Label>
                <Input
                  name="user.email"
                  value={values.user.email}
                  id="user-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.user?.email && touched.user?.email}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <FormattedMessage id="Phone Number" />
                </Label>
                <Input
                  name="user.phone_number"
                  value={values.user.phone_number}
                  id="user-phone-number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.user?.phone_number && touched.user?.phone_number}
                />
              </FormGroup>

              <hr />

              <Button type="submit" disabled={isSubmitting} color="primary" size="sm" block={true}>
                <FormattedMessage id="Pay" />
                {isSubmitting && <Spinner />}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const Fawry = ({ selectedPackage }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const trackers = useTrackers();

  const [loading, setLoading] = useState(false);
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const [referenceCode, setReferenceCode] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');

  const onPayNow = () => {
    setLoading(true);

    trackers.track('Button Clicked', {
      button: 'payment.fawry.pay_now',
    });

    dispatch(generateFawryReferenceCode(selectedPackage.id, phoneNumber)).then((r) => {
      setLoading(false);
      setReferenceCode(r);
      setShowPaymentConfirmation(true);
    });
  };

  const onPayAgain = () => {
    setPhoneNumber('');
    setReferenceCode(null);
    setShowPaymentConfirmation(false);
  };

  return (
    <div className="payment-method fawry">
      {!showPaymentConfirmation && (
        <React.Fragment>
          <div className="payment-steps">
            <p className="payment-steps-header">
              <FormattedMessage id="To pay using Fawry" />
            </p>

            <ol>
              <li>
                <div className="form-inline">
                  <span className="mr-1">
                    <FormattedMessage id="Enter your phone number" />
                  </span>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={intl.formatMessage({ id: 'Phone number' })}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                  />
                </div>
              </li>
              <li>
                <FormattedMessage id="Click on Pay Now" />
              </li>
              <li>
                <FormattedMessage id="Keep the generated reference code" />
              </li>
              <li>
                <FormattedMessage id="Pay at any of Fawry payment points" />
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <button
                className="btn btn-primary btn-lg btn-block"
                disabled={phoneNumber.length !== 11 || loading}
                onClick={onPayNow}
              >
                <FormattedMessage id="Pay Now" />
              </button>
              <p className="text-muted mb-0">
                <FormattedMessage id="You may be charged a service fee by Fawry" />
              </p>
            </div>
          </div>
        </React.Fragment>
      )}

      {showPaymentConfirmation && (
        <React.Fragment>
          <div className="mb-3">
            <p className="mb-1 payment-fawry-code">
              <FormattedMessage id="Your reference code is:" />{' '}
              <strong>{referenceCode.code}</strong>
            </p>

            <p className="mb-0 text-muted">
              <FormattedMessage id="Please, keep the reference code and pay at any Fawry payment point before" />{' '}
              <strong>{dayjs(referenceCode.expires_at).format('LLLL')}</strong>.
            </p>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <button className="btn btn-primary btn-lg btn-block" onClick={onPayAgain}>
                <FormattedMessage id="Pay Again" />
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const PaymentPage = () => {
  const [selectedMethod, setSelectedMethod] = useState('cc');
  const location = useLocation();
  const { selectedPackage } = location.state;

  return (
    <div className="page page-payment">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="payment-section">
              <h2 className="payment-label">
                <FormattedMessage id="Selected package" />:
              </h2>

              <SelectedPackage selectedPackage={selectedPackage} />
            </div>

            <div className="payment-section">
              <h2 className="payment-label">
                <FormattedMessage id="Select payment method" />:
              </h2>

              <div className="payment-options mb-3">
                <div
                  className={`payment-option mr-3 ${selectedMethod === 'cc' ? 'selected' : ''}`}
                  onClick={() => setSelectedMethod('cc')}
                >
                  <img
                    src={require('../assets/images/logo-visa.png')}
                    className="mr-1"
                    alt="visa logo"
                  />
                  <img
                    src={require('../assets/images/logo-mastercard.png')}
                    alt="mastercard logo"
                  />
                </div>

                <div
                  className={`payment-option ${selectedMethod === 'fawry' ? 'selected' : ''}`}
                  onClick={() => setSelectedMethod('fawry')}
                >
                  <img src={require('../assets/images/fawry.png')} alt="fawry logo" />
                </div>
              </div>

              {selectedMethod === 'fawry' && <Fawry selectedPackage={selectedPackage} />}

              {selectedMethod === 'cc' && <FawryCC selectedPackage={selectedPackage} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
