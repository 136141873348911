import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownToggle } from 'reactstrap';
import DatePicker, { getDefaultLocale } from 'react-datepicker';
import dayjs from 'dayjs';

const MatchFilters = (props) => {
  const { onFilter } = props;
  const [openedMenus, setOpenedMenus] = useState({
    from: false,
    to: false,
  });
  const [filters, setFilters] = useState({ from: null, to: null });

  const toggleMenu = (menu) => {
    setOpenedMenus(
      Object.keys(openedMenus).reduce((carry, newMenu) => {
        carry[newMenu] = newMenu === menu ? !openedMenus[newMenu] : false;
        return carry;
      }, {})
    );
  };

  const onDateChange = (field, newDate) => {
    onFilterChange(field, dayjs(newDate).format('YYYY-MM-DD'));
  };

  const onFilterChange = (field, newValue) => {
    setFilters({ ...filters, [field]: newValue });
  };

  useEffect(() => {
    const newFilters = { ...filters };

    Object.keys(filters).forEach((k) => {
      if (newFilters[k] === '') delete newFilters[k];
    });

    onFilter(newFilters);
  }, [filters]);

  return (
    <React.Fragment>
      <div className="match-filters">
        <div className="form-row align-items-center">
          <div className="col-auto">
            <h3 className="filter-title">
              <FormattedMessage id="Filter by" />:
            </h3>
          </div>

          <div className="col-auto">
            <Dropdown isOpen={openedMenus.from} toggle={() => toggleMenu('from')}>
              <DropdownToggle tag="a" caret>
                <FormattedMessage id="From" />
              </DropdownToggle>
              {openedMenus.from && (
                <DatePicker
                  dateFormat={getDefaultLocale() === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy'}
                  selected={filters.from ? dayjs(filters.from).toDate() : new Date()}
                  onSelect={(date) => onDateChange('from', date)}
                  inline={true}
                />
              )}
            </Dropdown>
          </div>
          <div className="col-auto">
            <Dropdown isOpen={openedMenus.to} toggle={() => toggleMenu('to')}>
              <DropdownToggle tag="a" caret>
                <FormattedMessage id="To" />
              </DropdownToggle>
              {openedMenus.to && (
                <DatePicker
                  dateFormat={getDefaultLocale() === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy'}
                  selected={filters.to ? dayjs(filters.to).toDate() : new Date()}
                  onSelect={(date) => onDateChange('to', date)}
                  inline={true}
                />
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatchFilters;
