import React from 'react';
import CustomInput from './CustomInput';

const EmailInput = (props) => {
  return (
    <CustomInput
      type="email"
      icon={<img src={require('../../assets/images/icon-envelope.svg')} alt="" />}
      {...props}
    />
  );
};

export default EmailInput;
