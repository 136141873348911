import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import config from '../config';

import useTrackers from '../hooks/useTrackers';

const ArabicMessage = ({ settings }) => {
  return (
    <React.Fragment>
      ادعى اصدقائك واحصل على <b>{settings.referral_reward_coins}</b> كوينز عند تفعيل الحساب الخاص
      بهم, واحصل على <b>{settings.referral_reward_coins_for_first_payment}</b> كوينز عند اول عملية
      شراء يقومون بها.
    </React.Fragment>
  );
};

const EnglishMessage = ({ settings }) => {
  return (
    <React.Fragment>
      Invite your friends and earn <b>{settings.referral_reward_coins}</b> coins once they activate
      their account, and <b>{settings.referral_reward_coins_for_first_payment}</b> coins once they
      make their first payment.
    </React.Fragment>
  );
};

const ReferringCard = (props) => {
  const { referralCode } = props;
  const referralUrl = `${config.baseUrl}/register?referrer=${referralCode}`;
  const { showGraphic } = props;
  const intl = useIntl();
  const trackers = useTrackers();
  const [copied, setCopied] = useState(false);
  const settings = useSelector((s) => s.generic.settings);

  const onCopy = () => {
    trackers.track('Button Clicked', { button: 'referring.invite_friend' });

    toast.success(intl.formatMessage({ id: 'referral_link_copied' }), {
      position: 'bottom-center',
    });

    setCopied(true);
  };

  return (
    <React.Fragment>
      <div className="referring-card">
        {showGraphic && (
          <img src={require('../assets/images/referring.png')} className="mb-n5" alt="" />
        )}

        <p className="mb-3 invite-message">
          {intl.locale === 'ar' ? (
            <ArabicMessage settings={settings} />
          ) : (
            <EnglishMessage settings={settings} />
          )}
        </p>

        <div className="form-group">
          <input type="text" readOnly={true} value={referralUrl} className="form-control" />
        </div>

        <div className="mb-3">
          <CopyToClipboard text={referralUrl} onCopy={onCopy}>
            <Button color="white">
              <FormattedMessage id={copied ? 'Copied' : 'Copy'} />{' '}
              {copied && <MdCheck size={18} className="icon-saved" />}
            </Button>
          </CopyToClipboard>
        </div>

        <p className="copy-link-message">{intl.formatMessage({ id: 'copy-link-message' })}</p>
      </div>
    </React.Fragment>
  );
};

ReferringCard.defaultProps = {
  showGraphic: true,
};

const mapStateToProps = (state) => {
  const referralCode = state.generic.userSettings.referral_code;
  return { referralCode };
};

export default connect(mapStateToProps)(ReferringCard);
