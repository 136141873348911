export const COMPETITION_STATE_UPCOMING = 'upcoming';
export const COMPETITION_STATE_PAST = 'past';
export const COMPETITION_STATE_RUNNING = 'running';

export const COMPETITION_TYPE_PREDICTIONS = 1;
export const COMPETITION_TYPE_FANTASY = 2;

export const MATCH_STATE_FINISHED = 'FINISHED';

export const QUESTION_TYPE_YES_NO = 'YES_NO';
export const QUESTION_TYPE_SELECT_LIST = 'SELECT_LIST';
export const QUESTION_TYPE_COUNTER = 'COUNTER';

export const TRANSACTION_STATUS_PENDING = 'pending';

export const IMAGE_FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const DEFAULT_LIST_SCHEMA = {
  items: [],
  meta: {
    current_page: 0,
    last_page: Infinity,
  },
  filters: {},
};

export const SCORE_BREAKDOWN_STATS_LIST = [
  'points',
  'playing-time',
  'goals',
  'assists',
  'cleansheets',
  'penalties',
  'cards',
  'successful-passes',
];

export const WALLET_ISSUERS = [
    'bank_wallet', 'aman', 'etisalat', 'vodafone', 'orange'
];

export const DEFAULT_LANDING_PAGE = '/predictions';

export const ENTRY_TEAM_PLAYER_STATUS_SIDELINED = 'sidelined';

export const IDENTIFIER_TYPE_PHONE_NUMBER = 'phone_number';
