import * as actionTypes from '../../constants/action-types/groups';
import { DEFAULT_LIST_SCHEMA } from '../../constants/generic';

const INIT_STATE = {
  myGroupsList: DEFAULT_LIST_SCHEMA,
  allGroupsList: DEFAULT_LIST_SCHEMA,
  recommendedGroupsList: DEFAULT_LIST_SCHEMA,
  groupDetails: {
    info: {},
    members: DEFAULT_LIST_SCHEMA,
    nonMembers: DEFAULT_LIST_SCHEMA,
    managers: DEFAULT_LIST_SCHEMA,
    ranking: { items: [], page: null, lastPage: null, loading: false, pending: false },
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_MY_GROUPS_LIST:
      return {
        ...state,
        myGroupsList: {
          ...state.myGroupsList,
          items: [...new Set(action?.items || INIT_STATE.myGroupsList.items)],
          meta: action?.meta || INIT_STATE.myGroupsList.meta,
          filters: action?.filters || INIT_STATE.myGroupsList.filters,
        },
      };
    case actionTypes.SAVE_ALL_GROUPS_LIST:
      return {
        ...state,
        allGroupsList: {
          ...state.allGroupsList,
          items: [...new Set(action?.items || INIT_STATE.allGroupsList.items)],
          meta: action?.meta || INIT_STATE.allGroupsList.meta,
          filters: action?.filters || INIT_STATE.allGroupsList.filters,
        },
      };
    case actionTypes.SAVE_RECOMMENDED_GROUPS_LIST:
      return {
        ...state,
        recommendedGroupsList: {
          ...state.recommendedGroupsList,
          items: [...new Set(action?.items || INIT_STATE.recommendedGroupsList.items)],
          meta: action?.meta || INIT_STATE.recommendedGroupsList.meta,
          filters: action?.filters || INIT_STATE.recommendedGroupsList.filters,
        },
      };
    case actionTypes.SAVE_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          info: action?.payload || INIT_STATE.groupDetails.info,
        },
      };
    case actionTypes.SAVE_GROUP_MEMBERS:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          members: {
            ...state.groupDetails.members,
            items: [...new Set(action?.items || INIT_STATE.groupDetails.members.items)],
            meta: action?.meta || INIT_STATE.groupDetails.members.meta,
            filters: action?.filters || INIT_STATE.groupDetails.members.filters,
          },
        },
      };
    case actionTypes.SAVE_NON_GROUP_MEMBERS:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          nonMembers: {
            ...state.groupDetails.nonMembers,
            items: [...new Set(action?.items || INIT_STATE.groupDetails.nonMembers.items)],
            meta: action?.meta || INIT_STATE.groupDetails.nonMembers.meta,
            filters: action?.filters || INIT_STATE.groupDetails.nonMembers.filters,
          },
        },
      };
    case actionTypes.SAVE_GROUP_MANAGERS:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          managers: {
            ...state.groupDetails.managers,
            items: [...new Set(action?.items || INIT_STATE.groupDetails.managers.items)],
            meta: action?.meta || INIT_STATE.groupDetails.managers.meta,
            filters: action?.filters || INIT_STATE.groupDetails.managers.filters,
          },
        },
      };
    case actionTypes.FETCH_GROUP_RANKING_START:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ranking: { ...state.groupDetails.ranking, loading: true },
        },
      };

    case actionTypes.CLEAR_GROUP_RANKING:

      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ranking: INIT_STATE.groupDetails.ranking,
        },
      };

    case actionTypes.FETCH_GROUP_RANKING_SUCCESS:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ranking: {
            items: [...state.groupDetails.ranking.items, ...action.payload.ranking],
            page: action.payload.meta.current_page,
            lastPage: action.payload.meta.last_page,
            pending: action.payload.meta.pending,
            loading: false,
          },
        },
      };

    case actionTypes.FETCH_GROUP_RANKING_FAILURE:
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          ranking: { ...state.groupDetails.ranking, loading: false },
        },
      };
    default:
      return { ...state };
  }
};
