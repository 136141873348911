import React from 'react';
import { Col, Row } from 'reactstrap';
import NotificationTime from './notification-time';

const Generic = ({ notification }) => {
  return (
    <div className="notification">
      <Row>
        <Col md={8}>
          <div className="content">
            <h3 className="notification-header">{notification.payload.title}</h3>
            <p className="notification-body">{notification.payload.body}</p>
          </div>
        </Col>
        <Col md={4}>
          <NotificationTime notification={notification} />
        </Col>
      </Row>
    </div>
  );
};

export default Generic;
