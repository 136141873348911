// @vendors
import { isEqual } from 'lodash';
// @api
import api from '../../helpers/api';
// @redux
import { saveUsersList } from './actions';
// @constants
import { DEFAULT_LIST_SCHEMA } from '../../constants/generic';

export function fetchUsersList(filters = {}) {
  return async function (dispatch, getState) {
    const {
      users: { usersList },
    } = getState();
    let currentList = usersList || {};
    // Check if not same filters
    if (!isEqual(currentList?.filters, filters)) {
      // Reset Items
      currentList = DEFAULT_LIST_SCHEMA;
    }
    // Check if there are more pages
    if (currentList?.meta?.current_page < currentList?.meta?.last_page) {
      await api
        .fetchUsersList({ ...filters, page: currentList?.meta?.current_page + 1 })
        .then((response) => response.data)
        .then((response) => {
          if (response?.data && response?.meta)
            dispatch(
              saveUsersList([...currentList?.items, ...response?.data], response?.meta, filters)
            );
        })
        .catch(console.error);
    }
  };
}
