// @vendors
import React from 'react';
import PropTypes from 'prop-types';
// @components
import Box from '../../Box';
import Text from '../../Text';
// @hocs
import withMediaQuery from '../../../hoc/withMediaQuery';
// @styles
import { PlayerImg, TeamImg } from './styles';

const CompetitionPlayerAvatar = ({
  image,
  name,
  number,
  teamImg,
  darkTheme,
  isMobile,
  ...rest
}) => (
  <Box
    width={{ xs: '7.75rem', md: '8.75rem' }}
    height={{ xs: '4.75rem', md: '5.75rem' }}
    position="relative"
    pie="3rem"
    flex="0 0 auto"
    {...rest}
  >
    <PlayerImg src={image} />
    <Text
      fontSize={{ xs: '1rem', md: '1.25rem' }}
      fontWeight="bold"
      color={darkTheme ? 'white' : 'black'}
      position="absolute"
      insetBlockStart="0"
      insetInlineStart="70%"
      whiteSpace="nowrap"
    >
      {isMobile ? String(name || '') : name}
    </Text>
    <Text
      fontSize={{ xs: '2rem', md: '2.25rem' }}
      fontWeight="bold"
      color="#fff"
      textStroke="1px #000"
      position="absolute"
      insetBlockStart="20%"
      insetInlineStart="70%"
    >
      {number}
    </Text>
    <TeamImg isMobile={isMobile} src={teamImg} />
  </Box>
);

CompetitionPlayerAvatar.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.number,
  teamImg: PropTypes.string.isRequired,
  darkTheme: PropTypes.bool,
};

CompetitionPlayerAvatar.defaultProps = {
  darkTheme: false,
};

export default withMediaQuery(CompetitionPlayerAvatar);
