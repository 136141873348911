import * as Yup from 'yup';

const bootstrap = () => {
  function equalTo(ref: any, msg: any) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      message: msg || '${path} must be the same as ${reference}',
      params: {
        reference: ref.path,
      },
      test: function (value: any) {
        return value === this.parent[ref];
      },
    });
  }

  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  Object.defineProperty(Array.prototype, 'chunk', {
    value: function (chunkSize) {
      let r = [];
      for (let i = 0; i < this.length; i += chunkSize) {
        r.push(this.slice(i, i + chunkSize));
      }

      return r;
    },
  });
};

bootstrap();
