import React from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';

const qs = require('query-string');

const AddCCStatusPage = () => {
  const location = useLocation();
  const intl = useIntl();

  const pqs = qs.parse(location.search);

  let status = 'succeeded';
  let message = intl.formatMessage({ id: 'add-cc-succeeded' });

  if (pqs['failed'] !== undefined) {
    status = 'failed';
    message = intl.formatMessage({ id: 'add-cc-failed' });
  }

  return (
    <div className="container-fluid">
      <div className="page page-add-cc-status">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <img
              className="status-icon"
              src={
                status === 'succeeded'
                  ? require('../assets/images/icon-check.png')
                  : require('../assets/images/icon-close.png')
              }
              alt=""
            />

            <h2 className="status-message">{message}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCCStatusPage;
