import React, { useEffect, useState } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompetitions } from '../../redux/competitions/thunks';
import { FormattedMessage } from 'react-intl';
import { clearCompetitions } from '../../redux/competitions/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CreateModal from '../../pages/competitions/components/create/Modal';
import CompetitionList from './CompetitionList';
import Filters from '../../pages/competitions/components/Filters';
import withMediaQuery from '../../hoc/withMediaQuery';
import { useHistory } from 'react-router';
import { isUserAuthenticated } from '../../helpers/auth';

const qs = require('query-string');

const fetchedTemplate = {
  running: false,
  upcoming: false,
  past: false,
};

const CompetitionLists = ({ type, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const [activeTab, setActiveTab] = useState('upcoming');
  const [fetched, setFetched] = useState({ ...fetchedTemplate });

  const authUser = useSelector((s) => s.auth.user);
  const { featured, upcoming, running, past } = useSelector(
    (s) => s.competitions.competitions[type]
  );

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const setInitialTab = () => {
    let pqs = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    if (pqs['running'] !== undefined) {
      toggle('running');
    } else if (pqs['past'] !== undefined) {
      toggle('past');
    }
  };

  useEffect(() => {
    setInitialTab();
    clearLists();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);

    if (!fetched[tab]) {
      dispatch(fetchCompetitions(type, tab, filters)).then(() => {
        setFetched({ ...fetched, [tab]: true });
      });
    }
  };

  const onFilter = (newFilters) => {
    setFetched({ ...fetchedTemplate });
    setFilters({ ...filters, ...newFilters });

    clearLists();

    dispatch(fetchCompetitions(type, 'featured', filters));
    dispatch(fetchCompetitions(type, activeTab, filters));
  };

  const clearLists = () => {
    dispatch(clearCompetitions(type, 'featured'));
    dispatch(clearCompetitions(type, 'running'));
    dispatch(clearCompetitions(type, 'upcoming'));
    dispatch(clearCompetitions(type, 'past'));
  };

  const isAuthenticated = isUserAuthenticated();

  return (
    <React.Fragment>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Container fluid={true}>
          <div className={`page page-competitions page-competitions-${type}`}>
            <div className="competition-topbar px-3 py-3 d-none">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <Filters onFilter={onFilter} />
                </div>
                {authUser?.creator && (
                  <div className="col-auto">
                    <button
                      className={`btn btn-primary btn-sm ${props.isMobile ? 'btn-block mt-3' : ''}`}
                      onClick={() => setCreateModalIsOpen(true)}
                    >
                      <FormattedMessage id="Create Competition" />
                    </button>
                  </div>
                )}
              </div>
            </div>

            {!!featured.items.length && (
              <div className="competitions-featured">
                <h2 className="section-header">
                  <FormattedMessage id="Featured" />
                </h2>

                <CompetitionList competitions={featured} type="featured" />
              </div>
            )}

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === 'upcoming' ? 'active' : ''}
                  onClick={() => toggle('upcoming')}
                >
                  <FormattedMessage id="Upcoming" />
                </NavLink>
              </NavItem>
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    className={activeTab === 'running' ? 'active' : ''}
                    onClick={() => toggle('running')}
                  >
                    <FormattedMessage id="Running" />
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    className={activeTab === 'past' ? 'active' : ''}
                    onClick={() => toggle('past')}
                  >
                    <FormattedMessage id="Past" />
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="upcoming">
                <CompetitionList competitions={upcoming} type={type} list="upcoming" />
              </TabPane>
              {isAuthenticated && (
                <TabPane tabId="running">
                  <CompetitionList competitions={running} type={type} list="running" />
                </TabPane>
              )}
              {isAuthenticated && (
                <TabPane tabId="past">
                  <CompetitionList competitions={past} type={type} list="past" />
                </TabPane>
              )}
            </TabContent>
          </div>
        </Container>
      </PerfectScrollbar>

      <CreateModal isOpen={createModalIsOpen} onToggle={() => setCreateModalIsOpen(false)} />
    </React.Fragment>
  );
};

export default withMediaQuery(CompetitionLists);
