import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import MatchFilters from '../MatchFilters';
import CompetitionWithMatches from '../matches/CompetitionWithMatches';
import { fetchMatches } from '../../redux/matches/thunks';
import { Nav, NavItem } from 'reactstrap';
import { useLocation, /*useHistory,*/ Link } from 'react-router-dom';
import { filterMatchesByState } from '../../redux/matches/actions';
import Loader from '../Loader';
import dayjs from 'dayjs';
import { getUpcomingDateWithMatches } from '../../redux/generic/thunks';

const qs = require('query-string');

const getFiltersFromQS = (location) => {
  const pqs = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { date, q, state, match } = pqs;

  return { date, q, state: state || 'ALL', match };
};

const Matches = (props) => {
  const location = useLocation();
  // const history = useHistory();
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    (async () => {
      const filters = getFiltersFromQS(location);
      filters.joined = true;
      if (!filters.date) {
        filters.date = await props.getUpcomingDateWithMatches();
      }
      setFilters(filters);
    })();
  }, []);

  useEffect(() => {
    if (filters) {
      //updateUrl();

      props.filterMatchesByState(filters.state);

      props.fetchMatches(filters).then(() => {
        setFirstLoad(false);
      });
    }
  }, [filters]);

  // const updateUrl = () => {
  //   const { date, state, match } = filters;

  //   history.push({
  //     pathname: '/matches',
  //     search: '?' + qs.stringify({ date, state, match }),
  //   });
  // };

  return (
    <React.Fragment>
      <div id="matches">
        <h2 className="home-section-header">
          <FormattedMessage id="Matches" />
        </h2>
        {firstLoad ? (
          <Loader />
        ) : (
          <React.Fragment>
            <MatchFilters onFilter={(filters) => setFilters(filters)} filters={filters} />

            <Nav tabs>
              <NavItem>
                <Link
                  to="/matches"
                  className={`nav-link ${props.matches.stateFilter === 'ALL' ? 'active' : ''}`}
                  onClick={() => setFilters({ ...filters, state: 'ALL' })}
                >
                  <FormattedMessage id="All Matches" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/matches?state=not-started"
                  className={`nav-link ${
                    props.matches.stateFilter === 'NOT_STARTED' ? 'active' : ''
                  }`}
                  onClick={() => setFilters({ ...filters, state: 'NOT_STARTED' })}
                >
                  <FormattedMessage id="Not Started" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/matches?state=live"
                  className={`nav-link ${props.matches.stateFilter === 'LIVE' ? 'active' : ''}`}
                  onClick={() => setFilters({ ...filters, state: 'LIVE' })}
                >
                  <FormattedMessage id="Live" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/matches?state=finished"
                  className={`nav-link ${props.matches.stateFilter === 'FINISHED' ? 'active' : ''}`}
                  onClick={() => setFilters({ ...filters, state: 'FINISHED' })}
                >
                  <FormattedMessage id="Finished" />
                </Link>
              </NavItem>
            </Nav>

            {props.matches.loading ? (
              <Loader />
            ) : !props.matches.items.length ? (
              <p className="no-matches">
                <FormattedMessage id="There are no matches on this day" />
              </p>
            ) : (
              props.matches.items.map((competition, i) => {
                return (
                  <CompetitionWithMatches
                    key={competition.id}
                    competition={competition}
                    first={i === 0}
                  />
                );
              })
            )}

            <div className="buttons clearfix">
              {props.matches.previousDate && (
                <div className="float-left">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        date: dayjs(props.matches.previousDate).format('YYYY-MM-DD'),
                      });
                    }}
                  >
                    <FormattedMessage id="Previous" />
                  </button>
                </div>
              )}
              {props.matches.nextDate && (
                <div className="float-right">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        date: dayjs(props.matches.nextDate).format('YYYY-MM-DD'),
                      });
                    }}
                  >
                    <FormattedMessage id="Next" />
                  </button>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { matches, loadedMatches } = state.matches;
  return { matches, loadedMatches };
};

export default connect(mapStateToProps, {
  fetchMatches,
  filterMatchesByState,
  getUpcomingDateWithMatches,
})(Matches);
