import React, { useEffect, useRef, useState } from 'react';
import { forgetPassword } from '../../redux/auth/thunks';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Row, Alert, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { isUserAuthenticated } from '../../helpers/auth';
import { Link, Redirect } from 'react-router-dom';
import FormTitle from '../../components/form/FormTitle';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import { locale } from '../../helpers/generic';
import { DEFAULT_LANDING_PAGE, IDENTIFIER_TYPE_PHONE_NUMBER } from '../../constants/generic';
import IdentifierInput from '../../components/form/IdentifierInput';
import { useHistory } from 'react-router';

function ForgetPasswordPage(props) {
  const initialValues = { identifier: '', recaptcha: '' };
  const validationSchema = Yup.object().shape({
    identifier: Yup.string().required(),
    recaptcha: Yup.string().required(),
  });
  const recaptchaEl = useRef(null);
  const currentLocale = locale();
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'Forget Password' }));
  }, []);

  const onSubmit = (values, setSubmitting) => {
    props
      .forgetPassword(values)
      .then((response) => {
        if (response.identifier_type === IDENTIFIER_TYPE_PHONE_NUMBER) {
          history.push({
            pathname: `/reset-password`,
            search: `identifier=${values.identifier}`,
          });
        } else {
          setMessage(intl.formatMessage({ id: 'password_reset_instructions' }));
          setMessageType('success');
        }
      })
      .catch(({ response }) => {
        recaptchaEl.current.reset();

        setMessage(response?.data?.errors?.identifier.concat());
        setMessageType('danger');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {isUserAuthenticated() && <Redirect to={DEFAULT_LANDING_PAGE} />}

      <AuthPageWrapper
        className="page-forget-password"
        description={intl.formatMessage({ id: 'meta.description.login' })}
      >
        <FormTitle>
          <FormattedMessage id="Welcome to Eksab" />
        </FormTitle>

        <Alert isOpen={!!message} color={messageType}>
          {message}
        </Alert>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="input-wrapper">
                <FormGroup className="mb-0">
                  <IdentifierInput
                    name="identifier"
                    id="identifier"
                    placeholder={intl.formatMessage({ id: 'enter_identifier' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.identifier}
                    invalid={errors.identifier && touched.identifier}
                  />
                </FormGroup>
              </div>

              <div className="mb-3 w-100">
                <ReCAPTCHA
                  sitekey={config.recaptcha.siteKey}
                  onChange={(token) => setFieldValue('recaptcha', token)}
                  hl={currentLocale}
                  ref={recaptchaEl}
                />
                {errors.recaptcha && touched.recaptcha && (
                  <p className="text-danger mb-0">
                    {intl.formatMessage({ id: 'recaptcha-error' })}
                  </p>
                )}
              </div>

              <Row className="justify-content-between align-items-center">
                <Col className="mt-1" md="6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="primary"
                    className="btn-lg w-100"
                  >
                    <FormattedMessage id="submit" />
                    {isSubmitting && <Spinner />}
                  </Button>
                </Col>
                <Col className="mt-1" md="6">
                  <p className="mb-0">
                    <FormattedMessage id="Changed your mind?" /> <br />
                    <Link to="/login">
                      <FormattedMessage id="Login" />
                    </Link>
                  </p>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </AuthPageWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { errors, identifier, identifier_type } = state.auth.forgetPassword;
  return { errors, identifier, identifier_type };
};

export default connect(mapStateToProps, { forgetPassword })(ForgetPasswordPage);
