import React, { useEffect } from 'react';
import { Alert, Badge, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaCoins } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { fetchMyPrizes } from '../../redux/profile/thunks';
import Loader from '../../components/Loader';
import { showPrizeRedemptionModal } from '../../redux/profile/actions';
import NoEntries from '../../components/NoEntries';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';

const PrizesPage = (props) => {
  const dispatch = useDispatch();
  const userPrizes = useSelector((s) => s.profile.prizes);
  const intl = useIntl();

  useEffect(() => {
    props.setDocumentTitle(intl.formatMessage({ id: 'My Prizes' }));

    if (userPrizes === null) {
      dispatch(fetchMyPrizes());
    }
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-store">
          <h2 className="section-header">
            <FormattedMessage id="My Prizes" /> <br />
            <small className="text-muted">
              <FormattedMessage id="prize-distribution" />
            </small>
          </h2>

          {!userPrizes ? (
            <Loader />
          ) : (
            <React.Fragment>
              {!userPrizes.length && (
                <NoEntries
                  message={
                    <React.Fragment>
                      <FormattedMessage id="join-more-competitions" />
                    </React.Fragment>
                  }
                />
              )}

              <EqualHeight>
                <div className="row">
                  {userPrizes.map((userPrize) => (
                    <div className="col-md-3" key={userPrize.id}>
                      <UserPrizeCard userPrize={userPrize} />
                    </div>
                  ))}
                </div>
              </EqualHeight>
            </React.Fragment>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

const UserPrizeCard = (props) => {
  const { userPrize } = props;
  const { prize } = userPrize;
  const dispatch = useDispatch();

  const onRedeem = () => {
    dispatch(showPrizeRedemptionModal(userPrize));
  };

  let latestRejectionReason = null;
  if (userPrize.status === 'rejected') {
    latestRejectionReason = userPrize.log
      .filter((e) => e.status === 'rejected')
      .slice(-1)
      .pop()?.['meta']['reason'];
  }

  return (
    <div className="user-prize-card">
      <EqualHeightElement name="cards">
        <div className="card-body">
          {prize ? (
            <img
              src={userPrize.prize.image.original}
              alt={userPrize.prize.name}
              className="user-prize-image"
            />
          ) : (
            <React.Fragment>
              <h3 className="user-prize-amount">
                <FaCoins className="icon-coins" />{' '}
                <NumberFormat
                  displayType="text"
                  value={userPrize.amount}
                  thousandSeparator={true}
                  className="amount"
                />{' '}
                <FormattedMessage id="EGP" className="currency" />{' '}
              </h3>
              <p className="text-muted mb-0">
                <FormattedMessage id="Id" />: <strong>{userPrize.id}</strong>
              </p>
              <div className="text-center">
                {userPrize.status === 'redeemed' && (
                  <Badge color="info">
                    <FormattedMessage id="Redeemed" />
                  </Badge>
                )}

                {userPrize.status === 'processing' && (
                  <Badge color="info">
                    <FormattedMessage id="Processing" />
                  </Badge>
                )}

                {userPrize.status === 'approved' && (
                  <Badge color="info">
                    <FormattedMessage id="Approved" />
                  </Badge>
                )}

                {userPrize.status === 'received' && (
                  <Badge color="success">
                    <FormattedMessage id="Received" />
                  </Badge>
                )}

                {userPrize.status === 'rejected' && (
                  <React.Fragment>
                    <Badge color="danger">
                      <FormattedMessage id="Rejected" />
                    </Badge>
                    <div className="px-3">
                      {latestRejectionReason && (
                        <Alert color="danger" className="mb-0 mt-3">
                          <FormattedMessage
                            id={`prize_redemption.rejection.reasons.${latestRejectionReason}`}
                          />
                        </Alert>
                      )}
                    </div>
                  </React.Fragment>
                )}

                {!!userPrize.extra_info && (
                  <React.Fragment>
                    <div className="px-3">
                      <Alert color="warning" className="mb-0 mt-3">
                        {userPrize.extra_info}
                      </Alert>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </EqualHeightElement>
      <div className="card-footer">
        <button
          className="btn btn-primary btn-block btn-lg"
          disabled={['pending', 'rejected'].indexOf(userPrize.status) === -1}
          onClick={() => onRedeem()}
        >
          <FormattedMessage id="Redeem" />
        </button>
      </div>
    </div>
  );
};

export default PrizesPage;
