import * as actionTypes from '../../constants/action-types/search';
import Paginator from '../../helpers/paginator';

const initResults = {
  items: [],
  paginator: new Paginator(),
  loading: false,
  selectedMatch: { id: null, loading: false },
};

const INIT_STATE = {
  results: initResults,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_SEARCH_RESULTS:
      return { ...state, results: initResults };
    case actionTypes.FETCH_SEARCH_RESULTS_START:
      return { ...state, results: { ...state.results, loading: true } };
    case actionTypes.FETCH_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          items: [...state.results.items, ...action.payload.results.map((match) => match.id)],
          paginator: action.payload.paginator,
          loading: false,
        },
      };
    case actionTypes.FETCH_SEARCH_RESULTS_FAILURE:
      return { ...state, results: { ...state.results, loading: false } };

    case actionTypes.SELECT_SEARCH_RESULT_START:
      return {
        ...state,
        results: { ...state.results, selectedMatch: { id: null, loading: true } },
      };
    case actionTypes.SELECT_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          selectedMatch: {
            ...state.results.selectedMatch,
            id: action.payload.matchId,
            loading: false,
          },
        },
      };

    default:
      return { ...state };
  }
};
