// @vendors
import React, { useEffect } from 'react';

const GroupRulesAr = () => {
  useEffect(() => {}, []);

  return (
    <div className="page-rules">
      <div className="rule-section">
        <p>يمكنك انشاء مجموعات جديدة ودعوة اصداقك لها.</p>

        <p>
          مديرين المجموعة يمكنهم انشاء مسابقات لاعضاء المجموعة. هذة المسابقات ستريح الفائز كوينز.
        </p>
      </div>

      <div>
        <h3 className="rule-header">قواعد #مجموعات_إكسب:</h3>

        <ul>
          <li>يمكنك الاختيار من بين نوعين من المسابقات (فانتازي، توقعات)</li>
          <li>
            المجموعات العامة تتيح لجميع أعضاء الموقع الاشتراك دون موافقة مسبقة من منظم المجموعة.
          </li>
          <li>تقتصر المجموعات الخاصة على الدعاوي فقط.</li>
          <li>
            المجموعات العامة تتيح لجميع أعضاء الموقع الاشتراك دون موافقة مسبقة من منظم المجموعة.
          </li>
          <li>
            البطولات المتاحة على المجموعات (الدوري الإنجليزي، الدوري الإيطالي، الدوري الإسباني،
            الدوري الأوروبي، دوري أبطال أوروبا).
          </li>
          <li>البطولات الكبرى المجمعة مثل (كأس العالم، كأس أمم أوروبا..الخ).</li>
          <li>
            مباريات مسابقة فانتازي المجموعات الفانتازي يجب أن تكون من نفس البطولة، عكس التوقعات لك
            حرية اختيار مباريات من بطولات مختلفة.
          </li>
          <li>منظم المجموعة ليس لديه الحق في تغيير أي تفاصيل بعد بداية المسابقة.</li>
          <li>
            يتم تحديد الجائزة بناءً على عدد المشاركين، يتم جمع رسوم المشاركة، ثم توزع الجوائز بناءً
            على نظام المجموعة.
          </li>
          <li>
            إذا لم يتم الوصول إلى الحد الأدنى للمشاركين في إحدى المجموعات قبل ساعة واحدة من بدء
            المسابقة، فسيتم رد رسوم الاشتراك لكل مشارك وسيتم إلغاء المسابقة تلقائيًا.
          </li>
          <li>يتم تحديد الحد الأدنى للمشاركين أثناء قيام المنظم بإنشاء المجموعة.</li>
        </ul>
      </div>
    </div>
  );
};

export default GroupRulesAr;
