import React, { useEffect } from 'react';
import useTrackers from "../../../hooks/useTrackers";

const FantasyCompetitionRulesAr = ({ competition }) => {
  const trackers = useTrackers();

  useEffect(() => {
    trackers.track('Page View', { page: `Competition`, name: competition.name, tab: 'Overview' });
  }, []);

  return (
    <React.Fragment>
      <div className="page-rules">
        <p>فى مسابقات الفانتزى, المتسابقون يختارون فريق ويحصلو على نقاط تبعا لاداء اللاعبين.</p>

        <p>يتم ترتيب المتسابقين بناءا على عدد النقاط المجمعة (تفاصيل نظام النقط لاحقا).</p>

        <div className="rule-section">
          <h3 className="rule-header">النقاط</h3>

          <div className="row">
            <div className="col-md-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="2">نظام النقاط</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>لعب اقل من 60 دقيقة</td>
                    <td>+1 نقطة</td>
                  </tr>
                  <tr>
                    <td>لعب 60 دقيقة او اكثر</td>
                    <td>+2 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل هدف تم تسجيله عن طريق حارس المرمى او مدافع</td>
                    <td>+6 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل هدف تم تسجيله عن طريق لاعب خط وسط</td>
                    <td>+5 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل هدف تم تسجيله عن طريق مهاجم</td>
                    <td>+4 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل مساعدة على هدف</td>
                    <td>+3 نقطة</td>
                  </tr>
                  <tr>
                    <td>شباك نظيف لحارس المرمى او المدافع (لعب 60 دقيقة او كثر)</td>
                    <td>+4 نقطة</td>
                  </tr>
                  <tr>
                    <td>شباك نظيف للاعب خط الوسط (لعب 60 دقيقة او كثر)</td>
                    <td>+1 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل صد ضربة جزاء</td>
                    <td>+5 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل اضاعة لضربة جزاء</td>
                    <td>-2 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل كارت اصفر</td>
                    <td>-1 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل كارت احمر</td>
                    <td>-3 نقطة</td>
                  </tr>
                  <tr>
                    <td>لكل هدف عكسى</td>
                    <td>-2 نقطة</td>
                  </tr>
                  <tr>
                    <td>تمريرة صحيحة</td>
                    <td>+0.02 نقطة</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">قواعد عامة</h3>

          <ul>
            <li>كل مستخدم يمكن ان يملك حساب واحد فقط على اكسب.</li>
            <li>يجب تقديم اثبات شخصية حتى تستلم الجائزة الخاصة بك.</li>
            <li>يمكنك تغيير فريقك فى اى وقت حتى قبل بداية اول مبارة فى المسابقة.</li>
            <li>يجب عليك الاشتراك فى المسابقة قبل بداية اول مبارة.</li>
            <li>يمكنك الغاء اشتراكك فى المسابقة فى اى وقت قبل بداية اول مبارة.</li>
          </ul>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">توزيع الجوائز</h3>

          <ul>
            <li>يتم توزيع الجوائز المعلن عنها فى تفاصيل المسابقة.</li>
            <li>
              اذا انتهى اكثر من شخص فى نفس الترتيب, سيتم تقسيم الجائزة بينهم. على سبيل المثال, اذا
              حصل مستخدمين على المركز الاول, سيتم الغاء المركز الثانى وتوزيع جائزة المركز الاول
              والثانى على المستخدمين بالتساوى.
            </li>
            <li>
              على سبيل المثال, اذا كانت جائزة المركز الاول 6,000 والمركز الثانى 3,000 والمركز الثالث
              1,000. وانتهى 2 مستخدمين فى المركز الاول, سيتم تقسيم جائزة المركز الاول والثانى عليهم
              بالتساوى. اى يحصل كل منهم على 4,500. ويحصل متسابق المركز الثالث على 1,000.
            </li>
            <li>سيتم تسليم الجوائز فى خلال شهر من تاريخ انتهاء المسابقة.</li>
          </ul>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">نظام حساب ومراجعة النقاط عند نهاية المسابقة</h3>

          <p>
            اكسب تستخدم نظام تلقائى لحساب النقاط. قد يحدث بعض التغييرات احيانا على النقاط. ستقوم
            اكسب دائما بمراجعة النقاط الخاصة بالفائزيين قبل تسيلم الجوائز للتأكد من سلامتها.
          </p>
        </div>

        <div className="rule-section">
          <h3 className="rule-header">الترتيب</h3>

          <p>
            نتيجة لعدد الكبير من المستخدمين والتوقعات, قد يحتاج نظام اكسب الى 24 ساعة ليقوم بتحديث قائمة
            الترتيب. سنعمل جاهدين لضمان توزيع النقاط بشكل سليم.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FantasyCompetitionRulesAr;
