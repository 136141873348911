import styled from 'styled-components';

export const CloseIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  background-color: black;
  border-radius: 50%;
  z-index: 1;
`;

export const PlayerImg = styled.img`
  width: 1.125rem;
  height: 1.125rem;
  margin-inline-end: 0.125rem;
`;
