export const FETCH_MY_PREDICTIONS_START = 'FETCH_MY_PREDICTIONS_START';
export const FETCH_MY_PREDICTIONS_SUCCESS = 'FETCH_MY_PREDICTIONS_SUCCESS';
export const FETCH_MY_PREDICTIONS_FAILURE = 'FETCH_MY_PREDICTIONS_FAILURE';

export const SELECT_MY_PREDICTIONS_MATCH_START = 'SELECT_MY_PREDICTIONS_MATCH_START';
export const SELECT_MY_PREDICTIONS_MATCH_SUCCESS = 'SELECT_MY_PREDICTIONS_MATCH_SUCCESS';
export const SELECT_MY_PREDICTIONS_MATCH_FAILURE = 'SELECT_MY_PREDICTIONS_MATCH_FAILURE';

export const UNSELECT_MY_PREDICTIONS_MATCH = 'UNSELECT_MY_PREDICTIONS_MATCH';

export const ADD_MATCH_TO_MY_PREDICTIONS = 'ADD_MATCH_TO_MY_PREDICTIONS';

export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const RECEIVE_FRIENDS = 'RECEIVE_FRIENDS';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const RECEIVE_USER_PRIZES = 'RECEIVE_USER_PRIZES';
export const RECEIVE_USER_NEW_PRIZE_COUNT = 'RECEIVE_USER_NEW_PRIZE_COUNT';

export const SHOW_PRIZE_REDEMPTION_MODAL = 'SHOW_PRIZE_REDEMPTION_MODAL';
export const HIDE_PRIZE_REDEMPTION_MODAL = 'HIDE_PRIZE_REDEMPTION_MODAL';

export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
