// @vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../Box';
import Text from '../../Text';
import CompetitionPlayerAvatar from '../CompetitionPlayerAvatar';
// @styles
import { CloseIcon, ArrowIcon, SelectButton, NewsImage } from './styles';
// @static
import closeIcon from '../../../assets/images/icon-close.svg';
import modalHeaderImg from '../../../assets/images/lineup-player-select-header.jpg';

const CompetitionLineupPlayerInfo = ({ value, selectedPlayers, onSelect, onClose, ...rest }) => {
  const [activeTab, setActiveTab] = useState('stats'); // can be 'news'
  const [featuredStats, setFeaturedStats] = useState({});
  const [regularStats, setRegularStats] = useState({});

  useEffect(() => {
    if (value?.stats) {
      const metrics = value?.stats;
      let newFeaturedStats = {};
      let newRegularStats = {};
      Object.keys(metrics).forEach((metricKey) => {
        // Metric is valid
        if (metrics?.[metricKey]?.value === 0 || metrics?.[metricKey]?.value) {
          if (metrics?.[metricKey]?.featured)
            newFeaturedStats[metricKey] = { ...metrics[metricKey] };
          else newRegularStats[metricKey] = { ...metrics[metricKey] };
        }
      });
      setFeaturedStats(newFeaturedStats);
      setRegularStats(newRegularStats);
    }
  }, [value]);

  return (
    <Box display="flex" flexDirection="column" width="100%" {...rest}>
      {/* Header */}
      <Box
        position="relative"
        display="flex"
        width="100%"
        height="7.5rem"
        justifyContent="flex-start"
        alignItems="center"
        backgroundImage={`url('${modalHeaderImg}')`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        p="0 1rem"
        pie="4rem"
      >
        <CompetitionPlayerAvatar
          mb="0"
          mt="auto"
          name={value?.common_name}
          image={value?.image?.original}
          number={value?.number}
          teamImg={value?.team?.logo?.original}
          darkTheme
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mie="0"
          mis="auto"
        >
          <Text fontSize="1.375rem" fontWeight="bold" color="#5ad37c">
            ${value?.price}
            <FormattedMessage id="M" />
          </Text>
          <Text fontSize="0.75rem" fontWeight="400" color="#2c9eff">
            <FormattedMessage id={`CompetitionLineupPlayerSelector.Title.${value?.position}`} />
          </Text>
        </Box>
        <CloseIcon src={closeIcon} onClick={onClose} />
      </Box>
      {/* Metrics */}
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflowY="auto"
        height="Calc(100% - 7.5rem)"
        p="2rem 0"
      >
        <Box
          width="80%"
          borderBottom="1px solid rgba(175, 173, 178, 0.5)"
          pb="1.25rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {featuredStats &&
            Object.keys(featuredStats).map((statsKey, index) => (
              <Box
                key={statsKey}
                display="flex"
                flexDirection="column"
                alignItems="center"
                p="0 1.25rem"
                borderInlineStart={index !== 0 ? '1px solid #ebeaeb' : ''}
              >
                <Text fontSize="0.75rem" fontWeight="400" color="#34495e" mb="0.25rem">
                  <FormattedMessage id={`CompetitionPlayerCard.Stats.${statsKey}`} />
                </Text>
                <Text fontSize="0.75rem" fontWeight="bold" color="#000000">
                  {featuredStats?.[statsKey]?.value}
                </Text>
              </Box>
            ))}
        </Box>
        {/* Tabs */}
        <Box className="row" width="80%" py="0.375rem">
          <Box
            className="col-6"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="0.5rem"
            borderInlineEnd="1px solid rgba(175, 173, 178, 0.5)"
          >
            <Text
              fontSize="1rem"
              fontWeight="600"
              color={activeTab === 'stats' ? '#5ad37c' : '#34495e'}
              cursor="pointer"
              onClick={() => setActiveTab('stats')}
            >
              <FormattedMessage id="CompetitionLineupPlayerInfo.TabStats" />
            </Text>
          </Box>
          <Box
            className="col-6"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="0.5rem"
          >
            <Text
              fontSize="1rem"
              fontWeight="600"
              color={activeTab === 'news' ? '#5ad37c' : '#34495e'}
              cursor="pointer"
              onClick={() => setActiveTab('news')}
            >
              <FormattedMessage id="CompetitionLineupPlayerInfo.TabNews" />
            </Text>
          </Box>
        </Box>
        {/* Body */}
        <Box display="flex" justifyContent="center" width="100%">
          {/* Prev Icon */}
          <ArrowIcon onClick={() => setActiveTab(activeTab === 'stats' ? 'news' : 'stats')} />
          {/* Stats Card */}
          {activeTab === 'stats' && (
            <Box width="80%" border="1px solid #34495e" borderRadius="0.625rem" p="0.75rem">
              {/* Inner Card */}
              <Box
                width="100%"
                border="1px solid #afadb2"
                borderRadius="0.375rem"
                p="0.25rem 0.625rem"
                disply="flex"
                flexDirection="column"
              >
                {/* Item */}
                {Object?.keys(regularStats)?.length ? (
                  Object.keys(regularStats).map((statsKey, index) => (
                    <Box
                      key={statsKey}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBlockStart={index !== 0 ? '1px solid #afadb2' : ''}
                      py="0.25rem"
                    >
                      <Text fontSize="0.75rem" color="#000000">
                        <FormattedMessage id={`CompetitionPlayerCard.Stats.${statsKey}`} />
                      </Text>
                      <Text fontSize="0.75rem" color="#201f2f">
                        {regularStats?.[statsKey]?.value}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text width="100%" textAlign="center">
                    <FormattedMessage id="CompetitionLineupPlayerInfo.StatsEmpty" />
                  </Text>
                )}
              </Box>
            </Box>
          )}
          {/* News Cards List */}
          {activeTab === 'news' && (
            <Box width="80%" maxHeight="14.5rem" overflowY="auto">
              {value?.latest_news?.length ? (
                value?.latest_news?.map((news, index) => (
                  <Box
                    width="100%"
                    display="flex"
                    borderRadius="0.375rem"
                    bg="rgba(215, 214, 216, 0.2)"
                    key={`news_${news?.id}`}
                    mt={index !== 0 ? '0.625rem' : ''}
                  >
                    <NewsImage src={news?.image?.original} />
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      p="0.625rem 1rem"
                      justifyContent="center"
                    >
                      <Text fontSize="0.875rem" fontWeight="bold" color="#201f2f" mb="0.25rem">
                        {news?.title}
                      </Text>
                      <Text fontSize="0.75rem" color="#201f2f">
                        {news?.content}
                      </Text>
                    </Box>
                  </Box>
                ))
              ) : (
                <Text width="100%" textAlign="center">
                  <FormattedMessage id="CompetitionLineupPlayerInfo.NewsEmpty" />
                </Text>
              )}
            </Box>
          )}
          {/* Next Icon */}
          <ArrowIcon
            onClick={() => setActiveTab(activeTab === 'stats' ? 'news' : 'stats')}
            isNext
          />
        </Box>
        {/* Add/Remove Button */}
        <SelectButton
          className={`btn ${
            find(selectedPlayers, { id: value?.id })?.id ? 'btn-danger' : 'btn-primary'
          }`}
          onClick={() => onSelect(value?.position, value)}
        >
          {find(selectedPlayers, { id: value?.id })?.id ? (
            <FormattedMessage id="CompetitionLineupPlayerInfo.RemoveButtonText" />
          ) : (
            <FormattedMessage id="CompetitionLineupPlayerInfo.AddButtonText" />
          )}
        </SelectButton>
      </Box>
    </Box>
  );
};

CompetitionLineupPlayerInfo.propTypes = {
  value: PropTypes.shape({}).isRequired,
  selectedPlayers: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

CompetitionLineupPlayerInfo.defaultProps = {
  selectedPlayers: [],
  onSelect: () => {},
  onClose: () => {},
};

export default CompetitionLineupPlayerInfo;
