import React from 'react';
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { locale } from '../helpers/generic';

const Modal = ({ title, footer, children, toggle, noModalBody, ...rest }) => {
  return (
    <React.Fragment>
      <BaseModal toggle={toggle} {...rest} dir={locale() === 'ar' ? 'rtl' : 'ltr'}>
        {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}

        {noModalBody ? children : <ModalBody>{children}</ModalBody>}

        {!!footer && <ModalFooter>{footer}</ModalFooter>}
      </BaseModal>
    </React.Fragment>
  );
};

export default Modal;
