import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FaBaseballBall, FaList, FaCheck } from 'react-icons/fa';

function Navigation(props) {
  const { step } = props;

  return (
    <React.Fragment>
      <div className={`navigation d-flex align-items-center justify-content-between step${step}`}>
        <h2 className={`navigation-step ${step > 0 ? 'checked' : ''}`}>
          <FaList className="icon" /> <br />
          <span className="step-name">
            <FormattedMessage id="Details" />
          </span>
        </h2>

        <h2 className={`navigation-step ${step > 1 ? 'checked' : ''}`}>
          <FaBaseballBall className="icon" /> <br />
          <span className="step-name">
            <FormattedMessage id="Select Matches" />
          </span>
        </h2>

        <h2 className={`navigation-step ${step > 2 ? 'checked' : ''}`}>
          <FaCheck className="icon" /> <br />
          <span className="step-name">
            <FormattedMessage id="Review" />
          </span>
        </h2>
      </div>
    </React.Fragment>
  );
}

export default Navigation;
