import { locale } from '../helpers/generic';
import Ad from './Ad';
import React from 'react';

const Ads = () => {
  const ads = {
    ar: [
      {
        title: 'اشترك فى جروب اكسب',
        body: 'اشترك الان فى جروب اكسب على فيسبوك وتابع اخر اخبار المسابقات!',
      },
    ],
    en: [
      {
        title: 'Join Eksab group',
        body: 'Join Eksab group on Facebook and join new competitions!',
      },
    ],
  };

  return ads[locale()].map((ad, i) => <Ad key={i} {...ad} />);
};

export default Ads;
