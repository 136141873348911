// @vendors
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
// @components
import Box from '../../Box';
import Text from '../../Text';
// @helpers
import { isElementInViewport } from '../../../helpers/generic';
// @styles
import { ResetButton } from './styles';
import withMediaQuery from '../../../hoc/withMediaQuery';

const CompetitionSquadSelectionCard = ({
  playersNo,
  money,
  deadline,
  onReset,
  isMobile,
  ...rest
}) => {
  const containerEl = useRef();
  const [showMiniCard, setMiniCard] = useState(false);

  useEffect(() => {
    if (containerEl?.current) {
      window.addEventListener('scroll', () => {
        setMiniCard(!isElementInViewport(containerEl?.current));
      });
    }
  }, []);

  return (
    <Box
      width="100%"
      bg="#f9fafd"
      border="1px solid #e5e4e6"
      p="0.5rem"
      borderRadius="0.625rem"
      ref={containerEl}
      {...rest}
    >
      <Box
        width="100%"
        p="0.5rem"
        border="1px solid #e5e4e6"
        bg="#fff"
        display="flex"
        borderRadius="0.3125rem"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          width="100%"
          pie={!isMobile ? '1rem' : 0}
          pb={isMobile ? '.5rem' : 0}
          mb={isMobile ? '.5rem' : 0}
          borderInlineEnd={!isMobile ? '1px solid #afadb2' : 0}
          borderBottom={isMobile ? '1px solid #afadb2' : 0}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            borderBottom="1px solid #afadb2"
            pb="0.5rem"
            mb="0.5rem"
          >
            <Box display="flex" alignItems="center" mie=".5rem" mb={{ xs: '0.5rem', md: '0' }}>
              <Text fontSize="1rem" fontWeight="bold">
                <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.PlayersSelected" />
              </Text>
              <Text fontSize="1.5rem" fontWeight="bold" color="#fc2c5d" mis=".5rem">
                {playersNo}/15
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Text fontSize="1rem" fontWeight="bold">
                <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.MoneyRemaining" />
              </Text>
              <Text fontSize="1.5rem" fontWeight="bold" color="#2c9eff" mis=".5rem">
                ${money}
                <FormattedMessage id="M" />
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Text fontSize="0.875rem" color="#000" mie="1.125rem">
              <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.ResetText" />
            </Text>
            <ResetButton type="button" onClick={onReset} disabled={!playersNo}>
              <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.ResetButton" />
            </ResetButton>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minWidth="max-content"
          pis="1rem"
        >
          <Text fontSize="0.75rem" fontWeight="bold" color="#000" mb="0.5rem">
            <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.GameDeadline" />
          </Text>
          <Text fontSize="1rem" fontWeight="bold" color="#fc2c5d">
            {dayjs(deadline).format('ddd D MMM. YYYY')}
          </Text>
        </Box>
      </Box>
      {/* Mini Card */}
      <Box
        alignItems="center"
        position="fixed"
        width="100%"
        padding="0.5rem 1rem"
        background={`linear-gradient(90deg,#5ad37c ${(playersNo * 100) / 15}%,#000 ${
          (playersNo * 100) / 15
        }%)`}
        top="0"
        left="0"
        zIndex="1001"
        display={showMiniCard ? 'grid' : 'none'}
        gridTemplateColumns="repeat(2, 1fr)"
        gridGap="0.5rem"
      >
        <Box textAlign="left">
          <Text fontSize="1rem" fontWeight="bold" color="#fff">
            <FormattedMessage id="CompetitionPage.CompetitionSquadSelectionCard.MiniCardMoney" /> $
            {money}
            <FormattedMessage id="M" />
          </Text>
        </Box>
        <Box textAlign="right">
          <Text fontSize="1rem" fontWeight="bold" color="#fff">
            {playersNo}/15
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

CompetitionSquadSelectionCard.propTypes = {
  playersNo: PropTypes.number,
  money: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  deadline: PropTypes.string.isRequired,
  onReset: PropTypes.func,
};

CompetitionSquadSelectionCard.defaultProps = {
  playersNo: 0,
  money: 0,
  onReset: () => {},
};

export default withMediaQuery(CompetitionSquadSelectionCard);
