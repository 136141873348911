import React from 'react';
import BaseSelect from 'react-select';
import {useIntl} from "react-intl";

export const Select = (props) => {
    const intl = useIntl();

    return (
        <BaseSelect placeholder={`${intl.formatMessage({id: 'Select'})}...`} {...props} />
    );
};
