import React from 'react';
import { Spinner } from 'reactstrap';

function Loader(props) {
  return (
    <React.Fragment>
      <div className="loader">
        <Spinner size={props.size || 'lg'} />
      </div>
    </React.Fragment>
  );
}

export default Loader;
