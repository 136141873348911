import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getInitSelectedMatches } from '../../../../helpers/generic';

const MatchPreviewList = (props) => {
  const { title } = props;
  const matchIds = props.matchIds.map((id) => parseInt(id));
  const fetchedMatches = useSelector((s) => s?.matches?.official);
  const [matches] = useState(Object.values(getInitSelectedMatches(matchIds, fetchedMatches)));

  return (
    <React.Fragment>
      <div className="match-list-wrapper">
        <h3 className="list-title">{title}</h3>

        <div className="match-list">
          {matches.map((match) => {
            return (
              <div className="list-match" key={match?.id}>
                <div className="row">
                  <div className="col-5">
                    <div className="team home-team">
                      <img src={match?.home_team?.logo?.thumb} alt={match?.home_team?.name} />
                      <span className="team-name" title={match?.home_team?.name}>
                        {match?.home_team?.name}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <FormattedMessage id="vs" />
                  </div>
                  <div className="col-5">
                    <div className="team away-team">
                      <span className="team-name" title={match.away_team.name}>
                        {match?.away_team?.name}
                      </span>
                      <img src={match?.away_team?.logo?.thumb} alt={match?.away_team?.name} />
                    </div>
                  </div>
                </div>

                <p className="mb-0 starts-at">
                  <b>
                    <FormattedMessage id="Starts at" />:
                  </b>{' '}
                  {dayjs(match?.start).format('YYYY-MM-DD')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatchPreviewList;
