import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Info from './Info';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { getEksabFees, getTotalCoins } from '../../../../helpers/generic';
import { useSelector } from 'react-redux';

function Step1(props) {
  const {
    fantasyCompetitions,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
  } = props;
  const intl = useIntl();
  // const settings = useSelector((s) => s.generic.settings);

  return (
    <React.Fragment>
      <div className="step step1">
        <Row>
          <Col sm={4}>
            <FormGroup>
              <Label for="name">
                <FormattedMessage id="Pick a Competition Name" />
              </Label>
              <Input
                type="text"
                className="form-control"
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!(touched.name && errors.name)}
                placeholder={intl.formatMessage({ id: 'Pick a Competition Name' })}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="type">
                <FormattedMessage id="Select a Competition Type" />
              </Label>
              <Input
                type="select"
                className="form-control"
                name="type"
                value={values.type}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!(touched.type && errors.type)}
              >
                <option value="">{intl.formatMessage({ id: 'Select a Competition Type' })}</option>
                <option value="1">{intl.formatMessage({ id: 'prediction' })}</option>
                <option value="2">{intl.formatMessage({ id: 'Fantasy' })}</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        {values.type === '2' && (
          <Row>
            <Col sm={4}>
              <FormGroup>
                <Label for="budget">
                  <FormattedMessage id="Budget" />
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  name="budget"
                  value={values.budget}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={!!(touched.budget && errors.budget)}
                />
              </FormGroup>
            </Col>

            <Col sm={4}>
              <FormGroup>
                <Label for="type">
                  <FormattedMessage id="League" />
                </Label>
                <Input
                  type="select"
                  className="form-control"
                  value={values.fantasy_competition_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('fantasy_competition_id', e.target.value);
                    setFieldValue('match_ids', []);
                  }}
                  invalid={!!(touched.fantasy_competition_id && errors.fantasy_competition_id)}
                >
                  <option value="">{intl.formatMessage({ id: 'Select league' })}</option>

                  {fantasyCompetitions.map((c) => (
                    <option value={c.id}>{c.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={4}>
            <Row className="align-items-center">
              <Col xs={8}>
                <FormGroup>
                  <Label for="entry-fee">
                    <FormattedMessage id="Set the Entry Fee" />
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="entry-fee"
                    name="entry_fee"
                    value={values.entry_fee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    invalid={!!(touched.entry_fee && errors.entry_fee)}
                  />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormattedMessage id="coins" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="entry-fee">
                <FormattedMessage id="Set Distribution of Prize" />
              </Label>
              <Input
                type="select"
                className="form-control"
                name="winning_strategy"
                value={values.winning_strategy}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!(touched.winning_strategy && errors.winning_strategy)}
              >
                <option value="">{intl.formatMessage({ id: 'Set Distribution of Prize' })}</option>
                <option value="top-1">{intl.formatMessage({ id: 'top-1' })}</option>
                <option value="top-3">{intl.formatMessage({ id: 'top-3' })}</option>
                <option value="top-10">{intl.formatMessage({ id: 'top-10' })}</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <div className="form-group">
          <label htmlFor="name">
            <FormattedMessage id="Choose Number of Participants" />
          </label>
          <InputRange
            maxValue={100}
            minValue={1}
            value={values.participants}
            onChange={(value) => setFieldValue('participants', value)}
          />
        </div>

        <Row className="mt-3">
          <Col>
            <Info label={intl.formatMessage({ id: 'Total Coins' })} value={getTotalCoins(values)} />
          </Col>
          <Col>
            <Info label={intl.formatMessage({ id: 'Eksab Fees' })} value={getEksabFees(values)} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Step1;
