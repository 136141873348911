import React from 'react';

function Tag({ title, children, color = 'secondary', ...props }) {
  let className = `tag-${color} ${props.className}`;

  return (
    <React.Fragment>
      <span className={`tag ${className}`} title={title}>
        {children}
      </span>
    </React.Fragment>
  );
}

export default Tag;
