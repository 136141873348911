import React from 'react';

const Info = (props) => {
  const { label, value } = props;

  return (
    <React.Fragment>
      <div className="modal-info">
        <h4 className="modal-info-label">{label}</h4>
        <div className="modal-info-value">{value}</div>
      </div>
    </React.Fragment>
  );
};

export default Info;
