import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchCompetitions } from '../../../redux/competitions/thunks';

const CompetitionList = (props) => {
  const { competitions, render, entities } = props;

  const onLoadMore = () => {
    props.fetchCompetitions(props.type);
  };

  return (
    <div className="competition-list">
      {render(competitions, entities)}

      {competitions.page < competitions.lastPage && (
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Button color="secondary" className="btn-block btn-load-more" onClick={onLoadMore}>
              <FormattedMessage id="Load More" />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { entities } = state.competitions;
  return { entities };
};

export default connect(mapStateToProps, { fetchCompetitions })(CompetitionList);
