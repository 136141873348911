import React, { useState, useEffect, memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import FullPageLoader from './components/FullPageLoader';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import { setTutorialStatus } from './redux/generic/actions';
import { Cookies } from 'react-cookie';
import { fetchCreators, fetchSettings } from './redux/generic/thunks';
import { Route, Switch } from 'react-router';
import routes from './routes/index';
import { ToastContainer } from 'react-toastify';
import withLayout from './hoc/withLayout';
import NotFoundPage from './pages/not-found';
import config from './config';
import PrizeRedemptionModal from './components/PrizeRedemptionModal';
import theme from './constants/theme';
import { useMixPanel } from 'react-mixpanel-provider-component';
import DownloadAppPage from './pages/download-app';
import { initFacebookSdk } from './helpers/init-facebook-sdk';
import useAccount from './hooks/useAccount';
import { getToken } from './helpers/auth';
import useTrackers from './hooks/useTrackers';
import { changeLocale, locale } from './helpers/generic';
import { fetchMe } from './redux/auth/thunks';
import { fetchMyFriends, fetchMyNewPrizeCount } from './redux/profile/thunks';
import { fetchJoinedCompetitionIds } from './redux/competitions/thunks';
import { fetchPrizes } from './redux/prizes/thunks';
import { setAccessToken } from './redux/auth/actions';
import * as Sentry from '@sentry/react';

import UnsubscribeNoticeModal from './components/Unsubscribe';

Sentry.init({
  dsn: config.sentry.dsn,
});

const Loading = () => <FullPageLoader />;

const AppLayout = Loadable({
  loader: () => import('./layouts/AppLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading: Loading,
});

const FormLayout = Loadable({
  loader: () => import('./layouts/FormLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading: Loading,
});

const MemoizedAppLayout = memo(AppLayout);
const MemoizedFormLayout = memo(FormLayout);

const App = (props) => {
  const [loading, setLoading] = useState(true);
  const { mixpanel } = useMixPanel();
  const account = useAccount();
  const trackers = useTrackers();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.auth.user);

  const token = getToken();

  const initApp = async () => {
    mixpanel.init(config.mixpanel.token);

    await Promise.all([props.fetchSettings(), initFacebookSdk()]);

    if (!token) {
      const facebookLogin = await account.getFacebookLoginStatus();
      if (facebookLogin.status) {
        await account.loginUsingFacebook(facebookLogin.accessToken);
      }
    } else {
      await bootstrapCurrentUser();
    }
  };

  useEffect(() => {
    if (me) trackers.identify(me);
  }, [me]);

  const bootstrapCurrentUser = async () => {
    const currentLocale = locale();

    const me = await dispatch(fetchMe());
    if (me.settings?.language !== currentLocale) {
      changeLocale(me.settings?.language);
    }

    dispatch(fetchMyFriends());

    await Promise.all([
      dispatch(fetchJoinedCompetitionIds()),
      dispatch(fetchPrizes()),
      dispatch(fetchMyNewPrizeCount()),
      dispatch(fetchCreators()),
    ]);

    dispatch(setAccessToken(token));
  };

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, []);

  const cookies = new Cookies();
  if (!cookies.get('tutorial')) {
    props.setTutorialStatus('active');
    cookies.set('tutorial', 'shown');
  }

  if (loading) return <Loading />;

  const formRoutes = routes.form.map((r) => r.path);
  const appRoutes = routes.app.map((r) => r.path);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path={appRoutes} component={() => withLayout(MemoizedAppLayout)} exact={true} />
          <Route path={formRoutes} component={() => withLayout(MemoizedFormLayout)} exact={true} />
          <Route path="/download-app" component={DownloadAppPage} exact />
          <Route component={NotFoundPage} exact={true} />
        </Switch>

        <ToastContainer />

        <PrizeRedemptionModal />

        <UnsubscribeNoticeModal />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default connect(null, { setTutorialStatus, fetchSettings })(App);
