import { useMixPanel } from 'react-mixpanel-provider-component';

export default function useMixPanelProfile() {
  const { mixpanel } = useMixPanel();

  const identifyUser = (user) => {
    mixpanel.people.set({ $name: user.name, $email: user.email, $coins: user.coins, $friendCount: user.friend_count });
    mixpanel.identify(user.id);
  };

  return { identifyUser };
}
