import * as actionTypes from '../../constants/action-types/groups';

export const saveMyGroupsList = (items, meta, filters) => ({
  type: actionTypes.SAVE_MY_GROUPS_LIST,
  items,
  meta,
  filters,
});

export const saveAllGroupsList = (items, meta, filters) => ({
  type: actionTypes.SAVE_ALL_GROUPS_LIST,
  items,
  meta,
  filters,
});

export const saveRecommendedGroupsList = (items, meta, filters) => ({
  type: actionTypes.SAVE_RECOMMENDED_GROUPS_LIST,
  items,
  meta,
  filters,
});

export const saveGroupDetails = (payload) => ({
  type: actionTypes.SAVE_GROUP_DETAILS,
  payload,
});

export const saveGroupMembers = (items, meta, filters) => ({
  type: actionTypes.SAVE_GROUP_MEMBERS,
  items,
  meta,
  filters,
});

export const saveNonGroupMembers = (items, meta, filters) => ({
  type: actionTypes.SAVE_NON_GROUP_MEMBERS,
  items,
  meta,
  filters,
});

export const saveGroupManagers = (items, meta, filters) => ({
  type: actionTypes.SAVE_GROUP_MANAGERS,
  items,
  meta,
  filters,
});

export const fetchGroupRankingStart = () => ({
  type: actionTypes.FETCH_GROUP_RANKING_START,
});

export const clearGroupRanking = () => ({
  type: actionTypes.CLEAR_GROUP_RANKING
});

export const fetchGroupRankingSuccess = (ranking, meta) => ({
  type: actionTypes.FETCH_GROUP_RANKING_SUCCESS,
  payload: { ranking, meta },
});

export const fetchGroupRankingFailure = () => ({
  type: actionTypes.FETCH_GROUP_RANKING_FAILURE,
});
