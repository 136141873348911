import React from 'react';
import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';
import theme from '../constants/theme';

const InlineEditButton = ({ onClick, className }) => {
  return (
    <StyledButton onClick={onClick} className={className}>
      <FaEdit />
    </StyledButton>
  );
};

const StyledButton = styled.span`
  cursor: pointer;
  color: ${(p) => p.color ?? theme.colors.primary};
`;

export default InlineEditButton;
