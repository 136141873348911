import styled from 'styled-components';
import ArrowLeftIconImg from '../../../assets/images/arrow-left-icon.svg';

export const CloseIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`;

export const ArrowIcon = styled.img.attrs((props) => ({ src: ArrowLeftIconImg, ...props }))`
  width: 10%;
  max-width: 1.5rem;
  margin: 0 1rem;
  cursor: pointer;
  ${({ isNext }) =>
    isNext &&
    `
    transform: rotate(180deg);
    transform-origin: center center;
  `}
`;

export const SelectButton = styled.button`
  width: 80%;
  margin-top: 1rem;
`;

export const NewsImage = styled.img`
  max-width: 30%;
`;
