import React from 'react';
import dayjs from 'dayjs';

const Time = ({ time, format = 'DD/MM/YYYY HH:mm' }) => {
  time = dayjs(time);

  return (
    <React.Fragment>
      <span dir="ltr">{time.format(format)}</span>
    </React.Fragment>
  );
};

export default Time;
