import * as actionTypes from '../../constants/action-types/search';

export const clearSearchResults = () => ({
  type: actionTypes.CLEAR_SEARCH_RESULTS,
});

export const fetchSearchResultsStart = (q) => ({
  type: actionTypes.FETCH_SEARCH_RESULTS_START,
  q: { q },
});

export const fetchSearchResultsSuccess = (results, paginator) => ({
  type: actionTypes.FETCH_SEARCH_RESULTS_SUCCESS,
  payload: { results, paginator },
});

export const fetchSearchResultsFailure = () => ({
  type: actionTypes.FETCH_SEARCH_RESULTS_FAILURE,
});

export const selectSearchResultStart = () => ({
  type: actionTypes.SELECT_SEARCH_RESULT_START,
});

export const selectSearchResultSuccess = (matchId) => ({
  type: actionTypes.SELECT_SEARCH_RESULT_SUCCESS,
  payload: { matchId },
});

export const selectSearchResultFailure = () => ({
  type: actionTypes.SELECT_SEARCH_RESULT_FAILURE,
});
