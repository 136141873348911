// theme.js
const breakpoints = ['320px', '576px', '768px', '992px', '1200px'];

// aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const colors = {
  primary: '#5ad37c',
  secondary: '#34495e',
  grey: {
    light: '#ccc',
    medium: '#7a7a7a',
    dark: '#434343',
  },
};

export default {
  breakpoints,
  colors,
};
