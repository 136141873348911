import React, { useState } from 'react';
import { connect } from 'react-redux';
import { redeemVoucher } from '../redux/generic/thunks';
import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { ERROR_CODE_REDEEMED_VOUCHER } from '../constants/errors';
import useTrackers from "../hooks/useTrackers";
import {DEFAULT_LANDING_PAGE} from "../constants/generic";

function RedeemVoucher(props) {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState('');
  const [alert, setAlert] = useState(null);
  const intl = useIntl();
  const history = useHistory();
  const trackers = useTrackers();

  const onRedeem = () => {
    setSubmitting(true);
    setAlert(null);

    props
      .redeemVoucher(code)
      .then(() => {
        setSuccess(true);

          trackers.track('Promo Code Redemption');

        setTimeout(() => {
          toast.info(intl.formatMessage({ id: 'join-competition' }));
          history.push({ pathname: DEFAULT_LANDING_PAGE });
        }, 1000);
      })
      .catch((err) => {
        let messageId = 'invalid-voucher';
        let code = err.response?.data?.code;

        if (code === ERROR_CODE_REDEEMED_VOUCHER) {
          messageId = 'redeemed-voucher';
        }

        setAlert(intl.formatMessage({ id: messageId }));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div id="charge-prepaid-card" className="card mt-4">
      <h2 className="card-header">
        <FormattedMessage id="Promo Codes" />
      </h2>

      <div className="card-body">
        {alert && <Alert color="danger">{alert}</Alert>}

        <FormGroup className="mb-0">
          <Label for="code">
            <FormattedMessage id="Promo Code" />:
          </Label>
          <Input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="xxxx"
          />
        </FormGroup>
      </div>

      <div className="card-footer">
        <Button color="primary-01" onClick={onRedeem} disabled={submitting} block>
          <FormattedMessage id="Redeem" /> {success && <MdCheck size={24} />}
        </Button>
      </div>
    </div>
  );
}

export default connect(null, { redeemVoucher })(RedeemVoucher);
