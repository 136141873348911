import React, { createRef, useEffect, useRef, useState } from 'react';
import { Input as BaseInput } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

let _ = require('lodash');

const OtpInput = ({ onEntered, label, value: initialValue, ...rest }) => {
  const [value, setValue] = useState(initialValue?.split('') ?? []);

  const inputs = useRef(_.times(6, () => createRef()));

  useEffect(() => {
    value.length === 6 && onEntered(value.join(''));
  }, [value]);

  const onChange = (i, v) => {
    let newValue = [...value];
    newValue[i] = v;

    setValue(newValue);

    const nextIndex = i + 1;
    if (v && nextIndex < inputs.current.length) {
      inputs.current[nextIndex].current.focus();
    }
  };

  return (
    <React.Fragment>
      <div className="otp-input-wrapper">
        <label>{label ?? <FormattedMessage id="OTP" />}</label>
        <div className="row">
          {_.times(6, (i) => (
            <div className="col" key={i}>
              <Input
                index={i}
                onChange={onChange}
                innerRef={inputs.current[i]}
                value={value[i]}
                {...rest}
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const Input = ({ index, onChange, ...rest }) => {
  return (
    <BaseInput
      {...rest}
      className="otp-input"
      maxLength={1}
      onChange={(e) => {
        onChange(index, e.target.value);
      }}
    />
  );
};

export default OtpInput;
