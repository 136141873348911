import React from 'react';
import { Input as BaseInput } from 'reactstrap';

const CustomInput = ({ icon, addon, ...rest }) => {
  return (
    <div className="custom-input-wrapper">
      <span className="icon-wrapper">{icon}</span>
      <BaseInput {...rest} />
      {!!addon && addon}
    </div>
  );
};

export default CustomInput;
