import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default function withMediaQuery(WrappedComponent) {
  return function (props) {
    const isDesktop = useMediaQuery({
      query: '(min-device-width: 1224px)',
    });
    const isMobile = useMediaQuery({
      query: '(max-device-width: 1224px)',
    });

    props = { ...props, isDesktop, isMobile };

    return <WrappedComponent {...props} />;
  };
}
