import React from 'react';
import { useHistory, useLocation } from 'react-router';

export default (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const location = useLocation();

    const onRedirect = (fallback, override) => {
      if (override) {
        return history.replace({ pathname: override, state: location.state });
      }

      history.replace(location.state && location.state.from ? location.state.from : fallback);
    };

    return <WrappedComponent {...props} redirect={onRedirect} />;
  };
};
