import React from 'react';
import { FormattedMessage } from 'react-intl';

import GoldMedal from '../../assets/images/ranking-1st.png';
import SliverMedal from '../../assets/images/ranking-2nd.png';
import BronzeMedal from '../../assets/images/ranking-3rd.png';
import ProfileIcon from '../../assets/images/user.png';

const RankingItem = ({ item = {}, hideRanking, action }) => {
  const { ranking, points, user } = item;

  const getMedal = (rank) => (rank === 1 ? GoldMedal : rank === 2 ? SliverMedal : BronzeMedal);

  return (
    <>
      <div className="ranking-item">
        <div className="ranking-left">
          {!hideRanking && (
            <>
              {ranking > 3 ? (
                <span className="ranking">{ranking}</span>
              ) : (
                <img className="ranking" src={getMedal(ranking)} alt="" />
              )}
            </>
          )}

          <img
            className="ranking-user-picture"
            src={user.photo?.thumb ?? item.user.photo?.original ?? ProfileIcon}
            alt={`${item.user.name}'s avatar`}
          />

          <div className="d-flex flex-column">
            <h2 className="ranking-user-name">{user.name}</h2>

            {action && <div className="ranking-user-action mt-1">{action(item)}</div>}
          </div>
        </div>

        {!hideRanking && (
          <h2 className="ranking-points">
            {points} <FormattedMessage id="Pts" />
          </h2>
        )}
      </div>
    </>
  );
};

export default RankingItem;
