// @vendors
import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// @components
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Modal from '../../../components/Modal';
import ListGroups from '../../../components/groups/ListGroups';
import CreateGroupModal from '../../../components/groups/CreateGroupModal';
// @redux
import {
  saveMyGroupsList,
  saveAllGroupsList,
  saveRecommendedGroupsList,
} from '../../../redux/groups/actions';
import {
  fetchMyGroupsList,
  fetchAllGroupsList,
  fetchRecommendedGroupsList,
  joinGroup,
} from '../../../redux/groups/thunks';
import {
  selectMyGroupsList,
  selectAllGroupsList,
  selectRecommendedGroupsList,
} from '../../../redux/groups/selectors';
// @helpers
import { isUserAuthenticated } from '../../../helpers/auth';
// @styles
import { SearchBoxContainer } from './styles';
import useTrackers from '../../../hooks/useTrackers';

const GroupsListPage = () => {
  const trackers = useTrackers();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { id: userId } = useSelector((state) => state?.auth?.user || {});
  // const [activeTab, setActiveTab] = useState(null); // can be 'mine' or 'all'
  const [filters, setFilters] = useState({});
  const myGroups = useSelector(selectMyGroupsList());
  const allGroups = useSelector(selectAllGroupsList());
  const recommendedGroups = useSelector(selectRecommendedGroupsList());
  // Create Group Modal Form
  const [isCreateGroupModal, showCreateGroupModal] = useState(false);
  const activeTab = history?.location?.pathname?.includes('/all') ? 'all' : 'mine';
  const isCreateGroupModalParam = history?.location?.pathname?.includes('/create');
  const activeGroupsList = activeTab === 'mine' ? myGroups : allGroups;

  const handleFetchGroupsList = () => {
    if (activeTab === 'mine') dispatch(fetchMyGroupsList(filters));
    else if (activeTab === 'all') dispatch(fetchAllGroupsList(filters));
  };

  const handleFetchRecommendedList = () => {
    dispatch(fetchRecommendedGroupsList());
  };

  useEffect(() => {
    // Get Recommended Groups list
    handleFetchRecommendedList();
    // Set Active Tab
    if (!isUserAuthenticated() && activeTab === 'mine')
      history.push('/groups/all' + (history?.location?.search || ''));
    // Check to automatically trigger the create modal
    if (isCreateGroupModalParam) handleCreateGroupModalToggle(true);
    // Set search value from param
    setFilters({ ...filters, q: history?.location?.search?.substring(8) || null });
  }, []);

  useEffect(() => {
    // Get Current Groups list
    handleFetchGroupsList();
  }, [filters, activeTab]);

  useEffect(() => {
    return () => {
      // Clear state on page leave
      dispatch(saveMyGroupsList());
      dispatch(saveAllGroupsList());
      dispatch(saveRecommendedGroupsList());
    };
  }, [dispatch]);

  const handleTabChange = (tabType) => {
    if (activeTab !== tabType) {
      // Reset Filters
      setFilters({});
      // Set Active tab
      history.push(`/groups${tabType === 'all' ? '/all' : ''}`);
    }
  };

  const handleSearchGroupsInput = (e) => {
    if (e?.target?.value)
      history.replace(`${history?.location?.pathname}?search=${e?.target?.value}`);
    else history.replace(`${history?.location?.pathname}`);

    setFilters({ ...filters, q: e?.target?.value || null });
  };

  const handleGroupClick = (item) => {
    if (isUserAuthenticated() && userId && item?.id) {
      if (item?.joined) {
        // Go to overview page
        history.push(`/groups/${item?.id}`);
      } else if (item?.type === 'public') {
        // Join Public Group
        dispatch(joinGroup(item?.id));
      } else {
        // Go to overview page when Private Group
        history.push(`/groups/${item?.id}`);
      }
    } else {
      // Redirect to Login
      history.push('/login');
    }
  };

  const handleCreateGroupModalToggle = (open = false) => {
    if (isUserAuthenticated()) {
      // show/hide create group modal
      showCreateGroupModal(open);
      if (open !== isCreateGroupModalParam) {
        if (open)
          history.push(`${history?.location?.pathname}/create${history?.location?.search || ''}`);
        else
          history.push(
            `${history?.location?.pathname.replace('/create', '')}${
              history?.location?.search || ''
            }`
          );
      }
    } else {
      // Redirect to Login
      history.push('/login');
    }
  };

  useEffect(() => {
    trackers.track('Page View', { page: `Groups` });
  }, []);

  return (
    <Box width="100%" p="1.25rem 2rem">
      {/* Tabs */}
      <Box
        display="flex"
        justifyContent="space-between"
        p={{ xs: '1rem 0.75rem', md: '0.875rem 1.5rem' }}
        bg="#fff"
        boxShadow="0 3px 10px 0 rgba(0, 0, 0, 0.10)"
        borderRadius="0.5rem"
        mb="2.75rem"
        flexWrap={{ xs: 'wrap', md: 'initial' }}
      >
        <Box
          flexShrink="0"
          width={{ xs: '100%', md: 'auto' }}
          display="flex"
          justifyContent="center"
        >
          {isUserAuthenticated() && (
            <Text
              fontSize="1rem"
              color={activeTab === 'mine' ? '#5ad37c' : '#201f2f'}
              cursor="pointer"
              onClick={() => handleTabChange('mine')}
              p="0.75rem 1.375rem"
              borderInlineEnd="1px solid #e4e8f0"
              mie="1.375rem"
              pis="0"
            >
              <FormattedMessage id="My Groups" />
            </Text>
          )}
          <Text
            fontSize="1rem"
            color={activeTab === 'all' ? '#5ad37c' : '#201f2f'}
            cursor="pointer"
            onClick={() => handleTabChange('all')}
            p="0.75rem 1.375rem"
            pis="0"
          >
            <FormattedMessage id="All Groups" />
          </Text>
        </Box>
        <SearchBoxContainer
          className="input-group"
          maxWidth={{ xs: 'auto', md: '25rem' }}
          height="100%"
          order={{ xs: '3', md: 'initial' }}
          mt={{ xs: '0.625rem', md: '0' }}
        >
          <div className="input-group-prepend">
            <span className="input-group-text" id="search-groups-input">
              <FiSearch />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: 'Search using group name...' })}
            aria-label={intl.formatMessage({ id: 'Search using group name...' })}
            aria-describedby="search-groups-input"
            onChange={handleSearchGroupsInput}
            value={filters?.q || ''}
          />
        </SearchBoxContainer>
        <Box flexShrink="0" width="100%" maxWidth="15.625rem" mt={{ xs: '1rem', md: '0' }}>
          <button
            className="btn btn-primary w-100"
            onClick={() => handleCreateGroupModalToggle(true)}
          >
            <FormattedMessage id="Create new group" />
          </button>
        </Box>
      </Box>
      {/* Recommended List */}
      {activeTab === 'all' && !filters?.q && (
        <>
          <Text fontSize="1rem" fontWeight="bold" color="#201f2f" mb="1rem">
            <FormattedMessage id="Recommended Groups" />
          </Text>
          {recommendedGroups?.items?.length > 0 && (
            <>
              <ListGroups
                id="recommened_groups_list"
                width="100%"
                items={recommendedGroups?.items || []}
                loadMore={handleFetchRecommendedList}
                hasMore={false}
                onClick={handleGroupClick}
              />
              <Box width="100%" borderBottom="1px solid #707070" opacity="0.5" mb="1.25rem" />
            </>
          )}
        </>
      )}
      {/* Groups List */}
      <Text fontSize="1rem" fontWeight="bold" color="#201f2f" mb="1rem">
        <FormattedMessage id={activeTab === 'mine' ? 'My Groups' : 'All Groups'} />
      </Text>
      {activeGroupsList?.items?.length > 0 ? (
        <ListGroups
          id="active_groups_list"
          width="100%"
          items={activeGroupsList?.items || []}
          loadMore={handleFetchGroupsList}
          hasMore={activeGroupsList?.meta?.current_page < activeGroupsList?.meta?.last_page}
          onClick={handleGroupClick}
        />
      ) : (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Text fontSize="1rem" color="#201f2f" mb="1rem">
            <FormattedMessage id="no_joined_groups_message" />
          </Text>
          <button className="btn btn-primary" onClick={() => handleCreateGroupModalToggle(true)}>
            <FormattedMessage id="Create new group" />
          </button>
        </Box>
      )}
      {/* Create Group Modal */}
      {isCreateGroupModal && (
        <Modal
          isOpen
          toggle={() => handleCreateGroupModalToggle(false)}
          id="create-group-modal"
          backdrop="static"
          noModalBody
          contentClassName="rounded-0 bg-transparent border-0"
        >
          <CreateGroupModal
            onClose={() => {
              handleCreateGroupModalToggle(false);
            }}
            maxHeight="84vh"
          />
        </Modal>
      )}
    </Box>
  );
};

export default GroupsListPage;
