import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tag from '../Tag';

const PointsWon = ({ points }) => {
  return (
    points !== undefined && (
      <React.Fragment>
        <Tag color="primary">
          <FormattedMessage id="You won" /> {points} <FormattedMessage id="Pts" />
        </Tag>
      </React.Fragment>
    )
  );
};
export default PointsWon;
