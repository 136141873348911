import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import MyRanking from '../../../components/ranking/MyRanking';
import RankingList from '../../../components/ranking/RankingList';
import {
  fetchCompetitionRanking,
  fetchCompetitionRankingBreakdown,
} from '../../../redux/competitions/thunks';
import { fetchMyRanking } from '../../../redux/ranking/thunks';
import Paginator from '../../../helpers/paginator';
import { FormattedMessage } from 'react-intl';
import useTrackers from '../../../hooks/useTrackers';
import { Button } from 'reactstrap';
import { COMPETITION_TYPE_FANTASY } from '../../../constants/generic';
import CompetitionRankingBreakdown from './CompetitionRankingBreakdown';

function CompetitionRanking(props) {
  const { competition } = props;
  const competitionProfile = useSelector((s) => s.competitions.entities[competition.id]);
  const trackers = useTrackers();
  const rankingType = `competition-${competition.id}`;
  const dispatch = useDispatch();

  useEffect(() => {
    trackers.track('Page View', { page: `Competition`, name: competition.name, tab: 'Ranking' });

    const seasonId = props.entities[competition.id].season.id;

    props.fetchCompetitionRanking(seasonId);

    if (props.authUser) {
      props.fetchMyRanking(rankingType, { 'season-id': seasonId });
    }
  }, []);

  const onLoadMore = () => {
    props.fetchCompetitionRanking(props.entities[competition.id].season.id);
  };

  const { page: current_page, lastPage: last_page } = competition.ranking;
  const paginator = new Paginator({ current_page, last_page });

  const onFetchTeamSetup = (rankingItem) => {
    dispatch(fetchCompetitionRankingBreakdown(competition.id, rankingItem));
  };

  return (
    <React.Fragment>
      {competition.ranking.pending ? (
        <p className="text-muted">
          <FormattedMessage id="pending-ranking-list-message" />
        </p>
      ) : (
        <div className="competition-ranking">
          {props.myRanking[rankingType]?.item && (
            <div className="my-ranking-wrapper">
              <MyRanking item={props.myRanking[rankingType].item} />
            </div>
          )}

          <RankingList
            items={competition.ranking.items}
            loading={competition.ranking.loading}
            paginator={paginator}
            onLoadMore={onLoadMore}
            action={
              competitionProfile.type === COMPETITION_TYPE_FANTASY
                ? (rankingListItem) => (
                    <Button
                      size="xs"
                      color="secondary"
                      onClick={() => onFetchTeamSetup(rankingListItem)}
                    >
                      <FormattedMessage id="View Team Setup" />
                    </Button>
                  )
                : null
            }
            breakdown={competition.ranking.breakdown && <CompetitionRankingBreakdown />}
          />
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { competition, entities } = state.competitions;
  const { myRanking } = state.ranking;
  const { user: authUser } = state.auth;
  return { competition, entities, myRanking, authUser };
};

export default connect(mapStateToProps, { fetchCompetitionRanking, fetchMyRanking })(
  CompetitionRanking
);
