export const styledLogicalProperties = {
  marginBlock: {
    property: 'marginBlock',
    scale: 'space',
  },
  marginBlockEnd: {
    property: 'marginBlockEnd',
    scale: 'space',
  },
  mbe: {
    property: 'marginBlockEnd',
    scale: 'space',
  },
  marginBlockStart: {
    property: 'marginBlockStart',
    scale: 'space',
  },
  mbs: {
    property: 'marginBlockStart',
    scale: 'space',
  },
  marginInline: {
    property: 'marginInline',
    scale: 'space',
  },
  marginInlineEnd: {
    property: 'marginInlineEnd',
    scale: 'space',
  },
  mie: {
    property: 'marginInlineEnd',
    scale: 'space',
  },
  marginInlineStart: {
    property: 'marginInlineStart',
    scale: 'space',
  },
  mis: {
    property: 'marginInlineStart',
    scale: 'space',
  },
  paddingBlock: {
    property: 'paddingBlock',
    scale: 'space',
  },
  paddingBlockEnd: {
    property: 'paddingBlockEnd',
    scale: 'space',
  },
  pbe: {
    property: 'paddingBlockEnd',
    scale: 'space',
  },
  paddingBlockStart: {
    property: 'paddingBlockStart',
    scale: 'space',
  },
  pbs: {
    property: 'paddingBlockStart',
    scale: 'space',
  },
  paddingInline: {
    property: 'paddingInline',
    scale: 'space',
  },
  paddingInlineEnd: {
    property: 'paddingInlineEnd',
    scale: 'space',
  },
  pie: {
    property: 'paddingInlineEnd',
    scale: 'space',
  },
  paddingInlineStart: {
    property: 'paddingInlineStart',
    scale: 'space',
  },
  pis: {
    property: 'paddingInlineStart',
    scale: 'space',
  },
  inset: {
    property: 'inset',
    scale: 'space',
  },
  insetBlock: {
    property: 'insetBlock',
    scale: 'space',
  },
  insetBlockEnd: {
    property: 'insetBlockEnd',
    scale: 'space',
  },
  insetBlockStart: {
    property: 'insetBlockStart',
    scale: 'space',
  },
  insetInline: {
    property: 'insetInline',
    scale: 'space',
  },
  insetInlineEnd: {
    property: 'insetInlineEnd',
    scale: 'space',
  },
  insetInlineStart: {
    property: 'insetInlineStart',
    scale: 'space',
  },
  borderBlock: {
    property: 'borderBlock',
    scale: 'borders',
  },
  borderBlockEnd: {
    property: 'borderBlockEnd',
    scale: 'borders',
  },
  borderBlockEndStyle: {
    property: 'borderBlockEndStyle',
    scale: 'borderStyles',
  },
  borderBlockEndWidth: {
    property: 'borderBlockEndWidth',
    scale: 'borderWidths',
  },
  borderBlockStart: {
    property: 'borderBlockStart',
    scale: 'borders',
  },
  borderBlockStartStyle: {
    property: 'borderBlockStartStyle',
    scale: 'borderStyles',
  },
  borderBlockStartWidth: {
    property: 'borderBlockStartWidth',
    scale: 'borderWidths',
  },
  borderBlockStyle: {
    property: 'borderBlockStyle',
    scale: 'borderStyles',
  },
  borderBlockWidth: {
    property: 'borderBlockWidth',
    scale: 'borderWidths',
  },
  borderEndEndRadius: {
    property: 'borderEndEndRadius',
    scale: 'radii',
  },
  borderEndStartRadius: {
    property: 'borderEndStartRadius',
    scale: 'radii',
  },
  borderInline: {
    property: 'borderInline',
    scale: 'borders',
  },
  borderInlineEnd: {
    property: 'borderInlineEnd',
    scale: 'borders',
  },
  borderInlineEndStyle: {
    property: 'borderInlineEndStyle',
    scale: 'borderStyles',
  },
  borderInlineEndWidth: {
    property: 'borderInlineEndWidth',
    scale: 'borderWidths',
  },
  borderInlineStart: {
    property: 'borderInlineStart',
    scale: 'borders',
  },
  borderInlineStartStyle: {
    property: 'borderInlineStartStyle',
    scale: 'borderStyles',
  },
  borderInlineStartWidth: {
    property: 'borderInlineStartWidth',
    scale: 'borderWidths',
  },
  borderInlineStyle: {
    property: 'borderInlineStyle',
    scale: 'borderStyles',
  },
  borderInlineWidth: {
    property: 'borderInlineWidth',
    scale: 'borderWidths',
  },
  borderStartEndRadius: {
    property: 'borderStartEndRadius',
    scale: 'radii',
  },
  borderStartStartRadius: {
    property: 'borderStartStartRadius',
    scale: 'radii',
  },
  blockSize: {
    property: 'blockSize',
    scale: 'sizes',
  },
  inlineSize: {
    property: 'inlineSize',
    scale: 'sizes',
  },
  maxBlockSize: {
    property: 'maxBlockSize',
    scale: 'sizes',
  },
  maxInlineSize: {
    property: 'maxInlineSize',
    scale: 'sizes',
  },
  minBlockSize: {
    property: 'minBlockSize',
    scale: 'sizes',
  },
  minInlineSize: {
    property: 'minInlineSize',
    scale: 'sizes',
  },
};
