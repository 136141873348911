import React, { useEffect } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import ProfileTabs from '../../components/profile/Tabs';
import ProfileSidebar from '../../components/profile/Sidebar';
import { fetchNotifications } from '../../redux/profile/thunks';
import { useIntl } from 'react-intl';
import Loader from '../../components/Loader';
import PrizeRedemptionRequestRejected from './notifications/prize-redemption-request-rejected';
import InvitedToGroup from './notifications/invited-to-group';
import Generic from './notifications/generic';

function ProfileNotificationsPage(props) {
  const intl = useIntl();
  useEffect(() => {
    props.setDocumentTitle([
      intl.formatMessage({ id: 'Notifications' }),
      intl.formatMessage({ id: 'Profile' }),
    ]);

    props.fetchNotifications();
  }, []);

  const { items, loading } = props.notifications;

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page page-profile page-profile-notifications">
          <Row>
            <Col md={3} className="col-sidebar">
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="profile-content">
                <ProfileTabs activeTab="notifications" />

                <TabContent activeTab="notifications">
                  <TabPane tabId="notifications">
                    {loading ? (
                      <Loader />
                    ) : (
                      items.map((item) => {
                        if (item.type === 'prize-redemption-request-rejected') {
                          return <PrizeRedemptionRequestRejected notification={item} />;
                        } else if (item.type === 'invited-to-group') {
                          return <InvitedToGroup notification={item} />;
                        } else {
                          return <Generic notification={item} />;
                        }
                      })
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { notifications } = state.profile;
  return { notifications };
};

export default connect(mapStateToProps, { fetchNotifications })(ProfileNotificationsPage);
