import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import CompetitionCard from './CompetitionCard';
import { fetchCompetitions } from '../../redux/competitions/thunks';
import ReferringCard from '../ReferringCard';
import { isUserAuthenticated } from '../../helpers/auth';
import { useMediaQuery } from 'react-responsive';

const CompetitionList = (props) => {
  const { competitions } = props;
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1224px)',
  });

  const onLoadMore = () => {
    props.fetchCompetitions(props.type, props.list, props.filters);
  };

  return (
    <div className="competition-list">
      <div className="row align-items-center">
        {competitions.items.map((competitionId, index) => {
          const counter = index + 1;
          const pos = isMobile ? 3 : 5;

          return (
            <React.Fragment key={`wrapper-${index}`}>
              {isUserAuthenticated() && counter / pos === 1 && (
                <Col md={6} lg={4} key={`${props.type}-referring-card-${index}`}>
                  <ReferringCard />
                </Col>
              )}

              <Col md={6} lg={4} key={`${props.type}-col-${competitionId}`}>
                <CompetitionCard competition={props.entities[competitionId]} key={competitionId} />
              </Col>
            </React.Fragment>
          );
        })}
      </div>

      {competitions.page < competitions.lastPage && (
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Button color="secondary" className="btn-block btn-load-more" onClick={onLoadMore}>
              <FormattedMessage id="Load More" />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { entities } = state.competitions;
  return { entities };
};

export default connect(mapStateToProps, { fetchCompetitions })(CompetitionList);
