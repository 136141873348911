import styled from 'styled-components';

export const HowToImg = styled.img`
  width: 5rem;
  max-width: 100%;
`;

export const WinnerImg = styled.img`
  max-width: 100%;
`;

export const CEOImg = styled.img`
  width: 100%;
`;

export const StyledICTLogoImg = styled.img`
  width: 150px;
`;

export const CompetitionImg = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 0.5rem;
  object-fit: cover;
  background-color: #fff;
  border-radius: 50%;
`;

export const SeasonBg = styled.div`
  content: '';
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
`;

export const FooterHeader = styled.h3`
  color: #656565;
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
`;

export const ArticleProvider = styled.img`
  height: 50px;
`;
