import React from 'react';
import withPage from '../hoc/withPage';
import MatchesPage from '../pages/matches';
import SearchPage from '../pages/search';
import PredictionsPage from '../pages/predictions';
import FantasyPage from '../pages/fantasy';
import CompetitionPage from '../pages/competitions/show';
import ProfileCompetitionsPage from '../pages/profile/competitions';
import ProfilePredictionsPage from '../pages/profile/predictions';
import ProfileWalletPage from '../pages/profile/wallet';
import ProfileNotificationsPage from '../pages/profile/notifications';
import ProfileFriendsPage from '../pages/profile/friends';
import PrizesPage from '../pages/profile/prizes';
import OrdersPage from '../pages/profile/orders';
import PricingPage from '../pages/pricing';
import PredictionPage from '../pages/prediction';
import VoucherPage from '../pages/voucher';
import PrivateRoute from './PrivateRoute';
import { Redirect, Route } from 'react-router';
import PaymentPage from '../pages/payment';
import AboutUsPage from '../pages/about-us/';
import GroupsListPage from '../pages/groups/GroupsListPage';
import GroupDetailsPage from '../pages/groups/GroupDetailsPage';
import StorePage from '../pages/store/';
import CCPaymentStatusPage from '../pages/cc-payment-status';
import { DEFAULT_LANDING_PAGE } from '../constants/generic';

export default [
  {
    path: '/lobby',
    name: 'lobby',
    component: () => <Redirect to={DEFAULT_LANDING_PAGE} />,
    route: Route,
    exact: true,
  },
  {
    path: '/matches',
    name: 'matches',
    component: withPage(MatchesPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: withPage(PricingPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/search',
    name: 'search',
    component: withPage(SearchPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/predictions/:matchId',
    name: 'prediction',
    component: withPage(PredictionPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/predictions',
    name: 'predictions',
    component: withPage(PredictionsPage),
    route: Route,
    exact: true,
  },
  {
    path: '/fantasy',
    name: 'fantasy',
    component: withPage(FantasyPage),
    route: Route,
    exact: true,
  },
  {
    path: '/competitions/:id',
    name: 'competitions.show',
    component: withPage(CompetitionPage),
    route: Route,
  },
  {
    path: '/competitions/:id/*',
    name: 'competitions.show.pages',
    component: withPage(CompetitionPage),
    route: Route,
  },
  {
    path: '/profile',
    name: 'profile.competitions',
    component: withPage(ProfileCompetitionsPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/predictions',
    name: 'profile.predictions',
    component: withPage(ProfilePredictionsPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/friends',
    name: 'profile.friends',
    component: withPage(ProfileFriendsPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/wallet',
    name: 'profile.wallet',
    component: withPage(ProfileWalletPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/notifications',
    name: 'profile.notifications',
    component: withPage(ProfileNotificationsPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/prizes',
    name: 'me.prizes',
    component: withPage(PrizesPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/profile/orders',
    name: 'me.orders',
    component: withPage(OrdersPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: withPage(VoucherPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/payment',
    name: 'payment',
    component: withPage(PaymentPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/payment/cc/status',
    name: 'payment.cc.status',
    component: withPage(CCPaymentStatusPage),
    route: PrivateRoute,
  },
  {
    path: '/about-us',
    name: 'about_us',
    component: withPage(AboutUsPage),
    route: Route,
    exact: true,
  },
  {
    path: /\/groups(\/all)?(\/create)?/,
    name: 'groups',
    component: withPage(GroupsListPage),
    route: Route,
    exact: true,
  },
  {
    path: '/groups/:id/:activeTab?/:isCreate?',
    name: 'groups.details',
    component: withPage(GroupDetailsPage),
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/store',
    name: 'store',
    component: withPage(StorePage),
    route: PrivateRoute,
    exact: true,
  },
];
