import React, { useEffect, useState } from 'react';
import { Container, Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { clearCompetition } from '../../redux/competitions/actions';
import { fetchCompetition } from '../../redux/competitions/thunks';
import { FormattedMessage } from 'react-intl';
import withMediaQuery from '../../hoc/withMediaQuery';
import Overview from './components/Overview';
import CompetitionTeamSetup from './components/CompetitionTeamSetup';
import Matches from './components/Matches';
import CompetitionRanking from './components/CompetitionRanking';
import CompetitionRulesAr from './components/CompetitionRulesAr';
import CompetitionRulesEn from './components/CompetitionRulesEn';
import { locale } from '../../helpers/generic';
import { Route, Switch } from 'react-router';
import JoinCompetition from './components/JoinCompetition';
import TabLink from '../../components/TabLink';
import { COMPETITION_TYPE_FANTASY, COMPETITION_TYPE_PREDICTIONS } from '../../constants/generic';
import FantasyCompetitionRulesAr from './components/FantasyCompetitionRulesAr';
import FantasyCompetitionRulesEn from './components/FantasyCompetitionRulesEn';

export const CompetitionContext = React.createContext({
  openJoiningModal: false,
  setOpenJoiningModal: () => {},
});

const CompetitionPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [openJoiningModal, setOpenJoiningModal] = useState(false);

  const { competition: competitionDescriptor } = props;
  const competition = props.entities[competitionDescriptor.id];

  useEffect(() => {
    props.fetchCompetition(props.match.params.id).then(() => setLoading(false));

    return () => {
      props.clearCompetition();
    };
  }, []);

  useEffect(() => {
    if (competition) {
      props.setDocumentTitle(competition.name);
    }
  }, [competition]);

  let path = null;
  if (competition) {
    path = `/competitions/${competition.id}`;
  }

  return (
    <React.Fragment>
      {!loading && (
        <CompetitionContext.Provider value={{ openJoiningModal, setOpenJoiningModal }}>
          <Container fluid={true}>
            <div className="page page-competition">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="competition-name">{competition.name}</h2>
                </div>

                <div className="col-md-6">
                  <JoinCompetition competition={competition} />
                </div>
              </div>

              <div className="competition-main-content mb-3">
                <Nav tabs>
                  <NavItem>
                    <TabLink to={`${path}`} exact={true}>
                      <FormattedMessage id="Overview" />
                    </TabLink>
                  </NavItem>
                  {competition?.type === COMPETITION_TYPE_FANTASY && (
                    <NavItem>
                      <TabLink to={`${path}/team-setup`} exact={true}>
                        <FormattedMessage id="CompetitionPage.TeamSetup" />
                      </TabLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <TabLink to={`${path}/matches`} exact={true}>
                      <FormattedMessage id="Matches" />
                    </TabLink>
                  </NavItem>
                  <NavItem>
                    <TabLink to={`${path}/ranking`} exact={true}>
                      <FormattedMessage id="Ranking" />
                    </TabLink>
                  </NavItem>
                  <NavItem>
                    <TabLink to={`${path}/rules`} exact={true}>
                      <FormattedMessage id="Rules" />
                    </TabLink>
                  </NavItem>
                </Nav>
                <Switch>
                  <Route path={`${path}`} exact>
                    <Overview competition={competition} />
                  </Route>
                  {competition?.type === 2 && (
                    <Route path={`${path}/team-setup`} exact>
                      <CompetitionTeamSetup
                        isEnabled={!!competition?.joined}
                        competition={competition}
                      />
                    </Route>
                  )}
                  <Route path={`${path}/matches/:gameId?`}>
                    <Matches
                      competitionDescriptor={competitionDescriptor}
                      competition={competition}
                    />
                  </Route>
                  <Route path={`${path}/ranking`}>
                    <CompetitionRanking competition={competition} />
                  </Route>
                  <Route path={`${path}/rules`}>
                    {locale() === 'ar' ? (
                      competition.type === COMPETITION_TYPE_PREDICTIONS ? (
                        <CompetitionRulesAr competition={competition} />
                      ) : (
                        <FantasyCompetitionRulesAr competition={competition} />
                      )
                    ) : competition.type === COMPETITION_TYPE_PREDICTIONS ? (
                      <CompetitionRulesEn competition={competition} />
                    ) : (
                      <FantasyCompetitionRulesEn competition={competition} />
                    )}
                  </Route>
                </Switch>
              </div>
            </div>
          </Container>
        </CompetitionContext.Provider>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { entities, competition } = state.competitions;
  return { entities, competition };
};

export default connect(mapStateToProps, {
  fetchCompetition,
  clearCompetition,
})(withMediaQuery(CompetitionPage));
