import Text from '../Text';
import NumberFormat from 'react-number-format';
import React from 'react';

const PlayerPoints = ({ points }) => {
  return (
    <Text fontWeight="bold" textAlign="center" display="block" lineHeight="1.5">
      <NumberFormat
        value={points}
        displayType={'text'}
        thousandSeparator={false}
        decimalScale={0}
      />
    </Text>
  );
};

export default PlayerPoints;
