// @vendors
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { differenceBy } from 'lodash';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
// @components
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import CompetitionPlayerCard from '../../../../components/competitions/CompetitionPlayerCard';
// @styles
import { CloseIcon } from './styles';
// @static
import closeIcon from '../../../../assets/images/icon-close.svg';
import modalHeaderImg from '../../../../assets/images/lineup-player-select-header.jpg';
// @redux
import { fetchPlayers } from '../../../../redux/players/thunks';

const CompetitionLineupPlayerSelector = ({
  competitionId,
  position,
  value,
  selectedPlayers,
  onSelect,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [playersList, setPlayersList] = useState([]);
  const [playersMeta, setPlayersMeta] = useState({
    current_page: 0,
    last_page: Infinity,
  });

  const handleGetPlayers = () => {
    // Get current position players from api
    if (competitionId && position && playersMeta?.current_page < playersMeta?.last_page) {
      dispatch(
        fetchPlayers({
          competition: competitionId,
          position,
          page: playersMeta.current_page + 1,
        })
      ).then((res) => {
        if (res?.data && res?.meta) {
          setPlayersList([...playersList, ...differenceBy(res.data, selectedPlayers, 'id')]);
          setPlayersMeta({
            ...playersMeta,
            current_page: res?.meta?.current_page,
            last_page: res?.meta?.last_page,
          });
        }
      });
    }
  };

  useEffect(() => {
    handleGetPlayers();
  }, [competitionId, position]);

  return (
    <Box display="flex" flexDirection="column" width="100%" {...rest}>
      <Box
        position="relative"
        display="flex"
        width="100%"
        height="7.5rem"
        maxHeight="100vh"
        justifyContent="center"
        alignItems="center"
        backgroundImage={`url('${modalHeaderImg}')`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        p="0 1rem"
      >
        {position && (
          <Text fontSize="1.75rem" fontWeight="500" color="#fff">
            <FormattedMessage id={`CompetitionLineupPlayerSelector.Title.${position}`} />
          </Text>
        )}
        <CloseIcon src={closeIcon} onClick={onClose} />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        overflowY="auto"
        overflowX="hidden"
        height="Calc(100% - 7.5rem)"
        p="0 0.625rem"
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={handleGetPlayers}
          hasMore={playersMeta?.current_page < playersMeta?.last_page}
          useWindow={false}
          initialLoad={false}
        >
          {playersList.map((player, index) => (
            <CompetitionPlayerCard
              key={`${player?.id}_${index}`}
              name={player?.common_name}
              image={player?.image?.original}
              number={player?.number}
              price={player?.price}
              metrics={player?.stats}
              teamImg={player?.team?.logo?.original}
              selected={player?.id === value?.id}
              onSelect={() => onSelect(position, player)}
              lastItem={index === playersList?.length - 1}
              mt="1.625rem"
            />
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

CompetitionLineupPlayerSelector.propTypes = {
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.shape({}),
  selectedPlayers: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

CompetitionLineupPlayerSelector.defaultProps = {
  value: {},
  selectedPlayers: [],
  onSelect: () => {},
  onClose: () => {},
};

export default CompetitionLineupPlayerSelector;
